import { PencilSimple, Trash } from "phosphor-react";
import { useState } from "react";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import Typography from "../../components/Typography";
import { ApiProposalMilestone } from "../../models/api/proposal";
import Card from "../Card";
import DialogBox, { useDialogBoxState } from "../DialogBox";
import { ProposalMilestoneFormDialog } from "./ProposalMilestoneFormDialog";
import { ProposalMilestoneFormValues } from "./ProposalMilestoneFormValues";

type ProposalMilestoneCardProps = {
    milestone: ApiProposalMilestone
    disabledDelete?: boolean
    onRemove: (milestoneId: string) => Promise<unknown>
    onEdit: (milestone: ProposalMilestoneFormValues) => Promise<GigApiFetcherResponse<unknown>>
    isTimeAndMaterial: boolean
    dailyRate: number | null
}

const ProposalMilestoneCard: React.FC<ProposalMilestoneCardProps> = ({
    milestone,
    disabledDelete,
    onRemove,
    onEdit,
    isTimeAndMaterial,
    dailyRate
}) => {
    const dialogState = useDialogBoxState();
    const [isRemoving, setIsRemoving] = useState(false);

    const handleDelete = async () => {
        setIsRemoving(true);

        try {
            await onRemove(milestone.id);
        }
        finally {
            setIsRemoving(false);
        }
    };

    return (
        <>
            <Card className="space-y-4 sm:flex sm:space-y-0 sm:space-x-6 sm:items-center sm:justify-between sm:text-left bg-surface-child-card">
                <div className="grow">
                    <Typography variant="title-small" component="h3" gutterBottom>{milestone.description}</Typography>
                    {isTimeAndMaterial && <Typography variant="body" component="p">{milestone.numberOfDays + " day(s)"}</Typography>    }
                    <Typography variant="body" component="p">£{isTimeAndMaterial ?  milestone.amount + " (Total amount)" : milestone.amount }</Typography>                    
                </div>
                <div className="flex justify-end space-x-6">
                    <button onClick={handleDelete} disabled={disabledDelete} type="button">
                        <Trash size={24} weight="fill" className={(isRemoving || disabledDelete) ? "text-disabled-text" : "text-primary-text"} />
                    </button>
                    <button onClick={dialogState.open} disabled={isRemoving} type="button">
                        <PencilSimple size={24} weight="fill" className={isRemoving ? "text-disabled-text" : "text-primary-text"} />
                    </button>
                </div>
            </Card>
            <DialogBox {...dialogState} title="Edit Proposal Milestone">
                <ProposalMilestoneFormDialog
                    {...dialogState}
                    isEdit
                    onSubmit={onEdit}
                    initialValues={milestone}
                    isTimeAndMaterial={isTimeAndMaterial}
                    dailyRate={dailyRate}
                />
            </DialogBox>
        </>
    );
};

export default ProposalMilestoneCard;