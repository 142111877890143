import * as yup from "yup";

export const validationSchemaFields = {
    payoneerEmail: yup.string()
        .email("Must be a valid email e.g. john.smith@yahoo.com")
        .required("Email is required."),
};

export type TalentUpdatePayoutAccountFormValues = {
    payoneerEmail: string
};