import { useEffect, useState } from "react";

import { useTalentPersonalInformationContext } from "../../api/talentPersonalInformation";
import Button from "../../components/Button";
import FormImageUploadInput, { useFormFileUploadInputState } from "../../components/FormFileUploadInput";
import FormTextAreaInput from "../../components/FormTextAreaInput";
import FormTextInput from "../../components/FormTextInput";
import { talentProfileEditTooltips } from "../../tooltipsContent";
import { successToast } from "../../toast";
import { TalentPersonalDetailsFormValues, usePersonalDetailsForm } from "./TalentPersonalDetailsFormValues";
import FormCheckbox from "../../components/FormCheckbox";
import { Controller } from "react-hook-form";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import FormDropdown, { FormDropdownOption } from "../../components/FormDropdown";
import useAccessToken from "../../auth/useAccessToken";
import { fetchLocationOptions } from "../../api/locations";

const TalentPersonalDetailsForm = () => {
    const accessToken = useAccessToken();
    const { gigTerminology, gigTerminologyPlural, giggedClientTerminologyPlural, organizationConfig } = useOrganizationContext();
    const [allLocationOptions, setAllLocationOptions] = useState<FormDropdownOption<string>[]>([]);
    const [isLoadingLocationOptions, setIsLoadingLocationOptions] = useState(false);

    const [isPersonalInformationLoaded, setIsPersonalInformationLoaded] = useState(false);

    const {
        talentPersonalInformation,
        updatePersonalInformation,
        isUpdatingPersonalInformation,
        isLoading,
        isValidating,
    } = useTalentPersonalInformationContext();
    const methods = usePersonalDetailsForm(organizationConfig.isPaymentsEnabled);

    const {
        urlOrDataUri: profileImageDataUri,
        loadFile,
        error: profileImageError,
        isDirty: isProfileImageDirty,
        reset: resetImageInput,
        ...fileInputProps
    } = useFormFileUploadInputState({
        maxFileSizeKb: 1000,
        initialUrl: talentPersonalInformation?.profileImageUrl,
    });

    const { reset } = methods;

    useEffect(() => {
        if (isPersonalInformationLoaded) return;

        reset({
            ...talentPersonalInformation,
            tradingName: talentPersonalInformation?.tradingName || "",
            locationId: talentPersonalInformation?.location?.id,
            dailyRate: talentPersonalInformation?.dailyRate?.toString() || "",
        });

        if (talentPersonalInformation) {
            setIsPersonalInformationLoaded(true);
        }
    }, [reset, talentPersonalInformation, isPersonalInformationLoaded]);

    useEffect(() => {
        if (!accessToken || !organizationConfig.isPaymentsEnabled) return;

        const loadAllLocations = async () => {
            setIsLoadingLocationOptions(true);
            try {
                const fetchedLocationOptions = await fetchLocationOptions("", accessToken);
                setAllLocationOptions(fetchedLocationOptions);
            }
            finally {
                setIsLoadingLocationOptions(false);
            }
        };

        loadAllLocations();
    }, [accessToken]);

    const handleSaveChanges = async (values: TalentPersonalDetailsFormValues) => {
        const response = await updatePersonalInformation({
            ...values,
            tradingName: values.tradingName || null,
            profileImageDataUri: isProfileImageDirty ? profileImageDataUri : undefined,
            dailyRate: organizationConfig.isPaymentsEnabled ?
                values.dailyRate ? parseFloat(values.dailyRate) : 0 :
                null,
        });

        if (response.success) {
            resetImageInput();
            reset({
                ...values,
                tradingName: values?.tradingName || "",
                locationId: values?.locationId,
                dailyRate: values?.dailyRate?.toString(),
            });
            successToast("Personal information successfully updated.");
        }
    };

    if (isLoading || isValidating) {
        return <p>Loading...</p>;
    }

    return (
        <form className="space-y-6" onSubmit={methods.handleSubmit(handleSaveChanges)}>
            <div className="md:flex space-y-6 md:space-x-6 md:space-y-0">
                <FormTextInput
                    required
                    label="First name"
                    placeholder="Enter first name"
                    error={methods.formState.errors.firstName}
                    {...methods.register("firstName")}
                />
                <FormTextInput
                    required
                    label="Last name"
                    placeholder="Enter last name"
                    error={methods.formState.errors.lastName}
                    {...methods.register("lastName")}
                />
            </div>
            <div className="md:flex space-y-6 md:space-x-6 md:space-y-0">
                <FormTextInput
                    label="Trading name"
                    tooltip={talentProfileEditTooltips.tradingName(giggedClientTerminologyPlural)}
                    placeholder="Enter trading name"
                    error={methods.formState.errors.tradingName}
                    {...methods.register("tradingName")}
                />
                <FormTextInput
                    required
                    tooltip={talentProfileEditTooltips.roleTitle(giggedClientTerminologyPlural)}
                    label="Role title"
                    placeholder="Enter role title"
                    error={methods.formState.errors.roleTitle}
                    {...methods.register("roleTitle")}
                />
            </div>
            <FormTextAreaInput
                required
                tooltip={talentProfileEditTooltips.aboutMe}
                id="personal-information-about-me"
                label="About me"
                placeholder="Write something here to describe yourself, your skills and your experience."
                register={methods.register("aboutMe")}
                error={methods.formState.errors.aboutMe}
            />
            {organizationConfig.isPaymentsEnabled && allLocationOptions.length > 0 && (          
                <div className="md:flex space-y-6 md:space-x-6 md:space-y-0">
                    <div className="w-full md:w-1/2">
                        <Controller
                            name="locationId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDropdown
                                    required
                                    label="Where are you in the world?"
                                    value={value || ""}
                                    options={allLocationOptions}
                                    disabled={isValidating || isLoading}
                                    onChange={onChange}
                                    error={methods.formState.errors.locationId}
                                    isLoadingOptions={isLoadingLocationOptions}
                                />
                            )}
                        />
                    </div>
                    <div className="w-full md:w-1/2">
                        <FormTextInput
                            required
                            type="number"
                            step="any"
                            label="Your daily rate (£)"
                            placeholder="Enter your daily rate (£)"
                            error={methods.formState.errors.dailyRate}
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            {...methods.register("dailyRate")}
                        />
                    </div>
                </div>
            )}
            <div className="md:flex space-y-6 md:space-x-6 md:space-y-0">
                <Controller
                    name="receiveNewGigNotifications"
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                        <FormCheckbox
                            label={`Receive notifications for matched ${gigTerminologyPlural.toLowerCase()}`}
                            tooltip={talentProfileEditTooltips.receiveNewGigNotifications(gigTerminology)}
                            checked={value || false} 
                            onChange={onChange}
                        />
                    )}
                />
            </div>
            <FormImageUploadInput
                label="Profile Photo"
                helpText="Recommended min size: 300x300px"
                inputId="profile-image-upload-button"
                onLoadFile={loadFile}
                error={profileImageError}
                {...fileInputProps}
                dataUrl={profileImageDataUri}
                isDirty={isProfileImageDirty}
                previewImgClassName="object-cover"
            />
            <div className="md:flex mt-8">
                <Button
                    className="md:ml-auto w-full md:w-auto"
                    loading={isUpdatingPersonalInformation || isValidating}
                    disabled={(!methods.formState.isDirty && !isProfileImageDirty) || !!profileImageError}
                    type="submit"
                >
                    Save changes
                </Button>
            </div>
        </form>
    );
};

export default TalentPersonalDetailsForm;
