import { ApiTalentCareerHistoryJobDto, ApiUpdateTalentCareerHistoryJobDto } from "../api/talentCareerHistory";
import { TalentCareerHistoryJobDto, UpdateTalentCareerHistoryJobDto } from "../app/talentCareerHistory";

export const mapFromApiTalentCareerHistory = (apiDto: ApiTalentCareerHistoryJobDto): TalentCareerHistoryJobDto => ({
    ...apiDto,
    startDate: new Date(apiDto.startDate),
    endDate: apiDto.endDate ? new Date(apiDto.endDate) : undefined,
});

export const mapToApiUpdateTalentCareerHistoryJob = (dto: UpdateTalentCareerHistoryJobDto): ApiUpdateTalentCareerHistoryJobDto => {
    return {
        ...dto,
        startDate: dto.startDate.toISOString(),
        endDate: dto.endDate ? dto.endDate.toISOString() : undefined,
    };
};