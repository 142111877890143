import { useNavigate } from "react-router-dom";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { useGig } from "../api/gig";
import { useProposals } from "../api/proposals";
import CreateProposalForm, { CreateProposalFormValues } from "../components/CreateProposalForm";
import MissingGigSkillsInfoBox from "../components/MissingGigSkillsInfoBox";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { ApiFile } from "../models/api/file";
import { useAppPaths, useGigId } from "../Routes";
import { DEFAULT_PROPOSAL_MILESTONE } from "../utils/constants";
import { successToast } from "../toast";
import ProposalGigSummaryCard from "../components/ProposalGigSummaryCard";

const ProposalCreatePage = () => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const { createProposal, isCreatingProposal } = useProposals();
    const gigId = useGigId();
    const { gig } = useGig(gigId as string);
    const { gigTerminology, organizationConfig } = useOrganizationContext();

    if (!gigId || !gig) return null;
    
    const onSubmit = async (
        values: CreateProposalFormValues, 
        proposalFiles: ApiFile[],
    ): Promise<GigApiFetcherResponse<string>> => {
        const proposalMilestones = values.talentDefinedMilestones ? values.milestones : [
            {
                description: DEFAULT_PROPOSAL_MILESTONE,
                amount: values.proposedAmount,
                numberOfDays: gig.isTimeAndMaterial ? values.estimatedDurationDays : null,
            }
        ];
        
        const response = await createProposal({ 
            ...values, 
            gigId, 
            fileDataUris: proposalFiles.map(proposalFile => proposalFile.url),
            milestones: values.paymentsEnabled ? proposalMilestones : []
        });        

        if (response.success) {
            successToast("Proposal submitted successfully.");
            navigate(appPaths.gigs.findAGigDetails(gigId));
        }

        return response;
    };
    
    return (
        <PageContent>
            <MissingGigSkillsInfoBox gigId={gigId} />
            <ProposalGigSummaryCard gig={gig} gigTerminology={organizationConfig.gigTerminology} isPaymentsEnabled={organizationConfig.isPaymentsEnabled} isWorksome={gig.isWorksome}/>
            <Typography variant="display-medium" component="h1" gutterBottom>{`${gigTerminology} Proposal`}</Typography>
            <CreateProposalForm
                isSubmitting={isCreatingProposal}
                onSubmit={onSubmit}
                submitButtonText="Submit proposal"
                gigBudget={gig.budget}
                isWorksomeGig={gig.isWorksome}
                isTimeAndMaterial={gig.isTimeAndMaterial}
            />
        </PageContent>
    );
};

export default ProposalCreatePage;