import Button from "./Button";
import Typography from "./Typography";

export type MarketingBannerProps = {
    title: string
    body: string
    howGiggedWorksUrl: string
    signUpUrl: string
};


const MarketingBanner = ({
    title,
    body,
    howGiggedWorksUrl,
    signUpUrl
}: MarketingBannerProps) => {

    return (
        <div className="!bg-secondary w-full text-center p-6 sm:py-16 sm:px-24 flex flex-col gap-8">
            <Typography
                className="text-white display-small sm:display-medium"
                variant="display-medium"
                component="h1"
            >
                {title}
            </Typography>
            <p className="text-white text-[1.375rem]">{body}</p>
            <div className="w-full flex flex-col justify-center md:flex-row gap-6">
                <a
                    className="w-full md:w-1/3"
                    href={howGiggedWorksUrl}
                >
                    <Button
                        className="border-surface text-surface hover:text-primary-text w-full"
                        variant="secondary"
                    >
                        See how Gigged.AI works
                    </Button>
                </a>
                <a className="w-full md:w-1/3" href={signUpUrl}>
                    <Button
                        className="w-full "
                        variant="primary"
                    >
                        Sign up for free
                    </Button>
                </a>
            </div>
        </div>
    );
};

export default MarketingBanner;