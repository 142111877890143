import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export type MilestonePurchaseOrderFormValues = {
    purchaseOrderNumber: string
    invoiceCompany: string
    invoiceEmail: string
}

export const milestonePurchaseOrderFormSchema = {
    purchaseOrderNumber: yup.string()
        .required("Purchase order number is required."),
    invoiceCompany: yup.string()
        .required("Company is required."),
    invoiceEmail: yup.string()
        .required("Email is required.")
        .email("Must be a valid email.")
};

const validationSchema = yup.object(milestonePurchaseOrderFormSchema);

export const useMilestonePurchaseOrderForm = (
    defaultValues?: MilestonePurchaseOrderFormValues
) => useForm<MilestonePurchaseOrderFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
        purchaseOrderNumber: defaultValues?.purchaseOrderNumber ?? "",
        invoiceCompany: defaultValues?.invoiceCompany ?? "",
        invoiceEmail: defaultValues?.invoiceEmail ?? ""
    }
});