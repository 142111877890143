import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const educationFormValidationSchema = {
    courseTitle: yup.string()
        .required("Course title is required.")
        .max(100, "The maximum length for course title is 100 characters."),
    instituteName: yup.string()
        .required("Institution name is required.")
        .max(100, "The maximum length for institution name is 100 characters."),
    startDate: yup.date()
        .required("Start date is required.")
        .nullable()
        .typeError("Start date must be a valid date.")
        .max(new Date(), "Start date can't be in the future."),
    endDate: yup.date()
        .test("end-greater-than-start", (value, context) => {
            if (!value) return true;

            if (value <= context.parent.startDate) {
                return context.createError({ message: "End date can't be earlier than start date." });
            }

            return true;
        })
        .nullable()
        .optional()
        .typeError("End date must be a valid date.")
        .max(new Date(), "End date can't be in the future. If you are currently working here, leave this field blank."),
    educationLevelId: yup.string().required("Education level is required."),
    courseDescription: yup.string()
        .required("Course description is required."),
};

export type EducationFormValues = {
    courseTitle: string
    instituteName: string
    educationLevelId: string
    startDate: Date | null
    endDate: Date | null
    courseDescription: string
}

const validationSchema = yup.object(educationFormValidationSchema);

export const useEducationForm = (defaultValues?: EducationFormValues) => {
    return useForm<EducationFormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            courseTitle: defaultValues?.courseTitle || "",
            instituteName: defaultValues?.instituteName || "",
            educationLevelId: defaultValues?.educationLevelId || "",
            startDate: defaultValues?.startDate || null,
            endDate: defaultValues?.endDate || null,
            courseDescription: defaultValues?.courseDescription || "",
        }
    });
};

