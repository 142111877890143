import { TalentSkillDto } from "../../models/app/talentSkill";
import Chip, { ChipContainer } from "../../components/Chip";

export type TalentSkillChipsProps = {
    skills: TalentSkillDto[],
    removeSkill: (skillId: string) => void,
    isRemovingSkill: boolean
}

const TalentSkillChips = ({
    skills,
    removeSkill,
    isRemovingSkill,
}: TalentSkillChipsProps) => {
    if (isRemovingSkill) return (
        <p className="mt-2">Loading...</p>
    );

    return (
        <div className="flex flex-col space-y-2 pt-4">
            <span>
                {skills.length < 3 && <p className="italic">Minimum 3 skills required</p>}
            </span>
            <ChipContainer>
                {skills
                    .sort((a, b) => (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()))
                    .map(talentSkill => (
                        <Chip
                            key={`talent-skill-${talentSkill.skillId}`}
                            label={`${talentSkill.skillName} (${talentSkill.yearsExperience < 11 ? talentSkill.yearsExperience : "11+"} ${talentSkill.yearsExperience === 1 ? "Year" : "Years"})`}
                            onDelete={() => removeSkill(talentSkill.skillId)}
                        />
                    ))
                }
                {Array.from({ length: 3 - skills.length }).map((_, index) => (
                    <Chip
                        key={`placeholder-${index}`}
                        label={`Skill ${index + skills.length + 1}`}
                        variant="secondary"
                        className="!px-12"
                        placeholder
                    />
                ))}
            </ChipContainer>
        </div>
    );
};

export default TalentSkillChips;