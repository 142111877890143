import clsx from "clsx";
import { ControlProps, GroupBase } from "react-select";

const FormSearchDropdownControl = ({ 
    selectProps,
    innerRef,
    menuIsOpen,
    innerProps,
    children,
    isDisabled
}: ControlProps<unknown, boolean, GroupBase<unknown>>) => (
    <div
        ref={innerRef}
        className={clsx(
            "flex flex-row border relative w-full cursor-default rounded-xl bg-white p-3 pr-10 text-left sm:text-sm focus:outline-none focus:ring-inset focus:ring-2 focus:ring-primary-text",
            menuIsOpen && !selectProps.error && "rounded-b-none border-b-0 z-50 ring-inset ring-2 ring-primary-text",
            selectProps.error && "outline-error border-error ring-error ring-0 outline outline-2 pr-10",
            menuIsOpen && selectProps.error && "border-b",
            isDisabled && "border-disabled"
        )
        }
        {...innerProps}
    >
        {children}
    </div>
);

export default FormSearchDropdownControl;