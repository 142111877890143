import { Divider } from "@mui/material";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { useInvoice } from "../api/invoice";
import useCurrentUser from "../auth/useCurrentUser";
import Card from "../components/Card";
import MoneyDisplay from "../components/MoneyDisplay";
import OrganizationLogo from "../components/OrganizationLogo";
import Typography from "../components/Typography";
import { useInvoiceId } from "../Routes";
import formatDateOnlyString from "../utils/formatDateString";
import Loader from "../components/Loader";

const InvoicePage = () => {
    const { userRole } = useCurrentUser();
    const id = useInvoiceId();
    const { invoice, isLoading } = useInvoice(id);
    const { gigTerminology } = useOrganizationContext();

    if (isLoading || !invoice) {
        return <Loader />;
    }

    let invoiceAmount = 0;
    let addedVat = 0;

    if (invoice.isVatRegistered) {
        addedVat = invoice.amount / 6;
        invoiceAmount = invoice.amount - addedVat;
    }else{
        invoiceAmount = invoice.amount;
    }

    const clientServiceCharge = invoiceAmount * (invoice.clientServiceFeePercentage / 100);
    const clientServiceChargeTax = clientServiceCharge * (invoice.vatRate / 100);
    const talentServiceCharge = -invoiceAmount * (invoice.talentServiceFeePercentage / 100);
    const talentServiceChargeTax = talentServiceCharge * (invoice.vatRate / 100);

    const total = userRole === "client" ?
        invoiceAmount + clientServiceCharge + clientServiceChargeTax :
        invoiceAmount + talentServiceCharge + talentServiceChargeTax;

    return (
        <Card className="max-w-4xl mx-auto space-y-4 mt-8 overflow-x-auto rounded-none md:rounded-lg">
            <div className="flex">
                <OrganizationLogo />
                <div>
                    <Typography className="font-bold" variant="title-medium" component="h2">Invoice</Typography>
                    <Typography className="text-right" variant="title-small" component="p">#{invoice.id}</Typography>
                </div>
            </div>            
            <div className="flex items-end">
                <div className="grow">
                    <Typography className="block font-bold mb-2" variant="title-small" component="label">{gigTerminology} title:</Typography>
                    <Typography className="font-bold" variant="title-medium" component="label">{invoice.gigTitle}</Typography>
                </div>
                <div>
                    <Typography variant="body" component="p">Issue Date: {formatDateOnlyString(invoice.createdDate.toISOString())}</Typography>
                </div>
            </div>
            <Divider className="py-4" />
            <div className="flex p-4">
                <div className="w-1/2">
                    <Typography className="font-bold" variant="body" component="p">To:</Typography>
                    <Typography variant="body" component="p">{invoice.clientName}</Typography>
                </div>
                <div className="w-1/2">
                    <Typography className="font-bold" variant="body" component="p">From:</Typography>
                    <Typography variant="body" component="p">{invoice.talentName}</Typography>
                </div>
            </div>
            <div className="overflow-x-auto">
                <table className="w-full">
                    <thead>
                        <tr className="text-left bg-white child:px-2 child:py-4 child:text-[grey]">
                            <th>#</th>
                            <th>Description</th>
                            <th>Cost</th>
                            <th>{userRole === "client" ? "Taxes" : "Transaction Fee"}</th>
                            {userRole === "talent" && invoice.isVatRegistered ? <th>VAT</th> : null}
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="child:p-2 child: border-b border-[#AAA]">
                            <td>1</td>
                            <td>milestone: {invoice.milestoneDescription}</td>
                            <td><MoneyDisplay value={invoiceAmount} /></td>
                            <td><MoneyDisplay value={userRole === "client" ? (invoice.isVatRegistered ? addedVat : 0 ) : talentServiceCharge  + talentServiceChargeTax} /></td>
                            {userRole === "talent" && invoice.isVatRegistered ? <td><MoneyDisplay value={addedVat} /></td> : null}
                            <td><MoneyDisplay value={invoice.isVatRegistered ? invoiceAmount + addedVat : invoiceAmount} /></td>
                        </tr>
                        {userRole === "client" && (
                            <tr className="child:p-2">
                                <td>2</td>
                                <td>Service charge</td>
                                <td><MoneyDisplay value={clientServiceCharge} /></td>
                                <td><MoneyDisplay value={clientServiceChargeTax} /></td>
                                <td><MoneyDisplay value={clientServiceCharge + clientServiceChargeTax} /></td>
                            </tr>
                        )}
                        <tr className="child:py-4">
                            <td></td>
                            <td></td>
                            <td></td>
                            {userRole === "talent" && invoice.isVatRegistered ? <td></td> : null}
                            <td>Subtotal:</td>
                            <td>
                                <Typography className="text-left px-2" variant="body" component="p">
                                    <MoneyDisplay className="font-bold" value={invoice.isVatRegistered ? invoiceAmount + addedVat : invoiceAmount} />
                                </Typography>
                            </td>
                        </tr>
                        {userRole === "talent" && (
                            <tr className="child:pb-4">
                                <td></td>
                                <td></td>
                                <td></td>
                                {userRole === "talent" && invoice.isVatRegistered ? <td></td> : null}
                                <td>Transaction fee:</td>
                                <td>
                                    <Typography className="text-left px-2" variant="body" component="p">
                                        <MoneyDisplay className="font-bold" value={talentServiceCharge + talentServiceChargeTax} />
                                    </Typography>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td></td>
                            <td></td>
                            {userRole === "talent" && invoice.isVatRegistered ? <td></td> : null}
                            <td className="bg-primary-text"></td>
                            <td className="bg-primary-text text-white font-bold py-4">Total:</td>
                            <td className="bg-primary-text text-white font-bold py-4">
                                <Typography className="text-left px-2" variant="body" component="p">
                                    <MoneyDisplay className="font-bold" value={invoice.isVatRegistered ? total + addedVat : total} />
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <p className="font-bold text-[grey] text-right pt-4">Thank you for using Gigged AI</p>
        </Card>
    );
};

export default InvoicePage;