import Button from "../Button";
import { formClassNames } from "../CreateGigForm/classNames";
import TalentCreateProfileFormPageLayout from "./TalentCreateProfileFormPageLayout";
import { useIsMediumScreen } from "../../hooks/screenSizes";
import Education from "../../pages/TalentProfileEdit/Education";
import { useTalentEducations } from "../../api/talentEducations";


export type CreateTalentProfileFormStepEducationProps = {
    onSubmit: () => void
    onBackClick: () => void
}

const CreateTalentProfileFormStepEducation = ({
    onSubmit,
    onBackClick,
}: CreateTalentProfileFormStepEducationProps) => {
    const isMediumScreen = useIsMediumScreen();
    const educations = useTalentEducations();

    const skipButtonText = isMediumScreen ? "Skip for now" : "Skip";

    return (
        <div className={formClassNames} >
            <TalentCreateProfileFormPageLayout
                title="Include your education"
                description={"Add any relevant education that complements your skills and specialisation here."}
                content={ <Education educations={educations} /> }
                iconName="GraduationCap"
                buttons={
                    <>
                        <Button variant="secondary" type="button" onClick={onBackClick}>Back</Button>
                        <div className="flex space-x-4 child:flex-grow sm:child:flex-grow-0">
                            <Button 
                                type="button" 
                                onClick={onSubmit} 
                                variant={educations.courses.length > 0 ? "primary" : "tertiary"}
                            >
                                {educations.courses.length > 0 ? "Next" : skipButtonText}
                            </Button>                        
                        </div>
                    </>
                }
            />
        </div>
    );
};

export default CreateTalentProfileFormStepEducation;
