import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { addDays, differenceInBusinessDays, format } from "date-fns";

import Button from "../Button";
import CreateGigFormPageLayout from "./CreateGigFormPageLayout";
import FormDateInput from "../FormDateInput";
import { GigFormValues, buildGigFormValidationSchemaFields } from "../GigForm";
import { formClassNames } from "./classNames";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { getExpectedDurationId } from "../../utils/getExpectedDurationId";
import { useEffect } from "react";
import FormRadioGroup from "../FormRadioGroup";
import { expectedDurationOptions } from "../../models/app/gig";

export type CreateGigFormStepDurationFields = Pick<GigFormValues, "startDate" | "deadlineDate" | "isTimeAndMaterial" | "expectedDurationId" | "numberOfDays">;

export type CreateGigFormStepDurationProps = {
    onSubmit: (value: CreateGigFormStepDurationFields) => void
    onBackClick: (value: CreateGigFormStepDurationFields) => void
    defaultValues: CreateGigFormStepDurationFields
}

const CreateGigFormStepDuration = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateGigFormStepDurationProps) => {
    const { gigTerminology, organizationConfig } = useOrganizationContext();

    const validationSchema: yup.SchemaOf<CreateGigFormStepDurationFields> = yup.object({
        startDate: buildGigFormValidationSchemaFields(organizationConfig.isPaymentsEnabled).startDate,
        deadlineDate: buildGigFormValidationSchemaFields(organizationConfig.isPaymentsEnabled).deadlineDate,
        isTimeAndMaterial: buildGigFormValidationSchemaFields(organizationConfig.isPaymentsEnabled).isTimeAndMaterial,
        expectedDurationId: buildGigFormValidationSchemaFields(organizationConfig.isPaymentsEnabled).expectedDurationId,
        numberOfDays: buildGigFormValidationSchemaFields(organizationConfig.isPaymentsEnabled).numberOfDays,
    });

    const methods = useForm<CreateGigFormStepDurationFields>({
        resolver: yupResolver(validationSchema),
        defaultValues
    });

    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    const startDate = methods.watch("startDate");
    const deadlineDate = methods.watch("deadlineDate");

    const numberOfDays = defaultValues.isTimeAndMaterial && startDate && deadlineDate ? differenceInBusinessDays(deadlineDate, startDate) + 1 : 0;
    const formattedStartDate = startDate instanceof Date && !isNaN(startDate.getTime()) ? format(startDate, "PPP") : "N/A";
    const formattedDeadlineDate = deadlineDate instanceof Date && !isNaN(deadlineDate?.getTime()) ? format(deadlineDate, "PPP") : "N/A";

    useEffect(() => {
        if (defaultValues.isTimeAndMaterial) {
            const expectedDurationId = getExpectedDurationId(numberOfDays);
            methods.setValue("expectedDurationId", expectedDurationId);
        }
        // Ensure numberOfDays is updated in form state if required for submission or further logic
        methods.setValue("numberOfDays", numberOfDays, { shouldValidate: true });
    }, [defaultValues.isTimeAndMaterial, numberOfDays]);

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateGigFormPageLayout
                title={`How long will your ${gigTerminology.toLowerCase()} take?`}
                description={`Give your best estimate of how long you think this ${gigTerminology.toLowerCase()} will take. ${!defaultValues.isTimeAndMaterial ? "If necessary, this can be updated throughout the engagement." : ""}`}
                content={
                    <div className="space-y-6">
                        <div className="w-full flex flex-col md:flex-row justify-between gap-4 md:gap-6">
                            <div className="md:w-1/2">
                                <Controller
                                    name="startDate"
                                    control={methods.control}
                                    render={({ field: { onChange, value } }) => (
                                        <FormDateInput
                                            required
                                            id="create-gig-form-start-date"
                                            label="Start date"
                                            value={value}
                                            onChange={onChange}
                                            error={methods.formState.errors.startDate}
                                            minDate={new Date()}
                                            shouldDisableWeekends={defaultValues.isTimeAndMaterial}
                                        />
                                    )}
                                />
                            </div>
                            <div className="md:w-1/2">
                                <Controller
                                    name="deadlineDate"
                                    control={methods.control}
                                    render={({ field: { onChange, value } }) => (
                                        <FormDateInput
                                            id="create-gig-form-deadline"
                                            label={defaultValues.isTimeAndMaterial ? "Deadline" : "Deadline (optional)"}
                                            value={value || null}
                                            onChange={onChange}
                                            error={methods.formState.errors.deadlineDate}
                                            minDate={startDate ? addDays(startDate as Date, 1) : undefined}
                                            required={defaultValues.isTimeAndMaterial}
                                            shouldDisableWeekends={defaultValues.isTimeAndMaterial}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div>
                            {defaultValues.isTimeAndMaterial ? (
                                <div>
                                    <div className="flex flex-row gap-2">
                                        <div className="font-bold">{"Total number of days (calculated): "}</div>
                                        <div className="text-xl font-semibold">{startDate && deadlineDate ? numberOfDays : 0}</div>
                                        <input type="hidden" name="numberOfDays" value={numberOfDays || undefined}/>
                                    </div>
                                    <p className="text-sm italic mt-2">
                                        Calculation is excluding dates that fall on the weekend.
                                        Calculation is inclusive of both the start date ({formattedStartDate}) and the deadline date ({formattedDeadlineDate}), totaling {numberOfDays} day(s).
                                    </p>
                                </div>

                            ) : (
                                <Controller
                                    name="expectedDurationId"
                                    control={methods.control}
                                    render={({ field: { onChange, value } }) => (
                                        <FormRadioGroup
                                            required
                                            label={`Expected duration of the ${gigTerminology.toLowerCase()}`}
                                            options={expectedDurationOptions}
                                            error={methods.formState.errors.expectedDurationId}
                                            defaultValue={value}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                            )}
                        </div>
                    </div>
                }
                iconName="CalendarCheck"
                buttons={
                    <>
                        <Button onClick={handleBackClick} variant="secondary" type="button">Back</Button>
                        <Button type="submit">Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateGigFormStepDuration;