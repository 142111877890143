import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { GigOrderByFieldKeys, GigSummary } from "../models/app/gig";
import { ApiGigSummary } from "../models/api/gig";
import { mapFromApiGigSummary } from "../models/mappers/gig";

export type UseTalentMyOngoingGigsReturn = {
    gigs: GigSummary[]
    isLoading: boolean
}


export const useTalentOngoingGigs = (gigStatusId: number, orderBy: GigOrderByFieldKeys = "Title"): UseTalentMyOngoingGigsReturn => {
    const url = "api/me/gigs/queries/hired?" +
        `gigStatusId=${gigStatusId}` +
        `&orderBy=${orderBy}`;

    const { data, isLoading } =
        useGiggedApiSWR<ApiGigSummary[]>(url);

    const gigs = data ? data.map(mapFromApiGigSummary) : [];

    return {
        isLoading,
        gigs,
    };
};