import { useNavigate } from "react-router-dom";

import { CreateGigDto, gigStatuses } from "../models/app/gig";
import { useGigs } from "../api/gigs";
import CreateGigForm from "../components/CreateGigForm";
import PageContent from "../components/PageContent";
import { useAppPaths } from "../Routes";

const GigCreatePage = () => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const { createGig } = useGigs();

    const handleSaveAsDraft = async (values: CreateGigDto) => {
        const response = await createGig({
            ...values,
            gigStatusId: gigStatuses.draft,
        });

        if (response.success) {
            navigate(appPaths.gigs.indexDraft);
        }

        return response;
    };

    const handleSaveAndPost = async (values: CreateGigDto) => {
        const response = await createGig({
            ...values,
            gigStatusId: gigStatuses.posted,
        });

        if (response.success) {
            navigate(appPaths.gigs.matches(response.content));
        }

        return response;
    };

    return (
        <PageContent className="pt-8">
            <CreateGigForm onSaveAsDraft={handleSaveAsDraft} onSaveAndPost={handleSaveAndPost} />
        </PageContent>
    );
};

export default GigCreatePage;