import useCurrentUser from "../../auth/useCurrentUser";
import LinkButton from "../../components/LinkButton";
import { useAppPaths } from "../../Routes";
import { GigDetails } from "../../models/app/gig";

export type SubmitProposalButtonProps = {
    gig: GigDetails,
}

const SubmitProposalButton = ({
    gig
} : SubmitProposalButtonProps) => {
    const appPaths = useAppPaths();
    const { userRole } = useCurrentUser();

    if (userRole !== "talent") return null;

    return (
        <LinkButton
            to={appPaths.proposals.create(gig.id)}
            scrollToTop
            className="block"
            disabled={!!gig.submittedProposalId}
        >
            {gig.submittedProposalId ? "Proposal submitted" : "Send proposal"}
        </LinkButton>
    );
};

export default SubmitProposalButton;
