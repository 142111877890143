import { useState } from "react";
import clsx from "clsx";
import { useClientPersonalInformationContext } from "../../api/clientPersonalInformation";
import { useGetMilestoneCheckoutSessionUrl } from "../../api/payments";
import Button from "../../components/Button";
import Card from "../../components/Card";
import Chip from "../../components/Chip";
import { useDialogBoxState } from "../../components/DialogBox";
import MoneyDisplay from "../../components/MoneyDisplay";
import Typography from "../../components/Typography";
import { ProposalMilestoneSummary } from "../../models/app/proposal";
import MilestonePurchaseOrderDialog from "../MilestonePurchaseOrderForm/MilestonePurchaseOrderDialog";
import CompleteMilestoneButton from "./CompleteMilestoneButton";
import ToolTip from "../../components/Tooltip";
import { clientMilestoneTooltips } from "../../tooltipsContent";
import config from "../../config.json";

type ClientMilestonePaymentSectionProps = {
    reloadGig: () => void
    milestones: ProposalMilestoneSummary[]
    gigIsCancelled: boolean
    isVatRegistered: boolean
}

const ClientMilestonePaymentSection = ({
    reloadGig,
    milestones,
    gigIsCancelled,
    isVatRegistered
}: ClientMilestonePaymentSectionProps) => {
    const [getMilestoneCheckoutSessionUrl, isGettingMilestoneCheckoutSessionUrl] = useGetMilestoneCheckoutSessionUrl();
    const paymentOptionDialogState = useDialogBoxState();
    const [selectedMilestoneId, setSelectedMilestoneId] = useState("");

    const {
        clientPersonalInformation,
        isLoading: isLoadingClientPersonalInformation
    } = useClientPersonalInformationContext();

    if (isLoadingClientPersonalInformation) {
        return <p>Loading...</p>;
    }

    const handlePayNow = async (milestoneId: string) => {
        if (clientPersonalInformation?.enablePurchaseOrders) {
            setSelectedMilestoneId(milestoneId);
            paymentOptionDialogState.open();
            return;
        }

        const result = await getMilestoneCheckoutSessionUrl({milestoneId});

        if (result.success) {
            window.location.replace(result.content.checkoutSessionUrl);
        }
    };

    const handlePayMilestoneWithPoSuccess = () => {
        paymentOptionDialogState.close();
        reloadGig();
    };

    const lastUncompletedMilestoneIndex = milestones.slice().reverse().findIndex(milestone => 
        !milestone.dateCompleted && !gigIsCancelled
    );
    
    const lastUncompletedMilestoneActualIndex = lastUncompletedMilestoneIndex >= 0
        ? milestones.length - 1 - lastUncompletedMilestoneIndex
        : -1;    

    return (
        <div>
            <div className="flex flex-row items-center space-x-2 mb-4">
                <Typography variant="title-large" component="h2">Milestones</Typography>
                <ToolTip tooltipText={clientMilestoneTooltips.milestones} />
            </div>
            <div className="space-y-2">
                {milestones.map((milestone, index) => {
                    const isPrevMilestoneCompleted = index > 0 && milestones[index - 1].dateCompleted && !gigIsCancelled;
                    const isMilestoneCompleted = milestone.clientPaymentCollectedDate && milestone.dateCompleted && !gigIsCancelled;
                    const isMilestoneOngoing = milestone.clientPaymentCollectedDate && !milestone.dateCompleted && !gigIsCancelled;
                    const isIncompleteMilestone = !milestone.clientPaymentCollectedDate && !milestone.dateCompleted && !gigIsCancelled;
                    const milestoneStatus = isMilestoneCompleted ? "Completed" :
                        isMilestoneOngoing ? "Awaiting completion" :
                            isPrevMilestoneCompleted && isIncompleteMilestone ? "Awaiting payment" :
                                "Awaiting previous milestone completion";
                    const isLastUncompletedMilestone = index === lastUncompletedMilestoneActualIndex;

                    return (
                        <Card className={clsx("sm:flex items-center space-y-4 sm:space-y-0", isMilestoneCompleted && "bg-status-primary-light", isMilestoneOngoing && "border-2 border-primary")}>
                            <div className="grow">
                                <Typography variant="title-small" component="h3" className={clsx((isIncompleteMilestone && !milestones[index - 1].dateCompleted) && "text-[grey]")}>{milestone.description}</Typography>
                                <Typography variant="title-small" component="h3" className="text-[grey] text-[1rem] mt-2">
                                    Budget: <MoneyDisplay value={isVatRegistered ? milestone.amount * config.VatMultiplier : milestone.amount} />
                                </Typography>
                                <Typography variant="title-small" component="h3" className="text-[grey] text-[1rem] mt-2">
                                    Status: {milestoneStatus}
                                </Typography>
                            </div>
                            <div className="space-x-2 shrink-0 sm:ml-4">
                                {milestone.dateCompleted && (
                                    <Chip size="large" label="Completed" variant="primary" isStatus={true}/>
                                )}
                                {milestone.clientPaymentCollectedDate && !milestone.dateCompleted && (
                                    <Chip size="large" label="Current" variant="secondary" isStatus={true}/>
                                )}
                                {!milestone.clientPaymentCollectedDate && !gigIsCancelled && (
                                    <Button disabled={isGettingMilestoneCheckoutSessionUrl || !isPrevMilestoneCompleted} onClick={() => handlePayNow(milestone.id)} variant={isPrevMilestoneCompleted ? "secondary" : undefined}>Pay now</Button>
                                )}
                                {milestone.clientPaymentCollectedDate && !milestone.dateCompleted && !gigIsCancelled && (
                                    <CompleteMilestoneButton reloadGig={reloadGig} milestoneId={milestone.id} disabled={isGettingMilestoneCheckoutSessionUrl} isLastMilestone={isLastUncompletedMilestone}/>
                                )}
                            </div>
                        </Card>
                    );
                })}
            </div>

            <MilestonePurchaseOrderDialog
                milestoneId={selectedMilestoneId}
                dialogBoxState={paymentOptionDialogState}
                getMilestoneCheckoutSessionUrl={getMilestoneCheckoutSessionUrl}
                onSuccess={handlePayMilestoneWithPoSuccess}
            />
        </div>
    );
};

export default ClientMilestonePaymentSection;
