import Card from "../../components/Card";
import Chip, { ChipContainer } from "../../components/Chip";

export const GigSummaryCardSkeleton = () => {
    return (
        <Card className='animate-pulse bg-surface'>
            <div className="flex flex-row items-center">
                <div className="flex flex-col space-y-4 p-4 grow">
                    <div className="h-5 w-1/4 rounded-full bg-chip-secondary text-sm font-medium bg-shimmer bg-200% animate-shimmer" />
                    <div className="h-5 w-2/4 rounded-full bg-chip-secondary text-sm font-medium bg-shimmer bg-200% animate-shimmer" />
                    <div className="flex flex-col space-y-2">
                        <div className="h-5 w-full rounded-full bg-chip-secondary text-sm font-medium bg-shimmer bg-200% animate-shimmer" />
                        <div className="h-5 w-full rounded-full bg-chip-secondary text-sm font-medium bg-shimmer bg-200% animate-shimmer" />
                        <div className="h-5 w-4/5 rounded-full bg-chip-secondary text-sm font-medium bg-shimmer bg-200% animate-shimmer" />
                    </div>
                    <div className="flex flex-row gap-4">
                        <div className="h-5 w-1/5 rounded-full bg-chip-secondary text-sm font-medium bg-shimmer bg-200% animate-shimmer" />
                        <div className="h-5 w-1/5 rounded-full bg-chip-secondary text-sm font-medium bg-shimmer bg-200% animate-shimmer" />
                        <div className="h-5 w-1/5 rounded-full bg-chip-secondary text-sm font-medium bg-shimmer bg-200% animate-shimmer" />
                        <div className="h-5 w-1/5 rounded-full bg-chip-secondary text-sm font-medium bg-shimmer bg-200% animate-shimmer" />
                    </div>
                    <ChipContainer>
                        <Chip label="" size="large" variant="secondary" className="bg-shimmer bg-200% animate-shimmer py-4 !px-10" />
                        <Chip label="" size="large" variant="secondary" className="bg-shimmer bg-200% animate-shimmer py-4 !px-10" />
                        <Chip label="" size="large" variant="secondary" className="bg-shimmer bg-200% animate-shimmer py-4 !px-10" />
                    </ChipContainer>
                </div>

            </div>
        </Card>
    );
};

export default GigSummaryCardSkeleton;