import { GigMatch } from "../models/app/gigMatch";
import Card from "./Card";
import { CardDataField } from "./Card";
import CardDataFieldLabel from "./Card/CardDataFieldLabel";
import TalentSkill from "./TalentSkill";
import InviteToGigButton from "./InviteToGigButton";
import UserAvatar from "./UserAvatar";
import clsx from "clsx";
import BadgeList from "./BadgeList";
import Button from "./Button";
import { Link } from "react-router-dom";
import { useAppPaths } from "../Routes";

export type GigTalentMatchCardProps = {
    gigId: string
    match: GigMatch
}

const GigTalentMatchCard = ({
    gigId,
    match
}: GigTalentMatchCardProps) => {
    const matchValue = `${Math.round(match.score * 100)}%`;
    const appPaths = useAppPaths();

    const matchDisplayName = match?.talentTradingName || `${match?.talentFirstName} ${match?.talentLastName}`;
    const displayBadgeSection = match.badges.length > 0;

    return (
        <Card className={"max-w-full space-y-6 md:space-y-0 md:space-x-6 md:flex md:items-center flex-row"}>
            <div className="md:w-[230px] flex flex-col items-center space-y-2">
                <UserAvatar name={matchDisplayName} userImageUrl={match.talentProfileImageThumbnailUrl} />
                <Link to={appPaths.talents.findTalentDetails(match.talentId)} target="_blank"><Button className="w-fit" variant="tertiary">View profile</Button></Link>
            </div>
            <div className={clsx("grow space-y-3 text-left")}>
                <CardDataField label="Match level">
                    {matchValue}
                </CardDataField>
                <div>
                    <CardDataFieldLabel className="text-left">Relevant skills</CardDataFieldLabel>
                    <div className="mt-2 space-y-2 md:max-w-xl text-left">
                        {match.matchedSkills.map(skill => (
                            <TalentSkill key={skill.skillName} skinnyProgress {...skill} />
                        ))}
                    </div>
                </div>
                {displayBadgeSection &&
                    <div>
                        <CardDataFieldLabel className="text-left mb-2">Badges</CardDataFieldLabel>
                        <BadgeList badges={match.badges} keyPrefix={match.talentId.toString()} />
                    </div>
                }

            </div>
            <div className="flex flex-col space-y-4">
                <InviteToGigButton
                    talentId={match.talentId}
                    isInvited={match.isInvited}
                    gigId={gigId}
                    className="block"
                />
            </div>
        </Card>
    );
};

export default GigTalentMatchCard;
