import React from "react";
import { SortOrder } from "../../api/common/sortOrder";
import { UseGigsReturn } from "../../api/gigs";
import { GigOrderByFieldKeys } from "../../models/app/gig";

import contextPlaceholderFunc from "../../utils/contextPlaceholderFun";

export type FindAGigContextType = Pick<UseGigsReturn, "gigs" | "isLoading" | "setSearchTerm"> & {
    searchTerm: string
    skillIds: string[]
    expectedDurationIds: number[]
    minBudget: number
    maxBudget: number
    globalMinBudget: number
    globalMaxBudget: number
    isCompletedRemotely: string
    sortOrder: SortOrder
    orderBy: GigOrderByFieldKeys
    anyActiveFilters: boolean
    setSkillIds: (skillIds: string[]) => void
    setExpectedDurationIds: (expectedDurationIds: number[]) => void
    setMinBudget: (value: number) => void
    setMaxBudget: (value: number) => void
    setIsCompletedRemotely: (value: string) => void
    setSortOrder: (order: SortOrder) => void
    setOrderBy: (orderBy: GigOrderByFieldKeys) => void
    clearAllFilters: () => void
    searchNow: () => void
}

export const FindAGigContext = React.createContext<FindAGigContextType>({
    gigs: [],
    isLoading: false,
    anyActiveFilters: false,
    setSearchTerm: contextPlaceholderFunc,
    setSkillIds: contextPlaceholderFunc,
    setExpectedDurationIds: contextPlaceholderFunc,
    setMinBudget: contextPlaceholderFunc,
    setMaxBudget: contextPlaceholderFunc,
    setIsCompletedRemotely: contextPlaceholderFunc,
    setSortOrder: contextPlaceholderFunc,
    setOrderBy: contextPlaceholderFunc,
    searchTerm: "",
    skillIds: [],
    expectedDurationIds: [],
    minBudget: 0,
    maxBudget: 0,
    globalMinBudget: 0,
    globalMaxBudget: 0,
    isCompletedRemotely: "",
    sortOrder: "asc",
    orderBy: "Title",
    clearAllFilters: contextPlaceholderFunc,
    searchNow: contextPlaceholderFunc,
});

export const useFindAGigContext: () => FindAGigContextType = () => React.useContext(FindAGigContext);

export default FindAGigContext;