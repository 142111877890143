import { RadioButton, Circle } from "phosphor-react";
import clsx from "clsx";

export type RadioCardGroupOptionProps = {
    id: string;
    label: string;
    value: boolean;
    description: string;
}
  
export type RadioCardGroupProps = {
    value: boolean;
    onChange: (value: boolean) => void;
    options: RadioCardGroupOptionProps[];
}

const RadioCardGroupOption = ({ 
    id,
    label,
    selected,
    onSelect,
}: RadioCardGroupOptionProps & { selected: boolean; onSelect: () => void; }) => (
    <div
        className={clsx(
            "flex rounded-lg shadow-sm cursor-pointer transition-colors",
            "w-full",
            {
                "border-2 border-solid border-gray-300 bg-white": !selected,
                "border-2 border-primary bg-white": selected,
            }
        )}
        onClick={onSelect}
        style={{ padding: "0.5rem" }}
    >
        <div className="flex justify-between items-start w-full ">
            <label htmlFor={`radio-${id}`} className="text-md font-semibold mt-8 my-auto cursor-pointer" style={{flexBasis: "min-content"}}>
                {label}
            </label>
            <div className="flex items-center">
                {selected ? (
                    <RadioButton className="text-black" size={24} weight="fill" />
                ) : (
                    <Circle className="text-black" size={24} />
                )}
            </div>
        </div>
    </div>
);  

const RadioCardGroup = ({ 
    value, // This is the value of isTimeAndMaterial from the form
    onChange,
    options,
}: RadioCardGroupProps) => {
    const selectedOption = options.find(option => option.value === value);

    return (
        <div>
            <div className="flex justify-center space-x-4 mb-4 w-full h-full">
                {options.map((option) => (
                    <RadioCardGroupOption
                        key={option.id}
                        id={option.id}
                        label={option.label}
                        description={option.description}
                        value={option.value}
                        selected={value === option.value}
                        onSelect={() => onChange(option.value)}
                    />
                ))}
            </div>
            <div className="description text-sm text-gray-600 p-2">
                {selectedOption ? selectedOption.description : ""}
            </div>
        </div>
    );
};  

export default RadioCardGroup;
