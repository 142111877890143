import gigApiFetcher from "./common/fetching";
import { IndustrialExperienceDto } from "../models/app/industrialExperience";

export const fetchIndustrialExperienceList = async (query: string | undefined, accessToken: string | null): Promise<IndustrialExperienceDto[]> => {
    const queryString = query ? `?searchTerm=${query}` : "";

    const response = await gigApiFetcher<never, IndustrialExperienceDto[]>({
        url: `api/industrial-experience${queryString}`,
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response;
};

export const fetchIndustrialExperienceOptions = async (query: string, accessToken: string | null) => {
    const skillsResponse = await fetchIndustrialExperienceList(query, accessToken);
    
    return skillsResponse
        .map(specialization => ({
            value: specialization.id,
            label: specialization.name,
        }));
};