import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import Button from "../Button";
import CreateGigFormPageLayout from "./CreateGigFormPageLayout";
import FormRadioGroup from "../FormRadioGroup";
import { GigFormValues, buildGigFormValidationSchemaFields } from "../GigForm";
import { formClassNames } from "./classNames";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { remoteOptions } from "../../models/app/gig";

export type CreateGigFormStepRemoteFields = Pick<GigFormValues, "isCompletedRemotely">;

export type CreateGigFormStepRemoteProps = {
    onSubmit: (value: CreateGigFormStepRemoteFields) => void
    onBackClick: (value: CreateGigFormStepRemoteFields) => void
    defaultValues: CreateGigFormStepRemoteFields
}

const CreateGigFormStepRemote = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateGigFormStepRemoteProps) => {
    const { gigTerminology, organizationConfig } = useOrganizationContext();

    const validationSchema: yup.SchemaOf<CreateGigFormStepRemoteFields> = yup.object({
        isCompletedRemotely: buildGigFormValidationSchemaFields(organizationConfig.isPaymentsEnabled).isCompletedRemotely,
    });

    const methods = useForm<CreateGigFormStepRemoteFields>({
        resolver: yupResolver(validationSchema),
        defaultValues
    });
    
    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateGigFormPageLayout
                title={`Can this ${gigTerminology.toLowerCase()} be completed remotely?`}
                content={
                    <Controller
                        name="isCompletedRemotely"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormRadioGroup
                                required
                                label={`Please select whether this ${gigTerminology.toLowerCase()} can be completed remotely`}
                                options={remoteOptions}
                                error={methods.formState.errors.isCompletedRemotely}
                                defaultValue={value}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                }
                iconName="Laptop"
                buttons={
                    <>
                        <Button variant="secondary" onClick={handleBackClick} type="button">Back</Button>
                        <Button type="submit">Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateGigFormStepRemote;