import { Link } from "react-router-dom";
import { useGig } from "../../api/gig";
import { useGigs } from "../../api/gigs";
import Card, { CardDataField } from "../../components/Card";
import CardDataFieldLabel from "../../components/Card/CardDataFieldLabel";
import Chip, { ChipContainer } from "../../components/Chip";
import GigSummaryCard from "../../components/GigSummaryCard";
import TitleUnderline from "../../components/TitleUnderline";
import Typography from "../../components/Typography";
import { useAppPaths } from "../../Routes";
import { gigStatuses } from "../../models/app/gig";
import { useGigId } from "../../Routes";
import SubmitProposalButton from "./SubmitProposalButton";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import MoneyDisplay from "../../components/MoneyDisplay";
import GigFilesAccordion from "../../components/GigFilesAccordion/GigFilesAccordion";
import ReportButton from "../../components/Reports";
import Loader from "../../components/Loader";
import Tooltip from "../../components/Tooltip";

const FindAGigDetailsPage = () => {
    const appPaths = useAppPaths();
    const gigId = useGigId();
    const { gig, isLoading } = useGig(gigId);
    const { gigs: clientGigs } = useGigs({
        gigStatusIds: [gigStatuses.posted],
        giggedClientId: gig?.giggedClientId,
        pauseFetching: !gig,
        allClients: true,
    });
    const { organizationConfig } = useOrganizationContext();

    if (!gig || isLoading) return <Loader />;

    const otherClientGigs = clientGigs.filter(_ => _.id !== gig.id);
    return (
        <div className="m-4 lg:m-8 break-words">
            <div className="mx-auto space-y-8 max-w-6xl">
                <Card key={gig.id} className="space-y-6 md:flex">                    
                    <div className="w-full md:mr-8 overflow-hidden relative">
                        <div className="space-y-4">                            
                            <Typography variant="title-small" component="p">
                                <Link to={appPaths.clients.details(gig.giggedClientId)}>
                                    {gig.giggedClientName || gig.giggedClientCompanyName}
                                </Link>
                            </Typography>
                            <Typography variant="display-medium" component="h1">{gig?.title}</Typography>
                            <Typography variant="body" component="p" className="italic whitespace-pre-wrap">{gig?.description}</Typography>
                            <TitleUnderline fullWidth />
                        </div>
                        <div className="mt-4 mb-4">
                            <div className="space-y-2">
                                <CardDataFieldLabel>Top three skills needed</CardDataFieldLabel>
                                <ChipContainer>
                                    {gig.skills.map(skill => (
                                        <Chip key={skill.id} variant="secondary" label={skill.name} />
                                    ))}
                                </ChipContainer>
                            </div>
                        </div>
                        {
                            gig.isGigFilesPublic && gig.files && gig.files.length > 0 && (
                                <GigFilesAccordion files={gig.files} />
                            )
                        }

                        {organizationConfig.isReportsEnabled && (
                            <div className="mt-6">
                                <ReportButton 
                                    type="Gig" 
                                    entityId={gigId}
                                    className="text-black hover:text-primary"
                                />
                            </div>
                        )}
                    </div>
                    <div className="md:ml-auto md:self-start md:shrink-0 space-y-8">
                        <div className="child:p-4 child:border-surface border border-surface rounded-lg">
                            <CardDataField className="border-b" label="Initial stage">{gig.initialStageLabel}</CardDataField>
                            <CardDataField className="border-b" label="Expected duration">{gig.expectedDurationLabel}</CardDataField>
                            {organizationConfig.isPaymentsEnabled && gig.isTimeAndMaterial && <CardDataField className="border-b" label="No. of days">{gig.numberOfDays || 0}</CardDataField>}
                            {organizationConfig.isPaymentsEnabled && gig.isTimeAndMaterial && <CardDataField className="border-b" label="Daily rate"><MoneyDisplay value={gig.dailyRate || 0} /></CardDataField>}
                            {organizationConfig.isPaymentsEnabled && <CardDataField className="border-b" label="Budget"><MoneyDisplay value={gig.budget || 0} /></CardDataField>}
                            <CardDataField className="" label="Remote">{gig.isCompletedRemotely ? "Yes" : "No"}</CardDataField>
                        </div>
                        <SubmitProposalButton gig={gig} />
                    </div>
                </Card>
                {otherClientGigs.length > 0 && (
                    <div>
                        <Typography variant="headline-large" component="h2" gutterBottom>More from {gig.giggedClientName}</Typography>
                        <div className="md:flex md:space-x-6">
                            <Card className="mb-6 md:self-start shrink-0 md:w-[250px]">
                                <div className="h-32 w-32 pt-[4px] text-[54px] flex items-center justify-center rounded-full bg-white mx-auto mb-4">
                                    {gig.giggedClientName && gig.giggedClientName[0].toUpperCase() || gig.giggedClientCompanyName[0].toUpperCase()}
                                </div>
                                <p className="text-center text-title-medium font-bold mb-4">
                                    {gig.giggedClientName || gig.giggedClientCompanyName}
                                </p>
                            </Card>
                            <div className="space-y-6 md:grow">
                                {otherClientGigs.map(clientGig => (
                                    <GigSummaryCard key={clientGig.id} gig={clientGig} viewGigLink={appPaths.gigs.findAGigDetails(clientGig.id)} />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FindAGigDetailsPage;
