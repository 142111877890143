import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";


export type TalentIndustrialExperienceFormValues = {
    industrialExperienceId: string;
    yearsExperience: number;
};

export const validationSchemaFields = {
    industrialExperienceId: yup.string().required("Industry is required."),
    yearsExperience: yup.number().required("Years of experience is required.")
};

const schema = yup.object(validationSchemaFields);

export const useTalentIndustrialExperienceForm = () => {
    return useForm<TalentIndustrialExperienceFormValues>({
        resolver: yupResolver(schema)
    });
};