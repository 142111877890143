import { useEffect, useState } from "react";
import { TalentSummary } from "../models/app/talent";
import gigApiFetcher from "./common/fetching";

const getTopThreeTalentsFromGigged = async (): Promise<TalentSummary[]> => {
    const path = "api/talents/queries/top-three-from-gigged";
    const talents = await gigApiFetcher<never, TalentSummary[]>({url: path, method: "GET"});
    
    return talents;
};

export type UseTopThreeTalentsFromGiggedReturn = {
    talents: TalentSummary[]
    isLoading: boolean
}

export const useTopThreeTalentsFromGigged = (): UseTopThreeTalentsFromGiggedReturn => {
    const [talents, setTalents] = useState<TalentSummary[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchTalents = async () => {
            setIsLoading(true);
            const fetchedTalents = await getTopThreeTalentsFromGigged();
            setTalents(fetchedTalents);
            setIsLoading(false);
        };

        fetchTalents();
    }, []);

    return {
        talents,
        isLoading,
    };
};