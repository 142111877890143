import { Participant } from "@twilio/conversations";
import clsx from "clsx";
import { Paperclip, PaperPlaneTilt } from "phosphor-react";
import React, { ChangeEvent, useRef, useState } from "react";
import { useSendMessageReceivedEmail } from "../../../api/conversations";
import { addNotifications } from "../../../store/action-creators";
import { ReduxConversation } from "../../../store/reducers/conversationReducer";
import { UNEXPECTED_ERROR_MESSAGE } from "../../../utils/constants";
import { unexpectedErrorNotification } from "../../../utils/conversationsHelpers";
import { getSdkConversationObject } from "../../../utils/conversationsObjects";
import Button from "../../Button";
import MessageFile from "./MessageFile";

export type MessageInputFieldProps = {
    conversation: ReduxConversation
};

function notNull(value: string | null): value is string {
    return value !== null;
}

const MessageInputField = ({
    conversation,
}: MessageInputFieldProps) => {
    const MAX_FILE_SIZE = 52428800;

    const [message, setMessage] = useState("");
    const [files, setFiles] = useState<File[]>([]);
    const [isSending, setIsSending] = useState(false);

    const fileInputRef = useRef<HTMLLabelElement>(null);

    const [sendMessageReceivedEmail] = useSendMessageReceivedEmail();

    const onMessageSend = async () => {
        if (!message.length && !files.length) return;
        setIsSending(true);

        const sdkConversation = getSdkConversationObject(conversation);

        const messageBuilder = await sdkConversation
            .prepareMessage()
            .setBody(message);

        files.forEach(file => {
            const fileData = new FormData();
            fileData.set(file.name, file, file.name);
            messageBuilder.addMedia(fileData);
        });

        const messageIndex = await messageBuilder
            .build()
            .send();


        const participants: Participant[] = [];
        sdkConversation._participants.forEach(p => participants.push(p));
        const participantIds = participants
            .map(participant => participant.identity)
            .filter(notNull);

        try {
            await sdkConversation.updateLastReadMessageIndex(messageIndex);
        } catch (error) {
            unexpectedErrorNotification(addNotifications);
            return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
        }

        setMessage("");
        setFiles([]);

        setIsSending(false);

        await sendMessageReceivedEmail(participantIds);
    };

    const onFilesChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { files: assets } = event.target;

        if (!assets?.length) {
            return;
        }

        const validFiles = Array.from(assets).filter(
            ({ size }) => size < MAX_FILE_SIZE + 1
        );

        setFiles([...files, ...validFiles]);
    };

    const onFileRemove = (file: string) => {
        const fileIdentityArray = file.split("_");
        const fileIdentity = fileIdentityArray
            .slice(0, fileIdentityArray.length - 1)
            .join();

        const existentFiles = files.filter(
            ({ name, size }) =>
                name !== fileIdentity &&
                size !== Number(fileIdentityArray[fileIdentityArray.length - 1])
        );

        setFiles(existentFiles);
    };

    return (
        <>
            <div className="flex flex-row">
                <label
                    ref={fileInputRef}
                    className={clsx(
                        "flex items-center rounded-full ml-4 cursor-pointer",
                        "font-bold py-[0.75rem] px-[1.5rem] rounded-full disabled:text-disabled-text focus:outline whitespace-nowrap", // button classes
                        "my-2 text-primary-bg-text bg-primary hover:bg-primary-hover focus:outline-3" // button primary classes
                    )}
                >
                    <Paperclip size={20} color="#fff" weight="bold" />
                    <input
                        id="file-input"
                        type="file"
                        className="hidden"
                        onChange={onFilesChange}
                    />
                </label>
                <input
                    type="text"
                    className={clsx(
                        "border rounded-xl w-full p-4 m-2",
                        "focus:outline-none focus:ring-inset focus:ring-2 focus:ring-primary-text"
                    )}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setMessage(e.currentTarget.value);
                    }}
                    style={{ resize: "none" }}
                    onKeyDown={async (e: React.KeyboardEvent) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            await onMessageSend();
                        }
                    }}
                    placeholder="Type your message here"
                    value={message}
                />
                <Button
                    variant="primary"
                    className="rounded-full my-2 mr-4"
                    onClick={onMessageSend}
                    disabled={message === "" && !files.length || isSending}
                >
                    <PaperPlaneTilt size={20} color="#fff" weight="bold" />
                </Button>
            </div>
            {files.length ? (
                <div className="flex gap-3 flex-wrap">
                    {files.map((file: File) => (
                        <MessageFile
                            media={{ filename: file.name, size: file.size }}
                            onRemove={() => onFileRemove(`${file.name}_${file.size}`)}
                        />
                    ))}
                </div>
            ) : null}
        </>
    );
};

export default MessageInputField;