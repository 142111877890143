import Card from "./Card";

export type GigCardSkeletonProps = {
    secondCard?: boolean
}

export const GigCardSkeleton = ({
    secondCard = false
}: GigCardSkeletonProps) => {
    return (
        <div className="flex flex-row !gap-4">
            <Card className="animate-pulse flex flex-col text-center md:text-left md:flex-row grow items-center rounded-b-none md:rounded-b-lg pb-2 md:pb-6">
                <div className="flex w-full flex-col space-y-4 p-4 grow">
                    <div className="h-8 w-1/2 rounded-md bg-chip-secondary text-sm font-medium bg-shimmer bg-200% animate-shimmer" />
                    <div className="h-4 w-2/3 rounded-md bg-chip-secondary text-sm font-medium bg-shimmer bg-200% animate-shimmer" />
                    <div className="h-4 w-1/3 rounded-md bg-chip-secondary text-sm font-medium bg-shimmer bg-200% animate-shimmer" />
                </div>
            </Card>
            {secondCard && (
                <Card className="flex-col md:min-w-[15%] animate-pulse flex items-center justify-center rounded-t-none md:rounded-t-lg pt-2 md:pb-6 md:pt-6">
                    <div className="flex flex-col w-full !h-full justify-center items-center my-auto space-y-4 p-4 grow">
                        <div className="h-8 w-1/4 rounded-md bg-chip-secondary text-sm font-medium bg-shimmer bg-200% animate-shimmer" />
                        <div className="h-4 w-full rounded-md bg-chip-secondary text-sm font-medium bg-shimmer bg-200% animate-shimmer" />
                    </div>
                </Card>
            )}
        </div>
    );
};

export default GigCardSkeleton;