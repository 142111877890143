import { useTalentWithdrawals } from "../api/talentWithdrawals";
import Card from "../components/Card";
import Chip from "../components/Chip";
import Loader from "../components/Loader";
import MoneyDisplay from "../components/MoneyDisplay";
import PageContent from "../components/PageContent";
import Table from "../components/Table";
import Typography from "../components/Typography";
import { useIsLargeScreen } from "../hooks/screenSizes";
import { TalentWithdrawalsSummaryDto, talentWithdrawalStatusOptions } from "../models/api/talentWithdrawals";
import formatDateOnlyString from "../utils/formatDateString";

const WithdrawalsListPage = () => {
    const { talentWithdrawals, isLoading } = useTalentWithdrawals();
    const isLargeScreen = useIsLargeScreen();

    const renderWithdrawalRow = (withdrawal: TalentWithdrawalsSummaryDto) => {
        const withdrawalStatusLabel = talentWithdrawalStatusOptions.find(option => option.value === withdrawal.statusId)?.label || "Unknown";

        return (
            <>
                <td>{withdrawal.id}</td>
                <td>{formatDateOnlyString(withdrawal.createdAt)}</td>
                <td><MoneyDisplay value={withdrawal.amount} /></td>
                <td>
                    <Chip
                        key={withdrawal.id}
                        variant={withdrawalStatusLabel === "Complete" ? "primary" : withdrawalStatusLabel === "Rejected / Cancelled" ? "secondary" : undefined}
                        label={withdrawalStatusLabel}
                        isStatus={true}
                    />
                </td>
            </>
        );
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>Withdrawals</Typography>
            {talentWithdrawals.length === 0 ? (
                <Card>
                    <Typography variant="title-large" component="p">None yet...</Typography>
                </Card>
            ) : (
                <Table
                    headings={["Id", "Date Created", "Amount", "Status"]}
                    data={talentWithdrawals}
                    renderRowCells={renderWithdrawalRow}
                />
            )}
        </PageContent>
    );
};

export default WithdrawalsListPage;