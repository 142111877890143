import { Disclosure } from "@headlessui/react";
import clsx from "clsx";
import { CaretDown, CaretUp } from "phosphor-react";

export type FindPagePanelSectionProps = {
    title: string
    subtitle?: string
    content: React.ReactNode
}

const FindPagePanelSection = ({
    title,
    subtitle,
    content,
}: FindPagePanelSectionProps) => {
    return (
        <Disclosure as="div" className="border-b border-surface p-4">
            <Disclosure.Button className="w-full">
                {({ open }) => (
                    <div className={clsx("flex items-center", open && "pb-4")}>
                        <div>
                            <h3 className="font-bold text-left">{title}</h3>
                            {subtitle && (
                                <p className="text-[0.8rem] text-left">{subtitle}</p>
                            )}
                        </div>
                        {open ? (
                            <CaretUp className="ml-auto block text-primary-text" weight="bold" />
                        ) : (
                            <CaretDown className="ml-auto block text-primary-text" weight="bold" />
                        )}
                    </div>
                )}
            </Disclosure.Button>
            <Disclosure.Panel className="text-primary-text">
                {content}
            </Disclosure.Panel>
        </Disclosure>
    );
};

export default FindPagePanelSection;
