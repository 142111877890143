import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import useAccessToken from "../auth/useAccessToken";
import FormTextInput from "./FormTextInput";
import { MagnifyingGlass } from "phosphor-react";
import { Switch } from "@headlessui/react";
import { CheckSquare, Square } from "phosphor-react";

export type GroupedSpecializationOption = {
    label: string;
    options: { value: string; label: string }[];
}

export type SearchableSpecializationCheckboxGroupProps = {
    selectedOptionIds: string[];
    searchPlaceholder: string;
    onChange: (selectedIds: string[]) => void;
    fetchOptions: (query: string, accessToken: string | null) => Promise<GroupedSpecializationOption[]>;
}

const SearchableSpecializationCheckboxGroup = ({
    selectedOptionIds,
    searchPlaceholder,
    onChange,
    fetchOptions,
}: SearchableSpecializationCheckboxGroupProps) => {
    const accessToken = useAccessToken();
    const [searchTerm, setSearchTerm] = useState("");
    const [groupedOptions, setGroupedOptions] = useState<GroupedSpecializationOption[]>([]);
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!accessToken) return;

        const loadOptions = async () => {
            setLoading(true);
            try {
                const options = await fetchOptions(debouncedSearchTerm, accessToken);
                setGroupedOptions(options);
            } finally {
                setLoading(false);
            }
        };

        loadOptions();
    }, [debouncedSearchTerm, accessToken]);

    const handleChange = (optionValue: string) => (checked: boolean) => {
        if (checked) {
            onChange([...selectedOptionIds, optionValue]);
        } else {
            onChange(selectedOptionIds.filter(id => id !== optionValue));
        }
    };

    return (
        <div className="space-y-4">
            <FormTextInput
                value={searchTerm}
                onChange={event => setSearchTerm(event.target.value)}
                placeholder={searchPlaceholder}
                StartAdornmentIcon={MagnifyingGlass}
            />
            <div className="max-h-96 overflow-y-auto scrollbar-thin scrollbar-thumb-scrollbar pr-3 -mr-2 space-y-4">
                {loading ? (
                    <p>Loading...</p>
                ) : groupedOptions.length === 0 ? (
                    <p>No results found</p>
                ) : (
                    groupedOptions.map(group => (
                        <div key={group.label} className="space-y-1">
                            <h3 className="font-semibold">{group.label}</h3>
                            {group.options.map(option => {
                                const checked = selectedOptionIds.includes(option.value);
                                return (
                                    <Switch
                                        key={option.value}
                                        checked={checked}
                                        onChange={handleChange(option.value)}
                                        className="w-full"
                                    >
                                        <div className="flex items-center w-full">
                                            <label className="block text-left mr-4 grow">{option.label}</label>
                                            {checked ? (
                                                <CheckSquare size="20" weight="bold" className="shrink-0" />
                                            ) : (
                                                <Square size="20" weight="bold" className="shrink-0" />
                                            )}
                                        </div>
                                    </Switch>
                                );
                            })}
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default SearchableSpecializationCheckboxGroup;