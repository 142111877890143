import { Disclosure } from "@headlessui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useClientPersonalInformationContext } from "../../api/clientPersonalInformation";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { useTalentPersonalInformationContext } from "../../api/talentPersonalInformation";
import useCurrentUser from "../../auth/useCurrentUser";
import { useIsExtraLargeScreen, useIsSmallScreen } from "../../hooks/screenSizes";
import { useAppPaths } from "../../Routes";
import { buildTalentNavItems, buildClientNavItems } from "./SideMenuNavItems";
import SideNavMenuItem from "./SideNavMenuItem";
import { SignOutButton } from "./SignOutButton";

export type SideNavMenuProps = {
    close: () => void
}

const SideNavMenu = ({
    close,
}: SideNavMenuProps) => {
    const currentUser = useCurrentUser();
    const { userRole, idpUserId } = useCurrentUser();
    const appPaths = useAppPaths();
    const { talentPersonalInformation } = useTalentPersonalInformationContext();
    const { clientPersonalInformation } = useClientPersonalInformationContext();
    const isSmallScreen = useIsSmallScreen();
    const isExtraLarge = useIsExtraLargeScreen();
    const { organizationConfig, gigTerminology, talentTerminologyPlural } = useOrganizationContext();
    const isOtm = organizationConfig.name === "Gigged.AI";
    const isAdmin = !userRole && idpUserId.length > 0;

    const showFindTalentMenuItem =
        !organizationConfig?.isTalentProfilesPrivate && userRole === "talent" ||
        currentUser.userRole === "client" ||
        isAdmin;

    const navItems = currentUser.userRole === "client"
        ? buildClientNavItems(currentUser.clientId)
        : buildTalentNavItems(currentUser.talentId);

    const wrapInContainer = (children: React.ReactNode) => (
        isExtraLarge ? (
            <div className="fixed xl:static inset-y-0 left-0 w-[288px] shrink-0 p-5 pr-12 bg-app-background z-40">
                {children}
            </div>
        ) : (
            <Disclosure.Panel className="fixed inset-y-0 left-0 w-[288px] p-5 pr-12 bg-white z-[1010] overflow-y-auto">
                {children}
            </Disclosure.Panel>
        )
    );

    const profileImage = () => {
        if (!clientPersonalInformation?.profileImageUrl && !talentPersonalInformation?.profileImageUrl) {
            return (
                <div className="h-32 w-32 pt-[4px] text-[54px] flex items-center justify-center rounded-full bg-surface mx-auto mb-8">
                    {currentUser.userGivenName?.length > 0 && currentUser.userGivenName[0].toUpperCase()}
                </div>
            );
        }
        return (
            <img
                src={currentUser.userRole === "talent" ? talentPersonalInformation?.profileImageUrl : clientPersonalInformation?.profileImageUrl}
                className="h-32 w-32 object-cover rounded-full mx-auto mb-8"
            />
        );
    };

    return wrapInContainer(
        <>
            <nav>
                {profileImage()}
                <div className="text-center mb-8 break-word">
                    {currentUser.userRole === "client" ?
                        <>
                            <p className="text-title-medium font-bold">
                                {clientPersonalInformation?.companyName}
                            </p>
                            <span>User: {currentUser.userGivenName} {currentUser.userFamilyName}</span>
                        </>
                        :
                        <p className="text-title-medium font-bold break-words">
                            {currentUser.userGivenName}
                        </p>
                    }
                </div>

                {currentUser.userRole === "client" && (
                    <Disclosure.Button
                        className="block w-full mb-8 text-primary-bg-text font-bold py-[0.75rem] px-[1.5rem] rounded-full bg-primary hover:bg-primary-hover focus:outline-3 text-center transition-colors ease-in-out duration-200"
                        as={Link}
                        to={appPaths.gigs.createIntro}
                    >
                        Post a {gigTerminology.toLowerCase()}
                    </Disclosure.Button>
                )}

                <div className="space-y-3">
                    {!isSmallScreen && (
                        <div className="border-y border-surface ">
                            {showFindTalentMenuItem &&
                                <Disclosure.Button
                                    as={Link}
                                    className="block text-primary-text font-bold text-left px-3 py-3 w-full border-b border-surface"
                                    to={appPaths.talents.findTalent}
                                >
                                    Find {talentTerminologyPlural}
                                </Disclosure.Button>
                            }
                            <Disclosure.Button
                                as={Link}
                                className="block text-primary-text font-bold text-left px-3 py-3 w-full border-b border-surface"
                                to={appPaths.gigs.findAGig}
                            >
                                Find a {gigTerminology}
                            </Disclosure.Button>
                            {isOtm && (
                                <a href="https://gigged.ai" target="_blank">
                                    <Disclosure.Button

                                        className="block text-primary-text font-bold text-left px-3 py-3 w-full"

                                    >
                                        Back to Home Page
                                    </Disclosure.Button>
                                </a>
                            )}
                        </div>
                    )}

                    {navItems.map((navItem) => (
                        <SideNavMenuItem
                            key={navItem.name}
                            item={navItem}
                            onLinkClick={() => !isExtraLarge && close()}
                        />
                    ))}

                    <SignOutButton />
                </div>
            </nav>
        </>
    );
};

export default SideNavMenu;
