export type TalentWithdrawalsSummaryDto = {
    id: string
    amount: number
    statusId: number
    createdAt: string
}

export const talentWithdrawalStatusOptions = [
    { label: "Pending", value: 1 },
    { label: "Complete", value: 2 },
    { label: "Rejected / Cancelled", value: 3 },
];