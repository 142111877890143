import { Link } from "react-router-dom";
import { useAppPaths } from "../Routes";
import { editableProposalStatuses, proposalStatuses, ProposalSummary } from "../models/app/proposal";
import Card, { CardDataField } from "./Card";
import LinkButton from "./LinkButton";
import ProposalStatusChip from "./ProposalStatusChip";
import Typography from "./Typography";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { useIsLargeScreen } from "../hooks/screenSizes";

export type ProposalsSummaryCardProps = {
    proposal: ProposalSummary
}
const ProposalsSummaryCard = ({
    proposal
}: ProposalsSummaryCardProps) => {
    const appPaths = useAppPaths();
    const isGigOnHold = proposal.gigStatusId === 0;
    const { gigTerminology, giggedClientTerminology } = useOrganizationContext();
    const isLargeScreen = useIsLargeScreen();

    return (
        <Card key={proposal.id} className="mb-6 md:mb-4 flex flex-row items-end relative justify-between">
            <div className="flex flex-col space-y-4">
                <div className="space-y-2">
                    <Link to={appPaths.gigs.findAGigDetails(proposal.gigId)}>
                        <Typography className="font-medium" variant="title-medium" component="p">{proposal.gigTitle}</Typography>
                    </Link>
                    <Typography className="" variant="title-small" component="p">{proposal.giggedClientName}</Typography>
                </div>
                <ProposalStatusChip onHold={isGigOnHold} proposalStatusId={proposal.proposalStatusId} />
                {proposal.proposalStatusId === proposalStatuses.reviewRejected && (
                    <CardDataField label="Rejected reason" textClass="text-body">{proposal.rejectedReason}</CardDataField>
                )}
            </div>
            {editableProposalStatuses.includes(proposal.proposalStatusId) && (
                <LinkButton
                    disabled={isGigOnHold}
                    className="block min-w-[150px] md:ml-auto"
                    to={appPaths.proposals.edit(proposal.id)}
                    variant="secondary"
                >
                    {isGigOnHold ?
                        isLargeScreen ?
                            `${gigTerminology} placed on hold by ${giggedClientTerminology.toLowerCase()}` :
                            `${gigTerminology} on hold}` :
                        "Edit proposal"}
                </LinkButton>
            )}
        </Card>
    );
};

export default ProposalsSummaryCard;
