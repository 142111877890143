import { useGig } from "../../api/gig";
import useCurrentUser from "../../auth/useCurrentUser";
import Card from "../../components/Card";
import { useDialogBoxState } from "../../components/DialogBox";
import ProposalCoverLetterButton from "../../components/ProposalCoverLetterButton";
import Typography from "../../components/Typography";
import UserAvatar from "../../components/UserAvatar";
import { useGigId } from "../../Routes";
import CancelGigDialog from "../../components/CancelGigDialog";
import CompleteGigButton from "./CompleteGigButton";
import { gigStatuses } from "../../models/app/gig";
import GigDetailsCard from "../../components/GigDetailsCard";
import Button from "../../components/Button";
import PageContent from "../../components/PageContent";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import GigBudgetSection from "./GigBudgetSection";
import ClientMilestonePaymentSection from "./ClientMilestonePaymentSection";
import TalentMilestones from "./TalentMilestones";
import Loader from "../../components/Loader";

const GigDetailsPage = () => {
    const gigId = useGigId();
    const { gig, isLoading, reload: reloadGig } = useGig(gigId);
    const { userRole } = useCurrentUser();
    const cancelGigDialogState = useDialogBoxState();
    const { gigTerminology, talentTerminology, organizationConfig: { isPaymentsEnabled } } = useOrganizationContext();

    if (isLoading || !gig) return <Loader />;

    const proposal = gig.hiredProposal;

    const userDisplayName = proposal?.talentTradingName || `${proposal?.talentFirstName} ${proposal?.talentLastName}`;

    const isUserMilestones: boolean = isPaymentsEnabled ? ((proposal?.milestones.length || 0) > 1) || proposal?.milestones[0].description !== "DEFAULT" : false;

    return (
        <>
            <PageContent className="space-y-8">
                <div>
                    <Typography variant="display-small" component="h1" gutterBottom>{gigTerminology} detail</Typography>
                    <GigDetailsCard gig={gig} />
                </div>
                {userRole === "client" && (
                    <div>
                        <Typography variant="title-large" component="h2" className="mb-4">Hired {talentTerminology.toLowerCase()}</Typography>
                        {proposal && (
                            <div className="md:flex md:space-x-4">
                                <Card className="pb-0 md:max-w-[230px] md:pb-6 flex justify-center items-center rounded-b-none md:rounded-b-lg">
                                    <UserAvatar name={userDisplayName} userImageUrl={proposal.talentProfileImageThumbnailUrl} />
                                </Card>
                                <Card key={proposal.id} className="md:flex md:grow items-center space-y-4 md:space-y-0 rounded-t-none md:rounded-t-lg">
                                    <div className="mr-auto">
                                        <Typography variant="title-large" component="p" className="text-center break-words max-w-xl">{proposal.gigTitle}</Typography>
                                    </div>
                                    <div className="flex justify-center">
                                        <ProposalCoverLetterButton proposal={proposal} />
                                    </div>
                                </Card>
                            </div>
                        )}
                    </div>
                )}
                {isPaymentsEnabled && gig.hiredProposal && isUserMilestones && (
                    <>
                        <GigBudgetSection milestones={gig.hiredProposal.milestones} isVatRegistered={gig.hiredProposal.isVATRegistered}/>
                        {userRole === "client" ? (
                            <ClientMilestonePaymentSection
                                reloadGig={reloadGig}
                                milestones={gig.hiredProposal.milestones}
                                gigIsCancelled={gig.gigStatusId === gigStatuses.cancelled}
                                isVatRegistered={gig.hiredProposal.isVATRegistered}
                            />
                        ) : (
                            <TalentMilestones
                                milestones={gig.hiredProposal.milestones}
                                isVatRegistered={gig.hiredProposal.isVATRegistered}
                            />
                        )}
                    </>
                )}
                {userRole === "client" && (
                    <div className="flex flex-col-reverse md:flex-row md:justify-between">
                        {gig.gigStatusId !== gigStatuses.completed && (
                            <Button
                                type="button"
                                className="w-full md:w-auto"
                                variant="secondary"
                                disabled={gig?.gigStatusId === gigStatuses.cancelled}
                                onClick={cancelGigDialogState.open}
                            >
                                {gig.gigStatusId === gigStatuses.cancelled ? "Cancelled" : `Cancel ${gigTerminology.toLowerCase()}`}
                            </Button>
                        )}
                        <div className="grow"></div>
                        <CompleteGigButton
                            // Typescript glitch here, probably fixable but this works.
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            disabled={isUserMilestones && !!gig.hiredProposal?.milestones.some(_ => (_ as any).dateCompleted === undefined)}
                            gigId={gig.id}
                            className="w-full mb-4 md:mb-0 md:w-auto"
                        />
                    </div>
                )}
            </PageContent>
            <CancelGigDialog
                gigId={gig.id}
                isOpen={cancelGigDialogState.isOpen}
                onClose={cancelGigDialogState.onClose}
                requireReason
            />
        </>
    );
};

export default GigDetailsPage;
