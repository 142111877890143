import { CreateProposalMilestoneDto, UpdateProposalMilestoneDto } from "../models/app/proposal";
import { useAuthenticatedGigApiFetcher } from "./common/fetching";

export const useCreateProposalMilestone = () => useAuthenticatedGigApiFetcher<CreateProposalMilestoneDto, string, { proposalId: string, dto: CreateProposalMilestoneDto }>("POST",
    ({ proposalId, dto }) => ({
        url: `api/proposals/${proposalId}/milestones`,
        body: dto
    }));

export const useUpdateProposalMilestone = () => useAuthenticatedGigApiFetcher<UpdateProposalMilestoneDto, void, { milestoneId: string, dto: UpdateProposalMilestoneDto }>("PUT",
    ({ milestoneId, dto }) => ({
        url: `api/proposal-milestones/${milestoneId}`,
        body: dto
    }));

export const useDeleteProposalMilestone = () => useAuthenticatedGigApiFetcher<void, void, { milestoneId: string }>("DELETE",
    ({ milestoneId }) => ({
        url: `/api/proposal-milestones/${milestoneId}`
    }));