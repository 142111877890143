import { Rating } from "@mui/material";
import { CalendarBlank, Star } from "phosphor-react";
import { Link } from "react-router-dom";
import Typography from "../../components/Typography";
import { TalentReview } from "../../models/app/talent";
import { useAppPaths } from "../../Routes";
import DataPoint from "./DataPoint";

export type TalentReviewComponentProps = {
    review: TalentReview
}

const TalentReviewComponent = ({
    review
}: TalentReviewComponentProps) => {
    const appPaths = useAppPaths();

    return (
        <div>
            <Link to={appPaths.clients.details(review.clientId)}>
                <Typography variant="body" component="p">{review.clientName}</Typography>
            </Link>
            <Typography variant="title-small" component="h3" className="mb-2 break-words">{review.gigTitle}</Typography>
            <div className="flex items-center space-x-4">
                <DataPoint Icon={CalendarBlank}>{review.gigCompletedDate.toLocaleDateString()}</DataPoint>
                <Rating
                    value={review.rating ?? 0}
                    icon={<Star className="text-primary-text" weight="fill" size={16} />}
                    emptyIcon={<Star className="text-primary-text" color="black" size={16} />}
                    readOnly
                />
            </div>
            <Typography variant="body" component="p" className="italic mt-4">{review.feedback}</Typography>
        </div>
    );
};

export default TalentReviewComponent;
