import { Switch } from "@headlessui/react";
import clsx from "clsx";
import { CheckSquare, Square } from "phosphor-react";
import FormInputLabel from "./FormInputLabel";

export type FormCheckboxProps = {
    label: string
    checked: boolean
    tooltip?: string
    onChange: (checked: boolean) => void
    className?: string
    required?: boolean
    disabled?: boolean
}

const FormCheckbox = ({
    label,
    checked,
    tooltip,
    onChange,
    className,
    required,
    disabled
}: FormCheckboxProps) => {
    return (
        <div className={clsx("space-y-1 space-x-2 flex flex-row", className)}>   
            <Switch
                checked={checked}
                disabled={disabled}
                onChange={() => onChange(!checked)}
            >
                <div className={clsx("flex items-center w-full", disabled && "cursor-not-allowed")}>
                    {checked ? (
                        <CheckSquare size="20" weight="bold" className="shrink-0" />
                    ) : (
                        <Square size="20" weight="bold" className="shrink-0" />
                    )}
                </div>
            </Switch>

            {label && (
                <FormInputLabel className="w-full pb-1" required={required} tooltipText={tooltip}>{label}</FormInputLabel>
            )}
        </div>
    );
};

export default FormCheckbox;
