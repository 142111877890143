import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { AccountLogInUrlDto, AccountOnboardingUrlDto, ApiTalentDashboardDto } from "../models/api/talentDashboard";
import { mapFromApiTalentDashboard } from "../models/mappers/talentDashboard";
import { useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useCreateTalentWithdrawal } from "./payments";

export const useTalentDashboard = () => {
    const url = "api/me/talent-dashboard";

    const apiCall = useGiggedApiSWR<ApiTalentDashboardDto>(url);
    
    const [createWithdrawal, isCreatingWithdrawal] = useCreateTalentWithdrawal();

    return {
        dashboardData: apiCall.data ? mapFromApiTalentDashboard(apiCall.data) : undefined,
        isLoading: apiCall.isLoading,
        createWithdrawal: async (amount: number) => {
            const response = await createWithdrawal(amount);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isCreatingWithdrawal,
    };
};

export const useCreateStripeAccount = () => useAuthenticatedGigApiFetcher<never, AccountOnboardingUrlDto, void>("POST",
    () => ({
        url: "api/me/actions/create-talent-stripe-account"
    }));

export const useGetStripeAccountOnboardingUrl = () => useAuthenticatedGigApiFetcher<never, AccountOnboardingUrlDto, void>("GET",
    () => ({
        url: "api/me/query/stripe-account-onboarding-url"
    }));

export const useGetStripeAccountLogInUrl = () => useAuthenticatedGigApiFetcher<never, AccountLogInUrlDto, void>("GET",
    () => ({
        url: "api/me/query/stripe-account-login-url"
    }));
