import { useState } from "react";

import TabStepper from "../TabStepper";
import StepTalentProfileCreateIntro from "./TalentStepIntro";
import { TalentCreateProfilePersonalDetailsFormValues } from "./TalentCreateProfilePersonalDetailsFormValues";
import useBeforeUnload from "../../hooks/useWarnBeforeUnload";
import { useTalentPersonalInformationContext } from "../../api/talentPersonalInformation";
import CreateTalentProfileStepPersonalInformation from "./TalentStepPersonalInformation";
import CreateTalentProfileStepSkills from "./TalentStepSkills";
import CreateTalentProfileStepSpecialization from "./TalentStepSpecialization";
import CreateTalentProfileFormStepIndustryExperience from "./TalentStepIndustryExperience";
import CreateTalentProfileFormStepCareerHistory from "./TalentStepCareerHistory";
import CreateTalentProfileFormStepEducation from "./TalentStepEducation";
import CreateTalentProfileFormStepCertifications from "./TalentStepCertifications";
import CreateTalentProfileFormStepExternalLinks from "./TalentStepExternalLinks";

const TalentCreateProfileForm = () => {
    useBeforeUnload();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const { talentPersonalInformation } = useTalentPersonalInformationContext();
    const [createProfileFormData, setCreateProfileFormData] = useState<TalentCreateProfilePersonalDetailsFormValues>({
        firstName: talentPersonalInformation ? talentPersonalInformation.firstName : "",
        lastName: talentPersonalInformation ? talentPersonalInformation.lastName : "",
        tradingName: "",
        roleTitle: "",
        aboutMe: "",
        receiveNewGigNotifications: false,
        locationId: "",
        dailyRate: null,
        profileImageDataUri: "",
    });

    const updateAction = (data: Partial<TalentCreateProfilePersonalDetailsFormValues>) => {
        setCreateProfileFormData(oldValue => ({
            ...oldValue,
            ...data
        }));
    };

    const handleBackClick = (data: Partial<TalentCreateProfilePersonalDetailsFormValues>) => {
        updateAction(data);
        setSelectedIndex(value => value - 1);
        window.scrollTo(0, 0);
    };

    const pages = [
        {
            title: "Intro",
            content: <StepTalentProfileCreateIntro onNextClick={() => setSelectedIndex(selectedIndex + 1)} />
        },
        {
            title: "Skills",
            content: <CreateTalentProfileStepSkills onSubmit={() => setSelectedIndex(selectedIndex + 1)} onBackClick={() => setSelectedIndex(selectedIndex - 1)} />
        },
        {
            title: "Specialisation",
            content: <CreateTalentProfileStepSpecialization onSubmit={() => setSelectedIndex(selectedIndex + 1)} onBackClick={() => setSelectedIndex(selectedIndex - 1)} />
        },
        {
            title: "Industries",
            content: <CreateTalentProfileFormStepIndustryExperience onSubmit={() => setSelectedIndex(selectedIndex + 1)} onBackClick={() => setSelectedIndex(selectedIndex - 1)} />
        },
        {
            title: "Career",
            content: <CreateTalentProfileFormStepCareerHistory onSubmit={() => setSelectedIndex(selectedIndex + 1)} onBackClick={() => setSelectedIndex(selectedIndex - 1)} />
        },
        {
            title: "Education",
            content: <CreateTalentProfileFormStepEducation onSubmit={() => setSelectedIndex(selectedIndex + 1)} onBackClick={() => setSelectedIndex(selectedIndex - 1)} />
        },
        {
            title: "Certifications",
            content: <CreateTalentProfileFormStepCertifications onSubmit={() => setSelectedIndex(selectedIndex + 1)} onBackClick={() => setSelectedIndex(selectedIndex - 1)} />
        },
        {
            title: "Links",
            content: <CreateTalentProfileFormStepExternalLinks onSubmit={() => setSelectedIndex(selectedIndex + 1)} onBackClick={() => setSelectedIndex(selectedIndex - 1)} />
        },
        {
            title: "Personal",
            content: <CreateTalentProfileStepPersonalInformation onBackClick={handleBackClick} defaultValues={createProfileFormData} />
        },
    ];

    return (
        <TabStepper
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            pages={pages}
        />
    );
};

export default TalentCreateProfileForm;