import { useState } from "react";
import { Controller } from "react-hook-form";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import Button from "../../components/Button";
import DialogBox, { useDialogBoxState } from "../../components/DialogBox";
import FormDateInput from "../../components/FormDateInput";
import FormDialogBox, { FormDialogBoxProps } from "../../components/FormDialogBox";
import FormImageUploadInput, { useFormFileUploadInputState } from "../../components/FormFileUploadInput";
import FormTextInput from "../../components/FormTextInput";
import { CertificationFormValues, useCertificationForm } from "./CertificationFormValues";
import ApiError from "../../api/common/apiError";
import GeneralFormError from "../../components/GeneralFormError";

export type CertificationFormProps = Omit<FormDialogBoxProps, "title" | "children"> & {
    initialValues: CertificationFormValues
    existingFileUrl?: string
    resetOnSubmit?: boolean
    isEdit?: boolean
    onSubmit: (values: CertificationFormValues) => Promise<GigApiFetcherResponse<unknown>>
}

export const CertificationFormDialog = ({
    initialValues,
    existingFileUrl,
    resetOnSubmit,
    isEdit,
    onSubmit,
    ...dialogProps
}: CertificationFormProps) => {
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);
    const methods = useCertificationForm(initialValues);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { isDirty: areFieldsDirty } = methods.formState;

    const confirmCancelDialogState = useDialogBoxState();
    const { onClose } = dialogProps;

    const {
        urlOrDataUri: fileDataUrl,
        loadFile,
        error: fileError,
        isDirty: isFileDirty,
        reset: resetFileInput,
        ...fileInputProps
    } = useFormFileUploadInputState({
        maxFileSizeKb: 1000,
        initialUrl: existingFileUrl,
        allowPdfs: true,
    });

    const isDirty = areFieldsDirty || isFileDirty;

    const handleSubmit = async (values: CertificationFormValues) => {
        setIsSubmitting(true);

        try {
            const result = await onSubmit({
                ...values,
                fileDataUri: isFileDirty ? fileDataUrl : undefined,
            });

            if (result.success) {
                methods.reset(resetOnSubmit ? initialValues : values);
                methods.clearErrors();
                resetFileInput();
                onClose();
            } else {
                setSubmissionError(result.error);
            }
        }
        finally {
            setIsSubmitting(false);
        }
    };

    const handleCancelButton = () => {
        if (isDirty) {
            confirmCancelDialogState.open();
            return;
        }
        methods.clearErrors();
        onClose();
    };

    const handleDiscardChanges = () => {
        confirmCancelDialogState.close();
        methods.reset(initialValues);
        methods.clearErrors();
        resetFileInput();
        onClose();
    };

    return (
        <>
            <FormDialogBox
                {...dialogProps}
                isOpen={dialogProps.isOpen && !confirmCancelDialogState.isOpen}
                onClose={handleCancelButton}
                title="Award / certification"
            >
                <form onSubmit={methods.handleSubmit(handleSubmit)} className="space-y-6">
                    <div className="space-y-8">
                        <div className="space-y-4 md:flex md:space-y-0 md:space-x-6">
                            <FormTextInput
                                required
                                id="certifications-form-title"
                                label="Certification title"
                                placeholder="Enter certification title"
                                error={methods.formState.errors.title}
                                {...methods.register("title")}
                            />
                            <Controller
                                name="achievementDate"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDateInput
                                        required
                                        id="certifications-form-achievement-date"
                                        label="Achievement date"
                                        value={value}
                                        onChange={onChange}
                                        error={methods.formState.errors.achievementDate}
                                        maxDate={new Date()}
                                        hideHelpText
                                    />
                                )}
                            />
                        </div>
                        <FormImageUploadInput
                            required
                            inputId="certification-upload-button"
                            onLoadFile={loadFile}
                            error={fileError}
                            {...fileInputProps}
                            dataUrl={fileDataUrl}
                            isDirty={isFileDirty}
                        />
                    </div>
                    <GeneralFormError error={submissionError} />
                    <div className="flex flex-col-reverse md:flex-row justify-end items-center md:space-x-4">
                        <Button type="button" disabled={isSubmitting} className="w-full md:w-auto" variant="tertiary" onClick={handleCancelButton}>Cancel</Button>
                        <Button type="submit" disabled={(isEdit && !isDirty) || !!fileError} loading={isSubmitting} className="w-full mb-4 md:mb-0 md:w-auto" variant="primary">Save certification</Button>
                    </div>
                </form>
            </FormDialogBox>
            <DialogBox {...confirmCancelDialogState} title="Discard changes?">
                <div className="flex items-center sm:justify-between sm:space-x-4 flex-col-reverse sm:flex-row">
                    <Button className="w-full sm:w-fit mt-4 sm:mt-0" type="button" variant="secondary" onClick={confirmCancelDialogState.close}>Back</Button>
                    <Button className="w-full sm:w-fit" type="button" onClick={handleDiscardChanges}>Discard</Button>
                </div>
            </DialogBox>
        </>
    );
};