import { useInvoices } from "../api/invoices";
import Card from "../components/Card";
import LinkButton from "../components/LinkButton";
import Loader from "../components/Loader";
import MoneyDisplay from "../components/MoneyDisplay";
import PageContent from "../components/PageContent";
import Table from "../components/Table";
import Typography from "../components/Typography";
import { useIsLargeScreen } from "../hooks/screenSizes";
import { useAppPaths } from "../Routes";
import formatDateOnlyString from "../utils/formatDateString";

const InvoicesListPage = () => {
    const appPaths = useAppPaths();
    const { invoices, isLoading } = useInvoices();
    const isLargeScreen = useIsLargeScreen();

    if (isLoading) {
        return <Loader />;
    }

    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>Invoices</Typography>
            {invoices.length === 0 ? (
                <Card>
                    <Typography variant="title-large" component="p">None yet...</Typography>
                </Card>
            ) : (
                <Table
                    headings={["Id", "Date Created", "Amount", ""]}
                    data={invoices}
                    renderRowCells={invoice => (
                        <>
                            <td>{invoice.id}</td>
                            <td>{formatDateOnlyString(invoice.createdDate.toISOString())}</td>
                            <td>
                                <div className="flex">
                                    <MoneyDisplay value={invoice.amount} />
                                    {invoice.isWorksome && <img className="max-h-[20px] ml-2" src="/worksome-icon-40x40.svg" alt="Worksome logo"/>}
                                </div>
                            </td>
                            <td><LinkButton className="max-w-[150px] ml-auto child:p-2 sm:child:py-3 sm:child:px-6" to={appPaths.invoices.details(invoice.id)} variant="secondary">
                                <span className="inline sm:hidden">View</span>
                                <span className="hidden sm:inline">View invoice</span>
                            </LinkButton></td>
                        </>
                    )}
                />
            )}
        </PageContent>
    );
};

export default InvoicesListPage;
