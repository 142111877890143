import { useEffect, useState } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import Button from "../Button";
import { GigFormValues } from "../GigForm";
import { formClassNames } from "./classNames";
import ApiError from "../../api/common/apiError";
import Typography from "../Typography";
import DialogBox, { useDialogBoxState } from "../DialogBox";
import GigFormFields from "../GigForm/GigFormFields";
import { useGigForm } from "../GigForm/GigFormValues";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { useAppPaths } from "../../Routes";
import GeneralFormError from "../GeneralFormError";
import { differenceInBusinessDays } from "date-fns";
import { getExpectedDurationId } from "../../utils/getExpectedDurationId";

export type CreateGigFormStepSummaryProps = {
    onSaveAsDraft: (value: GigFormValues) => void
    onSaveAndPost: (value: GigFormValues) => void
    defaultValues: GigFormValues
    isSubmitting: boolean
    submissionError?: ApiError
}

const CreateGigFormStepSummary = ({
    onSaveAsDraft,
    onSaveAndPost,
    defaultValues,
    isSubmitting,
    submissionError,
}: CreateGigFormStepSummaryProps) => {
    const appPaths = useAppPaths();
    const [hideSkillsDropdown, setHideSkillsDropdown] = useState(true);
    const confirmCancelDialogState = useDialogBoxState();
    const navigate = useNavigate();
    const { gigTerminology, organizationConfig } = useOrganizationContext();
    
    const methods = useGigForm(organizationConfig.isPaymentsEnabled, defaultValues);
    const { setValue, clearErrors, watch } = methods;
    const { skills, isTimeAndMaterial, startDate, deadlineDate, dailyRate, numberOfDays, budget } = watch();

    useEffect(function setTimeAndMaterialDuration(){
        if (!isTimeAndMaterial) return;

        if (startDate && deadlineDate) {
            const newNumberOfDays = differenceInBusinessDays(new Date(deadlineDate), new Date(startDate)) + 1;
            setValue("numberOfDays", newNumberOfDays, { shouldValidate: true });
            setValue("expectedDurationId", getExpectedDurationId(newNumberOfDays), { shouldValidate: true });
        }
    }, [isTimeAndMaterial, startDate, deadlineDate, numberOfDays]);

    useEffect(function setGigBudgetBasedOnDailyRate() {
        if (!isTimeAndMaterial || !dailyRate || !numberOfDays) return;

        methods.setValue("budget", dailyRate * numberOfDays);
        clearErrors(["numberOfDays", "dailyRate", "budget"]);

    }, [dailyRate, budget, numberOfDays]);

    useEffect(() => {
        if (skills.length < 3) setHideSkillsDropdown(false);
    }, [skills]);

    const handleSaveAsDraft = methods.handleSubmit(onSaveAsDraft);

    const handleSaveAsDraftInModal = () => {
        confirmCancelDialogState.close();
        handleSaveAsDraft();
    };

    const handleDiscardChanges = () => {
        navigate(appPaths.gigs.indexDraft);
    };

    return (
        <>
            <form
                className={clsx(formClassNames, "p-8")}
                onSubmit={methods.handleSubmit(onSaveAndPost)}
                noValidate
            >
                <div className="flex items-center justify-between">
                    <Typography variant="display-medium" component="h1" gutterBottom>
                        Review the {gigTerminology.toLowerCase()}
                    </Typography>
                </div>
                <GigFormFields
                    methods={methods}
                    hideSkillsDropdown={hideSkillsDropdown}
                    initialValues={defaultValues}
                />
                <GeneralFormError error={submissionError} className="ml-8" />
                <div className={clsx(
                    "mb-0 mx-0 mt-8 space-y-4",
                    "md:flex md:flex-row-reverse md:justify-end md:space-y-0"
                )}>
                    <Button type="submit" loading={isSubmitting} className="w-full md:w-auto">Post {gigTerminology.toLowerCase()}</Button>
                    <Button type="button" loading={isSubmitting} className="w-full md:w-auto md:mr-4" variant="secondary" onClick={handleSaveAsDraft}>Save as draft</Button>
                    <Button type="button" disabled={isSubmitting} className="w-full md:w-auto md:mr-auto" variant="tertiary" onClick={confirmCancelDialogState.open}>Cancel</Button>
                </div>
            </form>
            <DialogBox
                {...confirmCancelDialogState}
                title={`Discard changes to "${methods.watch("title")}"?`}
                maxWidth="400px"
            >
                <div className="space-y-4">
                    <Button className="block mx-auto w-full" onClick={handleDiscardChanges}>Discard changes</Button>
                    <Button className="block mx-auto w-full" variant="secondary" onClick={handleSaveAsDraftInModal}>Save as draft</Button>
                    <Button className="block mx-auto w-full" variant="tertiary" onClick={confirmCancelDialogState.close}>Back</Button>
                </div>
            </DialogBox>
        </>
    );
};

export default CreateGigFormStepSummary;