import { Briefcase } from "phosphor-react";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import useCurrentUser from "../auth/useCurrentUser";
import Card from "../components/Card";

import LinkButton from "../components/LinkButton";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { useAppPaths } from "../Routes";

const GigCreateIntroPage = () => {
    const appPaths = useAppPaths();
    const { userGivenName } = useCurrentUser();
    const { gigTerminology, talentTerminology, organizationConfig } = useOrganizationContext();

    return (
        <PageContent>
            <Card className="md:mx-auto max-w-6xl text-center">
                <Briefcase size={64} className="text-primary-text mx-auto mt-2 mb-6" weight="duotone" />
                <Typography variant="display-small" component="h1" gutterBottom>{`Ready to create your ${gigTerminology.toLowerCase()} with us, ${userGivenName}`}?</Typography>
                <p className="mb-6">We'll ask you some key questions to create the perfect {gigTerminology.toLowerCase()}, and then match you with the best {talentTerminology.toLowerCase()}.</p>
                <span className="flex flex-col items-center mx-auto mb-6">
                    <p className="font-bold mb-2 text-center">Information you'll need:</p>
                    <ul className="list-disc text-left list-inside columns-2">
                        <li>{gigTerminology} title</li>
                        <li>Description</li>
                        <li>Current stage</li>
                        <li>Skills required</li>
                        <li>Start date</li>
                        <li>Duration</li>
                        {organizationConfig.isPaymentsEnabled && <li>Budget</li>}
                        <li>Location</li>
                    </ul>
                </span>
                <div className="md:flex md:flex-row-reverse md:items-center md:justify-start space-y-4 md:space-y-0">
                    <LinkButton
                        to={appPaths.gigs.create}
                        className="max-w-full md:max-w-md block"
                    >
                        Create {gigTerminology.toLowerCase()}
                    </LinkButton>
                    <LinkButton
                        to={appPaths.dashboard}
                        variant="tertiary"
                        className="md:mr-2 max-w-full md:max-w-md block"
                    >
                        Cancel
                    </LinkButton>
                </div>
            </Card>
        </PageContent>
    );
};

export default GigCreateIntroPage;
