import Button from "../Button";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { formClassNames } from "../CreateGigForm/classNames";
import TalentCreateProfileFormPageLayout from "./TalentCreateProfileFormPageLayout";
import { useIsMediumScreen } from "../../hooks/screenSizes";
import { useTalentCertifications } from "../../api/talentCertifications";
import Certifications from "../../pages/TalentProfileEdit/Certifications";

export type CreateTalentProfileFormStepCertificationsProps = {
    onSubmit: () => void
    onBackClick: () => void
}

const CreateTalentProfileFormStepCertifications = ({
    onSubmit,
    onBackClick,
}: CreateTalentProfileFormStepCertificationsProps) => {
    const { giggedClientTerminologyPlural } = useOrganizationContext();
    const isMediumScreen = useIsMediumScreen();
    const certifications = useTalentCertifications();

    const skipButtonText = isMediumScreen ? "Skip for now" : "Skip";

    return (
        <div className={formClassNames} >
            <TalentCreateProfileFormPageLayout
                title="Showcase your certifications and awards"
                description={`Highlight any certifications or awards relevant to your skills. Some ${giggedClientTerminologyPlural.toLowerCase()} may require specific certifications, and listing them can open up more opportunities for you.`}
                content={ <Certifications talentCertifications={certifications} /> }
                iconName="Certificate"
                buttons={
                    <>
                        <Button variant="secondary" type="button" onClick={onBackClick}>Back</Button>
                        <div className="flex space-x-4 child:flex-grow sm:child:flex-grow-0">
                            <Button
                                type="button"
                                onClick={onSubmit}
                                variant={certifications.certifications.length > 0 ? "primary" : "tertiary"}
                            >
                                {certifications.certifications.length > 0 ? "Next" : skipButtonText}
                            </Button>                        
                        </div>
                    </>
                }
            />
        </div>
    );
};

export default CreateTalentProfileFormStepCertifications;
