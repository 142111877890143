import { useOrganizationContext } from "../api/current-organization/organizationContext";
import useCurrentUser from "../auth/useCurrentUser";
import { GigDetails } from "../models/app/gig";
import Card, { CardDataFieldLabel, CardDataField } from "./Card";
import Chip, { ChipContainer } from "./Chip";
import GigFilesAccordion from "./GigFilesAccordion/GigFilesAccordion";
import MoneyDisplay from "./MoneyDisplay";
import TitleUnderline from "./TitleUnderline";
import Typography from "./Typography";

export type GigDetailsCardProps = {
    gig: GigDetails
}

const GigDetailsCard = ({
    gig,
}: GigDetailsCardProps) => {
    const { userRole } = useCurrentUser();
    const { organizationConfig } = useOrganizationContext();

    return (
        <Card key={gig.id} className="md:flex break-words">            
            <div className="space-y-4 w-full md:mr-8 relative mb-6 md:mb-0">                
                <div className="">
                    {userRole === "talent" && (
                        <Typography className="mb-4" variant="title-large" component="p">{gig.giggedClientName || gig.giggedClientCompanyName}</Typography>
                    )}                    
                    <Typography variant="display-small" className="max-w-xl mb-4" component="h1">{gig.title}</Typography>
                    <Typography variant="body" component="p" className="italic whitespace-pre-wrap">{gig.description}</Typography>
                    <TitleUnderline fullWidth />                    
                </div>
                <div className="mt-4">
                    <CardDataFieldLabel>Top three skills needed</CardDataFieldLabel>
                    <ChipContainer className="mt-2 mr-4">
                        {gig.skills.map(skill => (
                            <Chip key={skill.name} variant="secondary" label={skill.name} />
                        ))}
                    </ChipContainer>
                </div>
                {
                    userRole && gig.files && gig.files.length > 0 && (
                        <GigFilesAccordion files={gig.files}/>
                    )
                }
            </div>
            <div className="md:ml-auto md:self-start md:shrink-0 space-y-8">
                <div className="child:p-4 child:border-surface border border-surface rounded-lg">
                    <CardDataField className="border-b" label="Initial stage">{gig.initialStageLabel}</CardDataField>
                    <CardDataField className="border-b" label="Expected duration">{gig.expectedDurationLabel}</CardDataField>
                    {organizationConfig.isPaymentsEnabled && gig.isTimeAndMaterial && <CardDataField className="border-b" label="No. of days">{gig.numberOfDays || 0}</CardDataField>}
                    {organizationConfig.isPaymentsEnabled && gig.isTimeAndMaterial && <CardDataField className="border-b" label="Daily rate"><MoneyDisplay value={gig.dailyRate || 0} /></CardDataField>}
                    {organizationConfig.isPaymentsEnabled && <CardDataField className="border-b" label="Budget"><MoneyDisplay value={gig.budget || 0} /></CardDataField>}
                    <CardDataField className="" label="Remote">{gig.isCompletedRemotely ? "Yes" : "No"}</CardDataField>
                </div>
            </div>
        </Card>
    );
};

export default GigDetailsCard;
