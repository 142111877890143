import { useState } from "react";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { usePayMilestoneWithPo } from "../../api/payments";
import Button from "../../components/Button";
import DialogBox from "../../components/DialogBox";
import { DialogBoxState } from "../../components/DialogBox/useDialogBoxState";
import FormRadioGroup from "../../components/FormRadioGroup";
import { CheckoutSessionUrlDto, MilestoneCheckoutUrlDto } from "../../models/api/payments";
import MilestonePurchaseOrderForm from "./MilestonePurchaseOrderForm";
import { MilestonePurchaseOrderFormValues } from "./MilestonePurchaseOrderFormValues";

export type MilestonePurchaseOrderDialogProps = {
    milestoneId: string
    dialogBoxState: DialogBoxState,
    clientReferenceId?: string
    getMilestoneCheckoutSessionUrl: (arg: MilestoneCheckoutUrlDto) => Promise<GigApiFetcherResponse<CheckoutSessionUrlDto>>,
    onSuccess: () => void
}

const MilestonePurchaseOrderDialog = ({
    milestoneId,
    dialogBoxState,
    clientReferenceId,
    getMilestoneCheckoutSessionUrl,
    onSuccess,
}: MilestonePurchaseOrderDialogProps) => {
    const [paymentOption, setPaymentOption] = useState<"stripe" | "po">("stripe");
    const [payMilestoneWithPo, isPayingMilestoneWithPo] = usePayMilestoneWithPo();
    const [isPayingWithStripe, setIsPayingWithStripe] = useState(false);

    const handlePayWithStripe = async () => {
        setIsPayingWithStripe(true);

        const result = await getMilestoneCheckoutSessionUrl({milestoneId, clientReferenceId});

        if (result.success) {
            window.location.replace(result.content.checkoutSessionUrl);
        }

        setIsPayingWithStripe(false);
    };

    const handlePayWithPo = async (values: MilestonePurchaseOrderFormValues) => {
        const response = await payMilestoneWithPo({ 
            milestoneId: milestoneId,
            dto: values
        });

        if (response.success) {
            onSuccess();
        }
    };

    const handleSelectPaymentOption = (value: string | number) => {
        if (value !== "po" && value !== "stripe") {
            return;
        }

        setPaymentOption(value);
    };
    
    return (
        <DialogBox
            {...dialogBoxState}
            title="How would you like to pay?"
            maxWidth="500px"
        >
            <div>
                <FormRadioGroup
                    required
                    label="Select payment option"
                    options={[
                        { label: "Credit card (Stripe)", value: "stripe" },
                        { label: "Purchase order", value: "po" },                                
                    ]}
                    defaultValue="stripe"
                    onChange={handleSelectPaymentOption}
                    value={paymentOption}
                />
            </div>
            {paymentOption === "po" && (
                <MilestonePurchaseOrderForm
                    dialogBoxState={dialogBoxState}
                    onSubmit={handlePayWithPo}
                    isPayingMilestoneWithPo={isPayingMilestoneWithPo}
                />
            )}

            {paymentOption === "stripe" && (
                <div className="flex">
                    <Button 
                        variant="secondary" 
                        onClick={dialogBoxState.close}
                        disabled={isPayingWithStripe}
                    >
                        Cancel
                    </Button>
                    <Button 
                        className="block ml-auto" 
                        variant="primary" 
                        onClick={handlePayWithStripe}
                        disabled={isPayingWithStripe}
                        loading={isPayingWithStripe}
                    >
                        Confirm
                    </Button>
                </div>
            )}
        </DialogBox>
    );
};

export default MilestonePurchaseOrderDialog;