import Card from "../components/Card";
import PageContent from "../components/PageContent";
import { TalentUpdatePayoutAccountFormValues } from "../components/TalentUpdatePayoutAccountForm/TalentUpdatePayoutAccountFormValues";
import Typography from "../components/Typography";
import { successToast } from "../toast";
import { useTalentPayoneerEmail } from "../api/talentPayoutAccount";
import TalentUpdatePayoutAccountForm from "../components/TalentUpdatePayoutAccountForm/TalentUpdatePayoutAccountForm";
import Loader from "../components/Loader";

const PayoutAccountPage = () => {

    const { payoneerEmail, updatePayoneerEmail, isUpdatingPayoneerEmail, isLoading } = useTalentPayoneerEmail();

    if (isLoading) {
        return <Loader />;
    }

    const handleSubmit = async (value: TalentUpdatePayoutAccountFormValues) => {
        const response = await updatePayoneerEmail(value);

        if (response.success) {
            successToast("Payout account details successfully updated.");
        }

        return response;
    };

    return (
        <PageContent>
            <Card>
                <Typography variant="title-small" component="h2" className="mr-auto font-bold p-6">
                    Payout account
                </Typography>
                <TalentUpdatePayoutAccountForm 
                    payoneerDetails={payoneerEmail}
                    onSubmit={handleSubmit}
                    isSubmitting={isUpdatingPayoneerEmail}
                />
            </Card>
        </PageContent>
    );
};

export default PayoutAccountPage;