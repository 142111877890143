import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../Button";
import { useForm } from "react-hook-form";
import FormTextInput from "../FormTextInput";
import { TalentUpdatePayoutAccountFormValues, validationSchemaFields } from "./TalentUpdatePayoutAccountFormValues";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { ApiTalentPayoutDetailsDto } from "../../models/api/talentPayoutAccount";
import { useState } from "react";
import ApiError from "../../api/common/apiError";
import GeneralFormError from "../GeneralFormError";

export type TalentUpdatePayoutAccountFormProps = {
    payoneerDetails?: ApiTalentPayoutDetailsDto
    isSubmitting: boolean
    onSubmit: (value: TalentUpdatePayoutAccountFormValues) => Promise<GigApiFetcherResponse<unknown>>
}

const TalentUpdatePayoutAccountForm = ({
    payoneerDetails,
    isSubmitting,
    onSubmit
}: TalentUpdatePayoutAccountFormProps) => {
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);

    const validationSchema = yup.object(validationSchemaFields);

    const methods = useForm<TalentUpdatePayoutAccountFormValues>({
        defaultValues: {
            payoneerEmail: payoneerDetails?.payoneerEmail || ""
        },
        resolver: yupResolver(validationSchema)
    });

    const handleSubmit = async (value: TalentUpdatePayoutAccountFormValues) => {
        const response = await onSubmit(value);

        if (!response.success) {
            setSubmissionError(response.error);
        } else {
            methods.reset(value);
        }

        return response;
    };

    return (
        <div className="p-6 space-y-4">
            <p>We use third-party payment vendor Payoneer to transfer your earnings.</p>
            <p>To get paid through Payoneer, simply register and set up a Payoneer account then save the email address associated with your new account below.</p>
            <p><a target="_blank" rel="noopener noreferrer" href="https://www.payoneer.com/" className="underline font-bold">Click here</a> to set up your Payoneer account</p>
            <form className="!mt-8 space-y-6" noValidate onSubmit={methods.handleSubmit(handleSubmit)}>
                <FormTextInput
                    required
                    id="payoneer-email"
                    label="Payoneer email address"
                    placeholder="Enter payoneer email address"
                    error={methods.formState.errors.payoneerEmail}
                    tooltip="Once you have created an account on Payoneer.com, enter the email address associated with your Payoneer account here."
                    {...methods.register("payoneerEmail")}
                />

                <GeneralFormError error={submissionError} className="ml-8" />

                <div className="flex justify-end">
                    <Button type="submit" loading={isSubmitting} disabled={!methods.formState.isDirty || isSubmitting}>Save and update</Button>
                </div>
            </form>
        </div>
    );
};

export default TalentUpdatePayoutAccountForm;