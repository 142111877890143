import { GigApiPaginatedData } from "./common/apiResponse";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { TalentWithdrawalsSummaryDto } from "../models/api/talentWithdrawals";

export type UseTalentWithdrawalsReturn = {
    talentWithdrawals: TalentWithdrawalsSummaryDto[]
    isLoading: boolean
};

export const useTalentWithdrawals = (): UseTalentWithdrawalsReturn => {

    const url = "api/me/withdrawals?pageSize=100";

    const apiCall = useGiggedApiSWR<GigApiPaginatedData<TalentWithdrawalsSummaryDto>>(url);

    return {
        talentWithdrawals: (apiCall.data?.items || []),
        isLoading: apiCall.isLoading,
    };
};