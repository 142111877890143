import { useState } from "react";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { usePayProposalWithPo } from "../../api/payments";
import Button from "../../components/Button";
import DialogBox from "../../components/DialogBox";
import { DialogBoxState } from "../../components/DialogBox/useDialogBoxState";
import FormRadioGroup from "../../components/FormRadioGroup";
import { CheckoutSessionUrlDto, MilestoneCheckoutUrlDto } from "../../models/api/payments";
import ProposalPurchaseOrderForm from "./ProposalPurchaseOrderForm";
import { ProposalPurchaseOrderFormValues } from "./ProposalPurchaseOrderFormValues";

export type ProposalPurchaseOrderDialogProps = {
    proposalId: string
    milestoneId: string
    dialogBoxState: DialogBoxState,
    clientReferenceId?: string
    isWorksome: boolean
    isTimeAndMaterial: boolean
    getMilestoneCheckoutSessionUrl: (arg: MilestoneCheckoutUrlDto) => Promise<GigApiFetcherResponse<CheckoutSessionUrlDto>>,
    onSuccess: () => void
}

const ProposalPurchaseOrderDialog = ({
    proposalId,
    milestoneId,
    dialogBoxState,
    clientReferenceId,
    isWorksome,
    isTimeAndMaterial,
    getMilestoneCheckoutSessionUrl,
    onSuccess,
}: ProposalPurchaseOrderDialogProps) => {
    const [paymentOption, setPaymentOption] = useState<"stripe" | "po">("stripe");
    const [payProposalWithPo, isPayingProposalWithPo] = usePayProposalWithPo();
    const [isPayingWithStripe, setIsPayingWithStripe] = useState(false);

    const handlePayWithStripe = async () => {
        setIsPayingWithStripe(true);

        const result = await getMilestoneCheckoutSessionUrl({milestoneId, clientReferenceId});

        if (result.success) {
            window.location.replace(result.content.checkoutSessionUrl);
        }

        setIsPayingWithStripe(false);
    };

    const handlePayWithPo = async (values: ProposalPurchaseOrderFormValues) => {
        const response = await payProposalWithPo({ 
            proposalId: proposalId,
            dto: values
        });

        if (response.success) {
            onSuccess();
        }
    };

    const handleSelectPaymentOption = (value: string | number) => {
        if (value !== "po" && value !== "stripe") {
            return;
        }

        setPaymentOption(value);
    };
    
    return (
        <DialogBox
            {...dialogBoxState}
            title="How would you like to pay?"
            maxWidth="500px"
        >
            <div>
                <FormRadioGroup
                    required
                    label="Select payment option"
                    options={isWorksome ?
                        [{ label: "Purchase order", value: "po" }] : 
                        [
                            { label: "Credit card (Stripe)", value: "stripe" },
                            { label: "Purchase order", value: "po" },                                
                        ]
                    }
                    defaultValue={isWorksome ? "po" : "stripe"}
                    onChange={handleSelectPaymentOption}
                    value={paymentOption}
                />
            </div>
            {paymentOption === "po" && (
                <ProposalPurchaseOrderForm
                    dialogBoxState={dialogBoxState}
                    onSubmit={handlePayWithPo}
                    isPayingProposalWithPo={isPayingProposalWithPo}
                />
            )}

            {paymentOption === "stripe" && (
                <div className="flex">
                    <Button 
                        variant="secondary" 
                        onClick={dialogBoxState.close}
                        disabled={isPayingWithStripe}
                    >
                        Cancel
                    </Button>
                    <Button 
                        className="block ml-auto" 
                        variant="primary"
                        onClick={handlePayWithStripe}
                        disabled={isPayingWithStripe}
                        loading={isPayingWithStripe}
                    >
                        Confirm
                    </Button>
                </div>
            )}
        </DialogBox>
    );
};

export default ProposalPurchaseOrderDialog;