import * as yup from "yup";

export const validationSchemaFields = {
    skillId: yup.string().required("Skill is required."),
    yearsExperience: yup.number().required("Years of experience is required.")
};

export type TalentSkillsFormValues = {
    skillId: string;
    yearsExperience: number;
};