import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import ApiError from "../../api/common/apiError";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { ApiFile } from "../../models/api/file";
import { talentProposalTooltips } from "../../tooltipsContent";
import { convertFileToDataUrl } from "../../utils/convertFileToDataUrl";
import Button from "../Button";
import { formClassNames } from "../CreateGigForm/classNames";
import FileManager from "../FileManager";
import FormTextAreaInput from "../FormTextAreaInput";
import FormTextInput from "../FormTextInput";
import FormToggle from "../FormToggle";
import GeneralFormError from "../GeneralFormError";
import ProposalFormMilestones from "./CreateProposalFormMilestones";
import { CreateProposalFormValues, validationSchemaFields } from "./CreateProposalFormValues";
import ProposalFormCostBreakdown from "./ProposalFormCostBreakdown";
import InfoBox from "../../components/InfoBox";
import Typography from "../Typography";

export type CreateProposalFormProps = {
    initialValues?: CreateProposalFormValues
    onSubmit: (value: CreateProposalFormValues, proposalFiles: ApiFile[], isTimeAndMaterial: boolean) => Promise<GigApiFetcherResponse<unknown>>
    submitButtonText: string
    isSubmitting: boolean
    proposalFiles?: ApiFile[]
    gigBudget: number | null
    isWorksomeGig: boolean
    isTimeAndMaterial: boolean
}

const CreateProposalForm = ({
    initialValues,
    onSubmit,
    submitButtonText,
    isSubmitting,
    proposalFiles: initialProposalFiles = [],
    gigBudget,
    isWorksomeGig,
    isTimeAndMaterial
}: CreateProposalFormProps) => {
    const { gigTerminology, gigTerminologyPlural, organizationConfig, giggedClientTerminology } = useOrganizationContext();

    const [proposalFiles, setProposalFiles] = useState<ApiFile[]>(initialProposalFiles);
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);

    const handleSubmit = async (values: CreateProposalFormValues) => {

        if (!values.isVATRegistered) {
            values.vatNumber = undefined;
        }

        const response = await onSubmit(values, proposalFiles, isTimeAndMaterial);

        if (!response.success) setSubmissionError(response.error);
    };

    const validationSchema = yup.object(validationSchemaFields(isTimeAndMaterial));

    const methods = useForm<CreateProposalFormValues>({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const { talentDefinedMilestones, proposedAmount, milestones, paymentsEnabled, isVATRegistered: vatRegistered, dailyRate, estimatedDurationDays } = methods.watch();

    useEffect(function calculateProposedAmount() {
        if (isTimeAndMaterial) {
            if (dailyRate && estimatedDurationDays) {
                const calculatedAmount = dailyRate * estimatedDurationDays;
                methods.setValue("proposedAmount", calculatedAmount);
            }
        }
    }, [isTimeAndMaterial, dailyRate, estimatedDurationDays]);

    const handleFileDrop = async (file: File) => {
        const dataUrl = await convertFileToDataUrl(file);

        const proposalFile: ApiFile = {
            id: Math.random().toString(),
            url: dataUrl,
            name: file.name,
            totalBytes: file.size
        };

        setProposalFiles([...proposalFiles, proposalFile]);
    };

    const handleFileDelete = (fileId: string) => {
        const updatedProposalFiles = proposalFiles.filter(proposalFile => proposalFile.id !== fileId);
        setProposalFiles(updatedProposalFiles);
    };

    useEffect(function setPaymentsEnabledAsFormValue() {
        // We need paymentsEnabled as a form value to conditional yup validation
        // we set the value here as we need to wait for the config to be fetched
        methods.setValue("paymentsEnabled", organizationConfig.isPaymentsEnabled);
    }, [organizationConfig, methods]);

    return (
        <form className={clsx(formClassNames, "p-8 space-y-8")} noValidate onSubmit={methods.handleSubmit(handleSubmit)}>
            {isWorksomeGig && (
                <InfoBox className="">
                    <Typography variant="body" component="p" className="font-bold">Third-party provider</Typography>
                    <Typography variant="body" component="p" className="whitespace-pre-wrap">
                        If the {organizationConfig.giggedClientTerminology.toLowerCase()} approves your proposal,
                        the {organizationConfig.gigTerminology.toLowerCase()} will be handled by one of our partners,
                        including payments and contractual details.
                    </Typography>
                </InfoBox>
            )}
            {paymentsEnabled &&
                <>
                    <Controller
                        name="isVATRegistered"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormToggle
                                label="VAT registered"
                                description="Are you VAT registered?"
                                tooltip={talentProposalTooltips.vatRegistered(gigTerminology)}
                                onChange={onChange}
                                checked={value}
                            />)}
                    />
                    {vatRegistered && (
                        <FormTextInput
                            required
                            id="create-proposal-vat-number"
                            tooltip={talentProposalTooltips.vatNumber}
                            label="VAT number"
                            placeholder="Enter your VAT number"
                            type="text"
                            error={methods.formState.errors.vatNumber}
                            defaultValue={""}
                            {...methods.register("vatNumber")}
                        />
                    )}
                    {!isTimeAndMaterial && (
                        <Controller
                            name="talentDefinedMilestones"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormToggle
                                    label={`${gigTerminology} milestones`}
                                    description={`Split this ${gigTerminology.toLowerCase()} into milestones`}
                                    tooltip={talentProposalTooltips.milestones(gigTerminology, giggedClientTerminology)}
                                    onChange={onChange}
                                    checked={value}
                                />)}
                        />
                    )}
                    
                    {!talentDefinedMilestones ? (
                        <div>
                            {isTimeAndMaterial ? (
                                <div className="flex flex-col gap-6">
                                    <p className="text-sm italic">
                                        The {giggedClientTerminology.toLowerCase()} has specified a preference for payment using a day rate for this {gigTerminology.toLowerCase()}. Therefore, this proposal requires you to enter a daily rate.                                    
                                    </p>
                                    <div className="flex flex-col sm:flex-row gap-4 sm:mb-4">
                                        <FormTextInput
                                            required
                                            tooltip={talentProposalTooltips.dailyRate(gigTerminology)}
                                            label="Daily rate (£)"
                                            placeholder="Enter your proposed daily rate"
                                            min={0}
                                            defaultValue={0}
                                            type="number"
                                            error={methods.formState.errors.dailyRate}
                                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                            {...methods.register("dailyRate")}
                                        />
                                        <FormTextInput
                                            required
                                            tooltip={talentProposalTooltips.duration(gigTerminology)}
                                            id="create-proposal-form-estimated-duration-days"
                                            label="Estimated duration (days)"
                                            placeholder="Enter the estimated duration in days"
                                            error={methods.formState.errors.estimatedDurationDays}
                                            defaultValue={0}
                                            type="number"
                                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                            min={1}
                                            step={1}
                                            {...methods.register("estimatedDurationDays")}
                                        />
                                        <input type="hidden" {...methods.register("proposedAmount")} name="proposedAmount" value={proposedAmount || 0} />
                                    </div>
                                </div>
                            ) : (
                                <FormTextInput
                                    required
                                    tooltip={talentProposalTooltips.proposedAmount(gigTerminology)}
                                    label="Proposed amount (£)"
                                    placeholder="Enter your proposed amount"
                                    min={0}
                                    defaultValue={0}
                                    type="number"
                                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                    error={methods.formState.errors.proposedAmount}
                                    {...methods.register("proposedAmount")}
                                    disabled={isTimeAndMaterial}
                                />
                            )}
                        </div>
                    ) : (
                        <ProposalFormMilestones methods={methods} isTimeAndMaterial={isTimeAndMaterial} dailyRate={dailyRate} />
                    )}

                    <ProposalFormCostBreakdown
                        gigBudget={gigBudget ?? 0}
                        proposedAmount={+proposedAmount ?? 0}
                        milestones={milestones ?? []}
                        talentDefinedMilestones={talentDefinedMilestones}
                        vatRegistered={vatRegistered}
                    />

                </>
            }

            {!isTimeAndMaterial &&
                <FormTextInput
                    required
                    tooltip={talentProposalTooltips.duration(gigTerminology)}
                    id="create-proposal-form-estimated-duration-days"
                    label="Estimated duration (days)"
                    placeholder="Enter the estimated duration in days"
                    error={methods.formState.errors.estimatedDurationDays}
                    defaultValue={0}
                    type="number"
                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                    min={1}
                    step={1}
                    {...methods.register("estimatedDurationDays")}
                />
            }

            <FormTextAreaInput
                required
                tooltip={talentProposalTooltips.introduction(giggedClientTerminology)}
                id="create-proposal-form-introduction"
                label="Introduction"
                placeholder="Enter an introduction"
                register={methods.register("introduction")}
                error={methods.formState.errors.introduction}
                defaultValue={""}
            />

            <FormTextAreaInput
                required
                tooltip={talentProposalTooltips.experience(giggedClientTerminology, gigTerminology, gigTerminologyPlural)}
                id="create-proposal-form-relevant-experience"
                label="Relevant experience"
                placeholder="Enter your relevant experience"
                register={methods.register("relevantExperience")}
                error={methods.formState.errors.relevantExperience}
                defaultValue={""}
            />

            <FormTextInput
                id="create-proposal-form-portfolio-url"
                tooltip={talentProposalTooltips.portfolio}
                label="Portfolio URL"
                placeholder="Enter your portfolio URL"
                error={methods.formState.errors.portfolioUrl}
                defaultValue={""}
                {...methods.register("portfolioUrl")}
            />

            <FileManager
                label="Relevant files"
                tooltip={talentProposalTooltips.relevantFiles(giggedClientTerminology)}
                inputId="proposal-files-upload-button"
                maxFileSizeKb={5000}
                existingFiles={proposalFiles}
                onFileDrop={handleFileDrop}
                onFileDelete={handleFileDelete}
            />

            <GeneralFormError error={submissionError} className="ml-8" />

            <div className="flex justify-end">
                <Button loading={isSubmitting} type="submit">{submitButtonText}</Button>
            </div>
        </form>
    );
};

export default CreateProposalForm;