import { useOrganizationContext } from "../../api/current-organization/organizationContext";

export type GigCountProps = {
    status: string
    count: number
}

const GigCount = ({
    status,
    count,
}: GigCountProps) => {
    const { gigTerminologyPlural } = useOrganizationContext();

    return (
        <div className="flex items-center">
            <p className="text-tertiary-bg-text text-[22px] pr-4 font-normal">{count}</p>
            <p className="text-tertiary-bg-text text-label-small font-bold">{status}<br />{gigTerminologyPlural.toLowerCase()}</p>
        </div>
    );
};

export default GigCount;
