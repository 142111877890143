import { Money } from "phosphor-react";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import Button from "../../components/Button";
import DialogBox, { useDialogBoxState } from "../../components/DialogBox";
import FormTextInput from "../../components/FormTextInput";
import MoneyDisplay from "../../components/MoneyDisplay";
import Typography from "../../components/Typography";
import { CreateWithdrawalFormValues as CreateTalentWithdrawalFormValues, useCreateWithdrawalForm as useCreateTalentWithdrawalForm } from "./CreateWithdrawalFormValues";
import { successToast } from "../../toast";
import { useAppPaths } from "../../Routes";
import { useNavigate } from "react-router-dom";


export type AvailableBalanceProps = {
    availableBalance: number
    pendingWithdrawalsTotal: number
    createWithdrawal: (amount: number) => Promise<GigApiFetcherResponse<string>>
    isCreatingWithdrawal: boolean
    theme: string
}

const AvailableBalance = ({
    availableBalance,
    pendingWithdrawalsTotal,
    createWithdrawal,
    isCreatingWithdrawal,
    theme
}: AvailableBalanceProps) => {
    const dialogState = useDialogBoxState();
    const availableForWithdrawal = availableBalance - pendingWithdrawalsTotal;
    const methods = useCreateTalentWithdrawalForm(availableForWithdrawal);
    const appPaths = useAppPaths();
    const navigate = useNavigate();

    const handleConfirm = async (values: CreateTalentWithdrawalFormValues) => {

        const response = await createWithdrawal(values.amount);
        
        if (response.success) {
            methods.reset();
            dialogState.close();
            successToast("Withdrawal requested.");
            navigate(appPaths.withdrawals.index);
        }
    };
    
    return (
        <>
            <div className={`w-full sm:flex items-center sm:h-28 h-44 p-6 rounded-lg space-x-4 ${theme === "black" ? "bg-primary" : "bg-primary-text"} text-white`}>
                <div className="flex items-center space-x-4">
                    <div className={`flex p-3 rounded-full opacity-80 ${theme === "black" ? "bg-white" : "bg-primary"} text-primary-text`}>
                        <Money size={32} weight="duotone" />
                    </div>
                    <div>
                        <Typography
                            variant="body"
                            component="label"
                            className="block font-bold"
                        >
                            <MoneyDisplay value={availableForWithdrawal} fixedDecimalScale={2} />
                        </Typography>
                        <Typography
                            variant="body"
                            component="label"
                            className="block font-bold"
                        >
                            Available Balance
                        </Typography>
                    </div>
                </div>
                <div className="flex justify-center sm:inline sm:!ml-auto">
                    <Button className="!bg-secondary !border-2 !border-[white] text-white block mt-4 sm:mt-0" variant="secondary" onClick={dialogState.onOpen}>Withdraw</Button>
                </div>
                <form className="text-primary-text" onSubmit={methods.handleSubmit(handleConfirm)}>
                    <DialogBox
                        {...dialogState}
                        title={"Create Withdrawal"}
                    >
                        <Typography variant="body" component="label" className="block font-bold">
                            Gross balance:
                        </Typography>
                        <MoneyDisplay value={availableBalance} fixedDecimalScale={2} />
                        <Typography variant="body" component="label" className="block font-bold">
                            Pending withdrawal(s) total:
                        </Typography>
                        <MoneyDisplay value={pendingWithdrawalsTotal} fixedDecimalScale={2} />
                        <Typography variant="body" component="label" className="block font-bold">
                            Available balance:
                        </Typography>
                        <MoneyDisplay value={availableForWithdrawal} fixedDecimalScale={2} />
                        <FormTextInput
                            id="create-withdrawal-amount"
                            label="Amount (£)"
                            placeholder="Enter amount (£)"
                            step={0.01}
                            error={methods.formState.errors.amount}
                            disabled={methods.formState.isSubmitting}
                            type="number"
                            {...methods.register("amount")}
                        />
                        <div className="mt-4">
                            <Button className="w-full" type="submit" loading={isCreatingWithdrawal}>Request</Button>
                        </div>
                    </DialogBox>
                </form>
            </div>
        </>
    );
};

export default AvailableBalance;
