import { ApiTalentEducationCourseDto, ApiUpdateTalentEducationCourseDto } from "../api/talentEducation";
import { TalentEducationCourseDto, UpdateTalentEducationCourseDto } from "../app/talentEducation";

export const mapFromApiTalentEducationCourse = (apiDto: ApiTalentEducationCourseDto): TalentEducationCourseDto => ({
    ...apiDto,
    startDate: new Date(apiDto.startDate),
    endDate: apiDto.endDate ? new Date(apiDto.endDate) : undefined,
});

export const mapToApiTalentEducationCourse = (dto: UpdateTalentEducationCourseDto): ApiUpdateTalentEducationCourseDto => {
    return {
        ...dto,
        startDate: dto.startDate.toISOString(),
        endDate: dto.endDate ? dto.endDate.toISOString() : undefined,
    };
};