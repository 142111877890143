import { format as formatDateFn } from "date-fns"; // using date-fns for example



export const formatMessageDate = (date: Date, hideCurrentYear?: boolean): string => {
    try {
        if (!(date instanceof Date) || isNaN(date.getTime())) {
            console.error("Invalid date input:", date);
            return "Invalid date";
        }

        const isCurrentYear = date.getFullYear() === new Date().getFullYear();
        const formattedDate = formatDateFn(date, "do MMMM yyyy");

        return hideCurrentYear && isCurrentYear ? formattedDate.replace(` ${date.getFullYear()}`, "") : formattedDate;
    } catch (error) {
        console.error("Error formatting date:", error);
        return "Error in date formatting";
    }
};

export const formatTime = (date:Date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();    
    const ampm = hours >= 12 ? "pm" : "am";
  
    hours %= 12;
    hours = hours || 12;    
    const tempMinutes = minutes < 10 ? `0${minutes}` : minutes;
  
    return `${hours}:${tempMinutes}${ampm}`;
  
};
//Commenting out as not currently used but may be reassigned or removed in the future
// const nth = function (date: Date): string {
//     if (date.getDate() > 3 && date.getDate() < 21) 
//         return "th";
    
//     switch (date.getDate() % 10) {
//     case 1:
//         return "st";
//     case 2:
//         return "nd";
//     case 3:
//         return "rd";
//     default:
//         return "th";
//     }
// };

export const todayAtMidnight = () => {
    const date = new Date();
    date.setHours(0,0,0,0);

    return date;
};

export const getDateStringMonthYear = (parseableDateString?: string): string => {
    if (!parseableDateString) return "";

    const date = new Date(parseableDateString);
    return date.toLocaleDateString("en-GB", { month: "long", year: "numeric" });
  
};