import clsx from "clsx";
import * as icons from "phosphor-react";

type IconName = keyof typeof icons;

type IconComponent = React.ElementType<{
    className?: string;
    size?: number;
    weight?: "bold" | "duotone" | "fill" | "light" | "regular" | "thin";
  }>;

export type CardDataFieldLabelProps = {
    children: React.ReactNode
    iconName?: IconName
    className?: string
    textClass?: string
}

const CardDataFieldLabel = ({
    children,
    iconName,
    className,
    textClass
}: CardDataFieldLabelProps) => {
    const Icon = iconName ? (icons[iconName] as IconComponent) : null;
    
    return (
        <label className={clsx("flex items-center mb-1 space-x-2", className)}>
            <span className={clsx("font-bold w-full", textClass)}>{children}</span>
            {Icon && (
                <Icon className="text-primary-text inline" size={16} weight="bold" />
            )}
        </label>
    );
};

export default CardDataFieldLabel;
