import { useEffect, useState } from "react";
import { ApiGigSummary } from "../models/api/gig";
import { GigSummary } from "../models/app/gig";
import { mapFromApiGigSummary } from "../models/mappers/gig";
import gigApiFetcher from "./common/fetching";

const getGigsForUnauthenticatedUsers = async (): Promise<ApiGigSummary[]> => {
    const path = "api/gigs/queries/gigs-unauthenticated";
    const gigs = await gigApiFetcher<never, ApiGigSummary[]>({url: path, method: "GET"});
    
    return gigs;
};

export type UseGigsForUnauthenticatedUsersReturn = {
    gigs: GigSummary[]
    isLoading: boolean
}

export const useGigsForUnauthenticatedUsers = (): UseGigsForUnauthenticatedUsersReturn => {
    const [gigs, setGigs] = useState<ApiGigSummary[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchGigs = async () => {
            setIsLoading(true);
            const fetchedGigs = await getGigsForUnauthenticatedUsers();
            setGigs(fetchedGigs);
            setIsLoading(false);
        };

        fetchGigs();
    }, []);

    return {
        gigs: gigs.map(mapFromApiGigSummary),
        isLoading,
    };
};