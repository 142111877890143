import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "../Button";
import CreateGigFormPageLayout from "./CreateGigFormPageLayout";
import FormTextAreaInput from "../FormTextAreaInput";
import { GigFormValues, buildGigFormValidationSchemaFields } from "../GigForm";
import { formClassNames } from "./classNames";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { useEffect, useState } from "react";
import clsx from "clsx";
import Tooltip from "../Tooltip";
import { useGetDeliverablesSuggestion } from "../../api/openAi";
import ErrorList from "../ErrorList";
import { GigDescriptionSuggestionDto } from "../../models/api/openAi";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import DialogBox, { useDialogBoxState } from "../DialogBox";
import { dialogWidths } from "../DialogBox/DialogBox";
import Typography from "../Typography";

export type CreateGigFormStepDescriptionFields = Pick<GigFormValues, "description" | "title">;

export type CreateGigFormStepDescriptionProps = {
    onSubmit: (value: CreateGigFormStepDescriptionFields) => void
    onBackClick: (value: CreateGigFormStepDescriptionFields) => void
    defaultValues: CreateGigFormStepDescriptionFields
}

const CreateGigFormStepDescription = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateGigFormStepDescriptionProps) => {
    const { gigTerminology, talentTerminology, organizationConfig } = useOrganizationContext();
    const [getGigDeliverableSuggestion, isGettingGigDeliverablesSuggestion] = useGetDeliverablesSuggestion();
    const [isDraftLoaded, setIsDraftLoaded] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);
    const suggestionClickDialogState = useDialogBoxState();

    const validationSchema: yup.SchemaOf<CreateGigFormStepDescriptionFields> = yup.object({
        description: buildGigFormValidationSchemaFields(organizationConfig.isPaymentsEnabled).description,
        title: buildGigFormValidationSchemaFields(organizationConfig.isPaymentsEnabled).title,
    });

    const methods = useForm<CreateGigFormStepDescriptionFields>({
        resolver: yupResolver(validationSchema),
        defaultValues
    });

    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    const handleOpenAiClick = () => {
        if (methods.getValues("description")) {
            suggestionClickDialogState.open();
            return;
        }
        handleSubmitOpenAiRequest();
    };

    const handleSubmitOpenAiRequest = async (): Promise<GigApiFetcherResponse<GigDescriptionSuggestionDto>> => {
        methods.setValue("description", "");
        setErrors([]);
        setIsDraftLoaded(false);

        const response = await getGigDeliverableSuggestion(defaultValues.title);

        if (!response.success) {
            setErrors(response.error.userMessages);
        } else {
            methods.setValue("description",
                JSON.parse(JSON.stringify(response.content.suggestion)).trimStart()
            );
            setIsDraftLoaded(true);
        }

        return response;
    };

    const handleConfirmSubmitOpenAiRequest = () => {
        handleSubmitOpenAiRequest();
        suggestionClickDialogState.close();
    };

    const [loadingCount, setLoadingCount] = useState(0);
    const periods = ".".repeat(loadingCount);

    useEffect(() => {
        if (isGettingGigDeliverablesSuggestion) {
            const intervalId = setInterval(() => {
                setLoadingCount(loadingCount => (loadingCount + 1) % 4);
            }, 750);

            return () => clearInterval(intervalId);
        }

        setLoadingCount(0);
    }, [isGettingGigDeliverablesSuggestion]);

    return (
        <>
            <form
                className={formClassNames}
                onSubmit={methods.handleSubmit(onSubmit)}
                noValidate
            >
                <CreateGigFormPageLayout
                    title={`Please provide a detailed description of your ${gigTerminology.toLowerCase()}.`}
                    description={`Include as much information as possible in the description. The ${talentTerminology.toLowerCase()} will see this before submitting their proposal so the more detail you can add here the better.`}
                    content={
                        <div className="mb-6">
                            <FormTextAreaInput
                                required
                                id="create-gig-form-description"
                                label={`${gigTerminology} description`}
                                placeholder={isGettingGigDeliverablesSuggestion ? "Please wait while we create your description. This can take up to 30 seconds..." : `Enter ${gigTerminology.toLowerCase()} description`}
                                register={methods.register("description")}
                                error={methods.formState.errors.description}
                                defaultValue={defaultValues.description}
                                rows={7}
                                disabled={isGettingGigDeliverablesSuggestion}
                                isResizeable
                                className="mb-2"
                            />
                            {organizationConfig.isOpenAiEnabled && (
                                <>
                                    <div className="space-y-2">
                                        <div className="flex flex-row items-center">
                                            <Typography variant="body" component="p">Unsure what to write? Let us create a draft for you.</Typography>
                                        </div>
                                        <div className="flex flex-row items-center space-x-2">
                                            <button
                                                type="button"
                                                disabled={isGettingGigDeliverablesSuggestion}
                                                className={clsx(
                                                    "flex flex-row space-x-2 items-center min-w-[7.5rem] text-[#36dd93] disabled:text-disabled-text text-[0.8rem] font-bold py-[0.3rem] px-[1rem] rounded-full whitespace-nowrap bg-[#0b1b29]",
                                                    isGettingGigDeliverablesSuggestion && "animate-pulse text-disabled-text"
                                                )}
                                                onClick={handleOpenAiClick}
                                            >
                                                <img src="/icons/favicon-32x32.png" className="h-3.5 w-3.5" />
                                                <span
                                                    className={clsx(
                                                        "font-bold",
                                                        isGettingGigDeliverablesSuggestion && "text-disabled-text"
                                                    )}
                                                >
                                                    {isGettingGigDeliverablesSuggestion ? `Creating${periods}` : isDraftLoaded ? "Refresh suggestion" : "Create for me"}
                                                </span>
                                            </button>
                                            <Tooltip
                                                tooltipText={`We can generate a draft ${gigTerminology.toLowerCase()} description based on the title entered at the first step. Feel free to edit the generated description to suit your own needs.`}
                                            />
                                        </div>
                                    </div>
                                    <ErrorList errors={errors} />
                                </>
                            )}
                        </div>
                    }
                    iconName="ClipboardText"
                    buttons={
                        <>
                            <Button onClick={handleBackClick} variant="secondary" type="button">Back</Button>
                            <Button type="submit" disabled={isGettingGigDeliverablesSuggestion}>Next</Button>
                        </>
                    }
                />
            </form>
            <DialogBox
                {...suggestionClickDialogState}
                title={`Please note your existing ${gigTerminology.toLowerCase()} description will be overwritten. Are you sure you want to continue?`}
                maxWidth={dialogWidths["small"]}
            >
                <div className="flex items-center sm:justify-between sm:space-x-4 flex-col-reverse sm:flex-row">
                    <Button className="w-full sm:w-fit mt-4 sm:mt-0" type="button" variant="secondary" onClick={suggestionClickDialogState.close}>Back</Button>
                    <Button className="w-full sm:w-fit" type="button" onClick={handleConfirmSubmitOpenAiRequest}>Continue</Button>
                </div>
            </DialogBox>
        </>
    );
};

export default CreateGigFormStepDescription;