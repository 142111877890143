import { CheckoutSessionUrlDto, PayMilestoneWithPoDto, CreateTalentWithdrawalDto, MilestoneCheckoutUrlDto, PayProposalWithPoDto } from "../models/api/payments";
import { useAuthenticatedGigApiFetcher } from "./common/fetching";

export const useGetMilestoneCheckoutSessionUrl = () => useAuthenticatedGigApiFetcher<never, CheckoutSessionUrlDto, MilestoneCheckoutUrlDto>("GET",
    ({milestoneId, clientReferenceId}) => {
        let url = `api/milestones/${milestoneId}/payment-url`;
        if (clientReferenceId) url += `?clientReferenceId=${clientReferenceId}`;
        return { url };
    });

export const usePayMilestoneWithPo = () => useAuthenticatedGigApiFetcher<
    PayMilestoneWithPoDto,
    void, 
    { milestoneId: string, dto: PayMilestoneWithPoDto }
>(
    "POST",
    ({ milestoneId, dto }) => ({
        url: `api/milestones/${milestoneId}/actions/pay-with-po`,
        body: dto,
    }));

export const usePayProposalWithPo = () => useAuthenticatedGigApiFetcher<
PayProposalWithPoDto,
    void, 
    { proposalId: string, dto: PayProposalWithPoDto }
>(
    "POST",
    ({ proposalId, dto }) => ({
        url: `api/proposals/${proposalId}/actions/pay-with-po`,
        body: dto,
    }));

export const useCreateTalentWithdrawal = () => useAuthenticatedGigApiFetcher<CreateTalentWithdrawalDto, string, number>("POST",
    (amount) => ({
        url: "api/withdrawals",
        body: {
            amount,
        },
    }));