import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { ApiEditableProposalMilestoneFields } from "../../models/api/proposal";

export type ProposalFormCostBreakdownProps = {
    gigBudget: number
    proposedAmount: number
    milestones: ApiEditableProposalMilestoneFields[]
    talentDefinedMilestones: boolean
    vatRegistered: boolean
};

const calculateMilestoneProposedTotalCost = (milestones: ApiEditableProposalMilestoneFields[]) => 
    milestones.reduce((prev, current) => prev + +current.amount, 0);

const ProposalFormCostBreakdown: React.FC<ProposalFormCostBreakdownProps> = ({
    gigBudget,
    proposedAmount,
    milestones,
    talentDefinedMilestones,
    vatRegistered
}) => {
    const { gigTerminology, giggedClientTerminology } = useOrganizationContext();

    let totalProposedCost = talentDefinedMilestones ? calculateMilestoneProposedTotalCost(milestones) : +proposedAmount;
    const serviceFee = totalProposedCost * 0.05 * 1.2;

    if(vatRegistered) {
        totalProposedCost = totalProposedCost * 1.2;
    }

    return (
        <div>
            <div className="sm:flex justify-between py-4 border-b">
                <div className="text-xl font-semibold">£{gigBudget.toFixed(2)}</div>
                <div className="font-bold">{`${giggedClientTerminology}'s Proposed ${gigTerminology} Cost`}</div>
            </div>
            <div className="sm:flex justify-between py-4 border-b border-opacity-20">
                <div className="text-xl font-semibold">£{totalProposedCost.toFixed(2)}</div>
                <div className="font-bold">{`Your Proposed ${gigTerminology} Cost`}</div>
            </div>
            <div className="sm:flex justify-between py-4 border-b border-opacity-20">
                <div className="text-xl font-semibold">- £{serviceFee.toFixed(2)}</div>
                <div><b>Gigged.AI's</b> Service Fee (inc. 20% VAT)</div>
            </div>
            <div className="sm:flex justify-between py-4">
                <div className="text-xl font-semibold">£{(totalProposedCost - serviceFee).toFixed(2)}</div>
                <div>Amount you'll receive after <b>Gigged.AI's</b> Service Fee deduction</div>
            </div>
        </div>
    );
};

export default ProposalFormCostBreakdown;