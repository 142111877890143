import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGig } from "../../api/gig";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { useAppPaths } from "../../Routes";
import Button from "../Button";
import DialogBox from "../DialogBox";
import FormTextAreaInput from "../FormTextAreaInput";
import { CancelGigFormValues, useCancelGigForm } from "./CancelGigFormValues";
import ApiError from "../../api/common/apiError";
import GeneralFormError from "../GeneralFormError";
import { dialogWidths } from "../DialogBox/DialogBox";

export type CancelGigDialogProps = {
    gigId: string
    isOpen: boolean
    onClose: () => void
    requireReason: boolean
}
const CancelGigDialog = ({
    gigId,
    isOpen,
    onClose,
    requireReason,
}: CancelGigDialogProps) => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const { cancelGig } = useGig(gigId);
    const methods = useCancelGigForm(requireReason);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { gigTerminology } = useOrganizationContext();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);

    const handleConfirm = async (values: CancelGigFormValues) => {
        setIsSubmitting(true);

        const response = await cancelGig(values);

        setIsSubmitting(false);

        if (response.success) {
            onClose();
            navigate(appPaths.gigs.cancelled);
            return;
        }

        setSubmissionError(response.error);
    };

    return (
        <DialogBox
            isOpen={isOpen}
            onClose={onClose}
            title={`Cancel this ${gigTerminology.toLowerCase()}?`}
            minWidth={dialogWidths.small}
        >
            <form onSubmit={methods.handleSubmit(handleConfirm)}>
                {requireReason  && (
                    <FormTextAreaInput
                        id="cancel-gig-reason"
                        placeholder="Type reason here"
                        register={methods.register("reason")}
                        error={methods.formState.errors.reason}
                        disabled={methods.formState.isSubmitting}
                    />
                )}
                <GeneralFormError error={submissionError} />
                <div className="mt-4">
                    <Button className="w-full" type="submit" loading={isSubmitting}>Cancel {gigTerminology.toLowerCase()}</Button>
                </div>
            </form>
        </DialogBox>
    );
};

export default CancelGigDialog;