import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import ApiError from "../../api/common/apiError";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { useProposal } from "../../api/proposal";
import { useUpdateProposalMilestone } from "../../api/proposal-milestone";
import { ApiFile } from "../../models/api/file";
import { talentProposalTooltips } from "../../tooltipsContent";
import { DEFAULT_PROPOSAL_MILESTONE } from "../../utils/constants";
import { convertFileToDataUrl } from "../../utils/convertFileToDataUrl";
import Button from "../Button";
import { formClassNames } from "../CreateGigForm/classNames";
import ProposalFormCostBreakdown from "../CreateProposalForm/ProposalFormCostBreakdown";
import FileManager from "../FileManager";
import FormTextAreaInput from "../FormTextAreaInput";
import FormTextInput from "../FormTextInput";
import FormToggle from "../FormToggle";
import GeneralFormError from "../GeneralFormError";
import { EditProposalFormValues, validationSchemaFields } from "./EditProposalFormValues";
import ProposalMilestones from "./ProposalMilestones";
import InfoBox from "../../components/InfoBox";
import Typography from "../Typography";
import { ApiProposalMilestone } from "../../models/api/proposal";

export type EditProposalFormProps = {
    proposalId: string
    initialValues: EditProposalFormValues
    onSubmit: (value: EditProposalFormValues, proposalFiles: ApiFile[]) => Promise<GigApiFetcherResponse<unknown>>
    submitButtonText: string
    isSubmitting: boolean
    proposalFiles?: ApiFile[]
    gigBudget?: number
    isWorksomeGig: boolean
    isTimeAndMaterial: boolean
}

// Helper functions to calculate totals
function calculateTotalNumberOfDays(milestones: ApiProposalMilestone[]) {
    return milestones.reduce((acc, curr) => acc + (curr.numberOfDays || 0), 0);
}

function calculateTotalAmount(milestones: ApiProposalMilestone[]) {
    return milestones.reduce((acc, curr) => acc + curr.amount, 0);
}

const EditProposalForm = ({
    proposalId,
    initialValues,
    onSubmit,
    submitButtonText,
    isSubmitting,
    proposalFiles: initialProposalFiles = [],
    gigBudget,
    isWorksomeGig,
    isTimeAndMaterial
}: EditProposalFormProps) => {
    const { gigTerminology, gigTerminologyPlural, giggedClientTerminology } = useOrganizationContext();
    const { refetchProposal } = useProposal(proposalId);
    const validationSchema = yup.object(validationSchemaFields(isTimeAndMaterial));

    const [updateProposalMilestone] = useUpdateProposalMilestone();

    const [talentDefinedMilestones, setTalentDefinedMilestones] = useState(initialValues.milestones.length > 0 ? initialValues.milestones[0].description !== DEFAULT_PROPOSAL_MILESTONE : false);
    const [proposalFiles, setProposalFiles] = useState<ApiFile[]>(initialProposalFiles);
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);
    const [currentMilestoneCount, setCurrentMilestoneCount] = useState<number>(initialValues.milestones.length);
    const [initialMilestoneCount] = useState<number>(initialValues.milestones.length);

    useEffect(() => {
        setCurrentMilestoneCount(initialValues.milestones.length);
    }, [initialValues.milestones]);

    const handleSubmit = async (values: EditProposalFormValues) => {
        if (!talentDefinedMilestones && paymentsEnabled) {
            const response = await updateProposalMilestone({
                milestoneId: initialValues.milestones[0].id,
                dto: {
                    ...initialValues.milestones[0],
                    description: DEFAULT_PROPOSAL_MILESTONE,
                    amount: values.proposedAmount,
                    numberOfDays: isTimeAndMaterial ? values.estimatedDurationDays : null,
                    dailyRate: isTimeAndMaterial ? values.dailyRate : null,
                }
            });

            if (!response.success) {
                setSubmissionError(response.error);
                // We return here as we do not want to continue and submit the proposal
                // update if there was an issue updating the proposal milestone
                return;
            }
        }

        if (!values.isVATRegistered) {
            values.vatNumber = undefined;
        }

        const response = await onSubmit(values, proposalFiles);

        if (!response.success) setSubmissionError(response.error);
    };

    const methods = useForm<EditProposalFormValues>({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const { formState: { isDirty } } = methods;

    const { proposedAmount, paymentsEnabled, isVATRegistered: vatRegistered, dailyRate, estimatedDurationDays } = methods.watch();

    useEffect(function calculateProposedAmount() {
        if (isTimeAndMaterial && !talentDefinedMilestones) {

            if (dailyRate && estimatedDurationDays) {
                const calculatedAmount = dailyRate * estimatedDurationDays;
                methods.setValue("proposedAmount", calculatedAmount);
            }
        }
    }, [isTimeAndMaterial, talentDefinedMilestones, dailyRate, estimatedDurationDays]);

    const hasFilesChanged = proposalFiles?.length !== initialProposalFiles?.length;
    const hasMilestonesChanged = initialMilestoneCount !== currentMilestoneCount;
    const hasFormChanged = isDirty || hasFilesChanged || hasMilestonesChanged;

    const handleFileDrop = async (file: File) => {
        const dataUrl = await convertFileToDataUrl(file);

        const proposalFile: ApiFile = {
            id: Math.random().toString(),
            url: dataUrl,
            name: file.name,
            totalBytes: file.size
        };

        setProposalFiles([...proposalFiles, proposalFile]);
    };

    const handleFileDelete = (fileId: string) => {
        const updatedProposalFiles = proposalFiles.filter(proposalFile => proposalFile.id !== fileId);
        setProposalFiles(updatedProposalFiles);
    };

    const handleTalentDefinedMilestoneChange = async (value: boolean) => {
        if (value) {
            await updateProposalMilestone({
                milestoneId: initialValues.milestones[0].id,
                dto: {
                    ...initialValues.milestones[0],
                    description: "First",
                    amount: +proposedAmount === 0 ? initialValues.milestones[0].amount : +proposedAmount,
                    numberOfDays: isTimeAndMaterial ? methods.getValues("estimatedDurationDays") : null,
                    dailyRate: isTimeAndMaterial ? methods.getValues("dailyRate") : null,
                }
            });
        }

        if (!value) {
            await updateProposalMilestone({ milestoneId: initialValues.milestones[0].id, dto: { ...initialValues.milestones[0], description: DEFAULT_PROPOSAL_MILESTONE, dailyRate: initialValues.dailyRate } });
            methods.setValue("proposedAmount", initialValues.milestones[0].amount);
        }

        refetchProposal();
        setTalentDefinedMilestones(value);
    };

    const [totalNumberOfDays, setTotalNumberOfDays] = useState(() => calculateTotalNumberOfDays(initialValues.milestones));
    const [totalAmount, setTotalAmount] = useState(() => calculateTotalAmount(initialValues.milestones));

    // Callbacks passed to ProposalMilestones
    const handleMilestoneUpdate = (updatedMilestones: ApiProposalMilestone[]) => {
        // Only proceed if isTimeAndMaterial is true
        if (!isTimeAndMaterial) return;

        setTotalNumberOfDays(calculateTotalNumberOfDays(updatedMilestones));
        setTotalAmount(calculateTotalAmount(updatedMilestones));
    };

    // Use useEffect to update form values based on totalNumberOfDays and totalAmount
    useEffect(() => {
        if (!isTimeAndMaterial) return;

        methods.setValue("estimatedDurationDays", totalNumberOfDays);
        methods.setValue("proposedAmount", totalAmount);
    }, [totalNumberOfDays, totalAmount, methods]);

    return (
        <form className={clsx(formClassNames, "p-8 space-y-8")} noValidate onSubmit={methods.handleSubmit(handleSubmit)}>
            {isWorksomeGig && (
                <InfoBox className="">
                    <div className="pb-2" style={{ maxWidth: "150px" }}>
                        <img
                            className="max-h-[24px]"
                            src="/worksome-logo-black-full-150x24.svg"
                            alt="Worksome logo"
                        />
                    </div>
                    <Typography variant="body" component="p" className="font-bold">Third-party provider</Typography>
                    <Typography variant="body" component="p" className="whitespace-pre-wrap">
                        If the {giggedClientTerminology.toLowerCase()} approves your proposal,
                        the {gigTerminology.toLowerCase()} will be handled by our partner, Worksome,
                        including payments and contractual details.
                    </Typography>
                </InfoBox>
            )}
            {paymentsEnabled &&
                <>
                    <Controller
                        name="isVATRegistered"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormToggle
                                label="VAT registered"
                                description="Are you VAT registered?"
                                tooltip={talentProposalTooltips.vatRegistered(gigTerminology)}
                                onChange={onChange}
                                checked={value}
                            />)}
                    />
                    {vatRegistered && (
                        <FormTextInput
                            required
                            id="edit-proposal-vat-number"
                            tooltip={talentProposalTooltips.vatNumber}
                            label="VAT number"
                            placeholder="Enter your VAT number"
                            error={methods.formState.errors.vatNumber}
                            defaultValue={""}
                            {...methods.register("vatNumber")}
                        />
                    )}

                    {!isTimeAndMaterial && (
                        <div>
                            <FormToggle
                                label={`${gigTerminology} milestones`}
                                tooltip={talentProposalTooltips.milestones(gigTerminology, giggedClientTerminology)}
                                description={`Split this ${gigTerminology.toLowerCase()} into milestones`}
                                disabled={initialValues.milestones.length > 1}
                                onChange={handleTalentDefinedMilestoneChange}
                                checked={talentDefinedMilestones}
                            />
                            {initialValues.milestones.length > 1 && <span className="text-[0.75rem] italic">Note: This toggle is disabled if more than one milestone is added. If you wish to turn off milestones, remove some below first.</span>}
                        </div>
                    )}

                    {!talentDefinedMilestones ? (
                        <div>
                            {isTimeAndMaterial ? (
                                <div className="flex flex-col gap-6">
                                    <p className="text-sm italic">
                                        The {giggedClientTerminology.toLowerCase()} has specified a preference for payment using a day rate for this {gigTerminology.toLowerCase()}. Therefore, this proposal requires you to enter a daily rate.
                                    </p>
                                    <div className="flex flex-col sm:flex-row gap-4 sm:mb-4">
                                        <FormTextInput
                                            required
                                            tooltip={talentProposalTooltips.dailyRate(gigTerminology)}
                                            label="Daily rate (£)"
                                            placeholder="Enter your proposed daily rate"
                                            min={0}
                                            defaultValue={0}
                                            type="number"
                                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                            error={methods.formState.errors.dailyRate}
                                            {...methods.register("dailyRate")}
                                        />
                                        <FormTextInput
                                            required
                                            tooltip={talentProposalTooltips.duration(gigTerminology)}
                                            id="create-proposal-form-estimated-duration-days"
                                            label="Estimated duration (days)"
                                            placeholder="Enter the estimated duration in days"
                                            error={methods.formState.errors.estimatedDurationDays}
                                            type="number"
                                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                            min={1}
                                            step={1}
                                            {...methods.register("estimatedDurationDays", { valueAsNumber: true })}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <FormTextInput
                                    required
                                    tooltip={talentProposalTooltips.proposedAmount(gigTerminology)}
                                    label="Proposed amount (£)"
                                    placeholder="Enter your proposed amount"
                                    min={0}
                                    defaultValue={0}
                                    type="number"
                                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                    error={methods.formState.errors.proposedAmount}
                                    {...methods.register("proposedAmount", { valueAsNumber: true })}
                                    disabled={isTimeAndMaterial}
                                />
                            )}
                        </div>
                    ) : (
                        <ProposalMilestones
                            milestones={initialValues.milestones}
                            proposalId={proposalId}
                            isTimeAndMaterial={isTimeAndMaterial}
                            dailyRate={dailyRate}
                            handleMilestoneUpdate={handleMilestoneUpdate}
                        />
                    )}

                    <ProposalFormCostBreakdown
                        gigBudget={gigBudget ?? 0}
                        proposedAmount={proposedAmount}
                        milestones={initialValues.milestones}
                        talentDefinedMilestones={talentDefinedMilestones}
                        vatRegistered={vatRegistered}
                    />
                </>
            }
            {!isTimeAndMaterial &&
                <FormTextInput
                    required
                    tooltip={talentProposalTooltips.duration(gigTerminology)}
                    id="edit-proposal-form-estimated-duration-days"
                    label="Estimated duration (days)"
                    placeholder="Enter the estimated duration in days"
                    error={methods.formState.errors.estimatedDurationDays}
                    type="number"
                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                    min={1}
                    step={1}
                    {...methods.register("estimatedDurationDays", { valueAsNumber: true })}
                />
            }
            <FormTextAreaInput
                required
                tooltip={talentProposalTooltips.introduction(giggedClientTerminology)}
                id="edit-proposal-form-introduction"
                label="Introduction"
                placeholder="Enter an introduction"
                register={methods.register("introduction")}
                error={methods.formState.errors.introduction}
                defaultValue={""}
            />
            <FormTextAreaInput
                required
                tooltip={talentProposalTooltips.experience(giggedClientTerminology, gigTerminology, gigTerminologyPlural)}
                id="edit-proposal-form-relevant-experience"
                label="Relevant experience"
                placeholder="Enter your relevant experience"
                register={methods.register("relevantExperience")}
                error={methods.formState.errors.relevantExperience}
                defaultValue={""}
            />
            <FormTextInput
                id="edit-proposal-form-portfolio-url"
                tooltip={talentProposalTooltips.portfolio}
                label="Portfolio URL"
                placeholder="Enter your portfolio URL"
                error={methods.formState.errors.portfolioUrl}
                defaultValue={""}
                {...methods.register("portfolioUrl")}
            />
            <FileManager
                label="Relevant files"
                tooltip={talentProposalTooltips.relevantFiles(giggedClientTerminology)}
                inputId="proposal-files-upload-button"
                maxFileSizeKb={5000}
                existingFiles={proposalFiles}
                onFileDrop={handleFileDrop}
                onFileDelete={handleFileDelete}
            />

            <GeneralFormError error={submissionError} className="ml-8" />

            <div className="flex justify-end">
                <Button loading={isSubmitting} type="submit" disabled={!hasFormChanged}>{submitButtonText}</Button>
            </div>
        </form>
    );
};

export default EditProposalForm;