import { GigSummary } from "./gig";

export type TalentDashboardDto = {
    ongoingGigsCount: number
    completedGigsCount: number
    ongoingGigs: GigSummary[]
    earnings: TalentEarningDto[]
    availableBalance: number
    pendingEarningsTotal: number
    stripeAccountStatus: number
    pendingWithdrawalsTotal: number
}

export type TalentEarningDto = {
    id: string
    gigId: string
    gigTitle: string
    paymentDate: Date
    amount: number
    isWorksome: boolean
    isTimeAndMaterial: boolean
}

export const stripeAccountStatus = {
    none: 0,
    incomplete: 1,
    complete: 2,
};