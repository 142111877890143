import { CheckCircle } from "phosphor-react";
import { useNavigate } from "react-router";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import Card from "../components/Card";
import LinkButton from "../components/LinkButton";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { useAppPaths } from "../Routes";
import { DEFAULT_PROPOSAL_MILESTONE } from "../utils/constants";

const PaymentSuccessPage = () => {
    const navigate = useNavigate();
    const search = new URLSearchParams(window.location.search);
    const gigId = search.get("gigId");
    const gigTitle = search.get("gigTitle");
    const milestone = search.get("milestoneDescription");
    const appPaths = useAppPaths();
    const { organizationConfig: { gigTerminology } } = useOrganizationContext();

    if(!gigId || !gigTitle || !milestone) 
    {
        navigate(appPaths.dashboard);
        return null;
    }

    const milestoneDescription = milestone === DEFAULT_PROPOSAL_MILESTONE ? "" : ` - ${milestone}`;

    return (
        <PageContent>
            <Card className="md:mx-auto max-w-6xl text-center">
                <CheckCircle size={64} className="text-primary-text mx-auto mt-2 mb-8" weight="duotone" />
                <Typography variant="display-small" component="h1" gutterBottom>Payment successful</Typography>
                <p className="mb-2">"<strong>{gigTitle}{milestoneDescription}</strong>" can now begin.</p>
                <p className="mb-16">Please check your emails for a receipt of your payment.</p>
                <div className="flex justify-center">
                    <LinkButton
                        to={appPaths.gigs.details(gigId)}
                        className="max-w-full md:max-w-md block"
                    >
                        Return to {gigTerminology.toLowerCase()}
                    </LinkButton>
                </div>
            </Card>
        </PageContent>
    );
};

export default PaymentSuccessPage;