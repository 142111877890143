import * as icons from "@phosphor-icons/react";

import Typography from "../Typography";
import TitleUnderline from "../TitleUnderline";

export type IconName =
    "Path" |
    "HandWaving" |
    "Target" |
    "Factory" |
    "Briefcase" |
    "GraduationCap" |
    "Certificate" |
    "IdentificationCard" |
    "Globe";

export type TalentCreateProfileFormPageLayoutProps = {
    title: string,
    description?: string,
    content: JSX.Element,
    buttons: JSX.Element,
    iconName: IconName
    helpText?: React.ReactNode
}

const TalentCreateProfileFormPageLayout = ({
    title,
    description,
    content,
    buttons,
    iconName,
    helpText
}: TalentCreateProfileFormPageLayoutProps) => {
    //Commenting out as not currently used but may be reassigned or removed in the future
    //const isMediumScreen = useIsMediumScreen();

    const Icon = icons[iconName];

    return (
        <div className="bg-surface md:flex-grow flex flex-col">
            <div className="md:flex flex-col md:h-full md:flex-grow">
                <div className="w-full bg-gray-100 md:pr-8">
                    <div className="flex flex-col-reverse gap-4 md:flex-row w-full justify-between items-start">
                        <Typography
                            variant="display-medium"
                            component="h1"
                            className="text-left text-[36px] md:text-display-medium"
                            gutterBottom
                        >
                            {title}
                        </Typography>
                        <Icon size={72} weight="duotone" className="text-primary-text" />
                    </div>
                    <TitleUnderline className="md:mx-0 mr-auto !my-0 !mb-8" />
                    {description && (
                        <Typography
                            variant="title-small"
                            component="p"
                            className="text-[16px] text-left"
                        >
                            {description}
                        </Typography>
                    )}
                </div>
                <div className="flex flex-col space-y-4 w-full mt-8">
                    <div>
                        {content}
                    </div>
                    <div>
                        {helpText}
                    </div>
                </div>
            </div>
            <div className="flex space-x-4 mt-8 justify-between child:flex-grow sm:child:flex-grow-0">
                {buttons}
            </div>
        </div>
    );
};

export default TalentCreateProfileFormPageLayout;
