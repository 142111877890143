import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const careerHistoryFormValidationSchema = {
    jobTitle: yup.string()
        .required("Job title is required.")
        .max(100, "The maximum length for job title is 100 characters."),
    companyTitle: yup.string()
        .required("Company title is required.")
        .max(100, "The maximum length for company title is 100 characters."),
    startDate: yup.date()
        .required("Start date is required.")
        .nullable()
        .typeError("Start date must be a valid date.")
        .max(new Date(), "Start date can't be in the future."),
    endDate: yup.date()
        .test("end-greater-than-start", (value, context) => {
            if (!value) return true;

            if (value <= context.parent.startDate) {
                return context.createError({ message: "End date can't be earlier than start date." });
            }

            return true;
        })
        .nullable()
        .optional()
        .typeError("End date must be a valid date.")
        .max(new Date(), "End date can't be in the future. If you are currently working here, leave this field blank."),
    description: yup.string()
        .required("Job description is required."),
};

export type CareerHistoryFormValues = {
    jobTitle: string
    startDate: Date | null
    endDate: Date | null
    companyTitle: string
    description: string
}

const validationSchema = yup.object(careerHistoryFormValidationSchema);

export const useCareerHistoryForm = (defaultValues?: CareerHistoryFormValues) => {
    return useForm<CareerHistoryFormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            startDate: defaultValues?.startDate || null,
            endDate: defaultValues?.endDate || null,
            jobTitle: defaultValues?.jobTitle || "",
            companyTitle: defaultValues?.companyTitle || "",
            description: defaultValues?.description || "",
        }
    });
};

