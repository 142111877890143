import { addDays } from "date-fns";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import Button from "../../components/Button";
import DialogBox, { useDialogBoxState } from "../../components/DialogBox";
import { dialogWidths } from "../../components/DialogBox/DialogBox";
import FormDateInput from "../../components/FormDateInput";
import FormDialogBox, { FormDialogBoxProps } from "../../components/FormDialogBox";
import FormTextAreaInput from "../../components/FormTextAreaInput";
import FormTextInput from "../../components/FormTextInput";
import { CareerHistoryFormValues, useCareerHistoryForm } from "./CareerHistoryFormValues";
import ApiError from "../../api/common/apiError";
import GeneralFormError from "../../components/GeneralFormError";

export type CareerHistoryFormProps = Omit<FormDialogBoxProps, "title" | "children"> & {
    initialValues: CareerHistoryFormValues
    resetOnSubmit?: boolean
    isEdit?: boolean
    onSubmit: (values: CareerHistoryFormValues) => Promise<GigApiFetcherResponse<unknown>>
}

export const CareerHistoryFormDialog = ({
    initialValues,
    resetOnSubmit,
    isEdit,
    onSubmit,
    ...dialogProps
}: CareerHistoryFormProps) => {
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);
    const methods = useCareerHistoryForm(initialValues);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { isDirty } = methods.formState;

    const confirmCancelDialogState = useDialogBoxState();
    const { onClose } = dialogProps;

    const handleSubmit = async (values: CareerHistoryFormValues) => {
        setIsSubmitting(true);

        try {
            const result = await onSubmit(values);

            if (result.success) {
                methods.reset(resetOnSubmit ? initialValues : values);
                methods.clearErrors();
                onClose();
            } else {
                setSubmissionError(result.error);
            }
        }
        finally {
            setIsSubmitting(false);
        }
    };

    const handleCancelButton = () => {
        if (isDirty) {
            confirmCancelDialogState.open();
            return;
        }
        methods.clearErrors();
        onClose();
    };

    const handleDiscardChanges = () => {
        confirmCancelDialogState.close();
        methods.reset(initialValues);
        methods.clearErrors();
        onClose();
    };

    return (
        <>
            <FormDialogBox
                {...dialogProps}
                isOpen={dialogProps.isOpen && !confirmCancelDialogState.isOpen}
                onClose={handleCancelButton}
                title="Career History"
            >
                <form onSubmit={methods.handleSubmit(handleSubmit)} className="space-y-6">
                    <div className="space-y-4">
                        <div className="space-y-4 md:flex md:space-y-0 md:space-x-6">
                            <FormTextInput
                                required
                                id="career-history-form-job-title"
                                label="Job title"
                                placeholder="Enter job title"
                                error={methods.formState.errors.jobTitle}
                                {...methods.register("jobTitle")}
                            />
                            <FormTextInput
                                required
                                id="career-history-form-company-name"
                                label="Company name"
                                placeholder="Enter company name"
                                error={methods.formState.errors.companyTitle}
                                {...methods.register("companyTitle")}
                            />
                        </div>
                        <div className="space-y-4 md:flex md:space-y-0 md:space-x-6">
                            <Controller
                                name="startDate"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDateInput
                                        required
                                        id="career-history-form-start-date"
                                        label="Start date"
                                        value={value}
                                        onChange={onChange}
                                        error={methods.formState.errors.startDate}
                                        maxDate={new Date()}
                                        hideHelpText
                                    />
                                )}
                            />
                            <Controller
                                name="endDate"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDateInput
                                        id="career-history-form-end-date"
                                        label="End date (optional)"
                                        value={value}
                                        onChange={onChange}
                                        error={methods.formState.errors.endDate}
                                        minDate={addDays(methods.watch().startDate as Date, 1)}
                                        maxDate={methods.formState.isDirty ? new Date() : undefined}
                                        helperText="Leave empty if this is your current job."
                                    />
                                )}
                            />
                        </div>
                        <FormTextAreaInput
                            id="career-history-form-job-description"
                            label="Job description"
                            placeholder="Write something here to describe the job"
                            error={methods.formState.errors.description}
                            register={methods.register("description")}
                            required
                        />
                    </div>
                    <GeneralFormError error={submissionError} />
                    <div className="flex flex-col-reverse md:flex-row justify-end items-center md:space-x-4">
                        <Button type="button" disabled={isSubmitting} className="w-full md:w-auto" variant="tertiary" onClick={handleCancelButton}>Cancel</Button>
                        <Button type="submit" disabled={isEdit && !isDirty} loading={isSubmitting} className="w-full mb-4 md:mb-0 md:w-auto" variant="primary">Save job</Button>
                    </div>
                </form>
            </FormDialogBox>
            <DialogBox {...confirmCancelDialogState} title="Discard changes?" minWidth={dialogWidths["extra-small"]}>
                <div className="flex items-center sm:justify-between sm:space-x-4 flex-col-reverse sm:flex-row">
                    <Button className="w-full sm:w-fit mt-4 sm:mt-0" type="button" variant="secondary" onClick={confirmCancelDialogState.close}>Back</Button>
                    <Button className="w-full sm:w-fit" type="button" onClick={handleDiscardChanges}>Discard</Button>
                </div>
            </DialogBox>
        </>
    );
};