import clsx from "clsx";
import { Briefcase, Eye, Money } from "phosphor-react";
import { Link } from "react-router-dom";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { useTalentDashboard } from "../../api/talentDashboard";
import Card from "../../components/Card";
import DashboardTable from "../../components/Dashboard/DashboardTable";
import LargeDashboardButton from "../../components/Dashboard/LargeDashboardButton";
import SmallDashboardButton from "../../components/Dashboard/SmallDashboardButton";
import MoneyDisplay from "../../components/MoneyDisplay";
import PageContent from "../../components/PageContent";
import Typography from "../../components/Typography";
import { useAppPaths } from "../../Routes";
import AvailableBalance from "./AvailableBalance";
import StripeAccountCard from "./StripeAccountCard";
import Loader from "../../components/Loader";

const TalentDashboardPage = () => {
    const appPaths = useAppPaths();
    const {
        dashboardData,
        isLoading,
        createWithdrawal,
        isCreatingWithdrawal,
    } = useTalentDashboard();

    const {
        gigTerminology,
        gigTerminologyPlural,
        giggedClientTerminology,
        organizationConfig: { isPaymentsEnabled, theme},
    } = useOrganizationContext();

    if (isLoading || !dashboardData) {
        return <Loader />;
    }

    return (
        <PageContent>
            <Card className="space-y-6">
                <LargeDashboardButton
                    variant="light"
                    Icon={Briefcase}
                    name={<h1>View <br />Proposals</h1>}
                    href={appPaths.proposals.index}
                />
                <div className={`space-y-6 md:space-y-0 md:flex md:space-x-6 md:child:grow ${theme === "black" ? "text-white" : ""}`}>
                    <SmallDashboardButton
                        Icon={Briefcase}
                        name={`Ongoing ${gigTerminologyPlural}`}
                        count={dashboardData.ongoingGigsCount}
                        href={appPaths.gigs.ongoing}
                    />
                    <SmallDashboardButton
                        Icon={Briefcase}
                        name={`Completed ${gigTerminologyPlural}`}
                        count={dashboardData.completedGigsCount}
                        href={appPaths.gigs.completed}
                    />
                </div>
                {isPaymentsEnabled && (
                    <>
                        <div className=" space-y-6 md:space-y-0 md:flex md:space-x-6 md:child:grow">
                            <SmallDashboardButton
                                Icon={Money}
                                name="Pending Balance"
                                variant={theme === "black" ? "normal" : "inverted"}
                                count={<MoneyDisplay value={dashboardData.pendingEarningsTotal} fixedDecimalScale={2} />}
                            />
                            <AvailableBalance
                                availableBalance={dashboardData.availableBalance}
                                pendingWithdrawalsTotal={dashboardData.pendingWithdrawalsTotal}
                                createWithdrawal={createWithdrawal}
                                isCreatingWithdrawal={isCreatingWithdrawal}
                                theme={theme.toString()}
                            />
                        </div>
                        <StripeAccountCard
                            dashboardData={dashboardData}
                        />
                    </>
                )}
                <div className={isPaymentsEnabled ? "space-y-6 md:space-y-0 md:flex md:space-x-6 md:items-start just" : undefined}>
                    <Card className={clsx(
                        "!bg-white",
                        isPaymentsEnabled && "md:w-1/2",
                        dashboardData.ongoingGigs.length === 0 && "flex flex-col min-h-[360px]"
                    )}>
                        <Typography variant="headline-small" component="h2" className="mb-6 flex-none">Ongoing {gigTerminologyPlural}</Typography>
                        {dashboardData.ongoingGigs.length === 0 ? (
                            <div className="flex-1 flex justify-center items-center">
                                <div className="flex flex-col items-center">
                                    <img 
                                        src="/gigs-empty-state-icon.svg"
                                        alt="No ongoing gigs icon"
                                    />
                                    <p className="mt-2 opacity-60">No ongoing gigs</p>
                                </div>
                            </div>
                        ) : (
                            <DashboardTable
                                data={dashboardData.ongoingGigs}
                                headings={[
                                    `${gigTerminology} title`,
                                    giggedClientTerminology,
                                    isPaymentsEnabled ? "Proposed cost" : "Expected duration",
                                    "",
                                ]}
                                renderRowCells={(gig) => (
                                    <>
                                        <td className="flex items-center">
                                            {gig.isWorksome && (
                                                <img 
                                                    className="max-h-[20px] mr-2"
                                                    src="/worksome-icon-40x40.svg" 
                                                    alt="Worksome logo" 
                                                />
                                            )}
                                            {gig.title}
                                        </td>
                                        <td>{gig.giggedClientName || gig.giggedClientCompanyName}</td>
                                        {isPaymentsEnabled ? (
                                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                            <td><MoneyDisplay value={gig.hiredProposalAmount!} fixedDecimalScale={2} /></td>
                                        ) : (
                                            <td>{gig.expectedDurationLabel}</td>
                                        )}
                                        <td>
                                            <Link to={appPaths.gigs.details(gig.id)}>
                                                <Eye size={20} />
                                            </Link>
                                        </td>
                                    </>
                                )}
                            />
                        )}
                    </Card>
                    {isPaymentsEnabled && (
                        <Card className={clsx("!bg-white md:w-1/2", dashboardData.earnings.length === 0 && "flex flex-col min-h-[360px]")}>
                            <Typography variant="headline-small" component="h2" className="mb-6 flex-none">Past Earnings</Typography>
                            {dashboardData.earnings.length === 0 ? (
                                <div className="flex-1 flex justify-center items-center">
                                    <div className="flex flex-col items-center">
                                        <img 
                                            src="/earnings-empty-state-icon.svg"
                                            alt="No earnings icon"
                                        />
                                        <p className="mt-2 opacity-60">No earnings to report yet</p>
                                    </div>
                                </div>
                            ) : (
                                <DashboardTable
                                    data={dashboardData.earnings}
                                    headings={[
                                        `${gigTerminology} title`,
                                        "Date",
                                        "Amount",
                                    ]}
                                    renderRowCells={(earning) => (
                                        <>
                                            <td className="flex items-center">                                                
                                                {earning.isWorksome && (
                                                    <img 
                                                        className="max-h-[20px] mr-2"
                                                        src="/worksome-icon-40x40.svg" 
                                                        alt="Worksome logo" 
                                                    />
                                                )}
                                                {earning.gigTitle}
                                            </td>
                                            <td>{earning.paymentDate.toLocaleDateString()}</td>
                                            <td><MoneyDisplay value={earning.amount} fixedDecimalScale={2} /></td>
                                        </>
                                    )}
                                />
                            )}
                        </Card>
                    )}
                </div>
            </Card>
        </PageContent>
    );
};

export default TalentDashboardPage;