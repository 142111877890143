import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

import Button from "../Button";
import CreateGigFormPageLayout from "./CreateGigFormPageLayout";
import { GigFormValues, buildGigFormValidationSchemaFields } from "../GigForm";
import { formClassNames } from "./classNames";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import FormTextInput from "../FormTextInput";
import { useEffect } from "react";

export type CreateGigFormStepBudgetFields = Pick<GigFormValues, "budget" | "dailyRate" | "isTimeAndMaterial" | "numberOfDays">;

export type CreateGigFormStepBudgetProps = {
    onSubmit: (value: CreateGigFormStepBudgetFields) => void
    onBackClick: (value: CreateGigFormStepBudgetFields) => void
    defaultValues: CreateGigFormStepBudgetFields
}

const CreateGigFormStepBudget = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateGigFormStepBudgetProps) => {
    const { gigTerminology, organizationConfig } = useOrganizationContext();

    const validationSchema: yup.SchemaOf<CreateGigFormStepBudgetFields> = yup.object({
        budget: buildGigFormValidationSchemaFields(organizationConfig.isPaymentsEnabled).budget,
        dailyRate: buildGigFormValidationSchemaFields(organizationConfig.isPaymentsEnabled).dailyRate,
        isTimeAndMaterial: buildGigFormValidationSchemaFields(organizationConfig.isPaymentsEnabled).isTimeAndMaterial,
        numberOfDays: buildGigFormValidationSchemaFields(organizationConfig.isPaymentsEnabled).numberOfDays,
    });

    const methods = useForm<CreateGigFormStepBudgetFields>({
        resolver: yupResolver(validationSchema),
        defaultValues
    });

    // Watch for changes to dailyRate to dynamically calculate budget
    const dailyRate = methods.watch("dailyRate");
    const numberOfDays = methods.watch("numberOfDays");
    const budget = methods.watch("budget");

    useEffect(function calculateBudget(){
        if (defaultValues.isTimeAndMaterial && dailyRate && numberOfDays) {
            // Automatically calculate and set the budget based on dailyRate and numberOfDays
            const calculatedBudget = dailyRate * numberOfDays;
            methods.setValue("budget", calculatedBudget);
        }
    }, [dailyRate, numberOfDays, defaultValues.isTimeAndMaterial]);

    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateGigFormPageLayout
                title={`What is the budget for your ${gigTerminology.toLowerCase()}?`}
                description={defaultValues.isTimeAndMaterial ?
                    `Please enter a daily rate for this ${gigTerminology.toLowerCase()} in order to calculate the overall budget.` :
                    `Please note this is the full ${gigTerminology.toLowerCase()} budget and not a day rate.`
                }
                content={
                    <div className="space-y-6">
                        {defaultValues.isTimeAndMaterial ? (
                            <>
                                <FormTextInput
                                    required
                                    id="create-gig-form-daily-rate"
                                    label={`${gigTerminology} daily rate (£)`}
                                    error={methods.formState.errors.dailyRate}
                                    type="number"
                                    placeholder={`Enter ${gigTerminology.toLowerCase()} daily rate (£)`}
                                    defaultValue={defaultValues.dailyRate || 0}
                                    {...methods.register("dailyRate")}
                                />
                                <div>
                                    <div className="flex flex-row gap-2">
                                        <div className="font-bold">{"Total budget (calculated): "}</div>
                                        <div className="text-xl font-semibold">£{dailyRate ? budget : 0}</div>
                                        <input type="hidden" name="budget" value={budget || undefined}/>
                                    </div>
                                    <p className="text-sm italic mt-2">
                                        Budget calculation is based on the number of day(s) (<b>{numberOfDays}</b>) multiplied by daily rate (<b>£{dailyRate || 0}</b>).
                                    </p>
                                </div>
                            </>
                        ) : (
                            <FormTextInput
                                required
                                id="create-gig-form-budget"
                                label={`${gigTerminology} budget (£)`}
                                error={methods.formState.errors.budget}
                                type="number"
                                placeholder={`Enter ${gigTerminology.toLowerCase()} budget (£)`}
                                defaultValue={defaultValues.budget || 0}
                                {...methods.register("budget")}
                                disabled={defaultValues.isTimeAndMaterial}
                            />
                        )}
                    </div>
                }
                iconName="Money"
                buttons={
                    <>
                        <Button onClick={handleBackClick} variant="secondary" type="button">Back</Button>
                        <Button type="submit">Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateGigFormStepBudget;