import { ApiInvoiceDetails } from "../models/api/invoice";
import { InvoiceDetails } from "../models/app/invoice";
import { mapFromApiInvoiceDetails } from "../models/mappers/invoice";
import { useGiggedApiSWR } from "./common/giggedApiSWR";

export type UseInvoiceReturn<TInvoiceId extends number | undefined> = TInvoiceId extends undefined ?
    { invoice: undefined } :
    {
        invoice?: InvoiceDetails
        isLoading: boolean
    }

export const useInvoice = <TInvoiceId extends number | undefined>(gigId: TInvoiceId): UseInvoiceReturn<TInvoiceId> => {
    const url = `api/me/invoices/${gigId}`;

    const apiCall = useGiggedApiSWR<ApiInvoiceDetails>(url);

    return {
        invoice: apiCall.data ? mapFromApiInvoiceDetails(apiCall.data) : undefined,
        isLoading: apiCall.isLoading,
    } as UseInvoiceReturn<TInvoiceId>;
};