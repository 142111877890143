import { useEffect, useRef } from "react";
import Swiper from "swiper";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import GigCompletedSummaryCard from "./GigCompletedSummaryCard";
import { GigSummary } from "../models/app/gig";
import Typography from "./Typography";
import { useOrganizationContext } from "../api/current-organization/organizationContext";

type RecentlyCompletedGigsCarouselProps = {
    gigs: GigSummary[]
}

const RecentlyCompletedGigsCarousel = ({
    gigs
}: RecentlyCompletedGigsCarouselProps) => {
    const swiperRef = useRef(null);
    const nextButtonRef = useRef(null);
    const prevButtonRef = useRef(null);
    const { gigTerminologyPlural } = useOrganizationContext();

    useEffect(() => {
        if (swiperRef.current === null) return;
        
        const swiper = new Swiper((swiperRef.current), {
            modules: [Pagination, Navigation],
            slidesPerView: 1,
            navigation: {
                nextEl: nextButtonRef.current,
                prevEl: prevButtonRef.current,
            },
            breakpoints: {
                1240: {
                    slidesPerView: gigs.length >= 2 ? 2 : 1,
                    spaceBetween: 30
                },
            },
            loop: true
        });

        return () => swiper.destroy(true, true);
    }, []);

    return (
        <div className="mt-12">
            <Typography variant="headline-small" component="h3" className="mb-2 flex-none">Recently completed {gigTerminologyPlural.toLowerCase()}</Typography>
            <div className="py-6 sm:bg-white rounded-md">
                <div className="flex flex-row items-center justify-center relative h-auto">
                    <div className="swiper-button-prev !text-black" ref={prevButtonRef}></div>
                    <div ref={swiperRef} className="swiper rounded-md h-full">
                        <div className="swiper-wrapper max-w-sm sm:max-w-2xl h-full">
                            {gigs.map(gig => (
                                <div className="swiper-slide h-auto" key={gig.id}>
                                    <GigCompletedSummaryCard completedGig={gig} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="swiper-button-next !text-black" ref={nextButtonRef}></div>
                </div>
            </div>
        </div>
    );
};

export default RecentlyCompletedGigsCarousel;