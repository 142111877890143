import { Bank, Coins, Money, Wallet } from "phosphor-react";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import SmallDashboardButton from "../../components/Dashboard/SmallDashboardButton";
import Typography from "../../components/Typography";
import { ProposalMilestoneSummary } from "../../models/app/proposal";
import config from "../../config.json";

export type GigBudgetSectionProps = {
    milestones: ProposalMilestoneSummary[]
    isVatRegistered: boolean
}

export const GigBudgetSection = ({
    milestones,
    isVatRegistered
}: GigBudgetSectionProps) => {
    const totalBudget = milestones.reduce((total, milestone) => total + (isVatRegistered ? milestone.amount * config.VatMultiplier : milestone.amount), 0);
    const { gigTerminology } = useOrganizationContext();

    const inEscrow = milestones
        .filter(_ => _.clientPaymentCollectedDate !== undefined && _.dateCompleted === undefined)
        .reduce((total, milestone) => total + (isVatRegistered ? milestone.amount * config.VatMultiplier : milestone.amount), 0);
        
    const paid = milestones
        .filter(_ => _.dateCompleted !== undefined)
        .reduce((total, milestone) => total + (isVatRegistered ? milestone.amount * config.VatMultiplier : milestone.amount), 0);

    return (
        <div>
            <Typography variant="title-large" component="h2" className="mb-4">{gigTerminology} budget</Typography>
            <div className="md:flex md:space-x-4 space-y-4 md:space-y-0">
                <SmallDashboardButton
                    Icon={Money}
                    count={totalBudget}
                    name="Total Budget"
                    variant="card"
                />
                <SmallDashboardButton
                    Icon={Bank}
                    count={inEscrow}
                    name="In Escrow"
                    variant="card"
                />
                <SmallDashboardButton
                    Icon={Coins}
                    count={paid}
                    name="Milestone Paid"
                    variant="card"
                />
                <SmallDashboardButton
                    Icon={Wallet}
                    count={totalBudget - paid - inEscrow}
                    name="Remaining"
                    variant="card"
                />
            </div>
        </div >
    );
};

export default GigBudgetSection;
