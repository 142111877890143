export type CarouselItemProps = {
    content: JSX.Element
}

export const CarouselItem = ({
    content
}: CarouselItemProps) => {

    return (
        <div className="space-y-2 px-2 mb-2 h-full">
            {content}
        </div>
    );
};