import clsx from "clsx";
import { Warning } from "phosphor-react";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";
import FormInputMessage from "./FormInputError";
import FormInputLabel from "./FormInputLabel";

export type FormTextAreaInputProps = {
    id: string,
    register: UseFormRegisterReturn,
    label?: string
    placeholder: string
    disabled?: boolean
    error?: FieldError
    defaultValue?: string
    required?: boolean
    rows?: number
    tooltip?: string
    isResizeable?: boolean
    className?: string
};

const FormTextAreaInput = ({
    id,
    register,
    label,
    placeholder,
    disabled,
    error,
    defaultValue,
    required,
    rows = 5,
    tooltip, 
    isResizeable = false,
    className
}: FormTextAreaInputProps) => {
    return (
        <div className={className}>
            {label && (
                <FormInputLabel htmlFor={id} required={required} tooltipText={tooltip}>{label}</FormInputLabel>
            )}
            <div className="relative">
                <textarea
                    {...register}
                    id={id}
                    defaultValue={defaultValue}
                    rows={rows}
                    disabled={disabled}
                    className={clsx(
                        "border rounded-xl w-full p-4",
                        !error && "focus:outline-none focus:ring-inset focus:ring-2 focus:ring-primary-text",
                        error && "outline-error border-error outline outline-2 pr-10 focus:ring-error",
                        isResizeable && "resize-y"
                    )}
                    placeholder={placeholder}
                    

                />
                {error && (
                    <Warning className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3 text-error" weight="fill" />
                )}
            </div>
            <FormInputMessage error={error} />
        </div>
    );
};

export default FormTextAreaInput;
