import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { SignOut } from "phosphor-react";
import React from "react";
import clsx from "clsx";

export type SignOutButtonProps = {
    className?: string
}

export const SignOutButton = React.forwardRef<HTMLButtonElement, SignOutButtonProps>(
    ({ className }, ref) => {
        const { instance, inProgress } = useMsal();
        const [isLoggingIn, setIsLoggingIn] = useState(false);

        const isLoading = isLoggingIn || inProgress === InteractionStatus.HandleRedirect;

        const handleLogout = () => {
            setIsLoggingIn(true);
            try {
                instance.logoutRedirect({
                    postLogoutRedirectUri: "/",
                });
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setIsLoggingIn(false);
            }
        };

        return (
            <button
                onClick={handleLogout}
                className={clsx(
                    "flex items-center px-4 py-2 text-sm text-gray-700",
                    className
                )}
                ref={ref}
            >
                {isLoading ? (
                    "Loading..."
                ) : (
                    <>
                        <SignOut className="mr-4" />
                        Sign Out
                    </>
                )}
            </button>
        );
    });