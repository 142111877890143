import React, { useState } from "react";
import { useGigs } from "../../api/gigs";
import { gigStatuses } from "../../models/app/gig";
import { silentlyReplaceSearchParams } from "../../utils/searchParams";
import FindAGigContext from "./findAGigContext";

export type FindAGigContextProviderProps = {
    children: React.ReactNode
}

const FindAGigContextProvider = ({
    children,
}: FindAGigContextProviderProps) => {
    
    const {
        gigs,
        isLoading,
        setSearchTerm: setUseGigsSearchTerm,
        setSkillIds: setUseGigsSkillIds,
        setExpectedDurationIds: setUseGigsExpectedDurationIds,
        setMinBudget: setUseGigsMinBudget,
        setMaxBudget: setUseGigsMaxBudget,
        globalMinBudget,
        globalMaxBudget,
        setIsCompletedRemotely: setUseIsCompletedRemotely,
        sortOrder,
        orderBy,
        searchTerm: searchTermQueryParam,
        skillIds: skillIdsQueryParam,
        expectedDurationIds: expectedDurationIdsQueryParam,
        minBudget: minBudgetQueryParam,
        maxBudget: maxBudgetQueryParam,
        isCompletedRemotely: isCompletedRemotelyQueryParam,
        setSortOrder,
        setOrderBy,
    } = useGigs({
        gigStatusIds: [gigStatuses.posted, gigStatuses.completed],
        allClients: true,
        initialOrderBy: "CreatedAt",
        initialSortOrder: "desc",
    });

    const [searchTerm, setSearchTerm] = useState(searchTermQueryParam);
    const [skillIds, setSkillIds] = useState<string[]>(skillIdsQueryParam);
    const [expectedDurationIds, setExpectedDurationIds] = useState<number[]>(expectedDurationIdsQueryParam);
    const [minBudget, setMinBudget] = useState<number>(minBudgetQueryParam);
    const [maxBudget, setMaxBudget] = useState<number>(maxBudgetQueryParam);
    const [isCompletedRemotely, setIsCompletedRemotely] = useState<string>(isCompletedRemotelyQueryParam);

    const anyActiveFilters =
    searchTerm.length > 0 ||
    skillIds.length > 0 ||
    expectedDurationIds.length > 0 ||
    minBudget && minBudget !== globalMinBudget ||
    maxBudget && maxBudget !== globalMaxBudget ||
    isCompletedRemotely.length > 0;
    
    const clearAllFilters = () => {
        setSearchTerm("");
        setSkillIds([]);
        setExpectedDurationIds([]);
        setMinBudget(globalMinBudget);
        setMaxBudget(globalMaxBudget);
        setIsCompletedRemotely("");
        setUseGigsMinBudget(globalMinBudget);
        setUseGigsMaxBudget(globalMaxBudget);
        setUseIsCompletedRemotely("");
        setUseGigsSearchTerm("");
        setUseGigsSkillIds([]);
        setUseGigsExpectedDurationIds([]);
        silentlyReplaceSearchParams("");
    };
    
    const searchNow = () => {
        setUseGigsSearchTerm(searchTerm);
        setUseGigsSkillIds(skillIds);
        setUseGigsExpectedDurationIds(expectedDurationIds);
        setUseGigsMinBudget(minBudget);
        setUseGigsMaxBudget(maxBudget);
        setUseIsCompletedRemotely(isCompletedRemotely);
    };

    return (
        <FindAGigContext.Provider value={{
            gigs,
            isLoading,
            searchTerm,
            minBudget,
            maxBudget,
            globalMinBudget,
            globalMaxBudget,
            isCompletedRemotely,
            skillIds,
            expectedDurationIds,
            sortOrder,
            orderBy,
            anyActiveFilters,
            setSearchTerm,
            setSkillIds,
            setExpectedDurationIds,
            setMinBudget,
            setMaxBudget,
            setIsCompletedRemotely,
            searchNow,
            setSortOrder,
            setOrderBy,
            clearAllFilters,
        }}>
            {children}
        </FindAGigContext.Provider>
    );
};

export default FindAGigContextProvider;
