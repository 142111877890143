import { Briefcase, HandWaving, Money, Target, UserCircle, Vault } from "phosphor-react";

import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { Carousel, CarouselItem } from "./Carousel";
import Typography from "./Typography";
import useCurrentUser from "../auth/useCurrentUser";


export const ClientDashboardCarousel = () => {
    const { gigTerminology } = useOrganizationContext();
    const { userGivenName } = useCurrentUser();

    return (
        <Carousel>
            <CarouselItem
                content={
                    <>
                        <HandWaving size={32} className="text-primary" />
                        <Typography component="h3" variant="title-small" className="font-bold">{userGivenName ? `Hi, ${userGivenName}!` : "Hi!"}</Typography>
                        <Typography component="h3" variant="title-small">Learn more about what Gigged can do for you.</Typography>
                    </>
                }
            />
            <CarouselItem
                content={
                    <>
                        <Briefcase size={36} className="text-primary" />
                        <Typography component="h3" variant="title-small">{`Unlimited ${gigTerminology.toLowerCase()} posting for free, with no commitment to hire`}</Typography>
                    </>
                }
            />
            <CarouselItem
                content={
                    <>
                        <Target size={36} className="text-primary" />
                        <Typography component="h3" variant="title-small">{`5 skill-based matches generated for your ${gigTerminology.toLowerCase()} in seconds`}</Typography>
                    </>
                }
            />
            <CarouselItem
                content={
                    <>
                        <UserCircle size={36} className="text-primary" />
                        <Typography component="h3" variant="title-small">Dedicated Customer Success Manager to vet all proposals</Typography>
                    </>
                }
            />
            <CarouselItem
                content={
                    <>
                        <Money size={36} className="text-primary" />
                        <Typography component="h3" variant="title-small">Transparent 15% transaction fee upon choosing your hire</Typography>
                    </>
                }
            />
            <CarouselItem
                content={
                    <>
                        <Vault size={36} className="text-primary" />
                        <Typography component="h3" variant="title-small">Payments held securely in escrow until you confirm project completion</Typography>
                    </>
                }
            />
        </Carousel>
    );
};

export default ClientDashboardCarousel;