import Typography from "./Typography";
import LinkButton from "./LinkButton";
import Card, { CardDataField } from "./Card";
import Chip, { ChipContainer } from "./Chip";
import CardDataFieldLabel from "./Card/CardDataFieldLabel";
import TitleUnderline from "./TitleUnderline";
import { GigSummary } from "../models/app/gig";
import { Link } from "react-router-dom";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { useAppPaths } from "../Routes";
import MoneyDisplay from "./MoneyDisplay";
import useCurrentUser from "../auth/useCurrentUser";
import { Check } from "phosphor-react";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import StatusLabel from "./StatusLabel";
import { useIsLargeScreen } from "../hooks/screenSizes";

export type GigSummaryCardProps = {
    gig: GigSummary
    viewGigLink: string
    showClient?: boolean
}

const GigSummaryCard = ({
    gig,
    viewGigLink,
    showClient
}: GigSummaryCardProps) => {
    const { gigTerminology, organizationConfig } = useOrganizationContext();
    const appPaths = useAppPaths();
    const { userRole } = useCurrentUser();
    const [lineClamp, setLineClamp] = useState<boolean>(true);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const textRef = useRef<HTMLParagraphElement>(null);
    const isLargeScreen = useIsLargeScreen();

    useEffect(() => {
        const checkOverflow = () => {
            const element = textRef.current;
            if (element) {
                setIsOverflowing(element.scrollHeight > element.clientHeight);
            }
        };

        checkOverflow();
        window.addEventListener("resize", checkOverflow);

        return () => {
            window.removeEventListener("resize", checkOverflow);
        };
    }, [gig]);

    const handleReadMore = () => setLineClamp(lineClamp => !lineClamp);

    return (
        <Card className="mb-6 w-full break-words relative" key={gig.id}>
            <div className="flex sm:flex-row flex-col-reverse justify-between mb-4">
                <div className="w-full space-y-2">
                    <div className="w-full flex flex-col-reverse md:flex-row md:space-x-4 justify-between">
                        {showClient ? (
                            <Typography variant="title-small" component="p">
                                <Link to={appPaths.clients.details(gig.giggedClientId)}>
                                    {gig.giggedClientName || gig.giggedClientCompanyName}
                                </Link>
                            </Typography>
                        ) : <Typography variant="title-large" component="h2" className="max-w-2xl">{gig?.title}</Typography>}
                        <div className={clsx("flex flex-row space-x-4 justify-start md:items-start md:justify-between mb-4 md:mb-0", isLargeScreen && "h-[32px]")}>
                            {((userRole === "talent" && gig.submittedProposalId) || gig.isWorksome || gig.isTimeAndMaterial) && (
                                <>
                                    {userRole === "talent" && gig.submittedProposalId && (
                                        <StatusLabel label="Proposal submitted" icon={<Check size={18} />} />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    {showClient && <Typography variant="title-large" component="h2" className="max-w-2xl">{gig?.title}</Typography>}
                    <Typography
                        variant="body"
                        component="p"
                        ref={textRef}
                        className={clsx("italic whitespace-pre-wrap", lineClamp && "line-clamp-3")}
                    >
                        {gig?.description}
                    </Typography>
                    {isOverflowing && <div onClick={handleReadMore} className="cursor-pointer font-bold hover:underline w-fit text-[0.95rem] mb-2">{lineClamp ? "Read more" : "Read less"}</div>}
                </div>
            </div>
            <TitleUnderline fullWidth />
            <div className="flex flex-col sm:flex-row flex-wrap gap-6 pt-2">
                <CardDataField label="Initial stage">{gig.initialStageLabel}</CardDataField>
                <CardDataField label="Expected duration">{gig.expectedDurationLabel}</CardDataField>
                {organizationConfig.isPaymentsEnabled && gig.isTimeAndMaterial && <CardDataField label="No. of days">{gig.numberOfDays || 0}</CardDataField>}
                {organizationConfig.isPaymentsEnabled && gig.isTimeAndMaterial && <CardDataField label="Daily rate"><MoneyDisplay value={gig.dailyRate || 0} /></CardDataField>}
                {organizationConfig.isPaymentsEnabled && <CardDataField label="Budget"><MoneyDisplay value={gig.budget || 0} /></CardDataField>}
                <CardDataField label="Remote">{gig.isCompletedRemotely ? "Yes" : "No"}</CardDataField>
            </div>
            <div className="flex flex-wrap flex-col sm:flex-row space-between gap-4 mt-6">
                <div className="space-y-2 md:w-[70%]">
                    <CardDataFieldLabel>Top three skills needed</CardDataFieldLabel>
                    <ChipContainer className="mr-4">
                        {gig.skills.map(skill => (
                            <Chip key={skill.id} variant="secondary" label={skill.name} />
                        ))}
                    </ChipContainer>
                </div>
                <div className="block mt-4 sm:self-end sm:ml-auto sm:mt-0">
                    <LinkButton
                        to={viewGigLink}
                        variant="secondary"
                        scrollToTop
                    >
                        View {gigTerminology.toLowerCase()}
                    </LinkButton>
                </div>
            </div>
        </Card>
    );
};

export default GigSummaryCard;
