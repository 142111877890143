import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { useGig } from "../../api/gig";
import { useProposals } from "../../api/proposals";
import GigDetailsCard from "../../components/GigDetailsCard";
import Loader from "../../components/Loader";
import PageContent from "../../components/PageContent";
import Typography from "../../components/Typography";
import { useGigId } from "../../Routes";
import GigProposalCard from "./GigProposalCard";

const GigsProposalsListPage = () => {
    const gigId = useGigId();
    const { gig } = useGig(gigId);
    const { proposals, isLoading } = useProposals({
        gigId,
        allTalents: true,
    });
    const { gigTerminology } = useOrganizationContext();

    if (isLoading || !gig) return <Loader />;

    return (
        <PageContent>
            <Typography variant="display-medium" component="h1" gutterBottom>{gigTerminology}</Typography>
            <div className="mb-8">
                <GigDetailsCard gig={gig} />
            </div>
            <Typography variant="display-small" component="h2" gutterBottom>Received proposals</Typography>
            <div className="space-y-8">
                {proposals.length === 0 && (
                    <div className="bg-surface p-8">
                        <Typography variant="title-large" component="p">None yet...</Typography>
                    </div>
                )}
                {proposals.map(proposal => (
                    <GigProposalCard key={proposal.id} proposal={proposal} />
                ))}
            </div>
        </PageContent>
    );
};

export default GigsProposalsListPage;