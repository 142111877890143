export type Option = {
    label: string
    value: number
}

const findOptionLabel = (options: Option[], value: number): string => {
    const option = options.find(o => o.value === value);

    if (!option) throw new Error(`No option exists with value ${value} in supplied list of options.`);

    return option.label;
};

export default findOptionLabel;