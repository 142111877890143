import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export type CreateWithdrawalFormValues = {
    amount: number
};

export const createCreateWithdrawalFormValidationSchemaFields = (availableBalance: number) => ({
    amount: yup.number()
        .min(1, "Must be at least £1.00")
        .max(availableBalance, "You cannot withdraw more than you have available.")
        .typeError("Must be a number e.g. 100.00"),
});

const createSchema = (availableBalance: number) => yup.object(createCreateWithdrawalFormValidationSchemaFields(availableBalance));

export const useCreateWithdrawalForm = (availableBalance: number) => {
    return useForm<CreateWithdrawalFormValues>({
        defaultValues: {
            amount: 0,
        },
        resolver: yupResolver(createSchema(availableBalance)),
    });
};