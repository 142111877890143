import clsx from "clsx";
import { Flag } from "phosphor-react";
import { useCreateReport } from "../../api/reports";
import { reportTypes } from "../../models/api/report";
import { successToast } from "../../toast";
import { useDialogBoxState } from "../DialogBox";
import { CreateReportFormDialog } from "./CreateReportFormDialog";
import { CreateReportFormValues } from "./CreateReportFormValues";

export type ReportType = "GiggedClient" | "Talent" | "Gig";

export type ReportButtonProps = {
  type: ReportType;
  entityId: string;
  className?: string;
};

const buildReasons = (type: ReportType): string[] => {
    if (type === "GiggedClient") {
        return [
            "Profile looks fake or contains stolen/plagiarized content",
            "Their behaviour is inappropriate or abusive",
            "Profile contains contact information",
            "Other",
        ];
    }

    if (type === "Talent") {
        return [
            "Profile looks fake or contains stolen/plagiarized content",
            "Their behaviour is inappropriate or abusive",
            "Profile contains contact information",
            "Person is requesting payment outside of Gigged",
            "Other",
        ];
    }

    return [
        "Project looks fake or contains stolen/plagiarized content",
        "Their behaviour is inappropriate or abusive",
        "Other",
    ];
};

const ReportButton = ({ type, entityId, className, }: ReportButtonProps) => {
    const reportDialogState = useDialogBoxState();
    const [createReport] = useCreateReport();

    const handleCreateReport = async (values: CreateReportFormValues) => {
        const response = await createReport({
            ...values,
            typeId: reportTypes[type],
            reportedEntityId: entityId,
        });

        if (response.success) {
            successToast("Report successfully created.");
            reportDialogState.close();
        }

        return response;
    };
    
    return (
        <>
            <div 
                className={clsx(className, "cursor-pointer transition inline-block")}
                onClick={reportDialogState.open}
            >
                <Flag size={24} weight="bold" className="ml-2" />
                <p className="text-label-small">Report</p>
            </div>
            <CreateReportFormDialog
                {...reportDialogState}
                initialValues={{ reason: "", description: "" }}
                onSubmit={handleCreateReport}
                resetOnSubmit
                reasons={buildReasons(type)}
            />
        </>
    );
};

export default ReportButton;
