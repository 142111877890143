import { MagnifyingGlass } from "phosphor-react";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import useAccessToken from "../auth/useAccessToken";
import FormCheckboxGroup, { FormCheckboxGroupOption } from "./FormCheckboxGroup";
import FormTextInput from "./FormTextInput";

export type SearchableCheckBoxGroupProps = {
    selectedOptionIds: string[],
    searchPlaceholder: string,
    onChange: (selectedSkillIds: string[]) => void
    fetchOptions: (query: string, accessToken: string) => Promise<FormCheckboxGroupOption<string>[]>
    accessTokenNotRequired?: boolean
}

const SearchableCheckBoxGroup = ({
    selectedOptionIds,
    searchPlaceholder,
    onChange,
    fetchOptions,
    accessTokenNotRequired = false,
}: SearchableCheckBoxGroupProps) => {
    const accessToken = useAccessToken();
    const [searchTerm, setSearchTerm] = useState("");
    const [options, setOptions] = useState<FormCheckboxGroupOption<string>[]>([]);
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
    const [loading, setLoading] = useState(false);
    const [noResultsFound, setNoResultsFound] = useState<boolean>(false);

    useEffect(() => {
        if (!accessTokenNotRequired && !accessToken) return;

        const loadInitialOptions = async () => {
            setLoading(true);
            try {
                const options = await fetchOptions("", accessToken || "");
                setOptions(options);
            } finally {
                setLoading(false);
            }
        };

        loadInitialOptions();
    }, [accessToken]);

    useEffect(() => {
        if (!accessTokenNotRequired && !accessToken) return;

        const loadOptions = async () => {
            setLoading(true);
            try {
                const options = await fetchOptions(debouncedSearchTerm, accessToken || "");
                setOptions(options);
                setNoResultsFound(options.length === 0);
            } finally {
                setLoading(false);
            }
        };

        loadOptions();
    }, [debouncedSearchTerm]);

    return (
        <div className="space-y-4">
            <FormTextInput
                value={searchTerm}
                onChange={event => setSearchTerm(event.target.value)}
                placeholder={searchPlaceholder}
                StartAdornmentIcon={MagnifyingGlass}
            />
            <FormCheckboxGroup
                allOptions={options}
                selectedOptionIds={selectedOptionIds}
                onChange={onChange}
                className="max-h-96 overflow-y-auto scrollbar-thin scrollbar-thumb-scrollbar pr-3 -mr-2"
                loading={loading}
                noResultsMessage={noResultsFound && !loading ? "No results" : null}
            />
        </div>
    );
};

export default SearchableCheckBoxGroup;