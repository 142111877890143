import { Link } from "react-router-dom";

import { useTalentPersonalInformation } from "../../api/talentPersonalInformation";
import Loader from "../Loader";
import OrganizationLogo from "../OrganizationLogo";
import UserMenu from "./UserMenu";
import TalentCreateProfile from "../../pages/TalentCreateProfile";

export type TalentProfileCreateRedirectProps = {
    children: JSX.Element
}

export type ProfileCreateLayoutProps = {
    children: JSX.Element
};

const TalentProfileCreateLayout = ({ children }: ProfileCreateLayoutProps) => {
    return (
        <div className="min-h-screen flex flex-col w-full">
            <nav className={"px-4 sm:px-6 lg:px-8"}>
                <div className="relative flex items-center h-16">
                    <Link
                        to={"/"}
                        className="mr-auto"
                    >
                        <OrganizationLogo />
                    </Link>
                    <div className="flex flex-row items-center space-x-4 cursor-pointer">
                        <UserMenu />
                    </div>
                </div>
            </nav>
            <main className="flex-grow flex items-center justify-center">
                {children}
            </main>
        </div>
    );
};

const TalentProfileCreateRedirect = ({
    children
}: TalentProfileCreateRedirectProps) => {
    const { talentPersonalInformation, isLoading, isUpdatingPersonalInformation } = useTalentPersonalInformation();

    if (isLoading || !talentPersonalInformation || isUpdatingPersonalInformation) {
        return (
            <div className="w-screen h-screen flex items-center justify-center">
                <Loader />
            </div>
        );
    }

    if (!talentPersonalInformation.profileIsComplete) {
        return (
            <TalentProfileCreateLayout>
                <TalentCreateProfile />
            </TalentProfileCreateLayout>
        );
    }

    return children;
};

export default TalentProfileCreateRedirect;
