import { ApiProposalDetails, ApiProposalMilestoneSummary, ApiProposalSummary } from "../api/proposal";
import { expectedDurationOptions, initialStageOptions } from "../app/gig";
import { ProposalDetails, ProposalMilestoneSummary, ProposalSummary } from "../app/proposal";

export const mapFromApiProposalSummary = (apiDto: ApiProposalSummary): ProposalSummary => ({
    ...apiDto,
    milestones: apiDto.milestones.map(mapFromApiProposalMilestoneSummary),
    gigExpectedDurationLabel: expectedDurationOptions.find(_ => _.value === apiDto.gigExpectedDurationId)?.label || "",
    gigInitialStageLabel: initialStageOptions.find(_ => _.value === apiDto.gigInitialStageId)?.label || "",
    isVATRegistered: apiDto.isVATRegistered,
    vatNumber: apiDto.vatNumber,
});

export const mapFromApiProposalMilestoneSummary = (apiDto: ApiProposalMilestoneSummary): ProposalMilestoneSummary => ({
    ...apiDto,
    clientPaymentCollectedDate: apiDto.clientPaymentCollectedDate ? new Date(apiDto.clientPaymentCollectedDate) : undefined,
    dateCompleted: apiDto.dateCompleted ? new Date(apiDto.dateCompleted) : undefined,
    isVatRegistered: apiDto.isVatRegistered
});

export const mapFromApiProposalDetails = (apiDto: ApiProposalDetails): ProposalDetails => ({
    ...mapFromApiProposalSummary(apiDto),
    milestones: apiDto.milestones.map(mapFromApiProposalMilestoneSummary),
    gig: apiDto.gig,
    files: apiDto.files,
    isTimeAndMaterial: apiDto.isTimeAndMaterial
});