import { ApiCreateProposalDto, ApiCreateProposalMilestoneDto, ApiEditableProposalFields, ApiProposalDetails, ApiProposalGigSummary, ApiProposalSummary, ApiUpdateProposalDto, ApiUpdateProposalMilestoneDto } from "../api/proposal";

export type EditableProposalFields = ApiEditableProposalFields

export type CreateProposalDto = ApiCreateProposalDto

export type UpdateProposalDto = ApiUpdateProposalDto

export type CreateProposalMilestoneDto = ApiCreateProposalMilestoneDto;

export type UpdateProposalMilestoneDto = ApiUpdateProposalMilestoneDto;

export type ProposalSummary = Omit<ApiProposalSummary, "milestones"> & {
    gigExpectedDurationLabel: string
    gigInitialStageLabel: string
    milestones: ProposalMilestoneSummary[]
}

export type ProposalMilestoneSummary = {
    id: string
    description: string
    amount: number
    clientPaymentCollectedDate?: Date
    dateCompleted?: Date
    isVatRegistered: boolean
    isWorksome: boolean
    isTimeAndMaterial: boolean,
    numberOfDays: number | null
}

export type ProposalGigSummary = ApiProposalGigSummary

export type ProposalDetails = Omit<ApiProposalDetails, "milestones"> & {
    gigExpectedDurationLabel: string
    gigInitialStageLabel: string
    milestones: ProposalMilestoneSummary[]
    isTimeAndMaterial: boolean
}

export const proposalStatuses = {
    pendingReview: 0,
    reviewRejected: 1,
    submitted: 2,
    hired: 3,
    unsuccessful: 4,
    cancelled: 5,
    completed: 6,
};

export const editableProposalStatuses = [
    proposalStatuses.pendingReview,
    proposalStatuses.reviewRejected,
    proposalStatuses.submitted,
];

export const proposalStatusLabels = [
    "Pending review",
    "Review rejected",
    "Submitted",
    "Hired",
    "Unsuccessful",
    "Cancelled",
    "Completed",
];
