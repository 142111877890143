import { SkillDto } from "../models/app/skill";
import { useGiggedApiSWR } from "./common/giggedApiSWR";

export type UseGigMissingSkillsReturn = {
    isLoading: boolean
    missingSkills: SkillDto[]
}

export const useGigMissingSkills = (gigId: string): UseGigMissingSkillsReturn => {
    const url = `api/gigs/${gigId}/queries/missing-skills`;
    const apiCall = useGiggedApiSWR<SkillDto[]>(url);

    return {
        isLoading: apiCall.isLoading || apiCall.isValidating,
        missingSkills: apiCall.data || [],
    };
};