import { fetchIndustrialExperienceOptions } from "../../api/industrialExperience";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { fetchSkillOptions } from "../../api/skills";
import { fetchSpecializationOptions } from "../../api/specializations";
import FindPage from "../../components/FindPage";
import SearchableCheckBoxGroup from "../../components/SearchableCheckBoxGroup";
import { useFindTalentContext } from "./findTalentContext";
import SortOrderDropdown from "./SortOrderDropdown";
import TalentsList from "./TalentsList";
import { FindPagePanelSectionProps } from "../../components/FindPage/FindPagePanelSection";
import FormRadioGroup from "../../components/FormRadioGroup";
import { dailyRateOptions } from "../../models/app/dailyRate";
import { fetchTalentBadgeOptions } from "../../api/badges";
import SearchableSkillsCheckBoxGroup from "../../components/SearchableSkillsCheckboxGroup";
import { fetchLocationOptions } from "../../api/locations";
import useCurrentUser from "../../auth/useCurrentUser";
import SearchableSpecializationCheckboxGroup from "../../components/SearchableSpecializationsCheckboxGroup";


const FindTalentPageComponent = () => {
    const {
        searchTerm,
        skillIds,
        badgeIds,
        locationIds,
        specializationIds,
        industrialExperienceIds,
        anyActiveFilters,
        dailyRateOptionId,
        talents,
        isLoading,
        setSearchTerm,
        setSkillIds,
        setBadgeIds,
        setLocationIds,
        setSpecializationIds,
        setIndustrialExperienceIds,
        setDailyRateOptionId,
        clearAllFilters,
        searchNow,
    } = useFindTalentContext();

    const { talentTerminologyPlural, organizationConfig } = useOrganizationContext();

    const { userRole } = useCurrentUser();

    const filterPanelSections: FindPagePanelSectionProps[] = [
        {
            title: "Specialisation",
            subtitle: `(${specializationIds.length} selected)`,
            content: (
                <SearchableSpecializationCheckboxGroup
                    selectedOptionIds={specializationIds}
                    searchPlaceholder="Search by specialisation"
                    onChange={setSpecializationIds}
                    fetchOptions={fetchSpecializationOptions}
                />
            )
        },
        {
            title: "Skills",
            subtitle: `(${skillIds.length} selected)`,
            content: (
                <SearchableSkillsCheckBoxGroup
                    selectedSkillIds={skillIds}
                    searchPlaceholder="Search by skill"
                    onChange={setSkillIds}
                    fetchSkills={fetchSkillOptions}
                    accessTokenNotRequired
                />
            )
        },
        {
            title: "Industry experience",
            subtitle: `(${industrialExperienceIds.length} selected)`,
            content: (
                <SearchableCheckBoxGroup
                    selectedOptionIds={industrialExperienceIds}
                    searchPlaceholder="Search by industry"
                    onChange={setIndustrialExperienceIds}
                    fetchOptions={fetchIndustrialExperienceOptions}
                />
            )
        },
    ];

    if (organizationConfig.isPaymentsEnabled) {
        filterPanelSections.push({
            title: "Day rate",
            content: (
                <FormRadioGroup
                    value={dailyRateOptionId}
                    onChange={(value) => setDailyRateOptionId(value as number)}
                    options={dailyRateOptions.map(option => ({
                        label: option.label,
                        value: option.id
                    }))}
                    defaultValue={dailyRateOptionId}
                />
            )
        });
    }

    if (organizationConfig.isBadgesEnabled) {
        filterPanelSections.push({
            title: "Badges",
            subtitle: `(${badgeIds.length} selected)`,
            content: (
                <SearchableCheckBoxGroup
                    selectedOptionIds={badgeIds}
                    searchPlaceholder="Search by badge"
                    onChange={setBadgeIds}
                    fetchOptions={fetchTalentBadgeOptions}
                />
            )
        });
    }

    if (userRole === "client") {
        filterPanelSections.push({
            title: "Location",
            subtitle: `(${locationIds.length} selected)`,
            content: (
                <SearchableCheckBoxGroup
                    selectedOptionIds={locationIds}
                    searchPlaceholder="Search by location"
                    onChange={setLocationIds}
                    fetchOptions={fetchLocationOptions}
                />
            )
        });
    }

    return (
        <FindPage
            title={`Filter ${talentTerminologyPlural.toLowerCase()} by`}
            searchTerm={searchTerm}
            anyActiveFilters={anyActiveFilters}
            setSearchTerm={setSearchTerm}
            clearAllFilters={clearAllFilters}
            searchNow={searchNow}
            searchPlaceholder="Search by talent name"
            sortOrderDropdown={(
                <SortOrderDropdown />
            )}
            filterPanelSections={filterPanelSections}
        >
            <TalentsList talents={talents} isLoading={isLoading} />
        </FindPage>
    );
};

export default FindTalentPageComponent;