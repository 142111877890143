import PageContent from "../components/PageContent";
import TalentCreateProfileForm from "../components/TalentCreateProfileForm";

const TalentCreateProfilePage = () => {
    return (
        <PageContent className="py-8 w-screen">
            <TalentCreateProfileForm />
        </PageContent>
    );
};

export default TalentCreateProfilePage;