import gigApiFetcher from "./common/fetching";
import { SpecializationDto } from "../models/app/specialization";

export const fetchSpecializations = async (query: string, accessToken: string | null): Promise<SpecializationDto[]> => {
    const response = await gigApiFetcher<never, SpecializationDto[]>({
        url: `api/specializations?searchTerm=${query}`,
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response;
};

export const fetchSpecializationOptions = async (query: string, accessToken: string | null) => {
    const specializationsResponse = await fetchSpecializations(query, accessToken);

    const groupedSpecializations = specializationsResponse.reduce((acc, specialization) => {
        const categoryName = specialization.specializationCategoryName;
        if (!acc[categoryName]) {
            acc[categoryName] = [];
        }
        acc[categoryName].push({
            value: specialization.id,
            label: specialization.name,
        });
        return acc;
    }, {} as Record<string, { value: string; label: string }[]>);

    // Sort categories and specializations alphabetically
    return Object.entries(groupedSpecializations)
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([category, options]) => ({
            label: category,
            options: options.sort((a, b) => a.label.localeCompare(b.label)),
        }));
};