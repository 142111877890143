import gigApiFetcher from "./common/fetching";
import { LocationDto } from "../models/app/location";

export const fetchLocations = async (query = "", accessToken: string | null): Promise<LocationDto[]> => {
    const response = await gigApiFetcher<never, LocationDto[]>({
        url: `api/locations?searchTerm=${query}`,
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response;
};

export const fetchLocationOptions = async (query: string, accessToken: string | null) => {
    const locationsResponse = await fetchLocations(query, accessToken);
    
    return locationsResponse
        .map(location => ({
            value: location.id,
            label: location.name,
        }));
};