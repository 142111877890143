import clsx from "clsx";
import FileDropZone from "../FileDropZone";
import FormInputMessage from "../FormInputError";
import FormInputLabel from "../FormInputLabel";
import Typography from "../Typography";

export type FormFileUploadInputProps = {
    label?: string
    helpText?: string
    inputId: string
    dataUrl?: string
    isImage: boolean
    fileSizeDescription?: string,
    fileName?: string
    error?: string
    isDirty: boolean
    onLoadFile: (file: File) => void
    required?: boolean
    disabled?: boolean
    tooltip?: string
    previewImgClassName?: string
}

const FormFileUploadInput = ({
    label,
    helpText,
    inputId,
    dataUrl,
    isImage,
    fileSizeDescription,
    fileName,
    error,
    onLoadFile,
    required,
    disabled,
    tooltip,
    previewImgClassName = "object-scale-down",
}: FormFileUploadInputProps) => {
    return (
        <div>
            {label && (
                <FormInputLabel className="!mb-0" required={required} tooltipText={tooltip}>{label}</FormInputLabel>
            )}
            <div className="space-y-3">
                {helpText &&
                    <Typography variant="body" component="span" className="italic"> {helpText} </Typography>
                }
                <FileDropZone
                    inputId={inputId}
                    onFileDrop={onLoadFile}
                    disabled={disabled}
                    className="mb-4"
                />
                <FormInputMessage className="mt-0 mb-4" error={error} />
                {dataUrl && (
                    <div className="flex justify-center sm:block">
                        <div 
                            className="flex flex-col items-stretch justify-between border border-surface rounded-lg w-full md:w-1/5 sm:w-2/5 bg-white"
                        >
                            <a 
                                href={dataUrl} 
                                target="_blank"
                                className="relative h-full bg-black rounded-t-lg"
                            >
                                <img 
                                    src={isImage ? dataUrl : "https://via.placeholder.com/300?text=File"} 
                                    className={clsx("rounded-t-lg max-h-36 min-w-full brightness-90 hover:brightness-100", previewImgClassName)} 
                                />     
                            </a>
                            <div className="flex flex-row px-2 bg-white rounded-b-lg w-full">
                                <div className="flex flex-col truncate w-3/4 py-[0.25rem]">
                                    <span className="font-bold text-label-small">{fileName || "Current"}</span>
                                    <span className="text-label-small">{fileSizeDescription && `File size: ${fileSizeDescription}`}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FormFileUploadInput;
