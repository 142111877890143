import { Message, Participant } from "@twilio/conversations";

export type SetUnreadMessagesType = (
    channelSid: string,
    unreadCount: number
) => void;

export type AddMessagesType = (channelSid: string, messages: Message[]) => void;

export type SetParticipantsType = (
    participants: Participant[],
    sid: string
) => void;

export type SetSidType = (sid: string) => void;

export const messageTypes = {
    GigProposal: "Gig Proposal",
    GigProposalAccepted: "Hired",
    GigPostedNotification: "Gig Posted",
    GigInvite: "Invite",
    GigCompleted: "Gig Completed",
    GigCancelled: "Gig Cancelled",
    DisputeCreated: "Dispute",
    GigOnHold: "Gig On Hold"
};