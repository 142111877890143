import { addDays } from "date-fns";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";

import FormTextInput from "../FormTextInput";
import { GigFormValues } from "./GigFormValues";
import { initialStageOptions, expectedDurationOptions, remoteOptions, isTimeAndMaterialOptions } from "../../models/app/gig";
import FormDateInput from "../FormDateInput";
import FormTextAreaInput from "../FormTextAreaInput";
import FormDropdown from "../FormDropdown";
import GigSkillsInput from "../GigSkillsInput";
import { clientEditGigTooltips } from "../../tooltipsContent";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import pluralizeString from "../../utils/pluralizeString";
import FormToggle from "../FormToggle";
import FormInputMessage from "../FormInputError";

export type CreateGigFormStep8SummaryProps = {
    methods: UseFormReturn<GigFormValues>
    initialValues: GigFormValues
    hideSkillsDropdown?: boolean
    proposalCount?: number
}

const GigFormFields = ({
    methods,
    initialValues,
    hideSkillsDropdown,
    proposalCount
}: CreateGigFormStep8SummaryProps) => {
    const { gigTerminology, gigTerminologyPlural, talentTerminology, organizationConfig } = useOrganizationContext();
    const { append: appendSkill, remove: removeSkill } = useFieldArray<GigFormValues, "skills">({
        control: methods.control,
        name: "skills",
        keyName: "id",
    });

    const { skills, isTimeAndMaterial, numberOfDays, budget, dailyRate, startDate, deadlineDate } = methods.watch();

    const hasProposals = proposalCount && proposalCount > 0 ? true : false;

    return (
        <>
            <div className="md:flex">
                <div className="space-y-8 md:pr-8 md:w-1/2">
                    <FormTextInput
                        required
                        id="create-gig-form-summary-title"
                        label={`Name of the ${gigTerminology.toLowerCase()}`}
                        placeholder={`Enter name of the ${gigTerminology.toLowerCase()}`}
                        error={methods.formState.errors.title}
                        defaultValue={initialValues.title}
                        tooltip={clientEditGigTooltips.gigName(gigTerminology)}
                        {...methods.register("title")}
                    />
                    <FormTextAreaInput
                        required
                        id="create-gig-form-summary-description"
                        label={`${gigTerminology} description`}
                        placeholder={`Enter ${gigTerminology.toLowerCase()} description`}
                        register={methods.register("description")}
                        error={methods.formState.errors.description}
                        defaultValue={initialValues.description}
                        tooltip={clientEditGigTooltips.gigDescription(gigTerminology)}
                    />
                    <Controller
                        name="initialStageId"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormDropdown
                                required
                                label={`Stage of the ${gigTerminology.toLowerCase()}`}
                                options={initialStageOptions}
                                error={methods.formState.errors.initialStageId}
                                onChange={onChange}
                                value={value}
                                tooltip={clientEditGigTooltips.gigStage(gigTerminology)}
                            />
                        )}
                    />

                    <GigSkillsInput
                        addSkill={appendSkill}
                        removeSkill={removeSkill}
                        skills={skills}
                        error={methods.formState.errors.skills}
                        showError={methods.formState.submitCount > 0}
                        hideDropdown={hideSkillsDropdown}
                        tooltip={clientEditGigTooltips.gigSkills(gigTerminology, pluralizeString(talentTerminology))}
                    />

                    <Controller
                        name="isCompletedRemotely"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormDropdown
                                required
                                label="Can be completed remotely?"
                                options={remoteOptions}
                                error={methods.formState.errors.isCompletedRemotely}
                                onChange={onChange}
                                value={value}
                                tooltip={clientEditGigTooltips.remoteWork(gigTerminology)}
                            />
                        )}
                    />
                    <Controller
                        name="isComplianceCheckRequired"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormToggle
                                label="Compliance check"
                                description="Compliance check required"
                                onChange={onChange}
                                checked={value}
                                tooltip={clientEditGigTooltips.complianceCheck(talentTerminology)}
                            />
                        )}
                    />
                </div>
                <div className="space-y-8 md:pl-8 md:w-1/2 mt-8 md:mt-0">
                    {organizationConfig.isPaymentsEnabled && (
                        <div>
                            <Controller
                                name="isTimeAndMaterial"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDropdown
                                        required
                                        label="Budget method"
                                        options={isTimeAndMaterialOptions}
                                        error={methods.formState.errors.isTimeAndMaterial}
                                        onChange={(selectedValue) => onChange(selectedValue === "true")}
                                        value={String(value)}
                                        tooltip={clientEditGigTooltips.gigStructure(gigTerminology)}
                                        disabled={hasProposals}
                                    />
                                )}
                            />
                            {hasProposals && <FormInputMessage className="ml-2 mt-8 italic" helpText={`Budget method cannot be changed on ${gigTerminologyPlural.toLowerCase()} with active proposals.`} />}

                            <div className="md:w-8 hidden md:block" />
                        </div>
                    )}
                    <div className="space-y-4">
                        <div className="md:flex space-y-6 md:space-y-0">
                            <Controller
                                name="startDate"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDateInput
                                        required
                                        id="create-gig-form-summary-start-date"
                                        label="Start date"
                                        value={value}
                                        onChange={onChange}
                                        minDate={new Date()}
                                        error={methods.formState.errors.startDate}
                                        hideHelpText
                                        tooltip={clientEditGigTooltips.gigStartDate(gigTerminology)}
                                        shouldDisableWeekends={isTimeAndMaterial}
                                    />
                                )}
                            />
                            <div className="w-16 hidden md:block" />
                            <Controller
                                name="deadlineDate"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDateInput
                                        id="create-gig-form-summary-deadline"
                                        label={isTimeAndMaterial ? "Deadline" : "Deadline (optional)"}
                                        value={value || null}
                                        onChange={onChange}
                                        error={methods.formState.errors.deadlineDate}
                                        minDate={addDays(startDate as Date, 1)}
                                        hideHelpText
                                        tooltip={clientEditGigTooltips.gigDeadline(gigTerminology)}
                                        required={isTimeAndMaterial}
                                        shouldDisableWeekends={isTimeAndMaterial}
                                    />
                                )}
                            />
                        </div>
                        {isTimeAndMaterial && (
                            <div className="flex flex-row gap-2">
                                <div className="font-bold">{"Total number of days (calculated): "}</div>
                                <div className="text-xl font-semibold">{startDate && deadlineDate ? numberOfDays : 0}</div>
                                <input type="hidden" name="numberOfDays" value={numberOfDays || undefined} />
                            </div>
                        )}
                    </div>
                    {!isTimeAndMaterial && (
                        <Controller
                            name="expectedDurationId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDropdown
                                    required
                                    label="Duration"
                                    options={expectedDurationOptions}
                                    error={methods.formState.errors.expectedDurationId}
                                    onChange={onChange}
                                    value={value}
                                    tooltip={clientEditGigTooltips.gigDuration(gigTerminology)}
                                    className="w-full"
                                />
                            )}
                        />
                    )}
                    {organizationConfig.isPaymentsEnabled && (
                        <div>
                            {isTimeAndMaterial && (
                                <div className="space-y-4">
                                    <FormTextInput
                                        required
                                        id="create-gig-form-daily-rate"
                                        className="w-full"
                                        label={`${gigTerminology} daily rate (£)`}
                                        error={methods.formState.errors.dailyRate || methods.formState.errors.budget}
                                        type="number"
                                        placeholder={`Enter ${gigTerminology.toLowerCase()} daily rate (£)`}
                                        defaultValue={initialValues.dailyRate || 0}
                                        tooltip={clientEditGigTooltips.gigDailyRate(gigTerminology)}
                                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                        {...methods.register("dailyRate")}
                                    />
                                    <div className="flex flex-row gap-2">
                                        <div className="font-bold">{"Total budget (calculated): "}</div>
                                        <div className="text-xl font-semibold">£{dailyRate ? budget : 0}</div>
                                        <input type="hidden" name="budget" value={budget || undefined} />
                                    </div>
                                </div>
                            )}
                            {!isTimeAndMaterial && (
                                <FormTextInput
                                    required
                                    id="create-gig-form-budget"
                                    className={`${isTimeAndMaterial ? "md:w-1/2" : "md:w-full"}`}
                                    label={`${gigTerminology} budget (£)`}
                                    error={methods.formState.errors.budget}
                                    type="number"
                                    placeholder={`Enter ${gigTerminology.toLowerCase()} budget (£)`}
                                    defaultValue={initialValues.budget || 0}
                                    tooltip={clientEditGigTooltips.gigBudget(gigTerminology, isTimeAndMaterial ? isTimeAndMaterial : false)}
                                    disabled={isTimeAndMaterial}
                                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                    {...methods.register("budget")}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default GigFormFields;
