import { useEffect, useState } from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { CreateProposalFormValues } from ".";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { talentProposalTooltips } from "../../tooltipsContent";
import Button from "../Button";
import FormTextInput from "../FormTextInput";

type CreateProposalFormMilestonesProps = {
    methods: UseFormReturn<CreateProposalFormValues, object>
    isTimeAndMaterial: boolean
    dailyRate: number | null
}

const CreateProposalFormMilestones: React.FC<CreateProposalFormMilestonesProps> = ({
    methods,
    isTimeAndMaterial,
    dailyRate
}) => {
    const { gigTerminology, giggedClientTerminology } = useOrganizationContext();

    const { fields, append, remove } = useFieldArray({
        control: methods.control,
        name: "milestones"
    });

    // Initialize a state to keep track of numberOfDays for each milestone
    const [milestoneDays, setMilestoneDays] = useState<number[]>(fields.map(field => field.numberOfDays || 0));

    useEffect(() => {
        // Update the milestoneDays state whenever the fields change
        setMilestoneDays(fields.map(field => field.numberOfDays || 0));
    }, [fields]);

    useEffect(() => {
        // Iterate over the milestoneDays to calculate and set the amount for each milestone
        milestoneDays.forEach((days, index) => {
            if (dailyRate && days) {
                const calculatedAmount = dailyRate * days;
                methods.setValue(`milestones.${index}.amount`, calculatedAmount);
            }
        });
    }, [milestoneDays, dailyRate, methods]);

    const handleNumberOfDaysChange = (index: number, value: number) => {
        // Update the specific milestone's numberOfDays in the state to trigger the useEffect above
        const updatedMilestoneDays = [...milestoneDays];
        updatedMilestoneDays[index] = value;
        setMilestoneDays(updatedMilestoneDays);
    };    

    const handleAddMilestone = () => {
        append({
            description: "",
            amount: 0,
        });
    };

    const handleRemoveMilestone = (index: number) => {
        remove(index);
    };

    useEffect(() => {
        const existingMilestones = methods.getValues("milestones");
        if(!existingMilestones || existingMilestones.length === 0) {
            append({
                description: "",
                amount: 0,
            });
        }
    }, []);
    
    return (
        <>
            {fields.map((field, index) =>
                <div className="flex flex-col sm:flex-row gap-4 sm:mb-4" key={`${field.id}-proposal-milestone`}>
                    <div className="flex-1">
                        <FormTextInput
                            required
                            tooltip={talentProposalTooltips.milestoneDescription(gigTerminology)}
                            label="Description"
                            placeholder="Enter milestone description"
                            type="text"
                            error={methods.formState.errors.milestones && methods.formState.errors.milestones[index]?.description}
                            {...methods.register(`milestones.${index}.description`)}
                        />
                    </div>
                    {isTimeAndMaterial && 
                        <div className="flex-none">
                            <FormTextInput
                                required
                                tooltip={talentProposalTooltips.milestoneNumberOfDays(giggedClientTerminology)}
                                label="Number of day(s)"
                                placeholder="Enter your number of days"
                                min={0}
                                type="number"
                                onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                error={methods.formState.errors.milestones && methods.formState.errors.milestones[index]?.numberOfDays}
                                {...methods.register(`milestones.${index}.numberOfDays`)}
                                onChange={e => handleNumberOfDaysChange(index, Number(e.target.value))}
                                value={milestoneDays[index]} // Controlled component
                            />
                        </div>
                    }
                    <div className="flex-none">
                        <FormTextInput
                            required
                            tooltip={talentProposalTooltips.milestoneAmount(giggedClientTerminology)}
                            label="Proposed amount (£)"
                            placeholder="Enter your proposed amount"
                            type="number"
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            error={methods.formState.errors.milestones && methods.formState.errors.milestones[index]?.amount}
                            {...methods.register(`milestones.${index}.amount`)}
                            disabled={isTimeAndMaterial}
                        />
                    </div>
                    <div className="flex-none flex justify-center items-end">
                        <Button
                            type="button"
                            variant="tertiary"
                            className="-translate-y-1"
                            onClick={() => handleRemoveMilestone(index)}
                        >
                                Remove
                        </Button>
                    </div>
                </div>
            )}
            <div className="flex justify-center sm:justify-start">
                <Button
                    type="button"
                    variant="secondary"
                    onClick={handleAddMilestone}
                >
                    Add milestone
                </Button>
            </div>
        </>
    );
};

export default CreateProposalFormMilestones;