import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";

import Button from "../Button";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { formClassNames } from "../CreateGigForm/classNames";
import TalentCreateProfileFormPageLayout from "./TalentCreateProfileFormPageLayout";
import SkillsSearchDropdown from "../SkillsSearchDropdown";
import FormDropdown from "../FormDropdown";
import TalentSkillChips from "../../pages/TalentProfileEdit/TalentSkillChips";
import { useTalentSkills } from "../../api/talentSkills";
import { useState } from "react";
import { TalentSkillsFormValues, validationSchemaFields } from "../../pages/TalentProfileEdit/TalentSkillsFormValues";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { talentProfileEditTooltips } from "../../tooltipsContent";
import ErrorList from "../ErrorList";
import { yearsExperienceOptions } from "../../utils/yearsExperienceOptions";

export type CreateTalentProfileStepSkillsProps = {
    onSubmit: () => void
    onBackClick: () => void
}

const CreateTalentProfileFormStepSkills = ({
    onSubmit,
    onBackClick,
}: CreateTalentProfileStepSkillsProps) => {
    const { gigTerminologyPlural } = useOrganizationContext();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const { 
        addTalentSkill, 
        removeTalentSkill, 
        talentSkills, 
        isRemovingTalentSkill,
        isLoading
    } = useTalentSkills();

    const handleRemoveSkill = async (skillId: string): Promise<GigApiFetcherResponse<void>> => {
        const response = await removeTalentSkill(skillId);

        if (!response.success) {
            setErrors(response.error.userMessages);
        }

        return response;
    };

    const handleAddSkill = async (values: TalentSkillsFormValues) => {
        setErrors([]);

        const existingSkill = talentSkills.find(_ => _.skillId === values.skillId);

        if (existingSkill) {
            setErrors([`Skill "${existingSkill.skillName}" already added.`]);
            methods.reset();
            return;
        }

        setIsSubmitting(true);

        const response = await addTalentSkill(values.skillId, values.yearsExperience);

        setIsSubmitting(false);

        if (!response.success) {
            setErrors(response.error.userMessages);
        } else { 
            methods.reset();
        }
    };

    const handleNextClick = () => {
        if (talentSkills.length < 3) {
            setErrors(["Please add at least three skills before proceeding."]);
        } else {
            setErrors([]);
            onSubmit();
        }
    };

    const validationSchema = yup.object(validationSchemaFields);

    const methods = useForm<TalentSkillsFormValues>({
        resolver: yupResolver(validationSchema)
    });

    return (
        <div className={formClassNames}>
            <TalentCreateProfileFormPageLayout
                title="Showcase your expertise"
                description={`Make sure to add at least three skills that truly showcase your expertise. The more specific and relevant your skills, the better your chances of being matched to the perfect ${gigTerminologyPlural.toLowerCase()} for you!`}
                content={
                    <>
                        <div className="flex flex-col space-y-2">
                            <div className="flex gap-6 flex-wrap w-full md:flex-nowrap">
                                <div className="w-full">
                                    <Controller
                                        name="skillId"
                                        control={methods.control}
                                        render={({ field: { onChange, value } }) => (
                                            <SkillsSearchDropdown
                                                required
                                                tooltip={talentProfileEditTooltips.addSkills}
                                                value={value}
                                                disabled={isSubmitting}
                                                onChange={onChange}
                                                error={methods.formState.errors.skillId}
                                                disabledSkillIds={talentSkills.map(_ => _.skillId)}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="w-full">
                                    <Controller
                                        name="yearsExperience"
                                        control={methods.control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormDropdown
                                                required
                                                label="Select years of experience"
                                                options={yearsExperienceOptions}
                                                error={methods.formState.errors.yearsExperience}
                                                value={value}
                                                onChange={onChange}
                                                disabled={isSubmitting}
                                            />
                                        )}
                                    />
                                </div>
                                <Button
                                    className="w-full h-fit md:mt-auto md:w-auto"
                                    loading={isSubmitting}
                                    variant="secondary"
                                    type="button"
                                    onClick={methods.handleSubmit(handleAddSkill)}
                                >
                                    Add skill
                                </Button>
                            </div>
                        </div>

                        <TalentSkillChips skills={talentSkills} removeSkill={handleRemoveSkill} isRemovingSkill={isRemovingTalentSkill} />
                        <ErrorList errors={errors} />
                    </>
                }
                iconName="Path"
                buttons={
                    <>
                        <Button variant="secondary" onClick={onBackClick} type="button">Back</Button>
                        <Button type="button" onClick={handleNextClick} loading={isLoading}>Next</Button>
                    </>
                }
            />
        </div>
    );
};

export default CreateTalentProfileFormStepSkills;