import useCurrentUser from "../../auth/useCurrentUser";
import Typography from "../Typography";
import Button from "../Button";
import { formClassNames } from "../CreateGigForm/classNames";
import TalentCreateProfileFormPageLayout from "./TalentCreateProfileFormPageLayout";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";

export type StepTalentProfileCreateIntroProps = {
    onNextClick: () => void
}

const StepTalentProfileCreateIntro = ({
    onNextClick
}: StepTalentProfileCreateIntroProps) => {
    const { userGivenName } = useCurrentUser();
    const { gigTerminologyPlural } = useOrganizationContext();

    return (
        <div
            className={formClassNames}
        >
            <TalentCreateProfileFormPageLayout
                title={`Welcome to Gigged${userGivenName ? `, ${userGivenName}!` : "!"}`}
                iconName="HandWaving"
                content={
                    <div className="flex flex-col space-y-4">
                        <Typography variant="body" component="span">{`We're excited to have you. Let's set up your profile so you can start getting invited to great ${gigTerminologyPlural.toLowerCase()}.`}</Typography>
                        <Typography variant="body" component="span">It only takes a few minutes and you can update it anytime.</Typography>
                        <Typography variant="body" component="span" className="font-bold">Ready to get started?</Typography>
                    </div>
                }
                buttons={ <Button type="button" className="ml-auto text-right" onClick={onNextClick}>Let's go!</Button> }
            />
        </div>
    );
};

export default StepTalentProfileCreateIntro;
