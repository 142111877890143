import * as yup from "yup";
import { ApiEditableProposalMilestoneFields } from "../../models/api/proposal";
import { hasNoMoreThan2DecimalPlaces } from "../../utils/yup-validators/hasNoMoreThan2DecimalPlaces";

export const validationSchemaFields = (isTimeAndMaterial: boolean) => ({
    estimatedDurationDays: yup.number()
        .required("Estimated duration is required.")
        .min(1, "Estimated duration must be at least 1 day.")
        .max(3650, "Estimated duration can be 10 years at most")
        .typeError("Estimated duration is required."),
    introduction: yup.string().required("Introduction is required."),
    relevantExperience: yup.string().required("Relevant experience is required."),
    portfolioUrl: yup.string(),
    talentDefinedMilestones: yup.boolean(),
    proposedAmount: yup.number().when(["talentDefinedMilestones", "paymentsEnabled"], {
        is: (talentDefinedMilestones: boolean, paymentsEnabled: boolean) => !talentDefinedMilestones && paymentsEnabled,
        then: yup.number().typeError("Proposed amount is required.").moreThan(0, "Proposed amount must be greater than £0.00").required("Proposed amount is required."),
    }),
    milestones: yup.array().when(["talentDefinedMilestones", "paymentsEnabled"], {
        is: (talentDefinedMilestones: boolean, paymentsEnabled: boolean) => talentDefinedMilestones && paymentsEnabled,
        then: yup.array().of(
            yup.object().shape({
                description: yup.string().transform((value: string) => value.trim()).required("Description is required."),
                amount: yup.number().typeError("Proposed amount is required.").moreThan(0, "Proposed amount must be greater than £0.00").required("Proposed milestone amount is required."),
            })
        )
    }),
    isVATRegistered: yup.boolean(),
    vatNumber: yup.string().when(["isVATRegistered", "paymentsEnabled"], {
        is: (isVATRegistered: boolean, paymentsEnabled: boolean) => isVATRegistered && paymentsEnabled,
        then: yup.string().typeError("VAT number is required.").transform((value: string) => value.trim()).max(25, "Cannot be more than 25 characters.").required("VAT number is required"),
        otherwise: yup.string().nullable()
    }),
    dailyRate: isTimeAndMaterial
        ? yup.number()
            .typeError("Daily rate is required.")
            .required("Daily rate is required.")
            .min(0.01, "Daily rate must be at least £0.01.")
            .max(10_000.01, "Daily rate cannot be greater than £10,000.")
            .test(hasNoMoreThan2DecimalPlaces)
        : yup.number().nullable(),
});

export type CreateProposalFormValues = {
    estimatedDurationDays: number,
    introduction: string,
    relevantExperience: string,
    portfolioUrl: string,
    talentDefinedMilestones: boolean,
    proposedAmount: number,
    milestones: ApiEditableProposalMilestoneFields[],
    paymentsEnabled: boolean,
    isVATRegistered: boolean,
    vatNumber?: string | null,
    dailyRate: number | null,
};