import { TalentProfileVideoDto } from "../models/app/talentProfileVideo";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";

export const useAddTalentProfileVideo = () => useAuthenticatedGigApiFetcher<TalentProfileVideoDto, void, TalentProfileVideoDto>("POST",
    (dto) => ({
        url: "api/me/profile-videos",
        body: dto,
    }));

export const useRemoveTalentProfileVideo = () => useAuthenticatedGigApiFetcher<TalentProfileVideoDto, void, TalentProfileVideoDto>("DELETE",
    (dto) => ({
        url: "api/me/profile-videos",
        body: dto,
    }));

export type UseTalentProfileVideosReturn = {
    profileVideoUrls?: string[]
    isLoading: boolean
    isValidating: boolean
    addProfileVideo: (dto: TalentProfileVideoDto) => Promise<GigApiFetcherResponse<void>>
    isAddingProfileVideo: boolean
    removeProfileVideo: (dto: TalentProfileVideoDto) => Promise<GigApiFetcherResponse<void>>
    isRemovingProfileVideo: boolean
}

export const useTalentProfileVideos = (): UseTalentProfileVideosReturn => {
    const url = "api/me/profile-videos";

    const apiCall = useGiggedApiSWR<string[]>(url);

    const [addProfileVideo, isAddingProfileVideo] = useAddTalentProfileVideo();
    const [removeProfileVideo, isRemovingProfileVideo] = useRemoveTalentProfileVideo();

    return {
        profileVideoUrls: apiCall.data,
        isLoading: apiCall.isLoading,
        isValidating: apiCall.isValidating,
        addProfileVideo: async (dto) => {
            const response = await addProfileVideo(dto);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isAddingProfileVideo,
        removeProfileVideo: async (dto) => {
            const response = await removeProfileVideo(dto);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isRemovingProfileVideo       
    };
};
