import { FormProvider } from "react-hook-form";
import FormDialogBox from "../../components/FormDialogBox";
import { ClientInviteUserFormValues, useClientInviteUsersForm } from "./ClientInviteUsersFormValues";
import Button from "../../components/Button";
import { successToast } from "../../toast";
import FormChipInput from "../../components/FormChipInput";
import { useClientUsers } from "../../api/clientUsers";

const ClientInviteUsersFormDialog = ({
    ...clientInviteUsersFormDialogState
}) => {
    const methods = useClientInviteUsersForm();
    const { formState: { errors } } = methods;

    const { inviteClientUser, isInvitingClientUser } = useClientUsers();

    const currentErrors = errors["emails"] || [];
    const lastErrorIndex = currentErrors.length ? currentErrors.length - 1 : undefined;
    const lastErrorMessage = currentErrors && currentErrors[lastErrorIndex || 0]?.message || undefined;
    const errorMessage = lastErrorMessage || errors?.["emails"]?.message;

    const handleSubmit = async (values: ClientInviteUserFormValues) => {
        const response = await inviteClientUser(values);

        if (response.success) {
            handleCloseDialog();
            successToast("Email invitations sent.");
        }

        return response;
    };

    const handleCloseDialog = () => {
        clientInviteUsersFormDialogState.onClose();
        methods.reset();
    };

    return (
        <>
            <FormDialogBox
                {...clientInviteUsersFormDialogState}
                isOpen={clientInviteUsersFormDialogState.isOpen}
                onClose={handleCloseDialog}
                title="Invite new user"
            >
                <p>Enter the email addresses of the user you wish to invite to join this client profile. Make sure to press enter after typing each email address.</p>
                <FormProvider {...methods}>
                    <form noValidate onSubmit={methods.handleSubmit(handleSubmit)} className="space-y-6">
                        <FormChipInput
                            name="emails"
                            placeholder="Enter email address"
                            errorMessage={errorMessage}
                            lastErrorIndex={lastErrorIndex}
                        />
                        <div className="mt-[1.5rem] flex items-center sm:justify-end sm:space-x-4 flex-col-reverse sm:flex-row">
                            <Button className="w-full sm:w-fit mt-4 sm:mt-0" disabled={methods.formState.isValidating || isInvitingClientUser} onClick={handleCloseDialog} variant="secondary">Cancel</Button>
                            <Button type="submit" className="w-full sm:w-fit" loading={methods.formState.isValidating || isInvitingClientUser} disabled={!methods.formState.isDirty}>Send invitations</Button>
                        </div>
                    </form>
                </FormProvider>
            </FormDialogBox>
        </>
    );
};

export default ClientInviteUsersFormDialog;