import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { CaretDown, CaretUp } from "phosphor-react";
import FormInputLabel from "./FormInputLabel";
import FormInputMessage from "./FormInputError";
import { FieldError } from "react-hook-form";

export type FormDropdownGroupOption<TValue extends string | number> = {
    label: string;
    options: {
        label: string;
        value: TValue;
    }[];
};

export type FormDropdownGroupProps<TValue extends string | number> = {
    options: FormDropdownGroupOption<TValue>[];
    label?: string;
    pleaseSelectText?: string;
    value: TValue | null;
    onChange: (value: TValue) => void;
    error?: FieldError | string;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    tooltip?: string;
    isLoadingOptions?: boolean;
};

const FormDropdownGroup = <TValue extends string | number>({
    label,
    pleaseSelectText = "Select an option",
    options,
    value,
    onChange,
    error,
    required,
    className,
    tooltip,
    disabled,
    isLoadingOptions,
}: FormDropdownGroupProps<TValue>) => {
    const _disabled = disabled || isLoadingOptions;

    const flatOptions = options.flatMap(group => group.options);
    const selected = value !== null ? flatOptions.find(option => option.value === value) : undefined;

    return (
        <div className={className}>
            {label && (
                <FormInputLabel className="whitespace-nowrap" required={required} tooltipText={tooltip}>{label}</FormInputLabel>
            )}
            <Listbox value={value} onChange={onChange} disabled={_disabled}>
                {({ open }) => (
                    <div className="relative mt-1">
                        <Listbox.Button className={clsx(
                            "border relative w-full cursor-default rounded-xl bg-white p-4 pr-10 text-left sm:text-sm focus:outline-none focus:ring-2 focus:ring-primary-text",
                            open && "border-b rounded-b-none z-50 ring-2 ring-primary-text",
                            error && "outline-error border-error focus:ring-error ring-error focus:outline outline outline-2 pr-10",
                            open && error && "ring-0",
                            _disabled && "border-disabled"
                        )}>
                            <span className={clsx(
                                "block truncate",
                                !selected && "text-placeholder-text",
                                _disabled && "text-disabled-text"
                            )}>
                                {isLoadingOptions ?
                                    "Loading..." :
                                    selected ? selected.label : pleaseSelectText}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                                {open ?
                                    <CaretUp
                                        className={clsx(
                                            "h-5 w-5 text-primary-text",
                                            _disabled && "text-disabled-text"
                                        )}
                                        aria-hidden
                                    /> :
                                    <CaretDown
                                        className={clsx(
                                            "h-5 w-5 text-primary-text",
                                            _disabled && "text-disabled-text"
                                        )}
                                        aria-hidden
                                    />
                                }
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options
                                className={clsx(
                                    "drop-shadow-lg rounded-b-lg absolute max-h-60 w-full overflow-auto",
                                    "scrollbar-thin scrollbar-thumb-scrollbar bg-white py-1 text-base",
                                    "focus:outline-none sm:text-sm z-50",
                                    open && "border-t"
                                )}
                            >
                                {options.map((group, groupIndex) => (
                                    <div key={groupIndex}>
                                        <div className="px-4 py-2 font-semibold text-gray-900 bg-gray-100">{group.label}</div>
                                        {group.options.map((option, optionIndex) => (
                                            <Listbox.Option
                                                key={`${groupIndex}-${optionIndex}`}
                                                className={({ active }) => clsx(
                                                    "relative cursor-default select-none py-2 pl-8 pr-4",
                                                    active && "bg-surface-accent",
                                                )}
                                                value={option.value}
                                            >
                                                {({ selected }) => (
                                                    <>
                                                        <span
                                                            className={clsx(
                                                                "block truncate",
                                                                selected ? "font-medium" : "font-normal"
                                                            )}
                                                        >
                                                            {option.label}
                                                        </span>
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </div>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                )}
            </Listbox>
            <FormInputMessage error={error} />
        </div>
    );
};

export default FormDropdownGroup;