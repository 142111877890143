import React, { useState } from "react";
import { ApiTalentDetails, ApiTalentReviewsDto } from "../models/api/talent";
import { TalentDetails, TalentReview } from "../models/app/talent";
import { mapFromApiTalentDetails, mapFromApiTalentReview } from "../models/mappers/talent";
import { GigApiPaginatedData } from "./common/apiResponse";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";

export type UseTalentReturn = {
    talent?: TalentDetails & {
        fullName: string
    }
    isLoading: boolean
}

export const useTalent = (talentId?: string): UseTalentReturn => {
    const url = `api/talents/${talentId}`;

    const apiCall = useGiggedApiSWR<ApiTalentDetails>(url);
    const talent = apiCall.data;

    return {
        talent: !talent ? undefined : mapFromApiTalentDetails(talent),
        isLoading: apiCall.isLoading
    };
};

export type UseTalentReviewsReturn =
    Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<ApiTalentReviewsDto>>, "data"> &
    Partial<Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<ApiTalentReviewsDto>>["data"], "items">> &
    {
        reviews: TalentReview[]
        totalCount: number
        isLoading: boolean
        top: number
        setTop: React.Dispatch<React.SetStateAction<number>>
    }

export const useTalentReviews = (talentId: string) => {
    const [top, setTop] = useState(5);

    const url = `api/talents/${talentId}/reviews?top=${top}`;

    const apiCall = useGiggedApiSWR<ApiTalentReviewsDto>(url);

    return {
        reviews: apiCall.data ? apiCall.data.reviews.map(mapFromApiTalentReview) : [],
        totalCount: apiCall.data?.totalCount || 0,
        isLoading: apiCall.isLoading,
        top,
        setTop,
    };
};
