import { NumericFormat } from "react-number-format";

export type MoneyDisplayProps = {
    value: number
    fixedDecimalScale?: number
    className?: string
}

const MoneyDisplay = ({ 
    value,
    fixedDecimalScale = 2,
    className,
}: MoneyDisplayProps) => {
    const isNegative = value < 0;
    return (
        <NumericFormat
            value={Math.abs(value)}
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            displayType="text"
            fixedDecimalScale={!!fixedDecimalScale}
            decimalScale={fixedDecimalScale}
            renderText={(value) => <span className={className}>{`${isNegative ? "-": ""}£${value}`}</span>}
        />
    );
};

export default MoneyDisplay;