import { UseProposalFilesReturn } from "../api/proposalFiles";
import { ProposalSummary } from "../models/app/proposal";
import Button from "./Button";
import { CardDataFieldLabel } from "./Card";
import DialogBox, { useDialogBoxState } from "./DialogBox";
import { dialogWidths } from "./DialogBox/DialogBox";
import FilePreview from "./FilePreview";

export type ProposalCoverLetterButtonProps = {
    proposal: ProposalSummary
    className?: string
    proposalFiles?: UseProposalFilesReturn
}

const ProposalCoverLetterButton = ({
    proposal,
    className,
    proposalFiles
}: ProposalCoverLetterButtonProps) => {
    const dialogState = useDialogBoxState();

    const portfolioHref = proposal.portfolioUrl.startsWith("http") ?
        proposal.portfolioUrl :
        `//${proposal.portfolioUrl}`;

    return (
        <span className={className}>
            <Button variant="tertiary" onClick={dialogState.open}>View cover letter</Button>
            <DialogBox {...dialogState} title="Cover letter" minWidth={dialogWidths.medium}>
                <div className="space-y-6 mt-6 text-left">
                    <div>
                        <CardDataFieldLabel>Introduction:</CardDataFieldLabel>
                        <p>
                            {proposal.introduction}
                        </p>
                    </div>
                    <div>
                        <CardDataFieldLabel>Relevant experience:</CardDataFieldLabel>
                        <p>
                            {proposal.relevantExperience}
                        </p>
                    </div>
                    {proposal?.portfolioUrl && (
                        <div>
                            <CardDataFieldLabel>Portfolio link</CardDataFieldLabel>
                            <a href={portfolioHref} target="_blank">
                                {proposal.portfolioUrl || "-"}
                            </a>
                        </div>
                    )}
                    {proposalFiles && proposalFiles.proposalFiles.map(file => (
                        <div className="space-y-2">
                            <CardDataFieldLabel>Proposal files:</CardDataFieldLabel>
                            <FilePreview
                                url={file.url}
                                name={file.name}
                                totalBytes={file.totalBytes}
                            />
                        </div>
                    ))}
                </div>
            </DialogBox>
        </span>
    );
};

export default ProposalCoverLetterButton;
