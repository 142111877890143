import Tooltip from "./Tooltip";

export type TooltipIconProps = {
    iconPath: string
    tooltipText: string
    size: number
}

export const TooltipIcon = ({
    iconPath,
    tooltipText, 
    size
}: TooltipIconProps) => {
    return (
        <Tooltip tooltipText={tooltipText}>
            <img 
                src={iconPath} 
                alt={tooltipText}
                width={size} 
                height={size}
            />
        </Tooltip>
    );
};

export default TooltipIcon;
