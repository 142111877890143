import { useGigMissingSkills } from "../api/gigMissingSkills";
import InfoBox from "./InfoBox";

export type MissingGigSkillsInfoBoxProps = {
    gigId: string
}

const MissingGigSkillsInfoBox = ({
    gigId,
}: MissingGigSkillsInfoBoxProps) => {
    const {missingSkills, isLoading } = useGigMissingSkills(gigId);

    if (isLoading || missingSkills.length === 0) return null;

    const missingSkillsString = missingSkills.map(_ => `"${_.name}"`).join(", ");
    const missingSkillsMessage = `This gig requires one or more skills not listed on your profile: ${missingSkillsString}`;

    return (
        <InfoBox className="mb-6">
            {missingSkillsMessage}
        </InfoBox>
    );
};

export default MissingGigSkillsInfoBox;
