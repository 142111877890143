import { ApiProposalSummary } from "../models/api/proposal";
import { CreateProposalDto, ProposalSummary } from "../models/app/proposal";
import { mapFromApiProposalSummary } from "../models/mappers/proposal";
import { GigApiPaginatedData } from "./common/apiResponse";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";

const useCreateProposal = () => 
    useAuthenticatedGigApiFetcher<CreateProposalDto, string, { dto: CreateProposalDto }>(
        "POST",
        ({ dto }) => ({
            url: "api/proposals",
            body: dto,
        })
    );

export type UseProposalsParams = {
    gigId?: string,
    allTalents?: boolean
}

export type UseProposalsReturn =
    Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<ApiProposalSummary>>, "data"> &
    Partial<Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<ApiProposalSummary>>["data"], "items">> &
    {
        proposals: ProposalSummary[]
        createProposal: (dto: CreateProposalDto) => Promise<GigApiFetcherResponse<string>>
        isCreatingProposal: boolean
    }

export type UseProposals = (params?: UseProposalsParams) => UseProposalsReturn

export const useProposals: UseProposals = ({
    gigId,
    allTalents,
} = {}) => {
    const url = `api/${allTalents ? "" : "me/"}proposals?` +
        "pageIndex=0" +
        "&pageSize=100" +
        (gigId ? `&gigId=${gigId}` : "");

    const apiCall = useGiggedApiSWR<GigApiPaginatedData<ApiProposalSummary>>(url);

    const [createProposal, isCreatingProposal] = useCreateProposal();

    const proposals = apiCall.data?.items ? apiCall.data.items.map(mapFromApiProposalSummary) : [];

    return {
        ...apiCall,
        ...apiCall.data,
        proposals,
        createProposal: async (dto: CreateProposalDto) => {
            const response = await createProposal({ dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isCreatingProposal,
    };
};