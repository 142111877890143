import * as yup from "yup";
import { isValidOptionalUrl } from "../../utils/yup-validators/isValidOptionalUrl";

const githubRegexRule = /^(https?:\/\/)?(www\.)?github\.com/i;
const facebookRegexRule = /^(https?:\/\/)?(www\.)?facebook\.com/i;
const twitterRegexRule = /^(https?:\/\/)?(www\.)?x\.com/i;
const linkedinRegexRule = /^(https?:\/\/)?(www\.)?linkedin\.com/i;

export const buildNullableUrlValidator = () => yup.string()
    .max(2048)
    .test(isValidOptionalUrl)
    .nullable();


export const githubURLValidator = () =>
    yup
        .string()
        .max(2048)
        .test(
            "is-github-url",
            "Must be a valid GitHub URL.",
            (value) => {
                if (!value) return true;
                return githubRegexRule.test(value);
            }
        )
        .nullable();

export const facebookURLValidator = () =>
    yup
        .string()
        .max(2048)
        .test(
            "is-facebook-url",
            "Must be a valid Facebook URL.",
            (value) => {
                if (!value) return true;
                return facebookRegexRule.test(value);
            }
        )
        .nullable();

export const twitterURLValidator = () =>
    yup
        .string()
        .max(2048)
        .test(
            "is-twitter-url",
            "Must be a valid X URL.",
            (value) => {
                if (!value) return true;
                return twitterRegexRule.test(value);
            }
        )
        .nullable();

export const linkedinURLValidator = () =>
    yup
        .string()
        .max(2048)
        .test(
            "is-linkedin-url",
            "Must be a valid LinkedIn URL.",
            (value) => {
                if (!value) return true;
                return linkedinRegexRule.test(value);
            }
        )
        .nullable();
