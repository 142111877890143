import { useEffect, useState } from "react";
import { FieldError } from "react-hook-form";
import { fetchIndustrialExperienceList } from "../api/industrialExperience";
import useAccessToken from "../auth/useAccessToken";
import FormDropdown from "./FormDropdown";
import { FormSearchDropdownOption } from "./FormSearchDropdown/FormSearchDropdown";

export type IndustrialExperienceDropdownProps = {
    value: string
    onChange: (value: string, option: FormSearchDropdownOption | null) => void
    disabled?: boolean
    required?: boolean
    error?: FieldError
    tooltip?: string
}

const IndustrialExperienceDropdown = ({
    value,
    onChange,
    disabled,
    required,
    error,
    tooltip
}: IndustrialExperienceDropdownProps) => {
    const accessToken = useAccessToken();
    const [allIndustrialExperienceOptions, setAllIndustrialExperienceOptions] = useState<FormSearchDropdownOption[]>([]);

    const fetchIndustrialExperienceOptions = async () => {
        const industrialExperiencesResponse = await fetchIndustrialExperienceList(undefined, accessToken);
        return industrialExperiencesResponse
            .map(experience => ({
                value: experience.id,
                label: experience.name,
            }));
    };

    useEffect(() => {
        if (!accessToken) return;

        const loadAllIndustrialExperiences = async () => {
            const fetchedIndustrialExperiences = await fetchIndustrialExperienceOptions();
            setAllIndustrialExperienceOptions(fetchedIndustrialExperiences);
        };

        loadAllIndustrialExperiences();
    }, [accessToken]);

    return (
        <FormDropdown
            required={required}
            label="Select industry"
            tooltip={tooltip}
            options={allIndustrialExperienceOptions}
            error={error}
            value={value}
            onChange={onChange}
            disabled={disabled}
        />
    );
};

export default IndustrialExperienceDropdown;