import { useGigForUnauthenticatedUsers } from "../../api/gigUnauthenticated";
import { useAppPaths, useGigId } from "../../Routes";
import Loader from "../../components/Loader";
import MarketingBanner from "../../components/MarketingBanner";
import { Helmet, HelmetProvider } from "react-helmet-async";
import GigDetailsCard from "../../components/GigDetailsCard";
import Button from "../../components/Button";
import { CaretLeft } from "phosphor-react";
import { Link } from "react-router-dom";
import { truncateString } from "../../utils/truncateString";

const UnauthenticatedFindAGigDetailsPage = () => {
    const gigId = useGigId();
    const appPaths = useAppPaths();
    const { gig, isLoading } = useGigForUnauthenticatedUsers(gigId);

    if (!gig || isLoading) return <Loader />;

    return (
        <HelmetProvider>
            <Helmet>
                <title>{truncateString(gig.title)} - Gigged.AI</title>
                <meta
                    name="description"
                    content={truncateString(gig.description, 100)}
                />
            </Helmet>
            <MarketingBanner
                title="Find your next freelance gig fast"
                body="Don't wait for our clients to come to you. Browse our current work packages and send a proposal to land your next gig."
                howGiggedWorksUrl="https://gigged.ai/for-freelancers/"
                signUpUrl="https://app.gigged.ai/sign-up?type=t"
            />
            <div className="mx-auto flex flex-col mb-12 max-w-6xl">
                <Link to={appPaths.gigs.findAGig}>
                    <Button
                        variant="tertiary"
                        className="my-6"
                        icon={<CaretLeft size={18} />
                        }>
                        View all gigs
                    </Button>
                </Link>
                <GigDetailsCard gig={gig} />
            </div>
        </HelmetProvider >
    );
};

export default UnauthenticatedFindAGigDetailsPage;