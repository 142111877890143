import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { useOrganizationContext } from "./api/current-organization/organizationContext";
import RequireAuthenticated from "./auth/RequireAuthenticated";
import useCurrentUser from "./auth/useCurrentUser";
import WithTitle from "./components/PageLayout/WithTitle";
import { FourOFourPage } from "./pages/404";
import { FourOThreePage } from "./pages/403";
import ClientDashboardPage from "./pages/ClientDashboard/ClientDashboardPage";
import ClientDetails from "./pages/ClientDetails";
import ClientProfileEditPage from "./pages/ClientProfileEdit/ClientProfileEditPage";
import DisputesPage from "./pages/Disputes";
import FindAGigPage from "./pages/FindAGig";
import FindAGigDetailsPage from "./pages/FindAGigDetails";
import FindTalentPage from "./pages/FindTalent";
import FindTalentDetails from "./pages/FindTalentDetails";
import GigCreatePage from "./pages/GigCreate";
import GigCreateIntroPage from "./pages/GigCreateIntro";
import GigDetailsPage from "./pages/GigDetails";
import GigEditPage from "./pages/GigEdit";
import GigInvitesPage from "./pages/GigInvites";
import GigsMatchesListPage from "./pages/GigMatchesList";
import GigsProposalsListPage from "./pages/GigProposalsList";
import GigsCancelledClientPage from "./pages/GigsCancelledClient";
import GigsCancelledTalentPage from "./pages/GigsCancelledTalent";
import GigsCompletedClientPage from "./pages/GigsCompletedClient";
import GigsCompletedTalentPage from "./pages/GigsCompletedTalent";
import GigsListDraftPage from "./pages/GigsListDraft";
import GigsListPostedPage from "./pages/GigsListPosted";
import GigsOngoingClientPage from "./pages/GigsOngoingClient";
import GigsOngoingTalentPage from "./pages/GigsOngoingTalent";
import { InboxPage, InboxArchivePage } from "./pages/Inbox";
import InvoiceDetailsPage from "./pages/InvoiceDetailsPage";
import InvoicesListPage from "./pages/InvoicesList";
import ManageUsersPage from "./pages/ManageUsers/ManageUsersPage";
import PaymentCancelledPage from "./pages/PaymentCancelled";
import PaymentSuccessPage from "./pages/PaymentSuccess";
import PayoutAccountPage from "./pages/PayoutAccount";
import ProposalCreatePage from "./pages/ProposalCreate";
import ProposalEditPage from "./pages/ProposalEdit";
import ProposalsListPage from "./pages/ProposalsList";
import SignUpPage from "./pages/SignUp";
import TalentDashboardPage from "./pages/TalentDashboard";
import TalentProfileEditPage from "./pages/TalentProfileEdit";
import UnauthenticatedFindAGigPage from "./pages/UnauthenticatedFindAGig/UnauthenticatedFindAGigPage";
import UnauthenticatedFindTalentPage from "./pages/UnauthenticatedFindTalent/UnauthenticatedFindTalentPage";
import LoginPage from "./components/Login";
import WithdrawalsListPage from "./pages/WithdrawalsList";
import UnauthenticatedFindAGigDetailsPage from "./pages/UnauthenticatedFindAGigDetails/UnauthenticatedFindAGigDetailsPage";
import { useUserGetMe } from "./api/userGetMe";
import DevTools from "./components/DevTools";

export const fourOFourPath = "/404";
export const fourOThreePath = "/403";
export const signUpPath = "/sign-up";

const appPaths = (gigTerminology: string, giggedTerminologyPlural: string, giggedClientTerminologyPlural: string, talentTerminology: string, talentTerminologyPlural: string) => {
    const gig = gigTerminology.toLowerCase().replace(" ", "-");
    const gigPlural = giggedTerminologyPlural.toLowerCase().replace(" ", "-");
    const clientPlural = giggedClientTerminologyPlural.toLowerCase().replace(" ", "-");
    const talentPlural = talentTerminologyPlural.toLowerCase().replace(" ", "-");

    return ({
        login: "/login",
        gigs: {
            indexDraft: `/draft-${gigPlural}`,
            indexPosted: `/posted-${gigPlural}`,
            invites: "/invites",
            ongoing: `/ongoing-${gigPlural}`,
            completed: `/completed-${gigPlural}`,
            cancelled: `/cancelled-${gigPlural}`,
            details: (gigId: string) => `/${gigPlural}/${gigId}`,
            createIntro: `/${gigPlural}/create-intro`,
            create: `/${gigPlural}/create`,
            proposals: (gigId: string) => `/${gigPlural}/${gigId}/proposals`,
            matches: (gigId: string) => `/${gigPlural}/${gigId}/matches`,
            findAGig: `/find-a-${gig}`,
            findAGigDetails: (gigId: string) => `/find-a-${gig}/${gigId}`,
            edit: (gigId: string) => `/${gig}/${gigId}/edit`,
        },
        clients: {
            details: (clientId: string) => `/${clientPlural}/${clientId}`
        },
        talents: {
            findTalent: `/find-${talentPlural}`,
            findTalentDetails: (talentId: string) => `/find-${talentPlural}/${talentId}`
        },
        dashboard: "/dashboard",
        proposals: {
            index: "/proposals",
            create: (gigId: string) => `/${gigPlural}/${gigId}/proposals/create`,
            edit: (proposalId: string) => `/proposals/${proposalId}/edit`,
        },
        profile: {
            edit: "/edit-profile"
        },
        payoutAccount: "/payout-account",
        disputes: {
            index: "/disputes",
        },
        payments: {
            success: "/payment-success",
            cancelled: "/payment-cancelled",
        },
        fourOFour: fourOFourPath,
        fourOThree: fourOThreePath,
        inbox: {
            index: "/inbox",
            archive: "/inbox/archive",
            conversation: (conversationId: string) => `/inbox/${conversationId}`
        },
        invoices: {
            index: "/invoices",
            details: (invoiceId: string | number) => `/invoices/${invoiceId}`,
        },
        account: {
            manageUsers: "/manage-users"
        },
        withdrawals: {
            index: "/withdrawals"
        },
        devTools: "/dev-tools"
    });
};

export const useAppPaths = () => {
    const {
        gigTerminology,
        gigTerminologyPlural,
        giggedClientTerminologyPlural,
        talentTerminology,
        talentTerminologyPlural
    } = useOrganizationContext();

    return appPaths(
        gigTerminology,
        gigTerminologyPlural,
        giggedClientTerminologyPlural,
        talentTerminology,
        talentTerminologyPlural
    );
};

const AppRoutes: React.FC = () => {
    const user = useCurrentUser();
    const { userRole } = user;
    const routeAppPaths = useAppPaths();
    const { organizationConfig, gigTerminology, gigTerminologyPlural, talentTerminology, talentTerminologyPlural } = useOrganizationContext();
    const { giggedClientIsUnverified } = useUserGetMe();

    const disableFindTalentRoute =
        organizationConfig?.isTalentProfilesPrivate && userRole === "talent" || 
        userRole === "client" && giggedClientIsUnverified;

    const isOtm = organizationConfig.name === "Gigged.AI";
    const isAdmin = !user.userRole && user.idpUserId.length > 0;

    const isDevelopment = process.env.NODE_ENV === "development";

    const currentPath = window.location.pathname;
    const talentTerminologyRedirect = currentPath.replace(/\/find-talent/i, `/find-${talentTerminologyPlural.toLowerCase()}`);

    return (
        <Routes>
            <Route element={<RequireAuthenticated />}>
                {!isAdmin && (
                    <Route path={routeAppPaths.dashboard} element={userRole === "client" ? (
                        <WithTitle pageTitle="Your Dashboard - Gigged.AI" C={ClientDashboardPage} />
                    ) : (
                        <WithTitle pageTitle="Your Dashboard - Gigged.AI" C={TalentDashboardPage} />
                    )} />
                )}
                <Route path={routeAppPaths.gigs.indexDraft} element={<WithTitle pageTitle={`Draft ${gigTerminologyPlural} - Gigged.AI`} C={GigsListDraftPage} />} />
                <Route path={routeAppPaths.gigs.invites} element={<WithTitle pageTitle={`${gigTerminology} Invites - Gigged.AI`} C={GigInvitesPage} />} />
                <Route path={routeAppPaths.gigs.indexPosted} element={<WithTitle pageTitle={`Posted ${gigTerminologyPlural} - Gigged.AI`} C={GigsListPostedPage} />} />
                {userRole === "client" ? (
                    <Route path={routeAppPaths.gigs.ongoing} element={<WithTitle pageTitle={`Ongoing ${gigTerminologyPlural} - Gigged.AI`} C={GigsOngoingClientPage} />} />
                ) : (
                    <Route path={routeAppPaths.gigs.ongoing} element={<WithTitle pageTitle={`Ongoing ${gigTerminologyPlural} - Gigged.AI`} C={GigsOngoingTalentPage} />} />
                )}
                {userRole === "client" ? (
                    <Route path={routeAppPaths.gigs.completed} element={<WithTitle pageTitle={`Completed ${gigTerminologyPlural} - Gigged.AI`} C={GigsCompletedClientPage} />} />
                ) : (
                    <Route path={routeAppPaths.gigs.completed} element={<WithTitle pageTitle={`Completed ${gigTerminologyPlural} - Gigged.AI`} C={GigsCompletedTalentPage} />} />
                )}
                {userRole === "client" ? (
                    <Route path={routeAppPaths.gigs.cancelled} element={<WithTitle pageTitle={`Cancelled ${gigTerminologyPlural} - Gigged.AI`} C={GigsCancelledClientPage} />} />
                ) : (
                    <Route path={routeAppPaths.gigs.cancelled} element={<WithTitle pageTitle={`Cancelled ${gigTerminologyPlural} - Gigged.AI`} C={GigsCancelledTalentPage} />} />
                )}
                <Route path={routeAppPaths.gigs.details(":gigId")} element={<WithTitle pageTitle={`${gigTerminology} Details - Gigged.AI`} C={GigDetailsPage} />} />
                {(!isOtm || !giggedClientIsUnverified) && (
                    <>
                        <Route path={routeAppPaths.gigs.createIntro} element={<WithTitle pageTitle={`Post a ${gigTerminology} - Gigged.AI`} C={GigCreateIntroPage} />} />
                        <Route path={routeAppPaths.gigs.create} element={<WithTitle pageTitle={`Post a ${gigTerminology} - Gigged.AI`} C={GigCreatePage} />} />
                    </>
                )}
                <Route path={routeAppPaths.gigs.matches(":gigId")} element={<WithTitle pageTitle={"Matches - Gigged.AI"} C={GigsMatchesListPage} />} />
                <Route path={routeAppPaths.gigs.edit(":gigId")} element={<WithTitle pageTitle={`Edit ${gigTerminology} - Gigged.AI`} C={GigEditPage} />} />
                <Route path={routeAppPaths.gigs.proposals(":gigId")} element={<WithTitle pageTitle={"Proposals - Gigged.AI"} C={GigsProposalsListPage} />} />
                <Route path={routeAppPaths.talents.findTalentDetails(":talentId")} element={<WithTitle pageTitle={`${talentTerminology} Details - Gigged.AI`} C={FindTalentDetails} />} />
                <Route path={routeAppPaths.proposals.index} element={<WithTitle pageTitle={"Proposals - Gigged.AI"} C={ProposalsListPage} />} />
                <Route path={routeAppPaths.proposals.create(":gigId")} element={<WithTitle pageTitle={"Create Proposal - Gigged.AI"} C={ProposalCreatePage} />} />
                <Route path={routeAppPaths.proposals.edit(":proposalId")} element={<WithTitle pageTitle={"Edit Proposal - Gigged.AI"} C={ProposalEditPage} />} />
                {userRole === "client" ? (
                    <Route path={routeAppPaths.profile.edit} element={<WithTitle pageTitle={"Edit Profile - Gigged.AI"} C={ClientProfileEditPage} />} />
                ) : (
                    <Route path={routeAppPaths.profile.edit} element={<WithTitle pageTitle={"Edit Profile - Gigged.AI"} C={TalentProfileEditPage} />} />
                )}
                <Route path={routeAppPaths.fourOFour} element={<WithTitle pageTitle={"404 - Gigged.AI"} C={FourOFourPage} />} />
                <Route path={routeAppPaths.fourOThree} element={<WithTitle pageTitle={"403 - Gigged.AI"} C={FourOThreePage} />} />
                <Route path={routeAppPaths.clients.details(":clientId")} element={<WithTitle pageTitle={"Client Profile - Gigged.AI"} C={ClientDetails} />} />
                <Route path={routeAppPaths.inbox.index} element={<WithTitle pageTitle="Inbox - Gigged.AI" C={InboxPage} />} />
                <Route path={routeAppPaths.inbox.conversation(":conversationId")} element={<WithTitle pageTitle="Inbox - Gigged.AI" C={InboxPage} />} />
                <Route path={routeAppPaths.inbox.archive} element={<WithTitle pageTitle={"Inbox Archive - Gigged.AI"} C={InboxArchivePage} />} />
                <Route path={routeAppPaths.payments.success} element={<WithTitle pageTitle={"Payment Success - Gigged.AI"} C={PaymentSuccessPage} />} />
                <Route path={routeAppPaths.payments.cancelled} element={<WithTitle pageTitle={"Payment Cancelled - Gigged.AI"} C={PaymentCancelledPage} />} />
                {organizationConfig?.isDisputesEnabled && <Route path={routeAppPaths.disputes.index} element={<WithTitle pageTitle={"Disputes - Gigged.AI"} C={DisputesPage} />} />}

                {userRole === "talent" && (
                    <Route path={routeAppPaths.payoutAccount} element={<WithTitle pageTitle={"Payouts - Gigged.AI"} C={PayoutAccountPage} />} />
                )}

                {organizationConfig.isPaymentsEnabled && (
                    <>
                        <Route path={routeAppPaths.invoices.index} element={<WithTitle pageTitle={"Invoices - Gigged.AI"} C={InvoicesListPage} />} />
                        <Route path={routeAppPaths.invoices.details(":invoiceId")} element={<WithTitle pageTitle={"Invoice Details - Gigged.AI"} C={InvoiceDetailsPage} />} />
                        <Route path={routeAppPaths.withdrawals.index} element={<WithTitle pageTitle={"Withdrawals - Gigged.AI"} C={WithdrawalsListPage} />} />
                    </>
                )}
                {organizationConfig.isAllowGiggedClientUserToInviteEnabled && <Route path={routeAppPaths.account.manageUsers} element={<WithTitle pageTitle={"Manage Users - Gigged.AI"} C={ManageUsersPage} />} />}

            </Route>

            <Route path={routeAppPaths.login} element={<LoginPage />} />

            <Route path={"/"} element={userRole === "talent" ? (
                <WithTitle pageTitle="Your Dashboard - Gigged.AI" C={TalentDashboardPage} />
            ) : userRole === "client" ? (
                <WithTitle pageTitle="Your Dashboard - Gigged.AI" C={ClientDashboardPage} />
            ) : <Navigate to={routeAppPaths.gigs.findAGig} replace />} />

            <Route path={routeAppPaths.gigs.findAGig} element={
                !isAdmin && userRole === undefined && isOtm ? (
                    <WithTitle pageTitle={`Find a ${gigTerminology} - Gigged.AI`} C={UnauthenticatedFindAGigPage} />
                ) : (
                    <RequireAuthenticated>
                        <WithTitle pageTitle={`Find a ${gigTerminology} - Gigged.AI`} C={FindAGigPage} />
                    </RequireAuthenticated>
                )}
            />

            <Route path={routeAppPaths.gigs.findAGigDetails(":gigId")} element={
                !isAdmin && userRole === undefined && isOtm ? (
                    <WithTitle pageTitle={`Find a ${gigTerminology} - Details`} C={UnauthenticatedFindAGigDetailsPage} />
                ) : (
                    <RequireAuthenticated>
                        <WithTitle pageTitle={`Find a ${gigTerminology} - Details`} C={FindAGigDetailsPage} />
                    </RequireAuthenticated>
                )}
            />

            <Route path={routeAppPaths.talents.findTalent} element={
                !isAdmin && userRole === undefined && isOtm ? (
                    <WithTitle pageTitle={`Find ${talentTerminology} - Gigged.AI`} C={UnauthenticatedFindTalentPage} />
                ) : (!disableFindTalentRoute || isAdmin) ? (
                    <RequireAuthenticated>
                        <WithTitle pageTitle={`Find ${talentTerminology} - Gigged.AI`} C={FindTalentPage} />
                    </RequireAuthenticated>
                ) : <WithTitle pageTitle={"403 - Gigged.AI"} C={FourOThreePage} />}
            />

            {isDevelopment && <Route path={routeAppPaths.devTools} element={<WithTitle pageTitle="Dev Tools" C={DevTools} />} />}

            <Route path={signUpPath} element={<WithTitle pageTitle={"Sign Up - Gigged.AI"} C={SignUpPage} />} />

            {currentPath.startsWith("/find-talent") && (
                <Route path="*" element={<Navigate to={talentTerminologyRedirect} replace />} />
            )}

            <Route path="*" element={<Navigate to={routeAppPaths.gigs.findAGig} replace />} />

        </Routes>
    );
};

export const useGigId = (): string => {
    const { gigId } = useParams();

    if (!gigId) throw new Error("No gig id param available.");

    return gigId;
};

export const useTalentId = (): string => {
    const { talentId } = useParams();

    if (!talentId) throw new Error("No talent id param available.");

    return talentId;
};

export const useProposalId = (): string => {
    const { proposalId } = useParams();

    if (!proposalId) throw new Error("No proposal id param available.");

    return proposalId;
};

export const useClientId = (): string => {
    const { clientId } = useParams();

    if (!clientId) throw new Error("No client id param available.");

    return clientId;
};

export const useInvoiceId = (): number => {
    const { invoiceId } = useParams();

    if (!invoiceId) throw new Error("No invoice id param available.");

    return parseInt(invoiceId);
};

export default AppRoutes;