import { ApiInvoiceDetails, ApiInvoiceSummary } from "../api/invoice";
import { InvoiceDetails, InvoiceSummary } from "../app/invoice";

export const mapFromApiInvoiceSummary = (apiDto: ApiInvoiceSummary): InvoiceSummary => ({
    ...apiDto,
    id: apiDto.invoiceId,
    createdDate: new Date(apiDto.createdDate),
    isVatRegistered: apiDto.isVatRegistered,
});

export const mapFromApiInvoiceDetails = (apiDto: ApiInvoiceDetails): InvoiceDetails => ({
    ...apiDto,
    ...mapFromApiInvoiceSummary(apiDto)
});
