import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { TalentListSkillsDto, TalentSkillDto } from "../models/app/talentSkill";
import { AddTalentSkillDto } from "../models/api/talentSkills";
import { SpecializationDto } from "../models/app/specialization";
import { ApiUpdateTalentSpecializationDto } from "../models/api/talentSpecialization";

const useAddTalentSkill = () => useAuthenticatedGigApiFetcher<AddTalentSkillDto, void, { skillId: string, yearsExperience: number }>("POST",
    ({ skillId, yearsExperience }) => ({
        url: `api/me/skills/${skillId}`,
        body: { yearsExperience }
    }));

const useRemoveTalentSkill = () => useAuthenticatedGigApiFetcher<never, void, string>("DELETE",
    (skillId: string) => ({
        url: `api/me/skills/${skillId}`,
    }));

const useUpdateTalentSpecialization = () => useAuthenticatedGigApiFetcher<ApiUpdateTalentSpecializationDto, void, ApiUpdateTalentSpecializationDto>("PUT",
    (dto) => ({
        url: "api/me/specialization",
        body: dto
    }));

export type UseTalentSkillsReturn =
    UseGiggedApiSWRReturn<TalentListSkillsDto> &
    {
        talentSkills: TalentSkillDto[]
        specialization?: SpecializationDto
        refetchTalentSkills: () => void
        addTalentSkill: (skillId: string, yearsExperience: number) => Promise<GigApiFetcherResponse<void>>
        removeTalentSkill: (skillId: string) => Promise<GigApiFetcherResponse<void>>
        updateTalentSpecialization: (dto: ApiUpdateTalentSpecializationDto) => Promise<GigApiFetcherResponse<void>>
        isUpdatingTalentSpecialization: boolean
        isAddingTalentSkill: boolean
        isRemovingTalentSkill: boolean
    }

export const useTalentSkills = (): UseTalentSkillsReturn => {
    const apiCall = useGiggedApiSWR<TalentListSkillsDto>("api/me/skills");
    const [addSkill, isAddingTalentSkill] = useAddTalentSkill();
    const [removeSkill, isRemovingTalentSkill] = useRemoveTalentSkill();
    const [updateTalentSpecialization, isUpdatingTalentSpecialization] = useUpdateTalentSpecialization();

    return {
        ...apiCall,
        talentSkills: apiCall.data?.skills || [],
        specialization: apiCall.data?.specialization,
        refetchTalentSkills: apiCall.refetch,
        addTalentSkill: async (skillId: string, yearsExperience: number) => {
            const response = await addSkill({ skillId, yearsExperience });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        removeTalentSkill: async (skillId: string) => {
            const response = await removeSkill(skillId);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        updateTalentSpecialization: async (dto: ApiUpdateTalentSpecializationDto) => {
            const response = await updateTalentSpecialization(dto);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isAddingTalentSkill,
        isRemovingTalentSkill,
        isUpdatingTalentSpecialization,
    };
};