import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { b2cPolicies } from "../auth/authConfig";
import { useAuthContext } from "../auth/authContext";
import { useAppPaths } from "../Routes";

const SignUpPage = () => {
    const { loginRequest } = useAuthContext();
    const { instance } = useMsal();
    const appPaths = useAppPaths();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate(appPaths.dashboard);
        }
    }, [isAuthenticated]);

    if (isAuthenticated) {
        return null;
    }

    const search = new URLSearchParams(window.location.search);
    const type = search.get("type");

    useEffect(() => {
        // Using abbreviations for giggedclient and talent here so it's a bit
        // quieter to the user/org that we're not using the org terminologies. They
        // might not be available to us at this point.
        if (type === "gc") {
            instance.loginRedirect({ 
                ...loginRequest,
                authority: b2cPolicies.authorities.giggedClientSignUp.authority
            });   
        } else if (type === "t") {
            instance.loginRedirect({ 
                ...loginRequest,
                authority: b2cPolicies.authorities.talentSignUp.authority
            });  
        }         
    }, [type]);

    return null;
};

export default SignUpPage;