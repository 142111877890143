import clsx from "clsx";

export type ProgressBarProps = {
    currentStepIndex: number,
    numberOfSteps: number,
    skinny?: boolean
    bgWhite?: boolean
}

const ProgressBar = ({
    currentStepIndex,
    numberOfSteps,
    skinny,
    bgWhite,
}: ProgressBarProps) => {
    const percentagePerStep = 100 / numberOfSteps;
    const percentageProgress = (currentStepIndex + 1) * percentagePerStep;
    // TODO better fix for the over 100% width issue.
    return (
        <div className={clsx(
            "w-full rounded-full h-2.5 dark:bg-gray-700",
            skinny ? "h-1.5" : "h-2.5",
            bgWhite ? "!bg-white" : "!bg-surface-inset",
        )}>
            <div className="bg-primary h-full rounded-full" style={{ width: `${percentageProgress}%` }} />
        </div>
    );
};

export default ProgressBar;
