import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { hasNoMoreThan2DecimalPlaces } from "../../utils/yup-validators/hasNoMoreThan2DecimalPlaces";

export type TalentCreateProfilePersonalDetailsFormValues = {
    firstName: string
    lastName: string
    tradingName?: string
    roleTitle: string
    aboutMe: string
    receiveNewGigNotifications?: boolean
    locationId?: string | null
    dailyRate?: number | null
    profileImageDataUri?: string | null
}

export const buildTalentCreateProfileFormValidationSchema = (isPaymentsEnabled: boolean) => ({
    firstName: yup.string().required("First name is required."),
    lastName: yup.string().required("Last name is required."),
    tradingName: yup.string().max(100, "The maximum length for trading name is 100 characters."),
    roleTitle: yup.string().required("Role title is required.").max(100, "The maximum length for role title is 100 characters."),
    aboutMe: yup.string().required("About me is required."),
    receiveNewGigNotifications: yup.boolean(),
    locationId: isPaymentsEnabled
        ? yup.string().required("Select your location")
        : yup.string().nullable(),
    dailyRate: isPaymentsEnabled
        ? yup.number()
            .min(0.01, "Daily rate must be at least £0.01.")
            .max(10_000.00, "Daily rate must be £10,000 or less.")
            .test(hasNoMoreThan2DecimalPlaces)
            .typeError("Must be at least £0.01.")
        : yup.number().nullable(),
    profileImageDataUri: yup.string().nullable(),
});

const buildValidationSchema = (isPaymentsEnabled: boolean) =>
    yup.object(buildTalentCreateProfileFormValidationSchema(isPaymentsEnabled));

export const useTalentCreateProfileForm = (
    isPaymentsEnabled: boolean,
    initialValues?: TalentCreateProfilePersonalDetailsFormValues
) => useForm<TalentCreateProfilePersonalDetailsFormValues>({
    resolver: yupResolver(buildValidationSchema(isPaymentsEnabled)),
    defaultValues: {
        firstName: initialValues?.firstName || "",
        lastName: initialValues?.lastName || "",
        tradingName: initialValues?.tradingName || "",
        roleTitle: initialValues?.roleTitle || "",
        aboutMe: initialValues?.aboutMe || "",
        receiveNewGigNotifications: initialValues?.receiveNewGigNotifications ? true : false,
        locationId: initialValues?.locationId || "",
        dailyRate: initialValues?.dailyRate ?? 0,
        profileImageDataUri: initialValues?.profileImageDataUri || ""
    }
});
