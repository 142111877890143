import { useDialogBoxState } from "../../components/DialogBox";
import FormInputLabel from "../../components/FormInputLabel";
import { UseTalentCertificationsReturn } from "../../api/talentCertifications";

import HistoryListAddItemButton from "./HistoryListAddItemButton";
import { CertificationFormValues } from "./CertificationFormValues";
import CertificationCard from "./CertificationCard";
import { CertificationFormDialog } from "./CertificationFormDialog";
import { useState } from "react";
import ErrorList from "../../components/ErrorList";

export type CertificationsProps = {
    talentCertifications: UseTalentCertificationsReturn
}
export const Certifications = ({
    talentCertifications
}: CertificationsProps) => {
    const [errors, setErrors] = useState<string[]>([]);
    const {
        certifications,
        addCertification,
        updateCertification,
        removeCertification,
    } = talentCertifications;

    const createCourseDialogState = useDialogBoxState();

    const handleAddCourse = async (values: CertificationFormValues) => {
        if (values.achievementDate === null) throw new Error("Expected achievement date not to be null.");

        const response = await addCertification({
            ...values,
            achievementDate: values.achievementDate as Date,
        });

        if (response.success) {
            createCourseDialogState.close();
        } else {
            setErrors([response.error.message]);
        }

        return response;
    };

    const makeUpdateCertificationHandler = (id: string) => async (values: CertificationFormValues) => {
        if (values.achievementDate === null) throw new Error("Expected achievement date not to be null.");

        const response = await updateCertification(id, {
            ...values,
            achievementDate: values.achievementDate as Date,
        });

        if (!response.success) {
            setErrors(response.error.userMessages);
        }

        return response;
    };

    const makeRemoveCertificationHandler = (id: string) => async () => {
        const response = await removeCertification(id);

        if (!response.success) {
            setErrors(response.error.userMessages);
        }
        return response;
    };

    return (
        <>
            <div>
                <FormInputLabel className="ml-0 mb-4">Awards / certifications</FormInputLabel>
                <div className="space-y-4">
                    <HistoryListAddItemButton onClick={createCourseDialogState.open}>Add certification</HistoryListAddItemButton>
                    {certifications.map(certification => (
                        <CertificationCard
                            key={certification.id}
                            certification={certification}
                            onRemove={makeRemoveCertificationHandler(certification.id)}
                            updateJob={makeUpdateCertificationHandler(certification.id)}
                        />
                    ))}
                </div>
            </div>
            <ErrorList errors={errors} />
            <CertificationFormDialog
                {...createCourseDialogState}
                initialValues={{
                    title: "",
                    achievementDate: null,
                }}
                resetOnSubmit
                onSubmit={handleAddCourse}
            />
        </>
    );
};

export default Certifications;