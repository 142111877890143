import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { ApiFile } from "../models/api/file";

export type UseProposalFilesReturn = {
    proposalFiles: ApiFile[]
    isLoading: boolean
};

export const useProposalFiles = <TProposalId extends string | undefined>(proposalId: TProposalId): UseProposalFilesReturn => {
    const url = `api/proposals/${proposalId}/queries/files`;

    const apiCall = useGiggedApiSWR<ApiFile[]>(url);

    return {
        isLoading: apiCall.isLoading,
        proposalFiles: apiCall.data || [],
    };
};