import { Buildings, CalendarBlank } from "phosphor-react";
import Typography from "../../components/Typography";
import { TalentEducationCourseDto } from "../../models/app/talentEducation";
import DataPoint from "./DataPoint";

export type EducationCourseProps = {
    educationCourse: TalentEducationCourseDto
}

const EducationCourse = ({
    educationCourse: {
        courseTitle,
        instituteName,
        startDate,
        endDate,
        courseDescription,
    }
}: EducationCourseProps) => {
    return (
        <div className="break-words">
            <Typography variant="title-small" component="label" className="block mb-4">{courseTitle}</Typography>
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mb-4">
                <DataPoint Icon={Buildings}>{instituteName}</DataPoint>
                <DataPoint Icon={CalendarBlank}>{startDate.toLocaleDateString()} - {endDate ? endDate.toLocaleDateString() : "present"}</DataPoint>
            </div>
            <p className="whitespace-pre-wrap">{courseDescription}</p>
        </div>
    );
};

export default EducationCourse;
