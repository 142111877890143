import { useNavigate } from "react-router-dom";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { useProposal } from "../api/proposal";
import EditProposalForm, { EditProposalFormValues } from "../components/EditProposalForm";
import MissingGigSkillsInfoBox from "../components/MissingGigSkillsInfoBox";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { ApiFile } from "../models/api/file";
import { useAppPaths, useProposalId } from "../Routes";
import Loader from "../components/Loader";
import { successToast } from "../toast";
import ProposalGigSummaryCard from "../components/ProposalGigSummaryCard";

const ProposalEditPage = () => {
    const appPaths = useAppPaths();
    const proposalId = useProposalId();
    const { proposal, updateProposal, isUpdatingProposal } = useProposal(proposalId);
    const { organizationConfig } = useOrganizationContext();
    const navigate = useNavigate();
    const { gigTerminology } = useOrganizationContext();

    if (!proposalId || !proposal || !organizationConfig) return <Loader />;

    const handleSubmit = async (
        values: EditProposalFormValues,
        proposalFiles: ApiFile[],
    ): Promise<GigApiFetcherResponse<void>> => {
        const fileDataUrisToAdd = proposalFiles
            .filter(file => !proposal.files.find(existingFile => existingFile.id === file.id))
            .map(file => file.url);

        const fileIdsToRemove = proposal.files
            .filter(existingFile => !proposalFiles.find(proposalFile => proposalFile.id === existingFile.id))
            .map(file => file.id);

        const response = await updateProposal({
            ...values,
            fileDataUrisToAdd,
            fileIdsToRemove
        });

        if (response.success) {
            successToast("Proposal updated successfully.");
            navigate(appPaths.proposals.index);
        }

        return response;
    };

    return (
        <PageContent>
            <MissingGigSkillsInfoBox gigId={proposal.gig.id} />
            <ProposalGigSummaryCard gig={proposal.gig} gigTerminology={organizationConfig.gigTerminology} isPaymentsEnabled={organizationConfig.isPaymentsEnabled} isWorksome={proposal.isWorksome}/>
            <Typography variant="display-medium" component="h1" gutterBottom>{`Edit ${gigTerminology} Proposal`}</Typography>
            <EditProposalForm
                proposalId={proposalId}
                isSubmitting={isUpdatingProposal}
                initialValues={{
                    ...proposal,
                    proposedAmount: proposal.milestones[0]?.amount ?? 0,
                    paymentsEnabled: organizationConfig.isPaymentsEnabled
                }}
                onSubmit={handleSubmit}
                submitButtonText="Update"
                proposalFiles={proposal.files}
                gigBudget={proposal.gig.budget}
                isWorksomeGig={proposal.isWorksome}
                isTimeAndMaterial={proposal.isTimeAndMaterial}
            />
        </PageContent>
    );
};

export default ProposalEditPage;