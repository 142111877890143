import { FacebookLogo, GithubLogo, LinkedinLogo, XLogo } from "@phosphor-icons/react";
import { useEffect, useState } from "react";

import Button from "../Button";
import { formClassNames } from "../CreateGigForm/classNames";
import TalentCreateProfileFormPageLayout from "./TalentCreateProfileFormPageLayout";
import { useAddedExtras } from "../../api/addedExtras";
import { AddedExtrasFromValues, useAddedExtrasForm } from "../../pages/TalentProfileEdit/AddedExtrasFormValues";
import FormTextInput from "../FormTextInput";
import { useIsMediumScreen } from "../../hooks/screenSizes";
import ErrorList from "../ErrorList";

export type CreateTalentProfileFormStepExternalLinksProps = {
    onSubmit: () => void
    onBackClick: () => void
}

const CreateTalentProfileFormStepExternalLinks = ({
    onSubmit,
    onBackClick,
}: CreateTalentProfileFormStepExternalLinksProps) => {
    const [errors, setErrors] = useState<string[]>([]);
    const isMediumScreen = useIsMediumScreen();

    const skipButtonText = isMediumScreen ? "Skip for now" : "Skip";

    const { addedExtras, updateAddedExtras, isUpdatingAddedExtras, isLoading } = useAddedExtras();

    const methods = useAddedExtrasForm({
        portfolioUrl: "",
        gitHubUrl: "",
        facebookUrl: "",
        twitterUrl: "",
        linkedInUrl: ""
    });
    const { reset, formState } = methods;

    useEffect(() => {
        reset({
            ...addedExtras,
            gitHubUrl: addedExtras?.gitHubUrl || "",
            facebookUrl: addedExtras?.facebookUrl || "",
            twitterUrl: addedExtras?.twitterUrl || "",
            linkedInUrl: addedExtras?.linkedInUrl || ""
        });
    }, [reset, addedExtras]);

    const handleUpdateAddedExtras = async (values: AddedExtrasFromValues) => {
        const response = await updateAddedExtras(values);

        if (!response.success) {
            setErrors(response.error.userMessages);
            return;
        }

        onSubmit();

        return response;
    };

    const fieldsContainValues = [addedExtras?.gitHubUrl, addedExtras?.facebookUrl, addedExtras?.twitterUrl, addedExtras?.linkedInUrl].some(url => url && url.trim() !== "");

    return (
        <form className={formClassNames} onSubmit={methods.handleSubmit(handleUpdateAddedExtras)} noValidate>
            <TalentCreateProfileFormPageLayout
                title="Enhance your profile"
                description={"Is there something we haven't covered? Link to external sites like your online portfolio, GitHub, or LinkedIn to make your profile even more compelling."}
                content={
                    <div>
                        <div className="space-y-6 ">
                            <FormTextInput
                                id="added-extras-form-portfolio-url"
                                label="Online portfolio"
                                placeholder="Enter online portfolio link"
                                error={methods.formState.errors.portfolioUrl}
                                {...methods.register("portfolioUrl")}
                            />
                            <FormTextInput
                                id="added-extras-form-github-url"
                                label="GitHub"
                                placeholder="Enter GitHub link"
                                error={methods.formState.errors.gitHubUrl}
                                StartAdornmentIcon={GithubLogo}
                                fillStartAdornment
                                {...methods.register("gitHubUrl")}
                            />
                            <FormTextInput
                                id="added-extras-form-facebook-url"
                                label="Facebook"
                                placeholder="Enter Facebook link"
                                error={methods.formState.errors.facebookUrl}
                                StartAdornmentIcon={FacebookLogo}
                                fillStartAdornment
                                {...methods.register("facebookUrl")}
                            />
                            <FormTextInput
                                id="added-extras-form-twitter-url"
                                label="X"
                                placeholder="Enter X link"
                                error={methods.formState.errors.twitterUrl}
                                StartAdornmentIcon={XLogo}
                                fillStartAdornment
                                {...methods.register("twitterUrl")}
                            />
                            <FormTextInput
                                id="added-extras-form-linkedin-url"
                                label="LinkedIn"
                                placeholder="Enter LinkedIn link"
                                error={methods.formState.errors.linkedInUrl}
                                StartAdornmentIcon={LinkedinLogo}
                                fillStartAdornment
                                {...methods.register("linkedInUrl")}
                            />
                        </div>
                        <ErrorList errors={errors} />
                    </div>
                }
                iconName="Globe"
                buttons={
                    <>
                        <Button variant="secondary" type="button" onClick={onBackClick}>Back</Button>
                        <Button
                            type="submit"
                            variant={formState.isDirty || fieldsContainValues ? "primary" : "tertiary"}
                            loading={isUpdatingAddedExtras || isLoading }
                        >
                            {formState.isDirty || fieldsContainValues ? "Next" : skipButtonText}
                        </Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateTalentProfileFormStepExternalLinks;
