import { X } from "phosphor-react";
import { Dialog as HeadlessUiDialog } from "@headlessui/react";

import TitleUnderline from "./TitleUnderline";
import Typography from "./Typography";
import { useOrganizationContext } from "../api/current-organization/organizationContext";

export type FormDialogBoxProps = {
    title?: string
    isOpen: boolean
    onClose: () => void
    children?: React.ReactNode
}

const FormDialogBox = ({
    title,
    isOpen,
    onClose,
    children,
}: FormDialogBoxProps) => {
    const { theme } = useOrganizationContext();

    return (
        <HeadlessUiDialog open={isOpen} onClose={onClose} className="relative z-[1015]" data-theme={theme}>
            <div className="fixed inset-0 bg-[#aaa] bg-opacity-50 flex !z-[1010] p-4 overflow-y-auto h-[100vh]">
                <HeadlessUiDialog.Panel className="p-6 w-full max-w-7xl overflow-y-auto min-w-[280px] shadow-lg rounded-lg space-y-6 bg-surface mx-auto my-auto">
                    <div className="flex items-start">
                        <div>
                            <HeadlessUiDialog.Title as={Typography} className="!text-[1.75rem]" variant="headline-small" component="h1">
                                {title}
                            </HeadlessUiDialog.Title>
                            <TitleUnderline fullWidth className="mt-2" />
                        </div>
                        <X size={24} className="ml-auto cursor-pointer" onClick={onClose} />
                    </div>
                    {children}
                </HeadlessUiDialog.Panel>
            </div>
        </HeadlessUiDialog>
    );
};

export default FormDialogBox;