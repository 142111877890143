import { useEffect, useState } from "react";
import gigApiFetcher from "./common/fetching";
import { ApiGigDetails } from "../models/api/gig";
import { mapFromApiGigDetails } from "../models/mappers/gig";
import { GigDetails } from "../models/app/gig";
import ApiError from "./common/apiError";

export type UseGigForUnauthenticatedUsersReturn<TGigId extends string | undefined> = TGigId extends undefined ?
    { gig: undefined } :
    {
        gig?: GigDetails
        isLoading: boolean
        error?: ApiError
        reload: () => Promise<void>
    }

export const useGigForUnauthenticatedUsers = <TGigId extends string | undefined>(gigId: TGigId): UseGigForUnauthenticatedUsersReturn<TGigId> => {
    const [gig, setGig] = useState<GigDetails | undefined>();
    const [isLoading, setIsLoading] = useState(true);

    const url = `api/gigs/unauthenticated/${gigId}`;

    useEffect(() => {
        const fetchGig = async () => {
            setIsLoading(true);
            const fetchedGig = await gigApiFetcher<never, ApiGigDetails>({url, method: "GET"});
            setGig(mapFromApiGigDetails(fetchedGig));
            setIsLoading(false);
        };

        fetchGig();
    }, []);


    if (gigId === undefined) return { gig: undefined } as UseGigForUnauthenticatedUsersReturn<TGigId>;

    return {
        gig,
        isLoading,
    } as UseGigForUnauthenticatedUsersReturn<TGigId>;
};