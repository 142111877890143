import { useGigs } from "../api/gigs";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import Card from "../components/Card";
import LinkButton from "../components/LinkButton";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { useAppPaths } from "../Routes";
import { gigStatuses } from "../models/app/gig";
import { useIsLargeScreen } from "../hooks/screenSizes";
import SkeletonList from "../components/SkeletonList";
import GigCardSkeleton from "../components/GigCardSkeleton";

const GigsListDraftPage = () => {
    const appPaths = useAppPaths();
    const { gigs, isLoading } = useGigs({
        gigStatusIds: [gigStatuses.draft],
        initialOrderBy: "CreatedAt",
    });
    const { gigTerminologyPlural } = useOrganizationContext();
    const isLargeScreen = useIsLargeScreen();

    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>Draft {gigTerminologyPlural}</Typography>
            {isLoading ? <SkeletonList skeletonCard={<GigCardSkeleton />} /> :
                gigs.length === 0 ? (
                    <Card>
                        <Typography variant="title-large" component="p">None yet...</Typography>
                    </Card>
                ) : (
                    <div className="space-y-8">
                        {gigs.map(gig => (
                            <Card key={gig.id} className="flex items-center flex-col md:flex-row mb-4 break-words">
                                <div key={gig.id} className="space-y-4 md:mr-8 text-center md:text-left">
                                    <Typography variant="title-large" component="p" className="max-w-md">{gig.title}</Typography>
                                    <p className="italic line-clamp-3 md:mr-8">{gig.description}</p>
                                </div>
                                <LinkButton className="block md:ml-auto self" to={appPaths.gigs.edit(gig.id)} variant="tertiary">Edit</LinkButton>
                            </Card>
                        ))}
                    </div>
                )}
        </PageContent>
    );
};

export default GigsListDraftPage;