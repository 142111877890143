import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { GigDetails, expectedDurationOptions, initialStageOptions } from "../models/app/gig";
import findOptionLabel from "../utils/findOptionLabel";
import Card, { CardDataField } from "./Card";
import Typography from "./Typography";
import MoneyDisplay from "./MoneyDisplay";
import { ApiProposalGigSummary } from "../models/api/proposal";

export type ProposalGigSummaryCardProps = {
    gig: ApiProposalGigSummary | GigDetails
    gigTerminology: string
    isPaymentsEnabled: boolean
    isWorksome: boolean
}

const ProposalGigSummaryCard = ({
    gig,
    isPaymentsEnabled,
}: ProposalGigSummaryCardProps) => {
    const [lineClamp, setLineClamp] = useState<boolean>(true);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const textRef = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
        const checkOverflow = () => {
            const element = textRef.current;
            if (element) {
                setIsOverflowing(element.scrollHeight > element.clientHeight);
            }
        };

        checkOverflow();
        window.addEventListener("resize", checkOverflow);

        return () => {
            window.removeEventListener("resize", checkOverflow);
        };
    }, [gig]);

    const handleReadMore = () => setLineClamp(lineClamp => !lineClamp);

    return (
        <Card className="mb-8 mt-8 break-words relative">
            <div className="space-y-4 pr-2 mb-4">
                <Typography variant="title-large" component="h2">{gig?.title}</Typography>
                <Typography variant="body" component="p" ref={textRef} className={clsx("italic whitespace-pre-wrap", lineClamp && "line-clamp-3")}>{gig?.description}</Typography>
            </div>
            {isOverflowing && <div onClick={handleReadMore} className="cursor-pointer font-bold hover:underline w-fit text-[0.95rem] mb-4">{lineClamp ? "Read more" : "Read less"}</div>}
            <div className="flex flex-wrap child:mb-2">
                <CardDataField label="Initial stage" iconName="Stack" className="mr-auto">{findOptionLabel(initialStageOptions, gig?.initialStageId)}</CardDataField>
                <CardDataField label="Estimated duration" iconName="Clock" className="mr-auto">{findOptionLabel(expectedDurationOptions, gig?.expectedDurationId)}</CardDataField>   
                {isPaymentsEnabled && gig.isTimeAndMaterial && <CardDataField label="No. of days" iconName="Clock" className="mr-auto">{gig.numberOfDays || 0}</CardDataField>}
                {isPaymentsEnabled && gig.isTimeAndMaterial && <CardDataField label="Daily rate" iconName="Money" className="mr-auto"><MoneyDisplay value={gig.dailyRate || 0} /></CardDataField>}                     
                {isPaymentsEnabled && <CardDataField label="Budget" iconName="Money" className="mr-auto"><MoneyDisplay value={gig.budget || 0} /></CardDataField>}
                <CardDataField label="Remote" iconName="Crosshair" className="mr-auto" >{gig?.isCompletedRemotely == true ? "Yes" : "No"}</CardDataField>
            </div>
        </Card>
    );
};

export default ProposalGigSummaryCard;