import { useState } from "react";
import { useTalentProfileVideos } from "../../api/talentProfileVideos";
import Button from "../../components/Button";
import ErrorList from "../../components/ErrorList";
import FormTextInput from "../../components/FormTextInput";
import { successToast } from "../../toast";
import TalentProfileVideo from "./TalentProfileVideo";
import { TalentProfileVideosFormValues, useTalentProfileVideosForm } from "./TalentProfileVideosFormValues";

const TalentProfileVideosForm = () => {
    const [errors, setErrors] = useState<string[]>([]);
    const methods = useTalentProfileVideosForm();

    const {
        profileVideoUrls,
        addProfileVideo,
        isAddingProfileVideo,
        removeProfileVideo,
        isRemovingProfileVideo
    } = useTalentProfileVideos();

    const handleSubmit = async (values: TalentProfileVideosFormValues) => {
        setErrors([]);

        const existingProfileVideoUrl = profileVideoUrls?.find(url => url === values.url);

        if (existingProfileVideoUrl) {
            setErrors(["Video URL already added."]);
            methods.reset();
            return;
        }

        const response = await addProfileVideo({ url: values.url });

        if (!response.success) {
            setErrors(response.error.userMessages);
        } else {
            successToast("Profile video successfully added.");
            methods.reset();
        }
    };

    const handleRemove = async (url: string) => {
        setErrors([]);

        const existingProfileVideoUrl = profileVideoUrls?.find(existingUrl => existingUrl === url);

        if (!existingProfileVideoUrl) {
            setErrors(["Video URL not found."]);
            return;
        }

        const response = await removeProfileVideo({ url });

        if (!response.success) {
            setErrors(response.error.userMessages);
        } else {
            successToast("Profile video successfully removed.");
        }
    };

    return (
        <>
            <form
                noValidate
                onSubmit={methods.handleSubmit(handleSubmit)}
            >
                <div className="flex gap-6 flex-wrap md:flex-nowrap md:items-start">
                    <FormTextInput
                        id="talent-profile-videos-form-url"
                        label="Video URL"
                        placeholder="Enter video URL"
                        error={methods.formState.errors.url}
                        tooltip="Use this section to upload any relevant video files"
                        {...methods.register("url")}
                    />
                    <Button 
                        type="submit" 
                        variant="secondary" 
                        className="w-full md:mt-[2rem] md:w-auto"
                        disabled={isAddingProfileVideo || isRemovingProfileVideo}
                    >
                        Add
                    </Button>
                </div>
            </form>

            <ErrorList errors={errors} />

            <div className="flex flex-row flex-wrap gap-2 pt-8">
                {profileVideoUrls?.map(url => (
                    <TalentProfileVideo
                        key={url}
                        url={url}
                        onRemoveClick={() => handleRemove(url)} 
                    />
                ))}
            </div>

        </>        
    );
};

export default TalentProfileVideosForm;