import { ApiProposalDetails } from "../models/api/proposal";
import { ProposalDetails, UpdateProposalDto } from "../models/app/proposal";
import { mapFromApiProposalDetails } from "../models/mappers/proposal";
import ApiError from "./common/apiError";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";

export const useUpdateProposal = () => useAuthenticatedGigApiFetcher<UpdateProposalDto, void, { proposalId: string, dto: UpdateProposalDto }>("PUT",
    ({ proposalId, dto }) => ({
        url: `api/proposals/${proposalId}`,
        body: dto,
    }));

export const useAcceptProposal = () => useAuthenticatedGigApiFetcher<never, void, string>("PUT",
    (proposalId) => ({
        url: `api/proposals/${proposalId}/actions/accept`
    }));

export const useCompleteMilestone = () => useAuthenticatedGigApiFetcher<never, void, string>("PUT",
    (milestoneId) => ({
        url: `api/proposal-milestones/${milestoneId}/actions/complete`
    }));

export type UseProposalReturn<TProposalId extends string | undefined> = TProposalId extends undefined ?
    {
        proposal: undefined,
        updateProposal: undefined,
    } :
    {
        isLoading: boolean
        error?: ApiError
        updateProposal: (dto: UpdateProposalDto) => Promise<GigApiFetcherResponse<void>>
        isUpdatingProposal: boolean
        proposal?: ProposalDetails
        refetchProposal: () => void
        isValidating: boolean
    }

export const useProposal = <TProposalId extends string | undefined>(proposalId: TProposalId): UseProposalReturn<TProposalId> => {
    const url = `api/proposals/${proposalId}`;

    const apiCall = useGiggedApiSWR<ApiProposalDetails>(url);

    const [updateProposal, isUpdatingProposal] = useUpdateProposal();

    if (proposalId === undefined) return { proposal: undefined } as UseProposalReturn<TProposalId>;

    return {
        isLoading: apiCall.isLoading,
        error: apiCall.error,
        updateProposal: async (dto: UpdateProposalDto) => {
            const response = await updateProposal({ proposalId, dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;

        },
        isUpdatingProposal,
        proposal: apiCall.data ? mapFromApiProposalDetails(apiCall.data) : undefined,
        refetchProposal: apiCall.refetch,
        isValidating: apiCall.isValidating
    } as UseProposalReturn<TProposalId>;
};