import { ApiTalentPayoutDetailsDto } from "../models/api/talentPayoutAccount";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";

export const useUpdateTalentPayoneerEmail = () => useAuthenticatedGigApiFetcher<ApiTalentPayoutDetailsDto, void, ApiTalentPayoutDetailsDto>("PUT",
    (dto) => ({
        url: "api/me/payoneer-account",
        body: dto
    })
);

export type UseTalentPayoneerEmailReturn =
    UseGiggedApiSWRReturn<ApiTalentPayoutDetailsDto> &
    {
        payoneerEmail?: ApiTalentPayoutDetailsDto
        updatePayoneerEmail: (dto: ApiTalentPayoutDetailsDto) => Promise<GigApiFetcherResponse<void>>
        isUpdatingPayoneerEmail: boolean
    }

export const useTalentPayoneerEmail = (): UseTalentPayoneerEmailReturn => {
    const apiCall = useGiggedApiSWR<ApiTalentPayoutDetailsDto>(
        "api/me/payoneer-account", 
        { preventRedirectOn404: true }
    );

    const [updatePayoneerEmail, isUpdatingPayoneerEmail] = useUpdateTalentPayoneerEmail();

    return {
        ...apiCall,
        payoneerEmail: apiCall.data,
        updatePayoneerEmail: async (dto) => {
            const response = await updatePayoneerEmail(dto);

            if (response.success) apiCall.mutate();

            return response;
        },
        isUpdatingPayoneerEmail
    };
};