import { ApiGigDetails, ApiGigSummary } from "../api/gig";
import { EditableGigFields, expectedDurationOptions, GigDetails, GigSummary, initialStageOptions } from "../app/gig";
import { mapFromApiProposalSummary } from "./proposal";

export const mapFromApiGigSummary = (apiDto: ApiGigSummary): GigSummary => ({
    ...apiDto,
    startDate: new Date(apiDto.startDate),
    deadlineDate: apiDto.deadlineDate ? new Date(apiDto.deadlineDate) : null,
    expectedDurationLabel: expectedDurationOptions.find(_ => _.value === apiDto.expectedDurationId)?.label || "",
    initialStageLabel: initialStageOptions.find(_ => _.value === apiDto.initialStageId)?.label || "",
});

export const mapFromApiGigDetails = (apiDto: ApiGigDetails): GigDetails => ({
    ...mapFromApiGigSummary(apiDto),
    matches: apiDto.matches,
    hiredProposal: apiDto.hiredProposal ? mapFromApiProposalSummary(apiDto.hiredProposal) : undefined,
    isFetchingMatches: apiDto.isFetchingMatches,
    externalMatches: apiDto.externalMatches
});

export const mapToApiGig = <TApi extends EditableGigFields>(apiDto?: TApi):
    TApi extends undefined ? undefined : Omit<TApi, "startDate" | "deadlineDate"> & { startDate: string, deadlineDate: string } => {
    if (apiDto === undefined) {
        return undefined as ReturnType<typeof mapToApiGig>;
    }

    return {
        ...apiDto,
        startDate: apiDto.startDate.toISOString(),
        deadlineDate: apiDto.deadlineDate ? apiDto.deadlineDate.toISOString() : undefined,
    } as ReturnType<typeof mapToApiGig>;
};