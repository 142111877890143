import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { isValidOptionalUrl } from "../../utils/yup-validators/isValidOptionalUrl";

export type TalentProfileVideosFormValues = {
    url: string
}

const talentProfileVideosFormValidationSchema: yup.SchemaOf<TalentProfileVideosFormValues> = yup.object({
    url: yup.string()
        .required("URL is required.")
        .max(2048)
        .test(isValidOptionalUrl)
});

export const useTalentProfileVideosForm = (initialValues?: TalentProfileVideosFormValues) => useForm<TalentProfileVideosFormValues>({
    resolver: yupResolver(talentProfileVideosFormValidationSchema),
    defaultValues: initialValues,
});
