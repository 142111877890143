import PageContent from "../components/PageContent";
import Typography from "../components/Typography";

export const FourOFourPage = () => {
    return (
        <PageContent>
            <Typography variant="display-large" component="h1" gutterBottom className="text-center text-primary font-bold md:text-[12rem] mt-8">404</Typography>
            <Typography variant="title-large" component="p" gutterBottom className="text-center md:text-title-large text-title-small">Sorry we couldn't find what you are looking for.</Typography>
        </PageContent>
    );
};
