import Slider from "react-slick";
import { CaretLeft, CaretRight } from "phosphor-react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export type CarouselProps = {
    children: React.ReactNode;
};

export const Carousel = ({
    children
}: CarouselProps) => {

    const SlickButtonFix = (
        props: {
            children: JSX.Element;
        }
    ) => {
        const { children, ...others } = props;
        return (
            <span {...others} style={{ position: "absolute", zIndex: 1 }}>
                {children}
            </span>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: (
            <SlickButtonFix>
                <div style={{ position: "absolute", top: "50%", left: "-5px", transform: "translateY(-50%)" }}>
                    <CaretLeft size={32} color="#0b1b29" aria-label="Previous slide" />
                </div>
            </SlickButtonFix>
        ),
        nextArrow: (
            <SlickButtonFix>
                <div style={{ position: "absolute", top: "50%", right: "-5px", transform: "translateY(-50%)" }}>
                    <CaretRight size={32} color="#0b1b29" aria-label="Next slide" />
                </div>
            </SlickButtonFix>
        ),
    };

    return (
        <div className="flex flex-col bg-white h-48 p-6 px-8 rounded-lg child:cursor-pointer w-full md:max-w-[33%]">
            <Slider
                {...settings}
                className="h-full my-2"
            >
                {children}
            </Slider>
        </div>
    );
};

export default Carousel;
