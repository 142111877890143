import { Disclosure } from "@headlessui/react";

import { useIsExtraLargeScreen } from "../../hooks/screenSizes";
import TopNavMenu from "./TopNavMenu";
import React from "react";
import SideNavMenu from "./SideNavMenu";
import ClickAwayOverlay from "./ClickAwayOverlay";
import useCurrentUser from "../../auth/useCurrentUser";
import { TalentPersonalInformationContextProvider } from "../../api/talentPersonalInformation";
import { ClientPersonalInformationContextProvider } from "../../api/clientPersonalInformation";
import Footer from "./Footer";
import TalentProfileCreateRedirect from "./TalentProfileCreateRedirect";
import ClientProfileEditRedirect from "./ClientProfileEditRedirect";
import ProductTourContainer from "../ProductTourContainer";

export type PageLayoutProps = {
    children: React.ReactElement
}

const PageLayout = ({
    children
}: PageLayoutProps) => {
    const isExtraLarge = useIsExtraLargeScreen();
    const { userRole, clientIsUnverified } = useCurrentUser();

    if (userRole === undefined) {
        return (
            <>
                <TopNavMenu />
                <div className="xl:flex">
                    <div className="grow">
                        {children}
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    const content = (
        <Disclosure as="div" className="bg-app-background min-h-screen flex flex-col" defaultOpen={isExtraLarge}>
            {({ open, close }) => {
                return (
                    <>
                        <TopNavMenu className="mb-6 sticky top-0 bg-white z-[1000]" />
                        <ClickAwayOverlay isOpen={open} closeSideMenu={close} />
                        <div className="xl:flex">
                            <SideNavMenu close={close} />
                            <div className="grow">
                                {children}
                            </div>
                        </div>
                        <Footer />
                    </>
                );
            }}
        </Disclosure >
    );

    if (userRole === "talent") {
        return (
            <TalentPersonalInformationContextProvider>
                <TalentProfileCreateRedirect>
                    {content}
                </TalentProfileCreateRedirect>
            </TalentPersonalInformationContextProvider>
        );
    }

    if (userRole === "client") {
        if (clientIsUnverified) {
            return (
                <div className="flex flex-col">
                    <p>Your account has been marked as unverified.</p>
                    <p>If you believe this to be a mistake, please <a className="underline" href="mailto:customersuccess@gigged.ai">contact us.</a></p>
                </div>
            );
        } else {
            return (
                <ClientPersonalInformationContextProvider>
                    <ClientProfileEditRedirect>
                        <ProductTourContainer>
                            {content}
                        </ProductTourContainer>
                    </ClientProfileEditRedirect>
                </ClientPersonalInformationContextProvider>
            );
        }
    }

    return <p>Unsupported user role: {userRole}</p>;
};

export default PageLayout;
