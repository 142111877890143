import clsx from "clsx";
import { useCreateStripeAccount, useGetStripeAccountLogInUrl, useGetStripeAccountOnboardingUrl } from "../../api/talentDashboard";
import { useTalentPersonalInformationContext } from "../../api/talentPersonalInformation";
import Card from "../../components/Card";
import { stripeAccountStatus, TalentDashboardDto } from "../../models/app/talentDashboard";

export type StripeAccountCardProps = {
    className?: string
    dashboardData: TalentDashboardDto
}

const StripeAccountCard = ({
    className,
    dashboardData
}: StripeAccountCardProps) => {
    const { 
        talentPersonalInformation,
        isLoading: isTalentPersonalInformationLoading
    } = useTalentPersonalInformationContext();

    if (isTalentPersonalInformationLoading || 
        talentPersonalInformation === undefined || 
        !talentPersonalInformation.allowStripe) {
        return null;
    }

    const [createStripeAccount, isCreatingStripeAccount] = useCreateStripeAccount();
    const [getStripeAccountOnboardingUrl, isGettingStripeAccountOnboardingUrl] = useGetStripeAccountOnboardingUrl();
    const [getStripeAccountLogInUrl, isGettingStripeAccountLoginUrl] = useGetStripeAccountLogInUrl();

    const handleCreateStripeAccount = async () => {
        if (isCreatingStripeAccount) return;

        const response = await createStripeAccount();

        if (response.success) {
            window.location.replace(response.content.accountOnboardingUrl);
        }
    };

    const handleCompleteStripeOnboarding = async () => {
        if (isGettingStripeAccountOnboardingUrl) return;

        const response = await getStripeAccountOnboardingUrl();

        if (response.success) {
            window.location.replace(response.content.accountOnboardingUrl);
        }
    };

    const handleLogInToStripe = async () => {
        if (isGettingStripeAccountLoginUrl) return;

        const response = await getStripeAccountLogInUrl();

        if (response.success) {
            window.location.replace(response.content.accountLogInUrl);
        }
    };

    return (
        <Card className={clsx("!bg-white", className)}>
            <div className="sm:flex sm:items-center sm:space-x-6 space-y-2 sm:space-y-0">
                <img
                    src="/stripe-payments-logo.svg"
                />
                {dashboardData.stripeAccountStatus == stripeAccountStatus.none ? (
                    <div>
                        <p>
                            <button
                                onClick={handleCreateStripeAccount}
                                className="font-bold mr-2"
                            >
                                Click here
                            </button>
                            to set up your stripe account.
                        </p>
                    </div>
                ) : dashboardData.stripeAccountStatus == stripeAccountStatus.incomplete ? (
                    <div>
                        <p className="text-title-small">Incomplete setup</p>
                        <p>
                            <button
                                onClick={handleCompleteStripeOnboarding}
                                className="font-bold mr-2"
                            >
                                Click here
                            </button>
                            to complete your account set up.
                        </p>
                    </div>
                ) : (
                    <div>
                        <p>
                            <button
                                onClick={handleLogInToStripe}
                                className="font-bold mr-2"
                            >
                                Click here
                            </button>
                            <span>to log in to your Stripe dashboard.</span>
                        </p>
                    </div>
                )}
            </div>
        </Card>
    );
};

export default StripeAccountCard;
