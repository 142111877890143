import FindPage from "../../components/FindPage";
import FormCheckboxGroup from "../../components/FormCheckboxGroup";
import { expectedDurationOptions, remoteFilterOptions } from "../../models/app/gig";
import { useFindAGigContext } from "./findAGigContext";
import GigsList from "./GigsList";
import SortOrderDropdown from "./SortOrderDropdown";
import { fetchSkillOptions } from "../../api/skills";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import FormRadioGroup from "../../components/FormRadioGroup";
import BudgetFilter from "./BudgetFilter";
import { FindPagePanelSectionProps } from "../../components/FindPage/FindPagePanelSection";
import SearchableSkillsCheckBoxGroup from "../../components/SearchableSkillsCheckboxGroup";

const FindAGigComponent = () => {
    const {
        searchTerm,
        isCompletedRemotely,
        skillIds,
        expectedDurationIds,
        anyActiveFilters,
        minBudget,
        maxBudget,
        globalMinBudget,
        globalMaxBudget,
        setSearchTerm,
        clearAllFilters,
        setSkillIds,
        setIsCompletedRemotely,
        setExpectedDurationIds,
        setMinBudget,
        setMaxBudget,
        searchNow,
        isLoading,
        gigs,
    } = useFindAGigContext();

    const { gigTerminology, gigTerminologyPlural, organizationConfig } = useOrganizationContext();

    const filterPanelSections: FindPagePanelSectionProps[] = [
        {
            title: "Skills",
            subtitle: `(${skillIds.length} selected)`,
            content: (
                <SearchableSkillsCheckBoxGroup
                    selectedSkillIds={skillIds}
                    searchPlaceholder="Search by skill"
                    onChange={setSkillIds}
                    fetchSkills={fetchSkillOptions}
                    accessTokenNotRequired
                />
            )
        },
        {
            title: "Expected duration",
            subtitle: `(${expectedDurationIds.length} selected)`,
            content: (
                <FormCheckboxGroup
                    allOptions={expectedDurationOptions}
                    selectedOptionIds={expectedDurationIds}
                    onChange={setExpectedDurationIds}
                />
            )
        },
        {
            title: "Remote work",
            content: (
                <FormRadioGroup
                    value={isCompletedRemotely}
                    onChange={(value) => setIsCompletedRemotely(value as string)}
                    options={remoteFilterOptions}
                    defaultValue={isCompletedRemotely}
                />
            )
        }
    ];

    if (organizationConfig.isPaymentsEnabled) {
        filterPanelSections.push({
            title: `${gigTerminology} budget`,
            content: isLoading ?
                <p>Loading...</p> :
                <BudgetFilter
                    minBudget={minBudget}
                    maxBudget={maxBudget}
                    globalMinBudget={globalMinBudget}
                    globalMaxBudget={globalMaxBudget}
                    setMinBudget={setMinBudget}
                    setMaxBudget={setMaxBudget}
                    isLoading={isLoading}
                />
        });
    }

    return (
        <FindPage
            title={`Filter ${gigTerminologyPlural.toLowerCase()} by`}
            searchTerm={searchTerm}
            anyActiveFilters={anyActiveFilters}
            setSearchTerm={setSearchTerm}
            clearAllFilters={clearAllFilters}
            searchNow={searchNow}
            searchPlaceholder={`Search by ${gigTerminology.toLowerCase()} title`}
            sortOrderDropdown={(
                <SortOrderDropdown />
            )}
            filterPanelSections={filterPanelSections}
        >
            <GigsList gigs={gigs} isLoading={isLoading} anyActiveFilters={anyActiveFilters} />
        </FindPage>
    );
};

export default FindAGigComponent;
