import { Link, useNavigate } from "react-router-dom";
import { CalendarBlank, Money } from "phosphor-react";

import { useAcceptProposal } from "../../api/proposal";
import Button from "../../components/Button";
import Card, { CardDataFieldLabel } from "../../components/Card";
import DialogBox, { useDialogBoxState } from "../../components/DialogBox";
import ProposalCoverLetterButton from "../../components/ProposalCoverLetterButton";
import Typography from "../../components/Typography";
import { ProposalSummary } from "../../models/app/proposal";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { useGetMilestoneCheckoutSessionUrl } from "../../api/payments";
import { useAppPaths } from "../../Routes";
import { DEFAULT_PROPOSAL_MILESTONE } from "../../utils/constants";
import { useClientPersonalInformationContext } from "../../api/clientPersonalInformation";
import ProposalPurchaseOrderDialog from "../ProposalPurchaseOrderForm/ProposalPurchaseOrderDialog";
import { dialogWidths } from "../../components/DialogBox/DialogBox";
import { useEffect, useState } from "react";
import TooltipIcon from "../../components/TooltipIcon";
import { clientProposalTooltips } from "../../tooltipsContent";
import BadgeList from "../../components/BadgeList";
import LinkButton from "../../components/LinkButton";
import { useProposalFiles } from "../../api/proposalFiles";
import UserAvatar from "../../components/UserAvatar";
import { useTalent } from "../../api/talent";

export type GigProposalCardProps = {
    proposal: ProposalSummary
}

const GigProposalCard = ({
    proposal,
}: GigProposalCardProps) => {
    const { organizationConfig: { isPaymentsEnabled }, gigTerminology, talentTerminology } = useOrganizationContext();
    const [getMilestoneCheckoutSessionUrl, isGettingMilestoneCheckoutSessionUrl] = useGetMilestoneCheckoutSessionUrl();
    const appPaths = useAppPaths();
    const confirmHireDialogState = useDialogBoxState();
    const paymentOptionDialogState = useDialogBoxState();
    const [acceptProposal, isSubmitting] = useAcceptProposal();
    const navigate = useNavigate();
    const [referralId, setReferralId] = useState<string>("");
    const proposalFiles = useProposalFiles(proposal.id);
    const { talent } = useTalent(proposal.talentId);

    const sendMessageUrl = proposal.conversationId ? appPaths.inbox.conversation(proposal.conversationId) : appPaths.inbox.index;

    const {
        clientPersonalInformation,
        isLoading: isLoadingClientPersonalInformation
    } = useClientPersonalInformationContext();

    if (isLoadingClientPersonalInformation) {
        return <p>Loading...</p>;
    }

    useEffect(() => {
        if (!isPaymentsEnabled) return;

        window.rewardful("ready", function () {
            if (window.Rewardful.referral) {
                setReferralId(window.Rewardful.referral);
            } else {
                setReferralId("checkout_" + new Date().getTime());
            }
        });
    }, []);

    const handleConfirmHire = async () => {
        const response = await acceptProposal(proposal.id);

        if (response.success) {
            navigate(appPaths.gigs.ongoing);
        }
    };

    const handleHire = async () => {
        if (!isPaymentsEnabled) {
            confirmHireDialogState.open();
            return;
        }

        if (clientPersonalInformation?.enablePurchaseOrders) {
            paymentOptionDialogState.open();
            return;
        }

        const milestoneResult = await getMilestoneCheckoutSessionUrl({ milestoneId: proposal.milestones[0].id, clientReferenceId: referralId });

        if (milestoneResult.success) {
            window.location.replace(milestoneResult.content.checkoutSessionUrl);
        }
    };

    const handlePayProposalWithPoSuccess = () => {
        navigate(appPaths.gigs.ongoing);
    };

    const userDisplayName = proposal.talentTradingName || `${proposal.talentFirstName} ${proposal.talentLastName}`;
    const displayBadgeSection = proposal.badges.length > 0;

    const totalGigAmount = proposal.milestones.reduce((total, milestone) => total + milestone.amount, 0);


    return (
        <>
            <Card className="grow">
                <div className="flex flex-col md:flex-row gap-6">
                    <div className="sm:w-28 rounded-full flex justify-start w-full">
                        <Link to={appPaths.talents.findTalentDetails(proposal.talentId)}>
                            <UserAvatar name={userDisplayName} userImageUrl={talent?.profileImageUrl} showName={false} size="large" />
                        </Link>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col flex-wrap">
                            <div className="flex flex-row justify-between w-full">
                                <div className="flex flex-col mb-4">
                                    <Link to={appPaths.talents.findTalentDetails(proposal.talentId)} className="flex-none">
                                        <Typography component="span" variant="body" className="font-bold text-primary-hover">{userDisplayName}</Typography>
                                    </Link>
                                    <CardDataFieldLabel>{talent?.roleTitle}</CardDataFieldLabel>
                                    <Typography component="span" variant="body">{talent?.location?.name}</Typography>
                                </div>
                                {displayBadgeSection &&
                                    <BadgeList badges={proposal.badges} keyPrefix={proposal.talentId.toString()} size="sm" className="justify-center md:justify-start" />
                                }
                            </div>

                            <div className="flex flex-col">
                                <div className="flex flex-col md:flex-row md:space-x-8 space-y-2 md:space-y-0 items-center">
                                    <div className="flex flex-row space-x-1 whitespace-nowrap w-full md:w-auto">
                                        <CalendarBlank size={24} />
                                        <CardDataFieldLabel>{proposal.estimatedDurationDays + (proposal.estimatedDurationDays > 1 ? " days" : " day")}</CardDataFieldLabel>
                                    </div>
                                    <div className="flex flex-row space-x-2 whitespace-nowrap w-full md:w-auto">
                                        <Money size={24} />
                                        <CardDataFieldLabel>£{totalGigAmount.toFixed(2)}</CardDataFieldLabel>
                                        {proposal.isTimeAndMaterial && <CardDataFieldLabel>(£{proposal.dailyRate ? proposal.dailyRate.toFixed(2) : ""} Daily rate)</CardDataFieldLabel>}
                                        {proposal.isVATRegistered &&
                                            <TooltipIcon
                                                iconPath='/icons/vat-icon.svg'
                                                tooltipText={clientProposalTooltips.vatRegistered(gigTerminology, talentTerminology)}
                                                size={30}
                                            />
                                        }
                                    </div>
                                </div>


                                {proposal.milestones.length > 0 && proposal.milestones[0].description !== DEFAULT_PROPOSAL_MILESTONE && (
                                    <div className="my-4">
                                        <CardDataFieldLabel>Milestones:</CardDataFieldLabel>
                                        {proposal.milestones.map((proposalMilestone, i) => (
                                            <div key={proposalMilestone.id} className="flex flex-col">
                                                <CardDataFieldLabel textClass="font-normal">
                                                    {i + 1}. {proposalMilestone.description}{" "}
                                                    <div className="flex flex-row space-x-2 whitespace-nowrap w-full md:w-auto mb-4 italic">                                                        
                                                        {proposal.isTimeAndMaterial && <CardDataFieldLabel>Number of days: {proposalMilestone.numberOfDays ? proposalMilestone.numberOfDays : ""} day(s)</CardDataFieldLabel>}
                                                        <CardDataFieldLabel>Total amount: £{proposalMilestone.amount.toFixed(2)}</CardDataFieldLabel>
                                                    </div>
                                                </CardDataFieldLabel>
                                            </div>
                                        ))}
                                    </div>
                                )}

                            </div>
                        </div>
                        <div>
                            <div className="flex flex-col-reverse sm:flex-row gap-4 mt-4 w-full sm:w-auto text-center justify-center sm:justify-end">
                                <ProposalCoverLetterButton proposal={proposal} proposalFiles={proposalFiles} />
                                <LinkButton variant="secondary" to={sendMessageUrl}>Send message</LinkButton>
                                <Button variant="primary" disabled={isGettingMilestoneCheckoutSessionUrl} onClick={handleHire}>Hire now</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Card >
            <DialogBox
                {...confirmHireDialogState}
                title={`Are you sure you want to hire ${userDisplayName}?`}
                maxWidth={dialogWidths.small}
            >
                <div className="flex items-center sm:justify-between sm:space-x-4 flex-col-reverse sm:flex-row">
                    <Button className="w-full sm:w-fit mt-4 sm:mt-0" disabled={isSubmitting} variant="secondary" onClick={confirmHireDialogState.close}>Cancel</Button>
                    <Button className="w-full sm:w-fit" loading={isSubmitting} variant="primary" onClick={handleConfirmHire}>Confirm</Button>
                </div>
            </DialogBox>
            {
                isPaymentsEnabled && (
                    <ProposalPurchaseOrderDialog
                        proposalId={proposal.id}
                        milestoneId={proposal.milestones[0].id}
                        getMilestoneCheckoutSessionUrl={getMilestoneCheckoutSessionUrl}
                        dialogBoxState={paymentOptionDialogState}
                        onSuccess={handlePayProposalWithPoSuccess}
                        clientReferenceId={referralId}
                        isWorksome={proposal.isWorksome}
                        isTimeAndMaterial={proposal.isTimeAndMaterial}
                    />
                )
            }
        </>
    );
};

export default GigProposalCard;
