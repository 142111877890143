import Button from "../../components/Button";
import { DialogBoxState } from "../../components/DialogBox/useDialogBoxState";
import FormTextInput from "../../components/FormTextInput";
import { ProposalPurchaseOrderFormValues, useProposalPurchaseOrderForm } from "./ProposalPurchaseOrderFormValues";

export type ProposalPurchaseOrderFormProps = {
    dialogBoxState: DialogBoxState,
    onSubmit: (values: ProposalPurchaseOrderFormValues) => Promise<void>,
    isPayingProposalWithPo: boolean,
}

const ProposalPurchaseOrderForm = ({
    dialogBoxState,
    onSubmit,
    isPayingProposalWithPo,
}: ProposalPurchaseOrderFormProps) => {
    const methods = useProposalPurchaseOrderForm();
    
    return (    
        <form onSubmit={methods.handleSubmit(onSubmit)}>            
            <p className="my-4">We will send our invoice to the address supplied.</p>            
            <div className="flex flex-col gap-4">
                <FormTextInput
                    required
                    label="Purchase order number"
                    placeholder="Enter purchase order number"
                    error={methods.formState.errors.purchaseOrderNumber}
                    {...methods.register("purchaseOrderNumber")}
                />
                <FormTextInput
                    required
                    label="Company"
                    placeholder="Enter company"
                    error={methods.formState.errors.invoiceCompany}
                    {...methods.register("invoiceCompany")}
                />
                <FormTextInput
                    required
                    label="Email"
                    placeholder="Enter email"
                    error={methods.formState.errors.invoiceEmail}
                    {...methods.register("invoiceEmail")}
                />
            </div>
            <div className="flex mt-6">
                <Button 
                    variant="secondary" 
                    onClick={dialogBoxState.close}
                    type="button"
                    disabled={isPayingProposalWithPo}
                >
                    Cancel
                </Button>
                <Button 
                    className="block ml-auto" 
                    variant="primary" 
                    type="submit"
                    disabled={isPayingProposalWithPo}
                    loading={isPayingProposalWithPo}
                >
                    Confirm
                </Button>
            </div>
        </form>
    );
};

export default ProposalPurchaseOrderForm;