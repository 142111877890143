import { ApiGigInviteDto } from "../models/api/gigInvite";
import { GigInviteDto } from "../models/app/gigInvite";
import { mapFromApiGigInvite } from "../models/mappers/gigInvite";
import { useGiggedApiSWR } from "./common/giggedApiSWR";

export type UseTalentGigInvitesReturn = {
    gigInvites: GigInviteDto[]
    isLoading: boolean
}

export const useTalentGigInvites = (): UseTalentGigInvitesReturn => {
    const url = "api/me/gig-invites";

    const apiCall = useGiggedApiSWR<ApiGigInviteDto[]>(url);

    return {
        gigInvites: (apiCall.data || []).map(mapFromApiGigInvite),
        isLoading: apiCall.isLoading,
    };
};
