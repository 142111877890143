import { Controller, useForm } from "react-hook-form";
import Button from "../Button";
import CreateGigFormPageLayout from "./CreateGigFormPageLayout";
import { GigFormValues } from "../GigForm";
import { formClassNames } from "./classNames";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import RadioCardGroup from "../FormRadioCardGroup";
import { budgetStructureOptions } from "../../models/app/gig";

export type CreateGigFormStepTimeAndMaterialFields = Pick<GigFormValues, "isTimeAndMaterial">;

export type CreateGigFormStepTimeAndMaterialProps = {
    onSubmit: (value: CreateGigFormStepTimeAndMaterialFields) => void
    onBackClick: (value: CreateGigFormStepTimeAndMaterialFields) => void
    defaultValues: CreateGigFormStepTimeAndMaterialFields
}

const CreateGigFormStepTimeAndMaterial = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateGigFormStepTimeAndMaterialProps) => {
    const { gigTerminology } = useOrganizationContext();

    const methods = useForm<CreateGigFormStepTimeAndMaterialFields>({
        defaultValues
    });

    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateGigFormPageLayout
                title={"What is your preferred budget method?"}
                description={`Select the way in which you would like to structure the budget for your ${gigTerminology.toLowerCase()}.`}
                content={
                    <Controller
                        name="isTimeAndMaterial"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <RadioCardGroup 
                                options={budgetStructureOptions}
                                value={value || false}
                                onChange={(selectedOptionValue) => onChange(selectedOptionValue)}
                            />
                        )}
                    />
                }
                iconName="Money"
                buttons={
                    <>
                        <Button onClick={handleBackClick} variant="secondary" type="button">Back</Button>
                        <Button type="submit">Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateGigFormStepTimeAndMaterial;