import { Controller } from "react-hook-form";
import { useState } from "react";

import Button from "../Button";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { formClassNames } from "../CreateGigForm/classNames";
import TalentCreateProfileFormPageLayout from "./TalentCreateProfileFormPageLayout";
import { useIsMediumScreen } from "../../hooks/screenSizes";
import { TalentIndustrialExperienceFormValues, useTalentIndustrialExperienceForm } from "../../pages/TalentProfileEdit/TalentIndustrialExperienceFormValues";
import { useTalentIndustrialExperience } from "../../api/talentIndustrialExperience";
import FormDropdown from "../FormDropdown";
import { yearsExperienceOptions } from "../../utils/yearsExperienceOptions";
import TalentIndustrialExperienceChips from "../../pages/TalentProfileEdit/TalentIndustrialExperienceChips";
import IndustrialExperienceDropdown from "../IndustrialExperienceDropdown";
import ErrorList from "../ErrorList";

export type CreateTalentProfileStepIndustryExperienceProps = {
    onSubmit: () => void
    onBackClick: () => void
}

const CreateTalentProfileFormStepIndustryExperience = ({
    onSubmit,
    onBackClick,
}: CreateTalentProfileStepIndustryExperienceProps) => {
    const isMediumScreen = useIsMediumScreen();
    const [errors, setErrors] = useState<string[]>([]);
    const methods = useTalentIndustrialExperienceForm();
    const {
        talentIndustrialExperience,
        addTalentIndustrialExperience,
        removeTalentIndustrialExperience,
        isRemovingTalentIndustrialExperience,
        isAddingTalentIndustrialExperience,
        isLoading
    } = useTalentIndustrialExperience();
    const { giggedClientTerminologyPlural } = useOrganizationContext();

    const skipButtonText = isMediumScreen ? "Skip for now" : "Skip";

    const handleSubmit = async (values: TalentIndustrialExperienceFormValues) => {
        setErrors([]);

        const existingIndustrialExperience = talentIndustrialExperience.find(industry => industry.industrialExperienceId === values.industrialExperienceId);

        if (existingIndustrialExperience) {
            setErrors([`Industry "${existingIndustrialExperience.industrialExperienceName}" already added.`]);
            methods.reset();
            return;
        }

        const response = await addTalentIndustrialExperience(values.industrialExperienceId, values.yearsExperience);

        if (!response.success) {
            setErrors(response.error.userMessages);
        } else {
            methods.reset();
            return;
        }
    };

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(handleSubmit)}
            noValidate
        >
            <TalentCreateProfileFormPageLayout
                title="Highlight your industry experience"
                description={`Many ${giggedClientTerminologyPlural.toLowerCase()} look for expertise in specific industries. List your industry experience here to ensure you stand out from the crowd.`}
                content={
                    <>
                        <div className="flex flex-col space-y-2">
                            <div className="flex gap-6 flex-wrap w-full md:flex-nowrap">
                                <div className="w-full">
                                    <Controller
                                        name="industrialExperienceId"
                                        control={methods.control}
                                        render={({ field: { onChange, value } }) => (
                                            <IndustrialExperienceDropdown
                                                error={methods.formState.errors.industrialExperienceId}
                                                onChange={onChange}
                                                value={value}
                                                disabled={isAddingTalentIndustrialExperience}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="w-full">
                                    <Controller
                                        name="yearsExperience"
                                        control={methods.control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormDropdown
                                                label="Select years of experience"
                                                options={yearsExperienceOptions}
                                                error={methods.formState.errors.yearsExperience}
                                                value={value}
                                                onChange={onChange}
                                                disabled={isAddingTalentIndustrialExperience}
                                            />
                                        )}
                                    />
                                </div>
                                <Button
                                    className="w-full md:mt-auto h-fit md:w-auto"
                                    type="submit"
                                    variant="secondary"
                                    loading={isAddingTalentIndustrialExperience}
                                >
                                    Add
                                </Button>
                            </div>
                        </div>

                        <ErrorList errors={errors} />
                        {isLoading ? <div className="space-y-2">Loading...</div> : 
                            <TalentIndustrialExperienceChips industrialExperience={talentIndustrialExperience} removeIndustrialExperience={removeTalentIndustrialExperience} isRemovingIndustrialExperience={isRemovingTalentIndustrialExperience} />
                        }
                    </>
                }
                iconName="Factory"
                buttons={
                    <>
                        <Button variant="secondary" type="button" onClick={onBackClick}>Back</Button>
                        <Button
                            type="button"
                            variant={talentIndustrialExperience.length > 0 ? "primary" : "tertiary"}
                            onClick={onSubmit}
                            loading={isAddingTalentIndustrialExperience}
                        >
                            {talentIndustrialExperience.length > 0 ? "Next" : skipButtonText}
                        </Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateTalentProfileFormStepIndustryExperience;