import Card from "../../components/Card";
import Chip from "../../components/Chip";
import MoneyDisplay from "../../components/MoneyDisplay";
import Typography from "../../components/Typography";
import { ProposalMilestoneSummary } from "../../models/app/proposal";
import config from "../../config.json";

type TalentMilestonesProps = {
    milestones: ProposalMilestoneSummary[]
    isVatRegistered: boolean
}

const TalentMilestones = ({
    milestones,
    isVatRegistered
}: TalentMilestonesProps) => {
    return (
        <div>
            <Typography variant="title-large" component="h2" className="mb-4">Milestones</Typography>
            <div className="space-y-2">
                {milestones.map(milestone => (
                    <Card className="sm:flex items-center space-y-4">
                        <div className="grow">
                            <Typography variant="title-small" component="h3">{milestone.description}</Typography>
                            <Typography variant="title-small" component="h3" className="text-[grey] text-[1rem] mt-2">
                                    Budget <MoneyDisplay value={isVatRegistered ? milestone.amount * config.VatMultiplier : milestone.amount} />                                
                            </Typography>
                        </div>
                        <div className="space-x-2 shrink-0 ml-4">
                            {milestone.dateCompleted && (
                                <Chip size="large" label="Completed" variant="primary" isStatus={true}/>
                            )}
                            {milestone.clientPaymentCollectedDate && !milestone.dateCompleted && (
                                <Chip size="large" label="Hired" variant="secondary" isStatus={true}/>
                            )}
                        </div>
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default TalentMilestones;
