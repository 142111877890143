import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
    buildNullableUrlValidator,
    githubURLValidator,
    facebookURLValidator,
    twitterURLValidator,
    linkedinURLValidator,
} from "./AddedExtrasFormValidators";

const schemaFields = {
    portfolioUrl: buildNullableUrlValidator(),
    gitHubUrl: githubURLValidator(),
    facebookUrl: facebookURLValidator(),
    twitterUrl: twitterURLValidator(),
    linkedInUrl: linkedinURLValidator(),
};

export type AddedExtrasFromValues = {
    portfolioUrl?: string
    gitHubUrl?: string,
    facebookUrl?: string
    twitterUrl?: string
    linkedInUrl?: string
}

const validationSchema = yup.object(schemaFields);

export const useAddedExtrasForm = (defaultValues?: AddedExtrasFromValues) => {
    
    const initialDefaultValues = {
        portfolioUrl: defaultValues?.portfolioUrl || "",
        gitHubUrl: defaultValues?.gitHubUrl || "",
        facebookUrl: defaultValues?.facebookUrl || "",
        twitterUrl: defaultValues?.twitterUrl || "",
        linkedInUrl: defaultValues?.linkedInUrl || "",
    };
    return useForm<AddedExtrasFromValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: initialDefaultValues
    });
};
