import Button from "./Button";
import Typography from "./Typography";

export type JoinNowFadeAwayProps = {
    text: string
    signUpUrl: string
};

const JoinNowFadeAway = ({ text, signUpUrl }: JoinNowFadeAwayProps) => {
    return (
        <div
            className="text-center mt-[-8rem] pb-16 absolute w-full"
            style={{
                background: "linear-gradient(180deg, rgba(252, 252, 255, 0) 2.46%, #FCFCFF 32.19%)"
            }}
        >
            <Typography
                className="mb-4 mt-[8rem]"
                variant="title-medium"
                component="h2"
            >
                {text}
            </Typography>
            <a className="w-full" href={signUpUrl}>
                <Button>Sign up</Button>
            </a>
        </div>
    );
};

export default JoinNowFadeAway;