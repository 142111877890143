import Typography from "./Typography";
import Card, { CardDataFieldLabel } from "./Card";
import Chip, { ChipContainer } from "./Chip";
import TitleUnderline from "./TitleUnderline";
import { GigSummary } from "../models/app/gig";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import MoneyDisplay from "./MoneyDisplay";
import { Check } from "phosphor-react";
import { getDateStringMonthYear } from "../utils/dateFormatters";
import { addEllipsis } from "../utils/addEllipsis";
import StatusLabel from "./StatusLabel";

export type GigCompletedSummaryCardProps = {
    completedGig: GigSummary
}

const GigCompletedSummaryCard = ({
    completedGig,
}: GigCompletedSummaryCardProps) => {
    const { organizationConfig, gigTerminology } = useOrganizationContext();

    return (
        <Card className="h-full w-full break-words sm:bg-surface px-12 sm:px-6 rounded-md" key={completedGig.id}>
            <div className="!min-h-[30%]">
                <div className="flex flex-col w-full">
                    <div className="flex flex-row justify-between items-center mb-4 gap-2">
                        <Typography variant="body" component="p">
                            {completedGig.giggedClientName || completedGig.giggedClientCompanyName}
                        </Typography>
                        <StatusLabel label={`${gigTerminology} Completed`} icon={<Check size={18} />} size="small" />
                    </div>
                    <Typography title={completedGig?.title} variant="title-medium" component="h2">{addEllipsis(completedGig?.title, 50)}</Typography>
                </div>
            </div>
            <TitleUnderline fullWidth />
            <div className="flex flex-col gap-2 pt-2">
                <CardDataFieldLabel className="w-full">
                    Completed: <span className="font-normal">{getDateStringMonthYear(completedGig.completedDate)}</span>
                </CardDataFieldLabel>
                {organizationConfig.isPaymentsEnabled && (
                    <CardDataFieldLabel className="w-full">
                        Paid: <span className="font-normal"><MoneyDisplay value={completedGig.hiredProposalAmount || 0} /></span>
                    </CardDataFieldLabel>
                )}
            </div>
            <div className="flex flex-wrap space-y-4 mt-6">
                <div className="space-y-2 grow">
                    <ChipContainer className="mr-4">
                        {completedGig.skills.map(skill => (
                            <Chip key={skill.id} variant="secondary" label={skill.name} />
                        ))}
                    </ChipContainer>
                </div>
            </div>
        </Card>
    );
};

export default GigCompletedSummaryCard;
