import { Switch } from "@headlessui/react";
import clsx from "clsx";
import FormInputLabel from "./FormInputLabel";

export type FormToggleProps = {
    description?: string
    checked: boolean
    tooltip?: string
    onChange: (checked: boolean) => void
    className?: string
    required?: boolean
    label?: string
    disabled?: boolean
}
const FormToggle = ({
    checked,
    description,
    tooltip,
    onChange,
    className,
    required,
    label,
    disabled
}: FormToggleProps) => {
    return (
        <Switch.Group>
            <div className="space-y-4">
                {label && <FormInputLabel tooltipText={tooltip} required={required}>{label}</FormInputLabel>}
                <div className={clsx("flex flex-row items-center", className)}>
                    <Switch
                        checked={checked}
                        onChange={onChange}
                        disabled={disabled}
                        className={clsx(
                            "relative inline-flex h-6 w-14 items-center rounded-full transition-colors focus:outline-none ",
                            checked && "bg-primary",
                            !checked && "bg-chip-secondary",
                            disabled && "bg-chip-secondary"
                        )}
                    >
                        <span
                            className={clsx(
                                "inline-block h-7 w-7 transform rounded-full bg-white transition-transform",
                                checked && "translate-x-7",
                                !checked && "translate-x-0",
                                disabled && "cursor-not-allowed"
                            )}
                        />
                    </Switch>
                    {description && <FormInputLabel className={clsx("font-normal ml-4 !mb-0", disabled && "text-disabled-text")}>{description}</FormInputLabel>}
                </div>
            </div>
        </Switch.Group>
    );
};

export default FormToggle;