import { ApiTalentCareerHistoryJobEditableFields, ApiTalentCareerHistoryJobDto, ApiUpdateTalentCareerHistoryJobDto } from "../models/api/talentCareerHistory";
import { TalentCareerHistoryJobEditableFields, TalentCareerHistoryJobDto, UpdateTalentCareerHistoryJobDto } from "../models/app/talentCareerHistory";
import { mapFromApiTalentCareerHistory, mapToApiUpdateTalentCareerHistoryJob } from "../models/mappers/talentCareerHistory";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";

export const useAddTalentCareerHistoryJob = () => useAuthenticatedGigApiFetcher<ApiTalentCareerHistoryJobEditableFields, string, TalentCareerHistoryJobEditableFields>("POST",
    (dto) => ({
        url: "api/me/career-history/",
        body: mapToApiUpdateTalentCareerHistoryJob(dto)
    })
);

export const useRemoveTalentCareerHistoryJob = () => useAuthenticatedGigApiFetcher<never, void, string>("DELETE",
    (careerHistoryId: string) => ({
        url: `api/me/career-history/${careerHistoryId}`,
    })
);

export const useUpdateTalentCareerHistoryJob = () => useAuthenticatedGigApiFetcher<ApiUpdateTalentCareerHistoryJobDto, void, { careerHistoryId: string, dto: UpdateTalentCareerHistoryJobDto }>("PUT",
    ({ careerHistoryId, dto }) => ({
        url: `api/me/career-history/${careerHistoryId}`,
        body: mapToApiUpdateTalentCareerHistoryJob(dto)
    }));

export type UseTalentCareerHistoriesReturn =
    UseGiggedApiSWRReturn<ApiTalentCareerHistoryJobDto[]> &
    {
        careerHistories: TalentCareerHistoryJobDto[]
        addJob: (dto: TalentCareerHistoryJobEditableFields) => Promise<GigApiFetcherResponse<string>>
        removeJob: (id: string) => Promise<GigApiFetcherResponse<void>>
        updateJob: (id: string, dto: UpdateTalentCareerHistoryJobDto) => Promise<GigApiFetcherResponse<void>>
        isAddingCareerHistory: boolean
        isRemovingCareerHistory: boolean
    }

export const useTalentCareerHistories = (): UseTalentCareerHistoriesReturn => {
    const apiCall = useGiggedApiSWR<ApiTalentCareerHistoryJobDto[]>("api/me/career-history");
    const [addCareerHistory, isAddingCareerHistory] = useAddTalentCareerHistoryJob();
    const [removeCareerHistory, isRemovingCareerHistory] = useRemoveTalentCareerHistoryJob();
    const [updateCareerHistory] = useUpdateTalentCareerHistoryJob();

    const careerHistories = apiCall?.data ? apiCall.data.map(mapFromApiTalentCareerHistory) : [];

    return {
        ...apiCall,
        careerHistories,
        addJob: async (dto) => {
            const response = await addCareerHistory(dto);

            if (response.success) apiCall.mutate();

            return response;
        },
        removeJob: async (id) => {
            const response = await removeCareerHistory(id);

            if (response.success) apiCall.mutate();

            return response;
        },
        updateJob: async (id, dto) => {
            const response = await updateCareerHistory({ careerHistoryId: id, dto });

            if (response.success) apiCall.mutate();

            return response;
        },
        isAddingCareerHistory,
        isRemovingCareerHistory
    };
};