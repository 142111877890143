import { EducationLevel } from "../models/app/talentEducation";
import { useGiggedApiSWR } from "./common/giggedApiSWR";

export const useEducationLevels = () => {
    const { data, isLoading } = useGiggedApiSWR<EducationLevel[]>("api/education-levels");

    return {
        educationLevels: data || [],
        isLoading,
    };
};