import { Buildings, CalendarBlank } from "phosphor-react";
import Typography from "../../components/Typography";
import { TalentCareerHistoryJobDto } from "../../models/app/talentCareerHistory";
import DataPoint from "./DataPoint";

export type CareerHistoryJobProps = {
    careerHistoryJob: TalentCareerHistoryJobDto
}

const CareerHistoryJob = ({
    careerHistoryJob: {
        jobTitle,
        companyTitle,
        startDate,
        endDate,
        description,
    }
}: CareerHistoryJobProps) => {
    return (
        <div className="break-words">
            <Typography variant="title-small" component="label" className="block mb-4">{jobTitle}</Typography>
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mb-4">
                <DataPoint Icon={Buildings}>{companyTitle}</DataPoint>
                <DataPoint Icon={CalendarBlank}>{startDate.toLocaleDateString()} - {endDate ? endDate?.toLocaleDateString() : "present"}</DataPoint>
            </div>
            <p className="whitespace-pre-wrap">{description}</p>
        </div>
    );
};

export default CareerHistoryJob;
