import { createRef, Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { actionCreators, AppState } from "../../../store";
import { ReduxConversation } from "../../../store/reducers/conversationReducer";
import ConversationListItem from "./ConversationListItem";
import ConversationListSearch from "./ConversationListSearch";
import { setUnreadMessagesCount } from "../../../utils/conversationsHelpers";
import useCurrentUser from "../../../auth/useCurrentUser";
import { useTalentPersonalInformationContext } from "../../../api/talentPersonalInformation";
import { useClientPersonalInformationContext } from "../../../api/clientPersonalInformation";
import ListHeader from "../ListHeader";
import _ from "lodash";

export type ConversationListProps = {
    handleConversationClicked: (conversation: ReduxConversation) => Promise<void>
    fromQueryParam: boolean
    setFromQueryParam: Dispatch<SetStateAction<boolean>>
}
const ConversationList = ({
    handleConversationClicked,
    fromQueryParam,
    setFromQueryParam
}: ConversationListProps) => {
    const { userGivenName, userFamilyName, userRole } = useCurrentUser();
    const { talentPersonalInformation } = useTalentPersonalInformationContext();
    const { clientPersonalInformation } = useClientPersonalInformationContext();

    const [searchTerm, setSearchTerm] = useState("");

    const sid = useSelector((state: AppState) => state.sid);
    const messages = useSelector((state: AppState) => state.messages);
    const unreadMessages = useSelector((state: AppState) => state.unreadMessages);
    const conversations = useSelector((state: AppState) => state.conversations);

    const dispatch = useDispatch();
    const {
        updateUnreadMessages,
    } = bindActionCreators(actionCreators, dispatch);

    const profileImage = () => {
        if (!talentPersonalInformation?.profileImageUrl && !clientPersonalInformation?.profileImageUrl) {
            return (
                <div className="size-12 flex items-center justify-center rounded-full bg-surface">
                    {userGivenName?.length > 0 && userGivenName[0].toUpperCase()}
                </div>
            );
        }
        return (
            <img
                src={userRole === "talent" ? talentPersonalInformation?.profileImageUrl : clientPersonalInformation?.profileImageUrl}
                className="size-12 object-cover rounded-full border border-white"
            />
        );
    };

    const sortConversations = () => {
        const lastMessagesDetails = conversations
            .map(conversation => {
                const lastMessage = _.last(messages[conversation.sid]);

                return {
                    sid: conversation.sid,
                    lastMessageTime: lastMessage?.dateCreated?.getTime() || 0,
                    hasUnreadMessages: !!unreadMessages[conversation.sid]
                };
            });

        const sortedLastMessagesDetails = _.orderBy(
            lastMessagesDetails,
            ["hasUnreadMessages", "lastMessageTime"],
            ["desc", "desc"]
        );

        const sortedConversations = sortedLastMessagesDetails.map((sortedConversation) =>
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            conversations.find(_ => _.sid === sortedConversation.sid)!
        );

        return sortedConversations;
    };

    const [sortedConversations, setSortedConversations] = useState<ReduxConversation[]>([]);

    useEffect(() => {
        setSortedConversations(sortConversations());
    }, [conversations]);

    const refs = useRef(conversations.map(() => createRef<HTMLDivElement>()));

    useEffect(() => {
        const index = conversations.findIndex(conversation => conversation.sid === sid);
        const ref = refs.current[index];

        if (fromQueryParam && ref && index !== -1 && refs.current[index].current && ref.current != null) {
            ref.current.scrollIntoView();
            window.scrollTo(0, 0);
            setFromQueryParam(false);
        }

    }, [sid, conversations, handleConversationClicked]);

    const userName = `${userGivenName} ${userFamilyName}`;

    if (!messages) return <div>"Loading..."</div>;

    return (
        <div className="md:w-[33%] border-surface text-secondary rounded-l-lg border-b-[1px] border-l-[1px] h-[85vh] flex flex-col">
            <ListHeader name={userName} className="rounded-tl-lg" variant="dark" profileImage={profileImage} />
            <ConversationListSearch
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
            />
            <div className="h-full overflow-y-auto scrollbar-thin scrollbar-thumb-scrollbar rounded-bl-lg">
                {sortedConversations
                    .filter(_ => !searchTerm || (_.friendlyName != null && _.friendlyName.toLowerCase().includes(searchTerm.toLowerCase())))
                    .map((conversation, index) => (
                        <div ref={refs.current[index]} key={conversation.sid}>
                            <ConversationListItem
                                key={conversation.sid}
                                currentConversationSid={sid}
                                messages={messages[conversation.sid]}
                                unreadMessagesCount={setUnreadMessagesCount(
                                    sid,
                                    conversation.sid,
                                    unreadMessages,
                                    updateUnreadMessages
                                )}
                                conversation={conversation}
                                onClick={() => handleConversationClicked(conversation)}
                            />
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default ConversationList;