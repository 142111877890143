import { useEffect, useState } from "react";
import { FieldError } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import { fetchSkills, fetchSkillsCount } from "../api/skills";
import useAccessToken from "../auth/useAccessToken";
import { FormSearchDropdown } from "./FormSearchDropdown";
import { FormSearchDropdownOption, LoadOptionsCallback } from "./FormSearchDropdown/FormSearchDropdown";

export type SkillsSearchDropdownProps = {
    value: string
    onChange: (value: string, option: FormSearchDropdownOption | null) => void
    disabled?: boolean
    required?: boolean
    error?: FieldError
    disabledSkillIds?: string[],
    tooltip?: string
}

const SkillsSearchDropdown = ({
    value,
    onChange,
    disabled,
    required,
    error,
    disabledSkillIds = [],
    tooltip
}: SkillsSearchDropdownProps) => {
    const accessToken = useAccessToken();
    const [allSkillOptions, setAllSkillOptions] = useState<FormSearchDropdownOption[]>([]);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);

    const fetchSkillOptions = async (searchQuery: string, pageIndex?: number) => {
        setIsLoading(true);

        try {
            const skillsResponse = await fetchSkills(searchQuery, accessToken, pageIndex);
            return skillsResponse
                .map(skill => ({
                    value: skill.id,
                    label: skill.name,
                }));
        } 
        finally {
            setIsLoading(false);
        }
    };

    const loadSkillOptions = (inputValue: string, callback: LoadOptionsCallback) => {
        fetchSkillOptions(inputValue)
            .then(options => callback(options));
    };

    useEffect(() => {
        if (!accessToken || allSkillOptions.length >= totalCount) return;

        const loadAllSkills = async () => {
            const fetchedSkillOptions = await fetchSkillOptions("", pageIndex);
            setAllSkillOptions(existingSkills => [...existingSkills, ...fetchedSkillOptions]);
        };

        loadAllSkills();
    }, [accessToken, pageIndex, totalCount]);

    useEffect(() => {
        if (!accessToken) return;

        fetchSkillsCount(accessToken)
            .then(count => setTotalCount(count));

    }, [accessToken]);

    const fetchMore = () => {
        if (allSkillOptions.length === totalCount) return;
        setPageIndex(prev => prev + 1);
    };

    const debouncedLoadSkillOptions = useDebouncedCallback(loadSkillOptions, 700);

    return (
        <FormSearchDropdown
            label="Select skill"
            tooltip={tooltip}
            value={value}
            loadOptions={debouncedLoadSkillOptions}
            onChange={onChange}
            isClearable
            defaultOptions={allSkillOptions}
            disabled={disabled}
            required={required}
            error={error}
            disabledOptionIds={disabledSkillIds}
            fetchMore={fetchMore}
            loading={isLoading}
        />
    );
};

export default SkillsSearchDropdown;