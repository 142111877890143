import clsx from "clsx";
import { FacebookLogo, GithubLogo, LinkedinLogo, XLogo } from "@phosphor-icons/react";
import Button from "../../components/Button";
import Card from "../../components/Card";
import TalentSkill from "../../components/TalentSkill";
import Typography from "../../components/Typography";
import { TalentDetails } from "../../models/app/talent";
import { getClickableLink } from "../../utils/getClickableLink";

export type SkillsAndExperienceCardProps = {
    talent: TalentDetails
    className?: string
}

const SkillsAndExperienceCard = ({
    talent,
    className,
}: SkillsAndExperienceCardProps) => {
    const handleShareOnLinkedIn = () => {
        const pageToShareUrl = encodeURIComponent(window.location.href);
        const talentName = talent.tradingName || `${talent.firstName} ${talent.lastName}`;
        const shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${pageToShareUrl}&title=${talentName}`;

        window.open(shareUrl, "_blank")?.focus();
    };

    return (
        <Card className={clsx("space-y-8", className)}>
            <div>
                <Typography variant="title-large" component="h2" underline className="mb-6">Role specialisation</Typography>
                {talent.specialization ? (
                    <Typography variant="title-small" component="h3">{talent.specialization}</Typography>
                ) : (
                    <p>Not specified.</p>
                )}
            </div>
            <div>
                <Typography variant="title-large" component="h2" underline className="mb-6">My skills</Typography>
                {talent.skills.length === 0 ? (
                    <p>None added yet.</p>
                ) : (
                    <div className="space-y-4">
                        {talent.skills.map(skill => (
                            <TalentSkill key={skill.skillId} {...skill} />
                        ))}
                    </div>
                )}
            </div>
            <div>
                <Typography variant="title-large" component="h2" underline className="mb-6">Experience</Typography>
                {talent.industrialExperiences.length === 0 ? (
                    <p>None added yet.</p>
                ) : (
                    <div className="space-y-4">
                        {talent.industrialExperiences.map(industrialExperience => (
                            <TalentSkill
                                key={industrialExperience.industrialExperienceId}
                                {...industrialExperience}
                                skillName={industrialExperience.industrialExperienceName}
                            />
                        ))}
                    </div>
                )}
            </div>
            <div>
                <Typography variant="title-large" component="h2" underline className="mb-6">Socials</Typography>
                {!talent.addedExtras.gitHubUrl && !talent.addedExtras.facebookUrl && !talent.addedExtras.linkedInUrl && !talent.addedExtras.twitterUrl ? (
                    <p>None added yet.</p>
                ) : (
                    <div className="flex flex-row gap-6">
                        {talent.addedExtras.gitHubUrl && <a target="_blank" href={getClickableLink(talent.addedExtras.gitHubUrl)}><GithubLogo size={32} className="text-primary-text" weight="fill" /></a>}
                        {talent.addedExtras.facebookUrl && <a target="_blank" href={getClickableLink(talent.addedExtras.facebookUrl)}><FacebookLogo size={32} className="text-primary-text" weight="fill" /></a>}
                        {talent.addedExtras.linkedInUrl && <a target="_blank" href={getClickableLink(talent.addedExtras.linkedInUrl)}><LinkedinLogo size={32} className="text-primary-text" weight="fill" /></a>}
                        {talent.addedExtras.twitterUrl && <a target="_blank" href={getClickableLink(talent.addedExtras.twitterUrl)}><XLogo size={32} className="text-primary-text" weight="fill" /></a>}
                    </div>
                )}
            </div>
            <div>
                <Typography variant="title-large" component="h2" underline className="mb-6">Share</Typography>
                <Button variant="secondary" className="w-full flex items-center gap-2 justify-center" onClick={handleShareOnLinkedIn}>
                    <LinkedinLogo width={20} height={20} weight="fill" />
                    Share on LinkedIn
                </Button>
            </div>
        </Card>
    );
};

export default SkillsAndExperienceCard;
