import { SortOrder } from "../../api/common/sortOrder";
import { ApiTalentReview } from "../api/talent";
import { AddedExtrasDto } from "./addedExtras";
import { TalentBadgeDetails } from "./badge";
import { LocationDto } from "./location";
import { TalentCareerHistoryJobDto } from "./talentCareerHistory";
import { TalentCertificationDto } from "./talentCertification";
import { TalentEducationCourseDto } from "./talentEducation";
import { TalentIndustrialExperienceDto } from "./talentIndustrialExperience";

export type TalentSkillDto = {
    skillId: string
    skillName: string
    yearsExperience: number
}

export type TalentSummary = {
    id: string
    firstName: string
    lastName: string
    fullName: string
    tradingName?: string
    email: string
    profileImageThumbnailUrl?: string
    skills: TalentSkillDto[]
    ratingAverage?: number
    completedGigsCount: number
    specialization?: string
    location?: LocationDto
    dailyRate?: number
    agency?: TalentAgencyDto
    badges: TalentBadgeDetails[]
    profileHealthScore?: number
}

export type TalentDetails = Omit<TalentSummary, "profileImageThumbnailUrl"> & {
    profileImageUrl?: string
    portfolioUrl?: string
    aboutMe?: string
    roleTitle?: string
    ongoingGigsCount: number
    cancelledGigsCount: number
    memberSince: string
    addedExtras: AddedExtrasDto
    educations: TalentEducationCourseDto[]
    careerHistories: TalentCareerHistoryJobDto[]
    certifications: TalentCertificationDto[]
    industrialExperiences: TalentIndustrialExperienceDto[]
    profileVideoUrls: string[]
    badges: TalentBadgeDetails[]
};

export type TalentReview = Omit<ApiTalentReview, "gigCompletedDate"> & {
    gigCompletedDate: Date
}

export type TalentReviewsDto = {
    reviews: TalentReview[],
    totalCount: number,
}

export type TalentAgencyDto = {
    id: string
    name: string
    description?: string
    url?: string
}

const talentOrderByFields = [
    { value: "", label: "" },
    { value: "Rating", label: "Rating" },
    { value: "FirstName", label: "First Name" },
    { value: "LastName", label: "Last Name" },
    { value: "TradingName", label: "Trading Name" },
] as const;

export type TalentOrderByFieldKeys = typeof talentOrderByFields[number]["value"];

export const talentOrderByOptions = talentOrderByFields.flatMap(({ value, label }): {
    value: `${TalentOrderByFieldKeys}:${SortOrder}`
    label: string
}[] => {
    return [
        {
            value: `${value}:asc`,
            label: `Sort by ${label.toLowerCase()} ASC`,
        },
        {
            value: `${value}:desc`,
            label: `Sort by ${label.toLowerCase()} DESC`,
        },
    ];
});
