import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useClientPersonalInformationContext } from "../../api/clientPersonalInformation";
import { useTalentPersonalInformationContext } from "../../api/talentPersonalInformation";
import useCurrentUser from "../../auth/useCurrentUser";
import { SignOutButton } from "./SignOutButton";
import clsx from "clsx";

type ProfileImageProps = {
    size: "large" | "small"
}

const UserMenu = () => {
    const currentUser = useCurrentUser();
    const { talentPersonalInformation } = useTalentPersonalInformationContext();
    const { clientPersonalInformation } = useClientPersonalInformationContext();

    const profileImage = ({ size }: ProfileImageProps) => {
        if (!talentPersonalInformation?.profileImageUrl && !clientPersonalInformation?.profileImageUrl) {
            return (
                <div className={clsx(
                    "flex items-center justify-center rounded-full bg-surface",
                    size === "small" && "!size-8",
                    size === "large" && "!size-14"
                )}>
                    {currentUser.userGivenName?.length > 0 && currentUser.userGivenName[0].toUpperCase()}
                </div>
            );
        }
        return (
            <img
                src={currentUser.userRole === "talent" ? talentPersonalInformation?.profileImageUrl : clientPersonalInformation?.profileImageUrl}
                className={clsx(
                    "object-cover rounded-full border-[1px] border-transparent",
                    size == "small" && "size-8",
                    size == "large" && "size-14"
                )}
            />
        );
    };

    return (
        <Menu as="div" className="relative">
            <div>
                <Menu.Button className="flex text-sm rounded-full focus:outline-none border-2 border-primary">
                    <span className="sr-only">Open user menu</span>
                    {profileImage({ size: "small" })}
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute right-0 mt-2  w-48 rounded-lg shadow-lg py-2 bg-white ring-1 ring-tertiary-text ring-opacity-5 focus:outline-none z-50">
                    <Menu.Item>
                        <div className={"flex flex-col hover:bg-none justify-center items-center px-4 py-2 text-sm text-gray-700"}>
                            {profileImage({ size: "large" })}
                            <div className="font-medium mt-2">{`${currentUser.userGivenName} ${currentUser.userFamilyName}`}</div>
                            <div className="text-tertiary-text text-label-small">{currentUser.userRole === "client" ? "Client" : currentUser.userRole === "talent" ? "Talent" : ""}</div>
                        </div>
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <div className={"border-t border-surface mt-1"}>
                                <SignOutButton className={`w-full text-left block px-4 py-2 text-sm  ${active ? "bg-primary-light transition-all duration-150 ease-in-out" : ""}`} />
                            </div>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Transition >
        </Menu >
    );
};

export default UserMenu;