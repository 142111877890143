import { useState } from "react";
import ApiError from "../../api/common/apiError";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import useBeforeUnload from "../../hooks/useWarnBeforeUnload";
import { CreateGigDto } from "../../models/app/gig";
import { GigFormValues } from "../GigForm";
import TabStepper from "../TabStepper";
import StepTitle from "./StepTitle";
import StepDescription from "./StepDescription";
import StepCurrentStage from "./StepCurrentStage";
import StepSkills from "./StepSkills";
import StepSummary from "./StepSummary";
import StepBudget from "./StepBudget";
import StepRemote from "./StepRemote";
import StepTimeAndMaterial from "./StepTimeAndMaterial";
import StepDuration from "./StepDuration";


export type GigFormProps = {
    onSaveAsDraft: (values: CreateGigDto) => Promise<GigApiFetcherResponse<unknown>>,
    onSaveAndPost: (values: CreateGigDto) => Promise<GigApiFetcherResponse<unknown>>,
}

const CreateGigForm = ({
    onSaveAsDraft,
    onSaveAndPost,
}: GigFormProps) => {
    useBeforeUnload();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [gigFormData, setGigFormData] = useState<GigFormValues>({
        title: "",
        expectedDurationId: -1,
        startDate: null,
        deadlineDate: null,
        description: "",
        initialStageId: -1,
        gigStatusId: 1,
        skills: [],
        budget: null,
        isComplianceCheckRequired: false,
        isGigFilesPublic: false,
        isCompletedRemotely: "",
        isTimeAndMaterial: false,
        dailyRate: null,
        numberOfDays: null,
    });
    const { organizationConfig } = useOrganizationContext();

    const updateAction = (data: Partial<GigFormValues>) => {
        setGigFormData(oldValue => ({
            ...oldValue,
            ...data
        }));
    };

    const handleBackClick = (data: Partial<GigFormValues>) => {
        updateAction(data);
        setSelectedIndex(value => value - 1);
        window.scrollTo(0, 0);
    };

    const handleStepSubmit = (data: Partial<GigFormValues>) => {
        updateAction(data);
        setSelectedIndex(value => value + 1);
        window.scrollTo(0, 0);
    };

    const handleSubmit = async (data: GigFormValues, callback: (values: CreateGigDto) => Promise<GigApiFetcherResponse<unknown>>) => {
        setIsSubmitting(true);
        updateAction(data);

        // Set both to UTC to avoid any time zone or DST issues
        if (data.startDate) data.startDate = new Date(Date.UTC(data.startDate.getFullYear(), data.startDate.getMonth(), data.startDate.getDate(), 0, 0, 0, 0));
        if (data.deadlineDate) data.deadlineDate = new Date(Date.UTC(data.deadlineDate.getFullYear(), data.deadlineDate.getMonth(), data.deadlineDate.getDate(), 0, 0, 0, 0));


        // Set daily rate and number of days to null for outcome based gigs that were previously set to t&m
        if (!data.isTimeAndMaterial) {
            data.dailyRate = null;
            data.numberOfDays = null;
        }

        if (data.startDate === null) throw Error("Expected start date not to be null.");

        const response = await callback({
            ...data,
            startDate: data.startDate,
            deadlineDate: data.deadlineDate || null,
            skillIds: data.skills.map(skill => skill.id),
            budget: data.budget || null,
            isCompletedRemotely: JSON.parse(data.isCompletedRemotely),
            dailyRate: data.dailyRate || null,
            isTimeAndMaterial: data.isTimeAndMaterial || false
        });
        setIsSubmitting(false);

        if (!response.success) {
            setSubmissionError(response.error);
        }
    };

    const handleSaveAsDraft = async (data: GigFormValues) => {
        await handleSubmit(data, onSaveAsDraft);
    };

    const handleSaveAndPost = async (data: GigFormValues) => {
        await handleSubmit(data, onSaveAndPost);
    };

    const pages = [
        {
            title: "Title",
            content: <StepTitle onSubmit={handleStepSubmit} defaultValues={gigFormData} />
        },
        {
            title: "Description",
            content: <StepDescription onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={gigFormData} />
        },
        {
            title: "Stage",
            content: <StepCurrentStage onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={gigFormData} />
        },
        {
            title: "Skills",
            content: <StepSkills onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={gigFormData} />
        },
    ];

    if (organizationConfig.isPaymentsEnabled) (
        pages.push({
            title: "Budget Method",
            content: <StepTimeAndMaterial onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={gigFormData} />
        })
    );

    pages.push({
        title: "Duration",
        content: <StepDuration onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={gigFormData} />
    });

    if (organizationConfig.isPaymentsEnabled) {
        pages.push({
            title: "Budget",
            content: <StepBudget onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={gigFormData} />
        });
    }

    pages.push(
        {
            title: "Remote",
            content: <StepRemote onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={gigFormData} />
        },
        {
            title: "Summary",
            content: <StepSummary onSaveAsDraft={handleSaveAsDraft} onSaveAndPost={handleSaveAndPost} isSubmitting={isSubmitting} defaultValues={gigFormData} submissionError={submissionError} />
        }
    );

    return (
        <>
            <TabStepper
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                pages={pages}
            />
            <p className="text-sm mt-6">
                Need help? Click the icon on the bottom right of your screen and we will be right with you.
            </p>
        </>
    );
};

export default CreateGigForm;