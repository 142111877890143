import clsx from "clsx";
import { useTalentReviews } from "../../api/talent";
import Button from "../../components/Button";
import Card from "../../components/Card";
import Typography from "../../components/Typography";
import { TalentDetails } from "../../models/app/talent";
import TalentProfileVideo from "../TalentProfileEdit/TalentProfileVideo";
import CareerHistoryJob from "./CareerHistoryJob";
import Certification from "./Certification";
import EducationCourse from "./EducationCourse";
import TalentReviewComponent from "./TalentReviewComponent";
import { getClickableLink } from "../../utils/getClickableLink";

export type TalentProfileDetailsCardProps = {
    talent: TalentDetails
    className?: string
}

const TalentProfileDetailsCard = ({
    talent,
    className,
}: TalentProfileDetailsCardProps) => {
    const talentReviews = useTalentReviews(talent.id);
    
    const handleLoadMore = () => {
        talentReviews.setTop(talentReviews.top + 1);
    };

    if (talentReviews.reviews.length === 0 &&
        talent.certifications.length === 0 &&
        talent.careerHistories.length === 0 &&
        talent.educations.length === 0 &&
        talent.profileVideoUrls.length === 0) {
        return <div className={className} />;
    }

    return (
        <Card className={clsx("space-y-8", className)}>
            {talent.portfolioUrl && (
                <div>
                    <Typography variant="title-large" component="h2" underline className="mb-6">Portfolio</Typography>
                    <div>
                        <a href={getClickableLink(talent.portfolioUrl)} target="_blank" className="hover:underline">Visit my portfolio</a>
                    </div>
                </div>
            )}
            {talentReviews.reviews.length > 0 && (
                <div>
                    <Typography variant="title-large" component="h2" underline className="mb-6">Reviews</Typography>
                    <div className="space-y-4">
                        {talentReviews.reviews.map(talentReview => (
                            <div key={talentReview.gigId}>
                                <TalentReviewComponent review={talentReview} />
                                <div className="border-b border-surface my-4" />
                            </div>
                        ))}
                        {talentReviews.reviews.length < talentReviews.totalCount && (
                            <Button onClick={handleLoadMore} loading={talentReviews.isLoading}>Load More</Button>
                        )}
                    </div>
                </div>
            )}
            {talent.certifications.length > 0 && (
                <div>
                    <Typography variant="title-large" component="h2" underline className="mb-6">Awards &amp; certifications</Typography>
                    <div>
                        {talent.certifications.map(certification => (
                            <div key={certification.id}>
                                <Certification certification={certification} />
                                <div className="border-b border-surface my-4" />
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {talent.careerHistories.length > 0 && (
                <div>
                    <Typography variant="title-large" component="h2" underline className="mb-6">Career history</Typography>
                    <div>
                        {talent.careerHistories.map(careerHistoryJob => (
                            <div key={careerHistoryJob.id}>
                                <CareerHistoryJob careerHistoryJob={careerHistoryJob} />
                                <div className="border-b border-surface my-4" />
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {talent.educations.length > 0 && (
                <div>
                    <Typography variant="title-large" component="h2" underline className="mb-6">Education</Typography>
                    <div>
                        {talent.educations.map(education => (
                            <div key={education.id}>
                                <EducationCourse educationCourse={education} />
                                <div className="border-b border-surface my-4" />
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {talent.profileVideoUrls.length > 0 && (
                <div>
                    <Typography variant="title-large" component="h2" underline className="mb-6">Videos</Typography>
                    <div className="flex flex-row flex-wrap gap-2">
                        {talent.profileVideoUrls.map(url => (
                            <TalentProfileVideo url={url} />
                        ))}
                    </div>
                </div>
            )}
        </Card>
    );
};

export default TalentProfileDetailsCard;
