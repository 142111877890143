
import { useGigsForUnauthenticatedUsers } from "../../api/gigsUnauthenticated";
import GigsList from "../FindAGig/GigsList";
import MarketingBanner from "../../components/MarketingBanner";
import { Helmet, HelmetProvider } from "react-helmet-async";

const UnauthenticatedFindAGigPage = () => {
    const { gigs, isLoading } = useGigsForUnauthenticatedUsers();

    return (
        <HelmetProvider>
            <Helmet>
                <title>Find A Gig - Gigged.AI</title>
            </Helmet>
            <MarketingBanner
                title="Find your next freelance gig fast"
                body="Don't wait for our clients to come to you. Browse our current work packages and send a proposal to land your next gig."
                howGiggedWorksUrl="https://gigged.ai/for-freelancers/"
                signUpUrl="https://app.gigged.ai/sign-up?type=t"
            />
            <div className="lg:m-8 m-4">
                <GigsList gigs={gigs} isLoading={isLoading} showClient={false} />
            </div>
        </HelmetProvider>
    );
};

export default UnauthenticatedFindAGigPage;