import clsx from "clsx";
import { proposalStatuses, proposalStatusLabels } from "../models/app/proposal";

export type ProposalStatusChipProps = {
    proposalStatusId: number
    onHold: boolean
}

const ProposalStatusChip = ({
    proposalStatusId,
    onHold
}: ProposalStatusChipProps) => {
    const label = onHold ? "On Hold" : proposalStatusLabels[proposalStatusId];
    return (
        <div className="md:min-w-[110px]">
            <span className={clsx(
                "rounded-full px-4 py-1.5 font-bold text-white",
                onHold ? "!bg-[#f49536]" :
                    proposalStatusId === proposalStatuses.pendingReview && "bg-[#9e9e9e]",
                proposalStatusId === proposalStatuses.submitted && "bg-[#9e9e9e]",
                proposalStatusId === proposalStatuses.reviewRejected && "bg-[#f44336]",
                proposalStatusId === proposalStatuses.hired && "bg-primary",
                proposalStatusId === proposalStatuses.unsuccessful && "bg-[#9e9e9e]",
                proposalStatusId === proposalStatuses.completed && "bg-black",
                proposalStatusId === proposalStatuses.cancelled && "bg-[#9e9e9e]",
            )}>
                {label}
            </span>
        </div>
    );
};

export default ProposalStatusChip;