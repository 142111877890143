import FindAGigComponent from "./FindAGigPageComponent";
import FindAGigContextProvider from "./FindAGigContextProvider";

const FindAGigPage = () => {
    return (
        <FindAGigContextProvider>
            <FindAGigComponent />
        </FindAGigContextProvider>
    );
};

export default FindAGigPage;
