import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export type CompleteGigFormDialogValues = {
    gigId: string
    feedback: string
    rating: number
};

export const completeGigFormValidationSchemaFields = {
    feedback: yup.string().required("Feedback is required."),
    rating: yup.number()
        .min(1, "Must be at least one star.")
        .max(5, "Must be no more than 5 stars.")
        .required("Rating is required.")
};

const schema = yup.object(completeGigFormValidationSchemaFields);

export const useCompleteGigForm = () => {
    return useForm<CompleteGigFormDialogValues>({
        resolver: yupResolver(schema)
    });
};