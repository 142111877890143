import { useState } from "react";
import { ApiInvoiceSummary } from "../models/api/invoice";
import { InvoiceSummary } from "../models/app/invoice";
import { mapFromApiInvoiceSummary } from "../models/mappers/invoice";
import { GigApiPaginatedData } from "./common/apiResponse";
import { useGiggedApiSWR } from "./common/giggedApiSWR";

export type UseInvoicesReturn = {
    invoices: InvoiceSummary[]
    isLoading: boolean
    pageIndex: number
    setPageIndex: (value: number) => void
};

export const useInvoices = (): UseInvoicesReturn => {
    const [pageIndex, setPageIndex] = useState(0);

    const url = "api/me/invoices?&pageSize=1000&" +
        `pageIndex=${pageIndex}`;
    

    const apiCall = useGiggedApiSWR<GigApiPaginatedData<ApiInvoiceSummary>>(url);

    return {
        invoices: (apiCall.data?.items || []).map(mapFromApiInvoiceSummary),
        isLoading: apiCall.isLoading,
        pageIndex,
        setPageIndex,
    };
};