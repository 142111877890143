import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import Button from "../../components/Button";
import Card from "../../components/Card";
import DialogBox, { useDialogBoxState } from "../../components/DialogBox";
import Typography from "../../components/Typography";
import { Chats, IdentificationBadge, UsersThree } from "phosphor-react";

export type MatchesInfoProps = {
    isClientVerified: boolean
}
const MatchesInfo = ({
    isClientVerified
}: MatchesInfoProps) => {
    const {
        gigTerminology,
        talentTerminology,
        talentTerminologyPlural
    } = useOrganizationContext();
    const howDoIHireDialogState = useDialogBoxState();

    return (
        <div className="space-y-2">
            {isClientVerified && <Typography variant="body" component="p">Our algorithm has matched the {talentTerminologyPlural.toLowerCase()} below to your {gigTerminology.toLowerCase()}. Take the first step towards hiring the best on-demand {talentTerminology.toLowerCase()} by inviting them to get started!</Typography>}
            <button className="underline text-primary " onClick={howDoIHireDialogState.open}>How do I hire?</button>
            <DialogBox {...howDoIHireDialogState} title="How do I hire?">
                <div className="flex flex-col md:flex-row gap-6">
                    <Card className="flex-1 flex flex-col items-center text-center space-y-4 p-8">
                        <Typography variant="title-medium" component="h3">Invite</Typography>
                        <UsersThree size={48} className="text-primary-text" weight="duotone" />
                        <Typography variant="body" component="p" className="text-sm">Start by inviting {talentTerminologyPlural.toLowerCase()} from the matches page or exploring our {talentTerminology.toLowerCase()} pool. You may also receive proposals directly from other {talentTerminologyPlural.toLowerCase()} interested in your {gigTerminology.toLowerCase()} posting.</Typography>
                    </Card>
                    <Card className="flex-1 flex flex-col items-center text-center space-y-4 p-8">
                        <Typography variant="title-medium" component="h3">Chat</Typography>
                        <Chats size={48} className="text-primary-text" weight="duotone" />
                        <Typography variant="body" component="p" className="text-sm">Like the look of a proposal? Communicate with the {talentTerminology.toLowerCase()} to discuss the project details and expectations.</Typography>
                    </Card>
                    <Card className="flex-1 flex flex-col items-center text-center space-y-4 p-8">
                        <Typography variant="title-medium" component="h3">Hire</Typography>
                        <IdentificationBadge size={48} className="text-primary-text" weight="duotone" />
                        <Typography variant="body" component="p" className="text-sm">Once you have selected the {talentTerminology.toLowerCase()}, hit the hire button and work can begin on your project.</Typography>
                    </Card>
                </div>
                <div className="flex justify-center">
                    <Button variant="tertiary" onClick={howDoIHireDialogState.close}>Close</Button>
                </div>
            </DialogBox>
        </div>
    );
};

export default MatchesInfo;