import { useState } from "react";
import { useTalents } from "../../api/talents";
import { dailyRateOptions } from "../../models/app/dailyRate";
import { silentlyReplaceSearchParams } from "../../utils/searchParams";
import { FindTalentContext } from "./findTalentContext";

export type FindTalentContextProviderProps = {
    children: React.ReactNode
}

const FindTalentContextProvider = ({
    children,
}: FindTalentContextProviderProps) => {
    const {
        talents,
        isLoading,
        sortOrder,
        orderBy,
        searchTerm: searchTermQueryParam,
        skillIds: skillIdsQueryParam,
        badgeIds: badgeIdsQueryParam,
        locationIds: locationIdsQueryParam,
        specializationIds: specializationIdsQueryParam,
        industrialExperienceIds: industrialExperienceIdsQueryParam,
        setSearchTerm: setUseTalentsSearchTerm,
        setSkillIds: setUseTalentsSkillIds,
        setBadgeIds: setUseTalentsBadgeIds,
        setLocationIds: setUseTalentsLocationIds,
        setSpecializationIds: setUseTalentsSpecializationIds,
        setIndustrialExperienceIds: setUseTalentsIndustrialExperienceIds,
        setMinDailyRate: setUseTalentsMinDailyRate,
        setMaxDailyRate: setUseTalentsMaxDailyRate,
        setSortOrder,
        setOrderBy,
    } = useTalents({ pageSize: 100 });

    const [searchTerm, setSearchTerm] = useState(searchTermQueryParam);
    const [skillIds, setSkillIds] = useState<string[]>(skillIdsQueryParam);
    const [badgeIds, setBadgeIds] = useState<string[]>(badgeIdsQueryParam);
    const [locationIds, setLocationIds] = useState<string[]>(locationIdsQueryParam);
    const [specializationIds, setSpecializationIds] = useState<string[]>(specializationIdsQueryParam);
    const [industrialExperienceIds, setIndustrialExperienceIds] = useState<string[]>(industrialExperienceIdsQueryParam);
    const [dailyRateOptionId, setDailyRateOptionId] = useState<number>(0);

    const anyActiveFilters =
        searchTerm.length > 0 ||
        skillIds.length > 0 ||
        badgeIds.length > 0 ||
        locationIds.length > 0 ||
        specializationIds.length > 0 ||
        industrialExperienceIds.length > 0 ||
        !!dailyRateOptionId;

    const clearAllFilters = () => {
        setSearchTerm("");
        setSkillIds([]);
        setBadgeIds([]);
        setLocationIds([]);
        setSpecializationIds([]);
        setIndustrialExperienceIds([]);
        setUseTalentsSearchTerm("");
        setUseTalentsSkillIds([]);
        setUseTalentsBadgeIds([]);
        setUseTalentsLocationIds([]);
        setUseTalentsSpecializationIds([]);
        setUseTalentsIndustrialExperienceIds([]);
        silentlyReplaceSearchParams("");
        setDailyRateOptionId(0);
        setUseTalentsMinDailyRate(undefined);
        setUseTalentsMaxDailyRate(undefined);
    };

    const searchNow = () => {
        setUseTalentsSearchTerm(searchTerm);
        setUseTalentsSkillIds(skillIds);
        setUseTalentsBadgeIds(badgeIds);
        setUseTalentsLocationIds(locationIds);
        setUseTalentsSpecializationIds(specializationIds);
        setUseTalentsIndustrialExperienceIds(industrialExperienceIds);

        const dailyRateOption = dailyRateOptions.find(option => option.id === dailyRateOptionId);
        setUseTalentsMinDailyRate(dailyRateOption?.minDailyRate);
        setUseTalentsMaxDailyRate(dailyRateOption?.maxDailyRate);
    };

    return (
        <FindTalentContext.Provider value={{
            talents,
            isLoading,
            searchTerm,
            skillIds,
            badgeIds,
            locationIds,
            specializationIds,
            industrialExperienceIds,
            sortOrder,
            orderBy,
            anyActiveFilters,
            dailyRateOptionId,
            setSearchTerm,
            setSkillIds,
            setBadgeIds,
            setLocationIds,
            setSpecializationIds,
            setIndustrialExperienceIds,
            setSortOrder,
            setOrderBy,
            setDailyRateOptionId,
            clearAllFilters,
            searchNow,
        }}>
            {children}
        </FindTalentContext.Provider>
    );
};

export default FindTalentContextProvider;
