import { useEffect, useState } from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useAppPaths } from "../../Routes";

import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import Button from "../Button";
import { formClassNames } from "../CreateGigForm/classNames";
import FormTextInput from "../FormTextInput";
import TalentCreateProfileFormPageLayout from "./TalentCreateProfileFormPageLayout";
import { TalentCreateProfilePersonalDetailsFormValues, buildTalentCreateProfileFormValidationSchema } from "./TalentCreateProfilePersonalDetailsFormValues";
import { fetchLocationOptions } from "../../api/locations";
import FormTextAreaInput from "../FormTextAreaInput";
import { talentProfileEditTooltips } from "../../tooltipsContent";
import FormCheckbox from "../FormCheckbox";
import FormImageUploadInput, { useFormFileUploadInputState } from "../FormFileUploadInput";
import { useTalentPersonalInformationContext } from "../../api/talentPersonalInformation";
import FormDropdown, { FormDropdownOption } from "../FormDropdown";
import useAccessToken from "../../auth/useAccessToken";
import { successToast } from "../../toast";
import ErrorList from "../ErrorList";

export type CreateTalentProfileStepPersonalInformationProps = {
    onBackClick: (values: TalentCreateProfilePersonalDetailsFormValues) => void
    defaultValues: TalentCreateProfilePersonalDetailsFormValues
}

const CreateTalentProfileStepPersonalInformation = ({
    onBackClick,
    defaultValues
}: CreateTalentProfileStepPersonalInformationProps) => {
    const [errors, setErrors] = useState<string[]>([]);
    const accessToken = useAccessToken();
    const { gigTerminology, gigTerminologyPlural, giggedClientTerminologyPlural, organizationConfig } = useOrganizationContext();
    const [allLocationOptions, setAllLocationOptions] = useState<FormDropdownOption<string>[]>([]);
    const [isLoadingLocationOptions, setIsLoadingLocationOptions] = useState(false);
    const navigate = useNavigate();
    const appPaths = useAppPaths();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {
        talentPersonalInformation,
        updatePersonalInformation,
        isUpdatingPersonalInformation,
        isLoading,
        isValidating,
    } = useTalentPersonalInformationContext();

    useEffect(() => {
        if (!accessToken || !organizationConfig.isPaymentsEnabled) return;

        const loadAllLocations = async () => {
            setIsLoadingLocationOptions(true);
            try {
                const fetchedLocationOptions = await fetchLocationOptions("", accessToken);
                setAllLocationOptions(fetchedLocationOptions);
            }
            finally {
                setIsLoadingLocationOptions(false);
            }
        };

        loadAllLocations();
    }, [accessToken]);

    const validationSchema: yup.SchemaOf<TalentCreateProfilePersonalDetailsFormValues> = yup.object({
        firstName: buildTalentCreateProfileFormValidationSchema(organizationConfig.isPaymentsEnabled).firstName,
        lastName: buildTalentCreateProfileFormValidationSchema(organizationConfig.isPaymentsEnabled).lastName,
        roleTitle: buildTalentCreateProfileFormValidationSchema(organizationConfig.isPaymentsEnabled).roleTitle,
        tradingName: buildTalentCreateProfileFormValidationSchema(organizationConfig.isPaymentsEnabled).tradingName,
        aboutMe: buildTalentCreateProfileFormValidationSchema(organizationConfig.isPaymentsEnabled).aboutMe,
        locationId: buildTalentCreateProfileFormValidationSchema(organizationConfig.isPaymentsEnabled).locationId,
        dailyRate: buildTalentCreateProfileFormValidationSchema(organizationConfig.isPaymentsEnabled).dailyRate,
        profileImageDataUri: buildTalentCreateProfileFormValidationSchema(organizationConfig.isPaymentsEnabled).profileImageDataUri,
        receiveNewGigNotifications: buildTalentCreateProfileFormValidationSchema(organizationConfig.isPaymentsEnabled).receiveNewGigNotifications
    });

    const methods = useForm<TalentCreateProfilePersonalDetailsFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues
    });

    useEffect(() => {
        methods.reset({
            firstName: defaultValues.firstName || talentPersonalInformation?.firstName,
            lastName: defaultValues.lastName || talentPersonalInformation?.lastName,
            roleTitle: defaultValues.roleTitle || talentPersonalInformation?.roleTitle,
            tradingName: defaultValues.tradingName ? defaultValues.tradingName : talentPersonalInformation?.tradingName ? talentPersonalInformation?.tradingName : undefined,
            aboutMe: defaultValues.aboutMe ? defaultValues.aboutMe : talentPersonalInformation?.aboutMe ? talentPersonalInformation?.aboutMe : undefined,
            locationId: defaultValues.locationId || talentPersonalInformation?.location?.id,
            dailyRate: defaultValues.dailyRate || talentPersonalInformation?.dailyRate,
            profileImageDataUri: defaultValues.profileImageDataUri || talentPersonalInformation?.profileImageUrl,
            receiveNewGigNotifications: defaultValues.receiveNewGigNotifications || talentPersonalInformation?.receiveNewGigNotifications
        });
    }, []);

    const {
        urlOrDataUri: profileImageDataUri,
        loadFile,
        error: profileImageError,
        isDirty: isProfileImageDirty,
        ...fileInputProps
    } = useFormFileUploadInputState({
        maxFileSizeKb: 1000,
        initialUrl: talentPersonalInformation?.profileImageUrl,
    });

    const handleSaveChanges = async (values: TalentCreateProfilePersonalDetailsFormValues) => {
        setIsSubmitting(true);
        const response = await updatePersonalInformation({
            ...values,
            firstName: values.firstName,
            lastName: values.lastName,
            tradingName: values.tradingName || null,
            profileImageDataUri: isProfileImageDirty ? profileImageDataUri : undefined,
            dailyRate: organizationConfig.isPaymentsEnabled ?
                values.dailyRate ? values.dailyRate : 0 :
                null,
        });

        if (response.success) {
            navigate(appPaths.dashboard, { replace: true });
            successToast("Profile successfully created!");
        } else {
            setErrors(response.error.userMessages);
        }
        setIsSubmitting(false);
    };

    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(handleSaveChanges)}
            noValidate
        >
            <TalentCreateProfileFormPageLayout
                title="Finally, your personal details"
                description={"Make sure to complete all your personal details accurately."}
                content={
                    <div className="space-y-6">
                        <div className="md:flex space-y-6 md:space-x-6 md:space-y-0">
                            <FormTextInput
                                required
                                label="First name"
                                placeholder="Enter first name"
                                error={methods.formState.errors.firstName}
                                {...methods.register("firstName")}
                            />
                            <FormTextInput
                                required
                                label="Last name"
                                placeholder="Enter last name"
                                error={methods.formState.errors.lastName}
                                {...methods.register("lastName")}
                            />
                        </div>
                        <div className="md:flex space-y-6 md:space-x-6 md:space-y-0">
                            <FormTextInput
                                required
                                tooltip={talentProfileEditTooltips.roleTitle(giggedClientTerminologyPlural)}
                                label="Role title"
                                placeholder="Enter role title"
                                error={methods.formState.errors.roleTitle}
                                {...methods.register("roleTitle")}
                            />
                            <FormTextInput
                                label="Trading name"
                                tooltip={talentProfileEditTooltips.tradingName(giggedClientTerminologyPlural)}
                                placeholder="Enter trading name"
                                error={methods.formState.errors.tradingName}
                                {...methods.register("tradingName")}
                            />
                        </div>
                        {organizationConfig.isPaymentsEnabled && (
                            <div className="md:flex space-y-6 md:space-x-6 md:space-y-0">
                                <div className="w-full md:w-1/2">
                                    <Controller
                                        name="locationId"
                                        control={methods.control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormDropdown
                                                required
                                                label="Where are you in the world?"
                                                value={value || ""}
                                                options={allLocationOptions}
                                                disabled={isValidating || isLoading}
                                                onChange={onChange}
                                                error={methods.formState.errors.locationId}
                                                isLoadingOptions={isLoadingLocationOptions}
                                                pleaseSelectText="Select your location"
                                            />
                                        )}
                                    />
                                </div>
                                <div className="w-full md:w-1/2">
                                    <FormTextInput
                                        required
                                        type="number"
                                        step="any"
                                        label="Your daily rate (£)"
                                        placeholder="Enter your daily rate (£)"
                                        error={methods.formState.errors.dailyRate}
                                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                        {...methods.register("dailyRate")}
                                    />
                                </div>
                            </div>
                        )}
                        <FormTextAreaInput
                            required
                            tooltip={talentProfileEditTooltips.aboutMe}
                            id="personal-information-about-me"
                            label="About me"
                            rows={3}
                            placeholder={`Use this section to showcase your strengths and create a compelling introduction for ${giggedClientTerminologyPlural.toLowerCase()}.`}
                            register={methods.register("aboutMe")}
                            error={methods.formState.errors.aboutMe}
                        />
                        <Controller
                            name="receiveNewGigNotifications"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormCheckbox
                                    label={`Receive notifications for matched ${gigTerminologyPlural.toLowerCase()}`}
                                    tooltip={talentProfileEditTooltips.receiveNewGigNotifications(gigTerminology)}
                                    checked={value || false}
                                    onChange={onChange}
                                />
                            )}
                        />
                        <FormImageUploadInput
                            label="Profile photo"
                            tooltip="Recommended minimum dimensions: 300x300px"
                            inputId="profile-image-upload-button"
                            onLoadFile={loadFile}
                            error={profileImageError}
                            dataUrl={profileImageDataUri}
                            isDirty={isProfileImageDirty}
                            previewImgClassName="object-cover"
                            helpText="While a profile picture isn’t mandatory, it's highly recommended. Profiles with photos receive up to 21 times more views, making it a powerful way to stand out and attract attention."
                            {...fileInputProps}
                        />
                        <ErrorList errors={errors} />
                    </div>
                }
                iconName="IdentificationCard"
                buttons={
                    <>
                        <Button variant="secondary" type="button" onClick={handleBackClick}>Back</Button>
                        <Button 
                            type="submit" 
                            loading={isUpdatingPersonalInformation || isSubmitting} 
                            disabled={isUpdatingPersonalInformation}
                        >
                                Submit profile
                        </Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateTalentProfileStepPersonalInformation;