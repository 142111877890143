import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { hasNoMoreThan2DecimalPlaces } from "../../utils/yup-validators/hasNoMoreThan2DecimalPlaces";

export type TalentPersonalDetailsFormValues = {
    firstName: string
    lastName: string
    tradingName?: string
    roleTitle: string
    aboutMe: string
    receiveNewGigNotifications?: boolean
    locationId?: string | null
    dailyRate?: string | null
}

const buildPersonalDetailsFormValidationSchema = (isPaymentsEnabled: boolean) => yup.object({
    firstName: yup.string().required("First name is required.").max(64, "The maximum length for first name is 64 characters."),
    lastName: yup.string().required("Last name is required.").max(64, "The maximum length for last name is 64 characters."),
    tradingName: yup.string().max(100, "The maximum length for trading name is 100 characters."),
    roleTitle: yup.string().required("Role title is required.").max(100, "The maximum length for role title is 100 characters."),
    aboutMe: yup.string().required("About me is required."),
    receiveNewGigNotifications: yup.boolean(),
    locationId: isPaymentsEnabled
        ? yup.string()
        : yup.string().nullable(),
    dailyRate: isPaymentsEnabled 
        ? yup.number()
            .transform((value) => (isNaN(value) ? null : value))
            .min(0.01, "Daily rate must be at least £0.01.")
            .max(10_000.00, "Daily rate must be £10,000 or less.")
            .test(hasNoMoreThan2DecimalPlaces)
            .typeError("Must be at least £0.01.")
        : yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null) 
});

export const usePersonalDetailsForm = (
    isPaymentsEnabled: boolean,
    initialValues?: TalentPersonalDetailsFormValues
) => useForm<TalentPersonalDetailsFormValues>({
    resolver: yupResolver(buildPersonalDetailsFormValidationSchema(isPaymentsEnabled)),
    defaultValues: initialValues,    
});
