import { useTopThreeTalentsFromGigged } from "../../api/topThreeTalentsFromGigged";
import TalentsList from "../FindTalent/TalentsList";
import JoinNowFadeAway from "../../components/JoinNowFadeAway";
import MarketingBanner from "../../components/MarketingBanner";
import { Helmet, HelmetProvider } from "react-helmet-async";

const UnauthenticatedFindTalentPage = () => {
    const { talents, isLoading } = useTopThreeTalentsFromGigged();

    return (
        <HelmetProvider>
            <Helmet>
                <title>Find Talent - Gigged.AI</title>
                <meta name="description" content="Hire verified on-demand tech talent in under 24hrs." data-rh="true" />
            </Helmet>
            <MarketingBanner
                title="Find the top tech talent"
                body="Search for contractors, consultants and freelancers and drive your digital projects forward with the best on-demand talent."
                howGiggedWorksUrl="https://gigged.ai/for-businesses/"
                signUpUrl="https://app.gigged.ai/sign-up?type=gc"
            />
            <div className="m-4 lg:m-8">
                <div className="relative w-full">
                    <TalentsList talents={talents} isLoading={isLoading} />
                    {!isLoading && <JoinNowFadeAway text="Join now to find the best talent!" signUpUrl="https://app.gigged.ai/sign-up?type=gc" />}
                </div>
            </div>
        </HelmetProvider>
    );
};

export default UnauthenticatedFindTalentPage;