import Button from "../Button";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { formClassNames } from "../CreateGigForm/classNames";
import TalentCreateProfileFormPageLayout from "./TalentCreateProfileFormPageLayout";
import { useIsMediumScreen } from "../../hooks/screenSizes";
import CareerHistory from "../../pages/TalentProfileEdit/CareerHistory";
import { useTalentCareerHistories } from "../../api/talentCareerHistories";

export type CreateTalentProfileFormStepCareerHistoryProps = {
    onSubmit: () => void
    onBackClick: () => void
}

const CreateTalentProfileFormStepCareerHistory = ({
    onSubmit,
    onBackClick,
}: CreateTalentProfileFormStepCareerHistoryProps) => {
    const { giggedClientTerminologyPlural } = useOrganizationContext();
    const isMediumScreen = useIsMediumScreen();
    const careerHistories = useTalentCareerHistories();

    const skipButtonText = isMediumScreen ? "Skip for now" : "Skip";

    return (
        <div className={formClassNames} >
            <TalentCreateProfileFormPageLayout
                title="Add relevant roles or projects"
                description={`Include any past roles or projects you've worked on. ${giggedClientTerminologyPlural} often seek individuals with experience similar to what they've done before!`}
                content={<CareerHistory careerHistories={careerHistories} />}
                iconName="Briefcase"
                buttons={
                    <>
                        <Button variant="secondary" type="button" onClick={onBackClick}>Back</Button>
                        <Button
                            type="button"
                            onClick={onSubmit}
                            variant={careerHistories.careerHistories.length > 0 ? "primary" : "tertiary"}
                        >
                            {careerHistories.careerHistories.length > 0 ? "Next" : skipButtonText}
                        </Button>
                    </>
                }
            />
        </div>
    );
};

export default CreateTalentProfileFormStepCareerHistory;
