import { ApiTalentEducationCourseDto, ApiTalentEducationCourseEditableFields, ApiUpdateTalentEducationCourseDto } from "../models/api/talentEducation";
import { TalentEducationCourseDto, TalentEducationCourseEditableFields, UpdateTalentEducationCourseDto } from "../models/app/talentEducation";
import { mapFromApiTalentEducationCourse, mapToApiTalentEducationCourse } from "../models/mappers/talentEducation";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";

export const useAddTalentEducationCourse = () => useAuthenticatedGigApiFetcher<ApiTalentEducationCourseEditableFields, string, TalentEducationCourseEditableFields>("POST",
    (dto) => ({
        url: "api/me/education",
        body: mapToApiTalentEducationCourse(dto)
    })
);

export const useRemoveTalentEducationCourse = () => useAuthenticatedGigApiFetcher<never, void, string>("DELETE",
    (educationId: string) => ({
        url: `api/me/education/${educationId}`,
    })
);

export const useUpdateTalentEducationCourse = () => useAuthenticatedGigApiFetcher<ApiUpdateTalentEducationCourseDto, void, { educationId: string, dto: UpdateTalentEducationCourseDto }>("PUT",
    ({ educationId, dto }) => ({
        url: `api/me/education/${educationId}`,
        body: mapToApiTalentEducationCourse(dto)
    }));

export type UseTalentEducationsReturn =
    UseGiggedApiSWRReturn<ApiTalentEducationCourseDto[]> &
    {
        courses: TalentEducationCourseDto[]
        addCourse: (dto: TalentEducationCourseEditableFields) => Promise<GigApiFetcherResponse<string>>
        removeCourse: (id: string) => Promise<GigApiFetcherResponse<void>>
        updateCourse: (id: string, dto: UpdateTalentEducationCourseDto) => Promise<GigApiFetcherResponse<void>>
    }

export const useTalentEducations = (): UseTalentEducationsReturn => {
    const apiCall = useGiggedApiSWR<ApiTalentEducationCourseDto[]>("api/me/education");
    const [addEducationCourse] = useAddTalentEducationCourse();
    const [removeEducationCourse] = useRemoveTalentEducationCourse();
    const [updateEducationCourse] = useUpdateTalentEducationCourse();

    const courses = apiCall?.data ? apiCall.data.map(mapFromApiTalentEducationCourse) : [];

    return {
        ...apiCall,
        courses,
        addCourse: async (dto) => {
            const response = await addEducationCourse(dto);

            if (response.success) apiCall.mutate();

            return response;
        },
        removeCourse: async (id) => {
            const response = await removeEducationCourse(id);

            if (response.success) apiCall.mutate();

            return response;
        },
        updateCourse: async (educationId, dto) => {
            const response = await updateEducationCourse({ educationId, dto });

            if (response.success) apiCall.mutate();

            return response;
        },
    };
};