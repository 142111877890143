import { useMsal } from "@azure/msal-react";
import { useGiggedApiSWR } from "../api";
import { UserGetMeDto } from "../models/app/userMe";

export type UseCurrentUserReturn = {
    userGivenName: string
    userFamilyName: string
    email: string
    userRole: "client" | "talent" | undefined
    talentId?: string
    talentTradingName?: string
    clientId?: string
    clientCompanyName?: string
    clientDisplayName?: string
    clientIsVerified?: boolean
    clientIsUnverified?: boolean
    idpUserId: string
    organizationName?: string
}

const useCurrentUser = (): UseCurrentUserReturn => {
    const { accounts } = useMsal();
    const idTokenClaims = accounts.length > 0 ? accounts[0].idTokenClaims : undefined;

    const url = "api/me";

    const getMeCall = useGiggedApiSWR<UserGetMeDto>(url, { disableAutoReValidation: true });

    const talentAccountStatus = idTokenClaims?.extension_talentAccountStatus;
    const clientAccountStatus = idTokenClaims?.extension_giggedClientAccountStatus;
    const selectedAccountType = idTokenClaims?.extension_selectedAccountType;

    const isTalent = 
        !!talentAccountStatus && talentAccountStatus !== "None" || 
        selectedAccountType === "talent";

    const isClient = 
        !!clientAccountStatus && clientAccountStatus !== "None" ||
        selectedAccountType === "giggedClient";

    return {
        userGivenName: idTokenClaims?.given_name as string || "",
        userFamilyName: idTokenClaims?.family_name as string || "",
        email: idTokenClaims?.email as string || "",
        userRole: isTalent ? "talent" :
            isClient ? "client" : undefined,
        talentId: getMeCall.data?.talentId,
        talentTradingName: getMeCall.data?.talentTradingName,
        clientId: getMeCall.data?.giggedClientId,
        clientCompanyName: getMeCall.data?.giggedClientName,
        clientDisplayName: getMeCall.data?.giggedClientDisplayName,
        clientIsVerified: getMeCall.data?.giggedClientIsVerified,
        clientIsUnverified: getMeCall.data?.giggedClientIsUnverified,
        idpUserId: idTokenClaims?.sub || "",
        organizationName: getMeCall.data?.organizationName
    };
};

export default useCurrentUser;