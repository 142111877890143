import clsx from "clsx";
import CardDataFieldLabel, { CardDataFieldLabelProps } from "./CardDataFieldLabel";

export type CardDataFieldProps = {
    label: CardDataFieldLabelProps["children"]
    children: React.ReactNode
    iconName?: CardDataFieldLabelProps["iconName"]
    className?: string
    labelClassName?: string
    textClass?: string
}

const CardDataField = ({
    label,
    children,
    iconName,
    className,
    labelClassName,
    textClass
}: CardDataFieldProps) => {

    return (
        <div className={className}>
            <CardDataFieldLabel iconName={iconName} className={clsx("w-full", labelClassName)}>{label}</CardDataFieldLabel>
            <span className={clsx(
                textClass,
                "text-title-small"
            )}>
                {children}
            </span>
        </div>
    );
};

export default CardDataField;