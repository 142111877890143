import { TalentIndustrialExperienceDto } from "../models/app/talentIndustrialExperience";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { AddTalentIndustrialExperienceDto } from "../models/api/talentIndustrialExperience";

const useAddTalentIndustrialExperience = () => useAuthenticatedGigApiFetcher<AddTalentIndustrialExperienceDto, void, { industrialExperienceId: string, yearsExperience: number }>("POST", 
    ({ industrialExperienceId, yearsExperience }) => ({
        url: `api/me/industrial-experience/${industrialExperienceId}`,
        body: {yearsExperience}
    })
);

const useRemoveTalentIndustrialExperience = () => useAuthenticatedGigApiFetcher<never, void, string>("DELETE",
    (industrialExperienceId: string) => ({
        url: `api/me/industrial-experience/${industrialExperienceId}`,
    })
);

export type UseTalentIndustrialExperienceReturn =
    UseGiggedApiSWRReturn<TalentIndustrialExperienceDto[]> &
    {
        talentIndustrialExperience: TalentIndustrialExperienceDto[]
        addTalentIndustrialExperience: (industrialExperienceId: string, yearsExperience: number) => Promise<GigApiFetcherResponse<void>>
        removeTalentIndustrialExperience: (industrialExperienceId: string) => Promise<GigApiFetcherResponse<void>>
        isAddingTalentIndustrialExperience: boolean
        isRemovingTalentIndustrialExperience: boolean
    }

export const useTalentIndustrialExperience = (): UseTalentIndustrialExperienceReturn => {
    const apiCall = useGiggedApiSWR<TalentIndustrialExperienceDto[]>("api/me/industrial-experience");
    const [addTalentIndustrialExperience, isAddingTalentIndustrialExperience] = useAddTalentIndustrialExperience();
    const [removeTalentIndustrialExperience, isRemovingTalentIndustrialExperience] = useRemoveTalentIndustrialExperience();

    return {
        ...apiCall,
        talentIndustrialExperience: apiCall.data || [],
        addTalentIndustrialExperience: async (industrialExperienceId: string, yearsExperience: number) => {
            const response = await addTalentIndustrialExperience({ industrialExperienceId, yearsExperience });

            if (response.success) apiCall.mutate();

            return response;
        },
        removeTalentIndustrialExperience: async (industrialExperienceId: string) => {
            const response = await removeTalentIndustrialExperience(industrialExperienceId);

            if (response.success) apiCall.mutate();

            return response;
        },
        isAddingTalentIndustrialExperience,
        isRemovingTalentIndustrialExperience
    };
};