import clsx from "clsx";
import { CheckCircle } from "phosphor-react";
import { useIsSmallScreen } from "../hooks/screenSizes";
import ProgressBar from "./ProgressBar";

export type GigMatchedSkillProps = {
    skillName: string
    yearsExperience: number
    isExactMatch?: boolean
    skinnyProgress?: boolean
}

const TalentSkill = ({
    skillName,
    yearsExperience,
    isExactMatch,
    skinnyProgress,
}: GigMatchedSkillProps) => {
    const experienceUnits = yearsExperience === 1 ? "year" : "years";
    const yearsExperienceLabel = yearsExperience < 11 ? yearsExperience : "11+";
    const isSmallScreen = useIsSmallScreen();

    return(
        <div>
            <div className="flex items-center justify-between mb-2">
                <label className="block mr-4">{skillName}</label>
                {isExactMatch && (
                    !isSmallScreen ? (
                        <CheckCircle className="ml-auto text-primary" weight="fill" size="24" />
                    ) : (
                        <p className="ml-auto mr-4 bg-primary text-primary-bg-text px-2 rounded-md font-bold">Exact Match</p>
                    )
                )}
                <label className={clsx(
                    "block min-w-[75px] text-right",
                    !isExactMatch && "ml-auto"
                )}>
                    {yearsExperienceLabel} {experienceUnits}
                </label>
            </div>
            <ProgressBar
                currentStepIndex={yearsExperience - 1}
                numberOfSteps={15}
                skinny={skinnyProgress}
                bgWhite
            />
        </div>
    );
};

export default TalentSkill;
