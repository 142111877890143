import { Plus } from "phosphor-react";
import { useClientUsers } from "../../api/clientUsers";
import Button from "../../components/Button";
import Card from "../../components/Card";
import { useDialogBoxState } from "../../components/DialogBox";
import PageContent from "../../components/PageContent";
import Typography from "../../components/Typography";
import { useIsLargeScreen } from "../../hooks/screenSizes";
import formatDateString from "../../utils/formatDateString";
import ClientInviteUsersFormDialog from "./ClientInviteUsersFormDialog";
import Loader from "../../components/Loader";

const ManageUsersPage = () => {
    const { clientUsers, isLoading } = useClientUsers();
    const clientInviteUsersFormDialogState = useDialogBoxState();
    const isLargeScreen = useIsLargeScreen();

    if (isLoading) return <Loader />;

    return (
        <PageContent>
            <div className="space-y-6">
                <div className="space-y-4 flex flex-col pr-4">
                    <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>Manage users</Typography>
                    <p>Use this area to manage users of this client account, and invite new users you wish to provide with access. </p>
                    <p>Invited users will be emailed with a link to join Gigged.AI. Once they complete sign up, they will have full access to this client profile. </p>
                    <Button
                        variant="primary"
                        onClick={clientInviteUsersFormDialogState.open}
                        className="flex items-center ml-auto"
                        disabled={false}
                    >
                        <><Plus className="mr-2" /> Invite new user</>
                    </Button>
                </div>
                <div className="space-y-4">
                    {clientUsers?.memberUsers?.map(user => (
                        <Card key={user.email} className="flex flex-col md:flex-row text-center md:text-left items-center grow md:mr-4 rounded-b-none md:rounded-b-lg pb-2 md:pb-6 break-words">
                            <div className="md:mr-8">
                                <Typography variant="title-large" component="p" className="mb-4">{user.firstName} {user.lastName}</Typography>
                                <p className="mb-2">
                                    {user.email}
                                </p>
                                <p> Registered: {formatDateString(user.createdAt)}</p>
                            </div>
                        </Card>
                    ))}
                    {clientUsers?.invitedUserEmails?.map((email, index) => (
                        <Card key={email + index} className="flex flex-col md:flex-row text-center md:text-left items-center grow md:mr-4 rounded-b-none md:rounded-b-lg pb-2 md:pb-6 break-words">
                            <div className="md:mr-8">
                                <p className="mb-2 !text-disabled-text">
                                    {email} (Invited user)
                                </p>
                            </div>
                        </Card>
                    ))}
                </div>
            </div>

            <ClientInviteUsersFormDialog
                {...clientInviteUsersFormDialogState}
            />
        </PageContent>
    );
};

export default ManageUsersPage;