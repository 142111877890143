import { Box, Slider } from "@mui/material";
import { PencilSimple } from "phosphor-react";
import { useEffect, useState } from "react";
import FormTextInput from "../../components/FormTextInput";
import MoneyDisplay from "../../components/MoneyDisplay";

export type BudgetFilterProps = {
    minBudget: number
    maxBudget: number
    globalMinBudget: number
    globalMaxBudget: number
    setMinBudget: (value: number) => void
    setMaxBudget: (value: number) => void
    isLoading: boolean
}
const BudgetFilter = ({
    minBudget,
    maxBudget,
    globalMinBudget,
    globalMaxBudget,
    setMinBudget,
    setMaxBudget,
    isLoading
}: BudgetFilterProps) => {
    const [showRefineBudget, setShowRefineBudget] = useState(false);

    const handleSliderChange = (event: Event, value: number | number[]) => {
        const sliderRange = value as number[];

        setMinBudget(sliderRange[0]);
        setMaxBudget(sliderRange[1]);
    };

    const valueText = (value: number) => {
        return `£${value}`;
    };

    useEffect(() => {
        if (minBudget === 0 && maxBudget === 0) {
            setMinBudget(globalMinBudget);
            setMaxBudget(globalMaxBudget);
        }

    }, [globalMinBudget, globalMaxBudget]);

    return (
        <div className="flex flex-col">
            <div className="w-100 mb-2 flex justify-center">
                <Box sx={{ width: "95%" }}>
                    <Slider
                        getAriaLabel={() => "Budget range"}
                        value={[minBudget, maxBudget]}
                        onChange={handleSliderChange}
                        getAriaValueText={valueText}
                        min={globalMinBudget}
                        max={globalMaxBudget}
                        step={100}
                        className="text-black"
                        disabled={isLoading}
                    />
                </Box>
            </div>
            <div className="flex flex-row mb-2">
                <div className="w-1/5" />
                <span className="mx-auto mb-4">
                    <MoneyDisplay value={minBudget} fixedDecimalScale={0} />
                    <span> - </span>
                    <MoneyDisplay value={maxBudget} fixedDecimalScale={0} />
                </span>
                <PencilSimple onClick={() => setShowRefineBudget(!showRefineBudget)} size={24} weight="fill" className="cursor-pointer w-1/5" />
            </div>

            {showRefineBudget && (
                <div className="space-x-4 flex flex-row">
                    <FormTextInput
                        type="number"
                        value={minBudget}
                        placeholder="min (£)"
                        min={globalMinBudget}
                        max={maxBudget}
                        onChange={event => setMinBudget(event.target.valueAsNumber || 0)}
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                    />
                    <FormTextInput
                        type="number"
                        value={maxBudget}
                        placeholder="max (£)"
                        min={minBudget}
                        max={globalMaxBudget}
                        onChange={event => setMaxBudget(event.target.valueAsNumber || 0)}
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                    />
                </div>
            )}
        </div>
    );
};

export default BudgetFilter;