import { GigApiFetcherResponse } from "../../api/common/fetching";

import DialogBox, { useDialogBoxState } from "../../components/DialogBox";
import { TalentCareerHistoryJobDto } from "../../models/app/talentCareerHistory";
import { CareerHistoryFormDialog } from "./CareerHistoryFormDialog";
import { CareerHistoryFormValues } from "./CareerHistoryFormValues";
import HistoryListItem from "./HistoryListItem";

export type CareerHistoryCardProps = {
    careerHistory: TalentCareerHistoryJobDto
    onRemove: () => Promise<unknown>
    updateJob: (values: CareerHistoryFormValues) => Promise<GigApiFetcherResponse<unknown>>
}
export const CareerHistoryCard = ({
    careerHistory,
    onRemove,
    updateJob: updateCareerHistory,
}: CareerHistoryCardProps) => {
    const dialogState = useDialogBoxState();

    const initialValues: CareerHistoryFormValues = {
        ...careerHistory,
        endDate: careerHistory.endDate || null,
    };

    return (
        <>
            <HistoryListItem
                title={careerHistory.jobTitle}
                startDate={careerHistory.startDate}
                endDate={careerHistory.endDate || "Current"}
                onRemove={onRemove}
                onEdit={dialogState.open}
            />
            <DialogBox {...dialogState} title="Edit Job">
                <CareerHistoryFormDialog
                    {...dialogState}
                    isEdit
                    onSubmit={updateCareerHistory}
                    initialValues={initialValues}
                />
            </DialogBox>
        </>
    );
};

export default CareerHistoryCard;