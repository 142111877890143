import { Disclosure } from "@headlessui/react";
import { CaretDown, CaretUp } from "phosphor-react";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { ApiFile } from "../../models/api/file";
import { CardDataFieldLabel } from "../Card";
import FilePreview from "../FilePreview";

type GigFilesAccordionProps = {
    files: ApiFile[]
}

const GigFilesAccordion = ({
    files
}: GigFilesAccordionProps) => {
    const { gigTerminology } = useOrganizationContext();

    return ( 
        <Disclosure>
            <div className="space-y-4 w-full">
                <Disclosure.Button className="flex">
                    {
                        ({open}) => (
                            <div className="flex gap-4 items-center">
                                <CardDataFieldLabel className="cursor-pointer">Relevant {gigTerminology.toLowerCase()} files</CardDataFieldLabel>
                                {open ? (
                                    <CaretUp className="ml-auto block text-primary-text" weight="bold" />
                                ) : (
                                    <CaretDown className="ml-auto block text-primary-text" weight="bold" />
                                )}
                            </div>
                        )
                    }
                </Disclosure.Button>
                <Disclosure.Panel>
                    <div className="flex flex-wrap gap-2">
                        {
                            files.map(file => 
                                <div className="items-stretch max-w-[100%] md:min-w-[49%] md:max-w-[49%]">
                                    <FilePreview 
                                        key={file.id}
                                        url={file.url}
                                        name={file.name}
                                        totalBytes={file.totalBytes}
                                    />
                                </div>
                            )
                            
                        }
                    </div>
                </Disclosure.Panel>
            </div>
        </Disclosure>
    );
};
 
export default GigFilesAccordion;