import { FieldError } from "react-hook-form";

import SkillsSearchDropdown from "./SkillsSearchDropdown";
import Chip, { ChipContainer } from "./Chip";
import { FormSearchDropdownOption } from "./FormSearchDropdown/FormSearchDropdown";
import FormInputLabel from "./FormInputLabel";


export type GigSkillsInputProps = {
    addSkill: (skill: { id: string, name: string }) => void
    removeSkill: (index: number) => void
    skills?: { id: string, name: string }[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any,
    showError?: boolean
    hideDropdown?: boolean
    tooltip?: string
}

const GigSkillsInput = ({
    addSkill,
    removeSkill,
    skills,
    error,
    showError,
    hideDropdown,
    tooltip
}: GigSkillsInputProps) => {
    const handleSelectSkill = (_: string, option: FormSearchDropdownOption | null) => {
        if (option === null) return;
        addSkill({
            id: option.value,
            name: option.label
        });
    };

    const handleRemoveSkill = (index: number) => {
        removeSkill(index);
    };

    const fieldError: FieldError | undefined = (error)?.message ? error as FieldError : undefined;

    if (!skills) return <>"Loading skills..."</>;

    return (
        <div>
            {!hideDropdown && (
                <SkillsSearchDropdown
                    value={""}
                    onChange={handleSelectSkill}
                    disabled={skills.length === 3}
                    disabledSkillIds={skills.map(_ => _.id)}
                    error={showError ? fieldError : undefined}
                    tooltip={tooltip}
                />
            )}
            {hideDropdown && (
                <FormInputLabel required className="mt-6">Top 3 skills</FormInputLabel>
            )}
            <ChipContainer className={hideDropdown ? undefined : "pt-6"}>
                {skills.map((skill, index) => (
                    <Chip
                        key={skill.id}
                        label={skill.name}
                        onDelete={() => handleRemoveSkill(index)} />
                ))}
            </ChipContainer>
        </div>
    );
};

export default GigSkillsInput;