import clsx from "clsx";
import Tooltip from "./Tooltip";

export type StatusLabelProps = {
    label: string
    tooltipText?: string
    icon: JSX.Element
    size?: "small" | "medium"
}
const StatusLabel = ({
    label,
    tooltipText,
    icon,
    size = "medium"
}: StatusLabelProps) => {

    const sizeClasses = {
        small: "text-[0.8rem]",
        medium: "text-[0.9rem]"
    };

    const content = (
        <div className={clsx(
            "flex flex-row items-center space-x-2 border rounded-full h-fit w-fit flex-shrink-0 px-[0.75rem] py-[0.25rem]",
            sizeClasses[size]
        )}>
            {icon}
            <p className="whitespace-nowrap">
                {label}
            </p>
        </div>
    );

    return tooltipText ? (
        <Tooltip tooltipText={tooltipText} className="w-fit">
            {content}
        </Tooltip>
    ) : (
        content
    );
};

export default StatusLabel;