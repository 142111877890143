import Card from "../../components/Card";
import Typography from "../../components/Typography";
import GigSummaryCard from "../../components/GigSummaryCard";
import { useAppPaths } from "../../Routes";
import { GigSummary, gigStatuses } from "../../models/app/gig";
import RecentlyCompletedGigsCarousel from "../../components/RecentlyCompletedGigCarousel";
import GigSummaryCardSkeleton from "./GigSummaryCardSkeleton";
import SkeletonList from "../../components/SkeletonList";

export type GigsListProps = {
    gigs: GigSummary[]
    isLoading: boolean
    anyActiveFilters?: boolean
    showClient?: boolean
};

const GigsList = ({ gigs, isLoading, anyActiveFilters, showClient = true }: GigsListProps) => {
    const appPaths = useAppPaths();
    const openGigs = gigs.filter(gig => gig.gigStatusId === gigStatuses.posted);
    const completedGigs = gigs
        .filter(gig => gig.gigStatusId === gigStatuses.completed && gig.giggedClientCompanyName !== "Gigged AI")
        .sort((gigA, gigB) => {
            const dateATime = gigA.completedDate ? new Date(gigA.completedDate).getTime() : 0;
            const dateBTime = gigB.completedDate ? new Date(gigB.completedDate).getTime() : 0;

            return dateBTime - dateATime;
        })
        .slice(0, 10);

    if (isLoading) return <SkeletonList skeletonCard={<GigSummaryCardSkeleton />} className="w-full"/>;

    return (
        <div className="flex flex-col grow w-full">
            <div className="space-y-8 w-full overflow-hidden">
                {openGigs.length === 0 ? (
                    <Card className="w-full self-start">
                        <Typography variant="title-large" component="p">No results</Typography>
                    </Card>
                ) : (openGigs.map(gig => <GigSummaryCard key={gig.id} gig={gig} viewGigLink={appPaths.gigs.findAGigDetails(gig.id)} showClient={showClient} />
                ))}
            </div>
            {!anyActiveFilters && completedGigs.length > 0 && <RecentlyCompletedGigsCarousel gigs={completedGigs} />}
        </div>
    );
};

export default GigsList;
