import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { todayAtMidnight } from "../../utils/dateFormatters";
import defaultIfUndefined from "../../utils/defaultIfUndefined";
import { hasNoMoreThan2DecimalPlaces } from "../../utils/yup-validators/hasNoMoreThan2DecimalPlaces";

export const buildGigFormValidationSchemaFields = (isPaymentsEnabled: boolean) => ({
    title: yup.string().max(128, "Name must be less than 128 characters.").required("Name is required."),
    expectedDurationId: yup.number().required().min(0, "Duration is required.").max(5, "Duration is required."),
    startDate: yup.date()
        .required("Start date is required.")
        .min(todayAtMidnight(), "Start date cannot be in the past.")
        .nullable()
        .typeError("Start date must be a valid date."),
    deadlineDate: yup.date().when("isTimeAndMaterial", {
        is: true,
        then: yup.date()
            .test("deadline-greater-than-start", (value, context) => {
                if (!value) {
                    return context.createError({ message: "Deadline is required." });
                }
            
                if (value <= context.parent.startDate) {
                    return context.createError({ message: "Deadline must be after start." });
                }
            
                return true;
            })
            .typeError("Deadline must be a valid date.")
            .nullable()
            .required("Deadline is required."),
        otherwise: yup.date()
            .test("deadline-greater-than-start", (value, context) => {
                if (!value) return true;
            
                if (value <= context.parent.startDate) {
                    return context.createError({ message: "Deadline must be after start." });
                }
            
                return true;
            })
            .nullable()
            .optional()
            .typeError("Deadline must be a valid date."),
    }),
    description: yup.string().required("Description is required."),
    initialStageId: yup.number().required().min(0, "Current stage is required.").max(2, "Current stage is required."),
    skills: yup
        .array()
        .length(3, "You must select 3 skills")
        .of(
            yup.object().shape({
                id: yup.string(),
                name: yup.string(),
            }),
        ),
    budget: isPaymentsEnabled 
        ? yup.number()
            .typeError("Budget is required.")
            .required("Budget is required.")
            .min(0.01, "Budget must be at least £0.01.")
            .max(1_000_000.01, "Budget cannot be greater than £1,000,000.")
            .test(hasNoMoreThan2DecimalPlaces)
        : yup.number().nullable(),
    isCompletedRemotely: yup.string().required("This field is required."),
    isTimeAndMaterial: yup.boolean(),
    numberOfDays: isPaymentsEnabled
        ? yup.number().when("isTimeAndMaterial", {
            is: true,
            then: yup.number()
                .typeError("Number of days expected. Ensure start and deadline dates are valid.")
                .required("Number of days expected. Ensure start and deadline dates are valid.")
                .min(1,"Must be at least 1 day"),
            otherwise: yup.number().nullable(),
        })
        : yup.number().nullable(),
    dailyRate: isPaymentsEnabled
        ? yup.number().when("isTimeAndMaterial", {
            is: true,
            then: yup.number()
                .typeError("Daily rate is required.")
                .required("Daily rate is required.")
                .min(0.01, "Daily rate must be greater than £0.")
                .max(10_000.01, "Daily rate cannot be greater than £10,000.")
                .test(hasNoMoreThan2DecimalPlaces),
            otherwise: yup.number().nullable(),
        })
        : yup.number().nullable(),    
});

export type GigFormValues = {
    title: string,
    expectedDurationId: number,
    startDate: Date | null,
    deadlineDate?: Date | null,
    description: string,
    initialStageId: number,
    gigStatusId: number,
    budget?: number | null,
    skills: { id: string, name: string }[],
    isComplianceCheckRequired: boolean,
    isGigFilesPublic: boolean,
    isCompletedRemotely: string,
    isTimeAndMaterial?: boolean,
    numberOfDays?: number | null,
    dailyRate?: number | null,
};

const buildValidationSchema = (isPaymentsEnabled: boolean) => 
    yup.object(buildGigFormValidationSchemaFields(isPaymentsEnabled));

export const useGigForm = (
    isPaymentsEnabled: boolean,
    defaultValues?: GigFormValues,    
) => {
    return useForm<GigFormValues>({
        resolver: yupResolver(buildValidationSchema(isPaymentsEnabled)),
        mode: "onChange",
        defaultValues: {
            title: defaultValues?.title || "",
            expectedDurationId: defaultIfUndefined(defaultValues?.expectedDurationId, -1),
            startDate: defaultValues?.startDate || null,
            deadlineDate: defaultValues?.deadlineDate || null,
            description: defaultValues?.description || "",
            initialStageId: defaultIfUndefined(defaultValues?.initialStageId, -1),
            gigStatusId: defaultValues?.gigStatusId || 1,
            skills: defaultValues?.skills || [],
            budget: defaultValues?.budget || null,
            isComplianceCheckRequired: defaultValues?.isComplianceCheckRequired || false,
            isGigFilesPublic: defaultValues?.isGigFilesPublic || false,
            isCompletedRemotely: defaultValues?.isCompletedRemotely ? defaultValues?.isCompletedRemotely : "",
            isTimeAndMaterial: defaultValues?.isTimeAndMaterial || false,
            numberOfDays: defaultValues?.numberOfDays || null,
            dailyRate: defaultValues?.dailyRate || null,
        },
    });
};