import { ApiTalentDashboardDto, ApiTalentEarningDto } from "../api/talentDashboard";
import { TalentDashboardDto, TalentEarningDto } from "../app/talentDashboard";
import { mapFromApiGigSummary } from "./gig";

export const mapFromApiTalentDashboard = (apiDto: ApiTalentDashboardDto): TalentDashboardDto => ({
    ...apiDto,
    ongoingGigs: apiDto.ongoingGigs.map(mapFromApiGigSummary),
    earnings: apiDto.earnings.map(mapFromApiTalentEarning),
});

export const mapFromApiTalentEarning = (apiDto: ApiTalentEarningDto): TalentEarningDto => ({
    ...apiDto,
    paymentDate: new Date(apiDto.paymentDate)
});