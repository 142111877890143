export type DailyRateOption = {
    id: number
    label: string
    minDailyRate?: number
    maxDailyRate?: number
}

export const dailyRateOptions: DailyRateOption[] = [
    {
        id: 0,
        label: "Any",
    },
    {
        id: 1,
        label: "Up to £100",
        maxDailyRate: 100,
    },
    {
        id: 2,
        label: "Up to £250",
        maxDailyRate: 250,
    },
    {
        id: 3,
        label: "Up to £500",
        maxDailyRate: 500,
    },
    {
        id: 4,
        label: "Up to £750",
        maxDailyRate: 750,
    },
    {
        id: 5,
        label: "£750+",
        minDailyRate: 750,
    },
];