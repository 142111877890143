import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { useTalentOngoingGigs } from "../api/talentMyOngoingGigs";
import Card from "../components/Card";
import LinkButton from "../components/LinkButton";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import UserAvatar from "../components/UserAvatar";
import { useAppPaths } from "../Routes";
import { gigStatuses } from "../models/app/gig";
import { useIsLargeScreen } from "../hooks/screenSizes";
import SkeletonList from "../components/SkeletonList";
import GigCardSkeleton from "../components/GigCardSkeleton";

const GigsOngoingTalentPage = () => {
    const appPaths = useAppPaths();
    const { gigs, isLoading } = useTalentOngoingGigs(gigStatuses.hired, "PostedDate");
    const { gigTerminologyPlural } = useOrganizationContext();
    const isLargeScreen = useIsLargeScreen();

    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>Ongoing {gigTerminologyPlural}</Typography>
            {isLoading ? <SkeletonList skeletonCard={<GigCardSkeleton secondCard={isLargeScreen ? true : false} />} /> :
                gigs.length === 0 ? (
                    <Card>
                        <Typography variant="title-large" component="p">None yet...</Typography>
                    </Card>
                ) : (
                    <div className="space-y-8">
                        {gigs.map(gig => (
                            <div key={gig.id} className="md:flex md:items-stretch break-words">
                                <Card className="space-y-6 md:flex md:space-y-0 items-center grow md:mr-4 rounded-b-none md:rounded-b-lg relative">
                                    <div className="space-y-4 mr-auto">
                                        <Typography variant="title-large" component="p" className="max-w-md">{gig.title}</Typography>
                                        <p className="italic line-clamp-3 mr-8">{gig.description}</p>
                                    </div>
                                    <LinkButton variant="tertiary" to={appPaths.gigs.details(gig.id)}>View details</LinkButton>
                                </Card>
                                <Card className="shrink-0 md:w-[230px] flex items-center justify-center rounded-t-none md:rounded-t-lg pt-0 md:pt-6">
                                    <UserAvatar name={gig.giggedClientName || gig.giggedClientCompanyName} />
                                </Card>
                            </div>
                        ))}
                    </div>
                )}
        </PageContent>
    );
};

export default GigsOngoingTalentPage;