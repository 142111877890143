import * as yup from "yup";

export const validationSchemaFields = (isTimeAndMaterial: boolean) => ({
    description: yup.string().transform((value: string) => value.trim()).required("Description is required."),
    amount: yup.number().typeError("Proposed amount is required.").moreThan(0, "Proposed amount must be greater than £0.00").required("Proposed milestone amount is required."),
    numberOfDays: isTimeAndMaterial 
        ? yup.number()
            .required("Number of days is required.")
            .min(1, "Number of days must be at least 1 day.")
            .max(3650, "Number of days can be 10 years at most")
            .typeError("Number of days is required.") 
        : yup.number().nullable(),
});

export type ProposalMilestoneFormValues = {
    description: string
    amount: number
    numberOfDays?: number | null
};