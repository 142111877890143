import pluralizeString from "./utils/pluralizeString";

export const talentProfileEditTooltips = {
    careerHistory: (gigTerminologyPlural: string) => `Add any relevant jobs or projects you've previously worked on. Remember to keep this section updated as you complete more ${gigTerminologyPlural.toLowerCase()}.`,
    tradingName: (giggedClientTerminologyPlural: string) => `Do you have a trading name you work under? Add it here if you do and it will show as your profile name to ${giggedClientTerminologyPlural.toLowerCase()}.`,
    roleTitle: (giggedClientTerminologyPlural: string) => `Add a title to tell our ${giggedClientTerminologyPlural.toLowerCase()} what you do. Keep it short and snappy.`,
    aboutMe: "Use this section to give a brief overview of your skills, experience, and current availability.",
    industrialExperience: (giggedClientTerminologyPlural: string) => `Some of our ${giggedClientTerminologyPlural.toLowerCase()} will be looking for specific industry experience. Add yours here to make sure you stand out from the crowd.`,
    addSkills: "Add as many relevant skills as you like, but you need at least 3 for our super-smart algorithm to work its magic!",
    addSpecialization: (giggedClientTerminologyPlural: string) => `Select one role specialisation that best describes the type of work you do. This helps us match you with ${giggedClientTerminologyPlural.toLowerCase()} who need your unique expertise. Email us at 'hello@gigged.ai' to request an addition to our specialisations list if yours isn't currently listed.`,
    receiveNewGigNotifications: (gigTerminology: string) => `Check this if you want to receive notifications whenever a new ${gigTerminology.toLowerCase()} is posted that matches your skillset.`,
};

export const clientProfileEditTooltips = {
    displayNameItm: (giggedClientTerminology: string) => `This is how this ${giggedClientTerminology.toLowerCase()} profile will appear to other users on the platform.`,
    displayNameOtm: "If you would like to appear to users on the platform as something other than your company name, enter it here. If nothing is entered, company name will be used.",
    companyName: "This is the name your company is known by and will be displayed to other users on the platform.",
    companyTagline: "The tagline/strapline of your company will appear under your company name in your profile. If you don't have one, simply write in a few words what you do or your line of business e.g. Technology Consultancy.",
    companyWebsite: "The website associated with your company.",
    companyNumber: "Your Company Registration Number (CRN) can be found on your certificate of incorporation or any official documentation received from Companies House. In Scotland, your CRN begins with 'SC' followed by 6 numbers. In England & Wales, it's 8 numbers. In NI, 'NI' followed by 6 numbers",
    companyOverview: "Describe your company - who you are and what you do. This will be displayed to users on your company profile page.",
    clientOverview: (giggedClientTerminology: string) => `This should be an overview of what this ${giggedClientTerminology.toLowerCase()} does and their role as part of the overall organisation.`,
    profileImage: (giggedClientTerminology: string) => `Add or update the profile image that represents your ${giggedClientTerminology.toLowerCase()}.`,
    ir35Info: "IR35 is a form of tax legislation in the UK. If you are UK-based, completing this section will allow us to determine your IR35 status.",
    phoneNumber: "Add a contact telephone number e.g. 07789 123 456. For non-UK numbers, make sure to include your country code e.g. +1 804 123 4567."
};

export const clientEditGigTooltips = {
    gigName: (gigTerminology: string) => `Use a catchy title - this will help your ${gigTerminology.toLowerCase()} stand out to the right freelancer.`,
    gigStage: (gigTerminology: string) => `Let us know where you are currently at with your ${gigTerminology.toLowerCase()} - has there been any work done on it previously or are you starting from scratch?`,
    gigDuration: (gigTerminology: string) => `Estimate how long your ${gigTerminology.toLowerCase()} will take.`,
    gigSkills: (gigTerminology: string, talentTerminologyPlural: string) => `Make sure to add the top three skills that are required for this ${gigTerminology.toLowerCase()} - this will ensure our matching algorithm matches you with the best ${talentTerminologyPlural.toLowerCase()}.`,
    gigStartDate: (gigTerminology: string) => `Tell us when you need your ${gigTerminology.toLowerCase()} to begin.`,
    gigDeadline: (gigTerminology: string) => `Add a deadline if you have a set date the ${gigTerminology.toLowerCase()} should be completed.`,
    gigDescription: (gigTerminology: string) => `Provide a detailed description of your ${gigTerminology.toLowerCase()}. We recommend including as much information as possible.`,
    gigFiles: (gigTerminology: string) => `Upload any additional files relevant to the ${gigTerminology.toLowerCase()} you are posting for example a job description`,
    gigBudget: (gigTerminology: string, isTimeAndMaterial: boolean) => !isTimeAndMaterial ? `This is the full ${gigTerminology.toLowerCase()} budget and not a day rate.` : `This is the full ${gigTerminology.toLowerCase()} budget calculated from the number of days and daily rate.`,
    complianceCheck: (talentTerminology: string) => `Ensure ${pluralizeString(talentTerminology.toLowerCase())} have undergone a compliance check through our partners at Amiqus before hiring.`,
    gigFilesPublic: (gigTerminology: string, talentTerminology: string) => `If enabled, all attached ${gigTerminology.toLowerCase()} files will be publicly available for ${pluralizeString(talentTerminology.toLowerCase())} to view/download on your ${gigTerminology.toLowerCase()} detail page.`,
    remoteWork: (gigTerminology: string) => `Select whether or not this ${gigTerminology.toLowerCase()} can be completed remotely.`,
    gigStructure: (gigTerminology: string) => `Select the way in which you would like to structure the budget for your ${gigTerminology.toLowerCase()}.`,
    numberOfDays: (gigTerminology: string) => `Calculation is inclusive of both the start date and the deadline date chosen for your ${gigTerminology.toLowerCase()}.`,
    gigDailyRate: (gigTerminology: string) => `This is the daily rate willing to be paid throughout the term of the ${gigTerminology.toLowerCase()}.`
};

export const talentProposalTooltips = {
    proposedAmount: (gigTerminology: string) => `This is the total amount you propose to charge for the ${gigTerminology.toLowerCase()} and will be displayed to the client on your proposal.`,
    milestones: (gigTerminology: string, giggedClientTerminiology: string) =>  `Turning milestones on will allow you to propose splitting the ${gigTerminology.toLowerCase()} up into smaller, more measurable pieces of work which are paid on completion, provided the ${giggedClientTerminiology.toLowerCase()} is happy with the work.`,
    duration: (gigTerminology: string) => `The number of days you propose it will take you to complete the whole ${gigTerminology.toLowerCase()}.`,
    introduction: (giggedClientTerminology: string) => `Use this space to give a quick overview as to who you are. This doesn't have to be super formal - it's a chance to give the ${giggedClientTerminology.toLowerCase()} an introduction to not only your skills, but also how you like to work, when you're available, and what your favoured method of communication is.`,
    experience: (giggedClientTerminology: string, gigTerminology: string, gigTerminologyPlural: string) => `Use this space to demonstrate why you're the ideal candidate for the ${gigTerminology.toLowerCase()} by highlighting your experience, including any similar ${gigTerminologyPlural.toLowerCase()} you've worked on and relevant industry expertise. If the ${giggedClientTerminology.toLowerCase()} requires particular tech skills, make sure to specify where and when you've used them.`,
    portfolio: "Show off your work - make yourself stand out from the crowd by adding a link to your portfolio if you have one.",
    relevantFiles: (giggedClientTerminology: string) => `Upload any files here that might help make you stand out to the ${giggedClientTerminology.toLowerCase()} e.g. portfolio examples, previous work or anything else relevant to your proposal.`,
    milestoneDescription: (gigTerminology: string) => `Describe this milestone e.g. for a UX ${gigTerminology.toLowerCase()}, this could be 'Compile user research reports, personas and user flows', or for a web development ${gigTerminology.toLowerCase()}, something like 'Create website designs and wireframes'.`,
    milestoneAmount: (giggedClientTerminology: string) => `This is the amount you propose to charge for this particular milestone and will be paid on completion of the milestone, provided the ${giggedClientTerminology.toLowerCase()} is happy with the work.`,
    vatRegistered: (gigTerminology: string) => `If you are VAT registered, VAT will automatically be added to your proposed ${gigTerminology.toLowerCase()} cost.`,
    vatNumber: "This is 9 or 12 numbers, sometimes with 'GB' at the start, like 123456789 or GB123456789.",
    dailyRate: (gigTerminology: string) => `This is the daily rate you propose to charge for the ${gigTerminology.toLowerCase()} and will be displayed to the client on your proposal.`,
    milestoneNumberOfDays: (giggedClientTerminology: string) => `This is the number of days you propose it will take for this particular milestone, provided the ${giggedClientTerminology.toLowerCase()} is happy with the work.`,
};

export const clientMilestoneTooltips = {
    milestones: "To get work started on a milestone, click 'Pay now'. Once you are satisfied with the output, you must mark it as complete to unlock the next milestone."
};

export const clientProposalTooltips = {
    vatRegistered: (gigTerminology: string, talentTerminology: string) => `Please be aware that this ${talentTerminology.toLowerCase()} is VAT registered. This means that you will be charged the VAT which has been added to their proposed ${gigTerminology.toLowerCase()} amount.`,
};