import clsx from "clsx";

export type SkeletonListProps = {
    skeletonCard: JSX.Element
    className?: string
}

export const SkeletonList = ({
    skeletonCard,
    className
}: SkeletonListProps) => {
    return (
        <div className={clsx(className, "flex flex-col space-y-8")}>
            {skeletonCard}
            {skeletonCard}
            {skeletonCard}
            {skeletonCard}
        </div>
    );
};

export default SkeletonList;