import { useState } from "react";
import Button from "../../components/Button";
import DialogBox, { useDialogBoxState } from "../../components/DialogBox";
import { useCompleteMilestone } from "../../api/proposal";
import Typography from "../../components/Typography";

export type CompleteMilestoneButtonProps = {
    milestoneId: string
    className?: string
    disabled?: boolean
    reloadGig: () => void
    isLastMilestone: boolean
}
const CompleteMilestoneButton = ({
    milestoneId,
    className,
    disabled,
    reloadGig,
    isLastMilestone,
}: CompleteMilestoneButtonProps) => {
    const [completeMilestone] = useCompleteMilestone();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dialogState = useDialogBoxState();

    const handleConfirm = async () => {
        setIsSubmitting(true);

        const response = await completeMilestone(milestoneId);

        setIsSubmitting(false);

        if (response.success) {
            reloadGig();
            dialogState.close();
            // If the response was successful and this was the last milestone then emit an event
            if (isLastMilestone){
                window.dispatchEvent(new CustomEvent("lastMilestoneCompleted"));
            }
        }
    };

    return (
        <span className={className}>
            <Button
                onClick={dialogState.onOpen}
                disabled={disabled}
                variant="secondary"
            >
                Complete now
            </Button>

            <DialogBox
                {...dialogState}
                title={"Complete Milestone"}
            >
                <Typography variant="body" component="p">Are you sure you want to mark this milestone as complete?</Typography>
                <div className="mt-4 flex flex-col sm:flex-row-reverse space-y-4 sm:space-y-0">
                    <Button type="button" className="w-full sm:w-28" onClick={handleConfirm} loading={isSubmitting}>Yes</Button>
                    <Button type="button" className="w-full sm:w-28 mr-auto" variant="secondary" onClick={dialogState.close} loading={isSubmitting}>No</Button>
                </div>
            </DialogBox>
        </span>
    );
};

export default CompleteMilestoneButton;