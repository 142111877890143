import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { isValidOptionalUrl } from "../../utils/yup-validators/isValidOptionalUrl";

export type ClientPersonalDetailsFormValues = {
    name?: string | null
    aboutMe?: string
    isTurnoverChecked: boolean
    isBalanceSheetChecked: boolean
    isEmployeesChecked: boolean    
    companyName?: string | null
    companyTagline?: string | null
    companyWebsite?: string | null
    companyNumber?: string | null
    locationId?: string | null
    phoneNumber?: string | null
}

const buildClientPersonalDetailsFormValidationSchema = (
    giggedClientTerminology: string,
    isPaymentsEnabled: boolean,
    hideGiggedClientCompanyInfo: boolean
): yup.SchemaOf<ClientPersonalDetailsFormValues> => {
    return yup.object({
        name: !hideGiggedClientCompanyInfo ?
            yup.string().nullable() :
            yup.string()
                .required("Name is required.")
                .max(150, "Name cannot be longer than 150 characters."),
        aboutMe: yup.string().typeError("Overview is required.").required("Overview is required."),
        isTurnoverChecked: yup.boolean().default(false),
        isEmployeesChecked: yup.boolean().default(false),
        isBalanceSheetChecked: yup.boolean().default(false),
        phoneNumber: yup.string().nullable(),

        companyName: isPaymentsEnabled 
            ? yup.string()
                .required("Company name is required.")
                .typeError("Company name is required.")
                .max(100, "Company name cannot be longer than 100 characters.") 
            : yup.string().nullable(),
        companyTagline: isPaymentsEnabled
            ? yup.string().nullable().max(60, "Company tagline cannot be longer than 60 characters.")
            : yup.string().nullable(),
        companyWebsite: isPaymentsEnabled
            ? yup.string().nullable().test(isValidOptionalUrl)
            : yup.string().nullable(),
        companyNumber: isPaymentsEnabled
            ? yup.string().nullable().max(8, "Company number cannot be longer than 8 characters.")
            : yup.string().nullable(),
        locationId: isPaymentsEnabled
            ? yup.string().required("Location is required")
            : yup.string().nullable(),
    });
} ;

export const useClientPersonalDetailsForm = (
    giggedClientTerminology: string,
    isPaymentsEnabled: boolean,
    hideGiggedClientCompanyInfo: boolean,
    defaultValues?: ClientPersonalDetailsFormValues
) => useForm<ClientPersonalDetailsFormValues>({
    resolver: yupResolver(buildClientPersonalDetailsFormValidationSchema(giggedClientTerminology, isPaymentsEnabled, hideGiggedClientCompanyInfo)),
    defaultValues: {
        ...defaultValues,
        name: defaultValues?.name ?? "",
        aboutMe: defaultValues?.aboutMe ?? "",
        companyName: defaultValues?.companyName ?? "",
        companyTagline: defaultValues?.companyTagline ?? "",
        companyNumber: defaultValues?.companyNumber ?? "",
        companyWebsite: defaultValues?.companyWebsite ?? "",
        phoneNumber: defaultValues?.phoneNumber ?? ""
    }
});