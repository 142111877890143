import { Plus } from "phosphor-react";
import { useProposal } from "../../api/proposal";
import { useCreateProposalMilestone, useDeleteProposalMilestone, useUpdateProposalMilestone } from "../../api/proposal-milestone";
import { ApiEditableProposalMilestoneFields, ApiProposalMilestone } from "../../models/api/proposal";
import { successToast, errorToast } from "../../toast";
import Button from "../Button";
import DialogBox, { useDialogBoxState } from "../DialogBox";
import ProposalMilestoneCard from "./ProposalMilestoneCard";
import { ProposalMilestoneFormDialog } from "./ProposalMilestoneFormDialog";
import { ProposalMilestoneFormValues } from "./ProposalMilestoneFormValues";
import { useEffect } from "react";

type ProposalMilestonesProps = {
    milestones: ApiProposalMilestone[]
    proposalId: string
    isTimeAndMaterial: boolean
    dailyRate: number | null
    handleMilestoneUpdate: (milestones: ApiProposalMilestone[]) => void
}

const ProposalMilestones: React.FC<ProposalMilestonesProps> = ({
    milestones,
    proposalId,
    isTimeAndMaterial,
    dailyRate,
    handleMilestoneUpdate
}) => {
    const dialogState = useDialogBoxState();
    const { refetchProposal, isValidating } = useProposal(proposalId);
    const [ createProposalMilestone ] = useCreateProposalMilestone();
    const [ updateProposalMilestone ] = useUpdateProposalMilestone();
    const [ deleteProposalMilestone, deletingProposalMilestone ] = useDeleteProposalMilestone();

    const handleDeleteProposalMilestone = async (milestoneId: string) => {
        const response = await deleteProposalMilestone({ milestoneId });

        if(response.success) {
            refetchProposal();
            successToast("Proposal milestone successfully removed.");
        }

        if (!response.success) errorToast(`${response.error.userMessages}`);
    };

    const handleUpdateProposalMilestone = (id: string) => async (milestone: ProposalMilestoneFormValues) => {
        const response = await updateProposalMilestone({
            milestoneId: id,
            dto: {
                id,
                isTimeAndMaterial,
                dailyRate,
                ...milestone
            }
        });

        if(response.success) {
            refetchProposal();
            successToast("Proposal milestone successfully updated.");
        }

        if (!response.success) errorToast(`${response.error.userMessages}`);

        return response;
    };

    const handleCreateProposalMilestone = async (value: ApiEditableProposalMilestoneFields) => {
        const response = await createProposalMilestone({ proposalId, dto: value });

        if(response.success) {
            refetchProposal();
        }

        if (!response.success) errorToast(`${response.error.userMessages}`);

        return response;
    };

    // Invoke handleMilestoneUpdate after any operation that changes T&M milestones
    useEffect(() => {
        if(!isTimeAndMaterial) return;

        handleMilestoneUpdate(milestones);
    }, [milestones, handleMilestoneUpdate]);

    return (
        <>
            {isValidating && <p>Loading milestones...</p>}
            {!isValidating && milestones.map(proposalMilestone =>
                <ProposalMilestoneCard
                    key={proposalMilestone.id}
                    milestone={proposalMilestone} 
                    disabledDelete={deletingProposalMilestone || milestones.length === 1}
                    onRemove={handleDeleteProposalMilestone} 
                    onEdit={handleUpdateProposalMilestone(proposalMilestone.id)} 
                    isTimeAndMaterial={isTimeAndMaterial}
                    dailyRate={dailyRate}
                />    
            )}
            <div className="flex justify-center border-2 border-dashed rounded-lg p-4">
                <Button variant="secondary" onClick={dialogState.open} className="flex items-center" type="button">
                    <Plus className="mr-2" /> Add milestone
                </Button>
            </div>
            <DialogBox {...dialogState} title="Edit Proposal Milestone">
                <ProposalMilestoneFormDialog
                    {...dialogState}
                    resetOnSubmit
                    onSubmit={handleCreateProposalMilestone}
                    isTimeAndMaterial={isTimeAndMaterial}
                    dailyRate={dailyRate}
                    initialValues={{
                        description: "",
                        amount: 0,
                        numberOfDays: isTimeAndMaterial ? 0 : null
                    }}
                />
            </DialogBox>
        </>
    );
};

export default ProposalMilestones;