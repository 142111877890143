import { useProposals } from "../api/proposals";
import Card from "../components/Card";
import GigCardSkeleton from "../components/GigCardSkeleton";
import PageContent from "../components/PageContent";
import ProposalsSummaryCard from "../components/ProposalSummaryCard";
import SkeletonList from "../components/SkeletonList";
import Typography from "../components/Typography";
import { useIsLargeScreen } from "../hooks/screenSizes";

const ProposalsListPage = () => {
    const { proposals, isLoading } = useProposals();
    const isLargeScreen = useIsLargeScreen();

    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>Proposals</Typography>
            {isLoading ? <SkeletonList skeletonCard={<GigCardSkeleton />} /> :
                proposals.length === 0 ? (
                    <Card>
                        <Typography variant="title-large" component="p">None yet...</Typography>
                    </Card>
                ) : (
                    <div className="space-y-8">
                        {proposals.map(proposal => (
                            <ProposalsSummaryCard key={proposal.id} proposal={proposal} />
                        ))}
                    </div>
                )
            }
        </PageContent>
    );
};

export default ProposalsListPage;