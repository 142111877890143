import PageContent from "../components/PageContent";
import Typography from "../components/Typography";

export const FourOThreePage = () => {
    return (
        <PageContent>
            <Typography variant="display-large" component="h1" gutterBottom className="text-center text-primary font-bold md:text-[12rem] mt-8">403</Typography>
            <Typography variant="title-large" component="p" gutterBottom className="text-center md:text-title-large text-title-small">You're not authorized to view the requested resource.</Typography>
        </PageContent>
    );
};
