import clsx from "clsx";
import { FacebookLogo, GithubLogo, LinkedinLogo, XLogo } from "@phosphor-icons/react";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import Button from "../../components/Button";
import Card from "../../components/Card";
import FilePreview from "../../components/FilePreview";
import Typography from "../../components/Typography";
import { ApiClientProfileDto } from "../../models/api/clientProfile";
import { getClickableLink } from "../../utils/getClickableLink";

export type ClientProfileDetailsCardProps = {
    clientProfile: ApiClientProfileDto
    className?: string
}

const ClientProfileDetailsCard = ({
    clientProfile,
    className,
}: ClientProfileDetailsCardProps) => {
    const { organizationConfig } = useOrganizationContext();

    const handleShareOnLinkedIn = () => {
        const pageToShareUrl = encodeURIComponent(window.location.href);
        const shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${pageToShareUrl}&title=${clientProfile.name || clientProfile.companyName}`;

        window.open(shareUrl, "_blank")?.focus();
    };

    return (
        <Card className={clsx("space-y-8 max-w-full md:max-w-[25%]", className)}>
            {organizationConfig.isPaymentsEnabled && (
                <div>
                    <Typography variant="title-large" component="h2" underline className="mb-6">Website</Typography>
                    {!clientProfile.companyWebsite ? (
                        <p>None added yet.</p>
                    ) : (
                        <div className="flex flex-row gap-6">
                            <a 
                                href={getClickableLink(clientProfile.companyWebsite)} 
                                target="_blank"
                                className="underline font-bold truncate"
                            >
                                {clientProfile.companyWebsite}
                            </a>
                        </div>
                    )}
                </div>
            )}

            <div>
                <Typography variant="title-large" component="h2" underline className="mb-6">Socials</Typography>
                {!clientProfile.addedExtras.gitHubUrl && 
                    !clientProfile.addedExtras.facebookUrl && 
                    !clientProfile.addedExtras.linkedInUrl && 
                    !clientProfile.addedExtras.twitterUrl ? (
                        <p>None added yet.</p>
                    ) : (
                        <div className="flex flex-row gap-6">
                            {clientProfile.addedExtras.gitHubUrl && <a target="_blank" href={getClickableLink(clientProfile.addedExtras.gitHubUrl)}><GithubLogo size={32} className="text-primary-text" weight="fill" /></a>}
                            {clientProfile.addedExtras.facebookUrl && <a target="_blank" href={getClickableLink(clientProfile.addedExtras.facebookUrl)}><FacebookLogo size={32} className="text-primary-text" weight="fill" /></a>}
                            {clientProfile.addedExtras.linkedInUrl && <a target="_blank" href={getClickableLink(clientProfile.addedExtras.linkedInUrl)}><LinkedinLogo size={32} className="text-primary-text" weight="fill" /></a>}
                            {clientProfile.addedExtras.twitterUrl && <a target="_blank" href={getClickableLink(clientProfile.addedExtras.twitterUrl)}><XLogo size={32} className="text-primary-text" weight="fill" /></a>}
                        </div>
                    )}
            </div>
            <div>
                <Typography variant="title-large" component="h2" underline className="mb-6">Brochures</Typography>
                {clientProfile.brochures.length === 0 ? (<p>None added yet.</p>) : (
                    <div className="flex flex-col gap-2">
                        {clientProfile.brochures.map(brochure => (
                            <FilePreview
                                url={brochure.file.url}
                                name={brochure.file.name}
                                totalBytes={brochure.file.totalBytes}
                            />
                        ))}
                    </div>
                )}
            </div>
            <div>
                <Typography variant="title-large" component="h2" underline className="mb-6">Share</Typography>
                <Button variant="secondary" className="w-full flex items-center gap-2 justify-center" onClick={handleShareOnLinkedIn}>
                    <LinkedinLogo width={20} height={20} weight="fill" />
                    Share on LinkedIn
                </Button>
            </div>
        </Card>
    );
};

export default ClientProfileDetailsCard;
