import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import Button from "../Button";
import { formClassNames } from "../CreateGigForm/classNames";
import TalentCreateProfileFormPageLayout from "./TalentCreateProfileFormPageLayout";
import { fetchSpecializationOptions } from "../../api/specializations";
import { talentProfileEditTooltips } from "../../tooltipsContent";
import ErrorList from "../ErrorList";
import useAccessToken from "../../auth/useAccessToken";
import { useTalentSkills } from "../../api/talentSkills";
import FormDropdownGroup, { FormDropdownGroupOption } from "../FormDropdownGroup";

export type CreateTalentProfileStepSpecializationProps = {
    onBackClick: () => void
    onSubmit: () => void
}

export const validationSchema = yup.object({
    specializationId: yup.string().required("Specialisation is required."),
});

export type TalentSpecializationFormValues = {
    specializationId: string;
};

const CreateTalentProfileStepSpecialization = ({
    onBackClick,
    onSubmit,
}: CreateTalentProfileStepSpecializationProps) => {
    const { giggedClientTerminologyPlural } = useOrganizationContext();
    const accessToken = useAccessToken();
    const [allSpecializationOptions, setAllSpecializationOptions] = useState<FormDropdownGroupOption<string>[]>([]);
    const [isLoadingOptions, setIsLoadingOptions] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const {
        updateTalentSpecialization,
        isUpdatingTalentSpecialization,
        specialization,
        isLoading
    } = useTalentSkills();

    const methods = useForm<TalentSpecializationFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            specializationId: specialization?.id
        }
    });

    useEffect(() => {
        if (!accessToken) return;

        const loadAllSpecializations = async () => {
            setIsLoadingOptions(true);
            try {
                const fetchedSpecializationOptions = await fetchSpecializationOptions("", accessToken);
                setAllSpecializationOptions(fetchedSpecializationOptions);
            }
            finally {
                setIsLoadingOptions(false);
            }
        };

        loadAllSpecializations();
    }, [accessToken]);

    const handleSubmit = async (value: TalentSpecializationFormValues) => {
        setErrors([]);

        const response = await updateTalentSpecialization(value);

        if (response.success) onSubmit();
        else setErrors(response.error.userMessages);

        return response;
    };

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(handleSubmit)}
            noValidate
        >
            <TalentCreateProfileFormPageLayout
                title="Select your specialisation"
                description={`Choose a specialisation that best reflects your primary focus and expertise. This helps us connect you with ${giggedClientTerminologyPlural.toLowerCase()} that need your unique skills.`}
                content={
                    <>
                        <div className="flex gap-6 flex-wrap w-full md:flex-nowrap">
                            <div className="w-full">
                                <Controller
                                    name="specializationId"
                                    control={methods.control}
                                    render={({ field: { onChange, value } }) => (
                                        <FormDropdownGroup
                                            required
                                            tooltip={talentProfileEditTooltips.addSpecialization(giggedClientTerminologyPlural)}
                                            label="Select specialisation"
                                            value={value}
                                            options={allSpecializationOptions}
                                            disabled={isUpdatingTalentSpecialization}
                                            onChange={onChange}
                                            error={methods.formState.errors.specializationId}
                                            isLoadingOptions={isLoadingOptions}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <ErrorList errors={errors} />
                    </>
                }
                iconName="Target"
                buttons={
                    <>
                        <Button variant="secondary" type="button" onClick={onBackClick}>Back</Button>
                        <Button type="submit" loading={isUpdatingTalentSpecialization}>Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateTalentProfileStepSpecialization;