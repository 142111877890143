import { ApiTalentDetails, ApiTalentReview } from "../api/talent";
import { TalentDetails, TalentReview } from "../app/talent";
import { mapFromApiTalentCareerHistory } from "./talentCareerHistory";
import { mapFromApiTalentCertificationDto } from "./talentCertification";
import { mapFromApiTalentEducationCourse } from "./talentEducation";

export const mapFromApiTalentDetails = (apiDto: ApiTalentDetails): TalentDetails => ({
    ...apiDto,
    fullName: `${apiDto.firstName} ${apiDto.lastName}`,
    careerHistories: apiDto.careerHistories.map(mapFromApiTalentCareerHistory),
    educations: apiDto.educations.map(mapFromApiTalentEducationCourse),
    certifications: apiDto.certifications.map(mapFromApiTalentCertificationDto),
    agency: apiDto.agency
});

export const mapFromApiTalentReview = (apiDto: ApiTalentReview): TalentReview => ({
    ...apiDto,
    gigCompletedDate: new Date(apiDto.gigCompletedDate),
});