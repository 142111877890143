import { GigApiFetcherResponse } from "../../api/common/fetching";

import DialogBox, { useDialogBoxState } from "../../components/DialogBox";
import { TalentEducationCourseDto } from "../../models/app/talentEducation";
import { EducationFormDialog } from "./EducationFormDialog";
import { EducationFormValues } from "./EducationFormValues";
import HistoryListItem from "./HistoryListItem";

export type EducationCardProps = {
    course: TalentEducationCourseDto
    onRemove: () => Promise<unknown>
    updateJob: (values: EducationFormValues) => Promise<GigApiFetcherResponse<unknown>>
}
export const EducationCard = ({
    course,
    onRemove,
    updateJob: updateCareerHistory,
}: EducationCardProps) => {
    const dialogState = useDialogBoxState();

    const initialValues: EducationFormValues = {
        ...course,
        endDate: course.endDate || null,
    };

    return (
        <>
            <HistoryListItem
                title={course.courseTitle}
                startDate={course.startDate}
                endDate={course.endDate || "Current"}
                onRemove={onRemove}
                onEdit={dialogState.open}
            />
            <DialogBox {...dialogState} title="Edit Course">
                <EducationFormDialog
                    {...dialogState}
                    isEdit
                    onSubmit={updateCareerHistory}
                    initialValues={initialValues}
                />
            </DialogBox>
        </>
    );
};

export default EducationCard;